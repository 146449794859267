<div class="w-full xl:container xl:mx-auto cont">
    <ngx-slick-carousel class="carousel w-full" #slickModal="slick-carousel" [config]="slideConfig"
        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">

        <div class="slide w-full" ngxSlickItem>
            <div class="h-44 sm:h-80 lg:h-96 flex bg-gray-100">
                <div class="flex items-center">
                    <div class="ml-14">
                        <div class="text-xl lg:text-6xl font-extrabold">
                            <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-blue-500">
                                Centralizamos todas tus necesidades!
                            </span>
                        </div>
                        <br>
                        <br>
                        <button type="button" [routerLink]="['/servicios']" class="btn-request text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">solicitar un servicio
                            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>
                    </div>
                </div>
                <img class="clip-path-1 cover object-center" src="assets/imagen/general/shutterstock_357915077.jpg"
                    alt="">
            </div>
        </div>

        <div class="slide w-full" ngxSlickItem>
            <div class="h-44 sm:h-80 lg:h-96  flex bg-aply-100">
                <img class="clip-path-2 cover object-center w-1/2"
                    src="assets/imagen/general/shutterstock_142553932.jpg" alt="">
                <div class="flex items-center">
                    <div class="lg:ml-14">
                        <div class="text-xl lg:text-6xl font-extrabold">
                            <span class="bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
                                ¿Te desempañas en una labor?
                            </span>
                        </div>
                        <div class="text-xl font-extrabold">
                            <span
                                class="hidden lg:flex bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
                                D3una.com te ayuda a prestar tu servicios con un click
                            </span>
                        </div>
                        <button (click)="irCrearServicio()"
                            class="btn-1 focus:ring text-aply-100  hover:bg-white active:bg-white focus:ring-dark-100 bg-dark-100 mt-5 mr-2">
                            Ofrecer servicios
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="slide" ngxSlickItem>
            <div class="h-vh-60 flex">
                <div class="flex items-center">
                    <div class="ml-14">
                        <div class="text-6xl font-extrabold">
                            <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-blue-500">
                                Centralizamos todas tus necesidades!
                            </span>
                        </div>
                        <button [routerLink]="['/servicios']" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100 mt-5">
                            solicitar servicio
                        </button>
                    </div>
                </div>
                <img class="cover object-center" src="assets/imagen/general/shutterstock_1022965273.png" alt="">
            </div>
        </div> -->

    </ngx-slick-carousel>
</div>

<!-- <div class="text-5xl font-extrabold">
    <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
      Hello world
    </span>
</div> -->