<!-- canecera de mensajes -->
<div (window:resize)="onResize($event)"  class="fixed w-full h-auto z-10">
  <div  class="flex w-full h-auto shadow-md border-md bg-white">
    <div [ngClass]="{'contacto': user==miembro._id }" class="flex flex-row py-2 px-2 items-center" *ngFor="let miembro of miembros">
      <a [routerLink]="[ '/chat/contacto' ]"><div  >
        <svg  xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mr-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      </div>
    </a> 
    
      <div class=" relative w-1/4">
        <img [routerLink]="['/perfil', miembro._id]"  *ngIf="miembro.imagen"
          [src]="miembro.imagen.thumbnail"
          class="object-cover h-10 w-10 rounded-full"
          alt=""/>
          <img [routerLink]="['/perfil', miembro._id]"  *ngIf="!miembro.imagen"
          src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-12 w-12 rounded-full"
            alt=""/>
          <a *ngIf="miembro._id | onLineUsers:onlineUsers"  class="absolute left-8 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
            <div class="w-2 h-2"></div>
            </a>
      </div>
      <div class="w-full pl-3" >
        <div [routerLink]="['/perfil', miembro._id]"  class="text-lg font-semibold">{{miembro.name}}</div>
        <span class="text-gray-500 font-semibold">Prestador de servicio</span>
      </div>
      
     </div>
     <div (click)="ubicacion()" class="absolute top-3 right-3  w-10 h-10  flex justify-center items-center bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
      <i class='bx bx-current-location text-3xl text-blue-500 text-center'></i>
    </div>
  </div>

  <div class="w-full h-16  grid grid-cols-4 ">
    <div (click)="select = data.num"  *ngFor="let data of data_carrucel" class="w-full">
      <div routerLinkActive="active"  [routerLink]="[ data.ruta, id, id_user]" 
      class="w-full h-full bg-gray-50 flex justify-center items-center border border-gray-100 ">
        <i class='{{data.icon}} text-3xl text-blue-500 mr-2'>  </i>
      </div>
    </div>
  </div>


</div>

<!-- foi canecera de mensajes -->