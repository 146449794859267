import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'crear_menu',
  templateUrl: './crear_menu.component.html',
  styleUrls: ['./crear_menu.component.css']
})
export class Crear_menuComponent implements OnInit {

  public log: boolean;
  public id_user: string;
  public verificacion: boolean = false;

  constructor(
    private router: Router,
    private perfilService: PerfilService,
    private usuarioService: UsuarioService,
  ) {
    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;
        if (this.log) {
          this.id_user = this.usuarioService.usuarios._ids
          this.perfilService.verificacionPerfil(this.id_user).subscribe(
            resp => { this.verificacion = resp.data; }
          )
        }
      }
    )
  }

  ngOnInit() {
    console.log(this.verificacion); 
  }

  irCrearServicio() {
    if (this.log) {
      this.router.navigate( ['/crear/servicio'] );
    } else {
      this.router.navigateByUrl('/login');      
    }
  }

  irCrearNecesidad() {
    if (this.log) {
      this.router.navigate( ['/crear/necesidades'] );
    } else {
      this.router.navigateByUrl('/login');      
    }
  }
  
}