import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  
  @Output() salida: EventEmitter<boolean> = new EventEmitter();
  
  public user: any;
  public log: boolean;
  public id_user: string;

  constructor(
    private usuario: UsuarioService,
    private router: Router,
    private readonly _authService: SocialAuthService
  ) { 
    this.usuario.validarToken().subscribe(
      resp => {
        this.log = resp;
        if (this.log) {
          this.id_user = this.usuario.usuarios._ids;
        }
      }
    )
  }

  ngOnInit() {
    this.i();
  }

  i(){
    this.usuario.user().subscribe(
      resp => { this.user = resp.data }
    )
    //emitir un evento del nuevo usuario con su id
    //this.socket.emit('new:user', this.user );
  }

  salir(){
    Swal.fire({
      title: "Saliendo de D3una!",
      text: 'Esta seguro de salir del sistema?',
      width: '330px',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Aceptar`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Cancelar`,
      denyButtonColor: '#F62326',
    }).then((result) => {
      if (result.isConfirmed) {
        this.usuario.logout();
        this._authService.signOut();
        this.salida.emit(false);
        this.router.navigateByUrl('/');
        //window.location.href= this.link;
      }else if(result.isDenied){
        result.isDenied
      }
    })
  }

  doSomething($event:any){
    $event.stopPropagation();
    //Another instructions
}

}
