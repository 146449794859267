import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'forms-ubicacion',
  templateUrl: './ubicacion.component.html',
  styleUrls: ['./ubicacion.component.css']
})
export class UbicacionComponent implements OnInit {

  public selectedDept: deptI = { _id: "", name: "" };
  public town_id:string;
  public deptItems;
  public muniItems: ciudadI;
  public muni: string;

  public id: string;
  public id_perfil: string;
  public formSubmitted = false;
  public perfilForm = this.fb.group(
    {
      town : ['',  [ Validators.required ]],  
    }
  );

  constructor(
    private ubicacionService: UbicacionService,
    private perfilService: PerfilService,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.listadoDept();
    this.perfilSeleccionado();
  }

      // listadoDepartamento
  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => {

        console.log("dept", resp)
        this.deptItems = resp.data;
      }
    )
  }
 
  onSelect(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => {

        console.log("munis", resp)
        this.muniItems = resp.data.municipalities;
      }
    )
  }
  
  campoNoValido(campo: string): boolean {
    if (this.perfilForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }
  
  updateUbicacion() {
    this.formSubmitted = true;
  
    if (this.perfilForm.invalid) {
      return;
    }
      
    this.perfilService.updateUbicacion(this.perfilForm.value, this.id_perfil).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg }) 
        this.perfilSeleccionado();
      }
    )
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => {
        this.id_perfil = resp.data[0]._id;
        this.muni = resp.data[0].town.name;
        this.searchDept(resp.data[0].town.code_dpto)
        this.town_id = resp.data[0].town._id;
      }
    )
  }


  searchDept(code){

    const dept =  this.deptItems.filter((it : {code:number;}) => it.code == code )
    this.selectedDept._id = dept[0]._id
    this.onSelect(dept[0]._id)

  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
}
