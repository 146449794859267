
<div *ngIf="aplicaciones == ''" class="mt-12 h-full">
  <div class="w-full h-auto px-12 flex justify-center  lg:pb-16">
    <img class="w-3/5 lg:w-3/5" src="assets/imagen/general/no-aplicar.png" alt="">
  </div>
  <div class="w-full h-auto flex justify-center pt-8 lg:pt-2">
    <p class="text-2xl font-semibold text-gray-700">¡Aun no aplican!</p>
  </div>
  <div class="w-full h-auto flex justify-center px-2">
    <p class="text-xl font-medium text-gray-700 text-center">Aparecerán solicitudes de prestadores de servicio en tus necesidades</p>
  </div>
  <div class="w-full h-auto flex justify-center pt-8">
    <div class="w-auto px-4 mb-24 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-gray-500/50">
      <p [routerLink]="['/']" class="text-lg text-white font-semibold pb-1 cursor-pointer">Pagina principal</p>
    </div>
  </div>
</div>

<div *ngFor="let aplicacion of aplicaciones" class="block w-full h-auto  border-b py-2 px-2 lg:px-4">
  <div class="flex w-full">
    <div [routerLink]="['/perfil', aplicacion.sender._id]" class="w-24 pr-2 lg:mr-4 py-2 cursor-pointer flex relative items-center justify-center"> 
      <img *ngIf="aplicacion.sender.imagen"  class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="aplicacion.sender.imagen.thumbnail" alt=""> 
      <img *ngIf="!aplicacion.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
    </div>
    <div class="w-full content-start flex relative items-center " >
      <div>
        <p [routerLink]="['/perfil', aplicacion.sender._id]" class="text-base font-semibold text-gray-700 cursor-pointer">{{aplicacion.sender.name}}</p>
        <span class="text-sm text-gray-500 font-semibold -mt-1">Esta aplicando a tu necesidad</span>
        <span class="text-sm text-gray-500 font-semibold -mt-1"> {{aplicacion.necessity.tittle}}</span>
        <span class="text-sm text-gray-500 font-semibold -mt-1"> {{aplicacion.necessity.description}}</span>
      </div>
    </div>
    <div class="w-32 pr-2 lg:ml-4 py-2">
      <div class="flex justify-end mb-6">
        <p class="text-xs">{{ aplicacion.createdAt | date:'mediumDate'}}</p>
      </div>
      <div class="flex justify-end cursor-pointer">
        <div [matMenuTriggerFor]="menu" class="w-auto hover:bg-gray-100 rounded-full inline-block align-middle">
          <i class='bx bx-dots-vertical-rounded text-3xl mx-1 mt-1'></i>
        </div>

        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>Eliminar solicitud</span>
          </button>
        </mat-menu>


      </div>
     </div>
  </div>
  <div *ngIf="aplicacion.status == 'Espera'" class="flex w-full h-10 mb-4 pl-14">
    <div  class="w-80 flex">
      <div (click)="veredicto(aplicacion._id, 'Aceptar')" class="w-36 h-8 mr-2 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-gray-300 cursor-pointer">
        <p class="text-lg text-white font-semibold pb-1">Aceptar</p>
      </div>
      <div (click)="veredicto(aplicacion._id, 'Negado')" class="w-36 h-8 bg-gray-400 rounded-full flex justify-center items-center shadow-lg shadow-gray-300 cursor-pointer">
        <p class="text-lg text-white font-semibold pb-1">Cancelar</p>
      </div>
    </div>
  </div>

</div>
