<div class="h-20 w-full">

</div>
<div class="w-full h-screen lg:h-full lg:px-24 lg:pt-4 bg-white">
  <div class="lg:grid h-full lg:grid-rows-3 lg:grid-flow-col lg:gap-4">
    <!--menu para web notificicaiones-->
    <div  class="h-screen lg:h-full row-span-3 hidden lg:block lg:w-72  ">
      <div class="w-full border py-4 rounded-lg bg-white">
        <div class="flex w-full h-auto px-2 py-2">
          <div class="w-1/5 pr-2 lg:mr-4 py-2 cursor-pointer">
            <img [routerLink]="['/perfil', user?._id]" *ngIf="user?.imagen"  class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="user.imagen.thumbnail" alt=""> 
            <img [routerLink]="['/perfil', user?._id]" *ngIf="!user?.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
         </div>
          <div class="w-4/5 " >
            <p [routerLink]="['/perfil', user?._id]" class="text-base font-semibold text-gray-700 cursor-pointer">{{user?.name}}</p>
            <p class="text-sm text-gray-500 font-semibold">Prestador de servicio</p>
            
          </div>
        </div>
        <div class="flex w-full px-2 mt-3">
          <div [ngClass]="{'select': btn_n}" title="Notificaciones" (click)="btn_notificacion()" class="relative flex mr-2 justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md focus:outline-none focus:ring focus:ring-blue-300 cursor-pointer">
            <i class='bx bxs-bell text-4xl text-blue-500'></i>
            <div *ngIf="n_notificacion > 0" class="w-6 h-6 bg-red-500 rounded-full absolute -top-2 -right-2 flex justify-center">
              <p class="text-base text-white font-semibold">{{n_notificacion}}</p>
            </div>
          </div>
          <div [ngClass]="{'select': btn_s}" title="Solicitudes" (click)="btn_solicitud()" class="relative flex mr-2 justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md cursor-pointer">
            <i class="icon-mano-ok text-aply-100 text-4xl  mr-1"> </i>
            <div *ngIf="n_solicitud?.length > 0"  class="w-6 h-6 bg-red-500 rounded-full absolute -top-2 -right-2 flex justify-center">
              <p class="text-base text-white font-semibold">{{n_solicitud?.length}}</p>
            </div>
          </div>
          <div [ngClass]="{'select': btn_a}" title="Aplicaciones" (click)="btn_aplicar()" class="relative flex justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md cursor-pointer">
            <span class="icon-mano-solicitar text-aply-100 text-4xl mr-1"></span>
            <div *ngIf="n_aplicacion?.length > 0" class="w-6 h-6 bg-red-500 rounded-full absolute -top-2 -right-2 flex justify-center">
              <p class="text-base text-white font-semibold">{{n_aplicacion?.length}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--menu termina para web notificicaiones-->
    <!-- aqui va el menu de notificcaiones-->
    <div  class=" lg:relative w-full h-20  col-span-2 lg:hidden bg-white">
      <div class="flex flex-row py-2 px-2 items-center shadow-md border-md">
        <div  id="atras" class="flex justify-center w-full py-3 rounded-lg hover:bg-gray-50 hover:shadow-md focus:outline-none focus:ring focus:ring-blue-300">
          <a ><div  >
            <svg  xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mr-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </a>
        </div>
        <div  class="w-full pr-2 lg:mr-4 py-2 cursor-pointer">
          <img [routerLink]="['/perfil', user?._id]" *ngIf="user?.imagen"  class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="user.imagen.thumbnail" alt=""> 
          <img [routerLink]="['/perfil', user?._id]" *ngIf="!user?.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
         </div>
        <div [ngClass]="{'select': btn_n}"  (click)="btn_notificacion()" class="relative flex mr-2 justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md focus:outline-none focus:ring focus:ring-blue-300">
          <i class='bx bxs-bell text-4xl text-blue-500'></i>
          <div *ngIf="n_notificacion > 0" class="w-6 h-6 bg-red-500 rounded-full absolute -top-1 -right-1 flex justify-center">
            <p class="text-base text-white font-semibold">{{n_notificacion}}</p>
          </div>
        </div>
        <div [ngClass]="{'select': btn_s}"  (click)="btn_solicitud()" class="relative flex mr-2 justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md">
          <i class="icon-mano-ok text-aply-100 text-4xl  mr-1"> </i>
          <div *ngIf="n_solicitud?.length > 0"  class="w-6 h-6 bg-red-500 rounded-full absolute -top-1 -right-1 flex justify-center">
            <p class="text-base text-white font-semibold">{{n_solicitud?.length}}</p>
          </div>
        </div>
        <div [ngClass]="{'select': btn_a}" (click)="btn_aplicar()" class="relative flex mr-2 justify-center w-full py-3 rounded-lg hover:bg-gray-50 shadow-md">
          <span class="icon-mano-solicitar text-aply-100 text-4xl mr-1"></span>
          <div *ngIf="n_aplicacion?.length > 0" class="w-6 h-6 bg-red-500 rounded-full absolute -top-1 -right-1 flex justify-center">
            <p class="text-base text-white font-semibold">{{n_aplicacion?.length}}</p>
          </div>
        </div>
       </div>
    </div>
    <!-- aqui termina el menu de notificcaiones-->

    <div class="w-full lg:mt-0 lg:row-span-1 lg:col-span-2 h-full ">
      <div  class="body-notificaion lg:h-96 overflow-y-auto w-full lg:border lg:rounded-t-lg sm:justify-center items-center sm:pt-0  ">
        
        <div *ngIf="!notificaciones && btn_n"  class="mt-12 h-full">
          <div class="w-full h-auto px-12 flex justify-center  lg:pb-16">
            <img class="w-3/5 lg:w-3/5" src="assets/imagen/general/no-notificacion.png" alt="">
          </div>
          <div class="w-full h-auto flex justify-center pt-8 lg:pt-2">
            <p class="text-2xl font-semibold text-gray-700">¡Mantente atento!</p>
          </div>
          <div class="w-full h-auto flex justify-center">
            <p class="text-xl font-medium text-gray-700 text-center">aparecerán notificaciones sobre tu actividad</p>
          </div>
          <div class="w-full h-auto flex justify-center pt-8">
            <div class="w-auto px-4 mb-24 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-gray-500/50">
              <p [routerLink]="['/']" class="text-lg text-white font-semibold pb-1 cursor-pointer">Pagina principal</p>
            </div>
          </div>
        </div>

        <div *ngIf="notificaciones">
          <div  *ngIf="btn_n">
            <div [ngClass]="{'no_leida': notificacion.read_by == '' }"  *ngFor="let notificacion of notificaciones"  class="block w-full h-auto  border-b py-2 px-2 lg:px-4 border-b-2 border-gray-200 ">
              <div class="flex w-full">
                <div [routerLink]="['/perfil', notificacion.sender._id]" class="w-24 pr-2 lg:mr-4 py-2 cursor-pointer flex relative items-center justify-center">
                  <img *ngIf="notificacion.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="notificacion.sender.imagen.thumbnail" alt="">
                  <img *ngIf="!notificacion.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
                 </div>
                <div class="w-full content-start flex relative items-center " >
                  <div>
                    <p [routerLink]="['/perfil', notificacion?.sender._id]" class="text-base font-semibold text-gray-700 cursor-pointer">{{notificacion?.sender.name}}</p>
                    <span class="text-sm text-gray-500 font-semibold -mt-1">{{notificacion.message[0].description}}</span>
                    <span (click)="onRead_notificacion(notificacion._id)" [routerLink]="['/servicios', notificacion.message[0].property_id ]" class="text-sm text-gray-600 font-semibold"> {{ notificacion?.message[0].title}} </span>
                    
                  </div>
                  
                </div>
                <div class="w-32 pr-2 lg:ml-4 py-2">
                  <div class="flex justify-end mb-6">
                    <p class="text-xs">{{ notificacion.message[0].time | date:'mediumDate'}}</p>
                  </div>

                  <div class="flex justify-end cursor-pointer">

                    <div [matMenuTriggerFor]="menu"  (click)="menu(notificacion._id)" class="w-auto hover:bg-gray-100 rounded-full inline-block align-middle">
                      <i class='bx bx-dots-vertical-rounded text-3xl mx-1 mt-1'></i>
                    </div>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                      <button (click)="onRead_notificacion(notificacion._id)" mat-menu-item>
                        <mat-icon>check_circle</mat-icon>
                        <span>Marcar Leido</span>
                      </button>
                    </mat-menu>

                  </div> 
                 </div>
              </div>

              <!-- <div class="flex w-full h-10 mb-4 pl-24">
                <div  class="w-80 flex">
                  <div class="w-36 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-red-500/50">
                    <p class="text-lg text-white font-semibold pb-1">Ver</p>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
        
        <div *ngIf="btn_a">
          <app-aplicaciones></app-aplicaciones>
        </div>
        
        <div *ngIf="btn_s">
          <app-solicitudes></app-solicitudes>
        </div>

      </div>
    </div>

    <div (click)="cerrar()" class="w-full h-screen lg:h-full row-span-3  hidden lg:flex lg:w-72"></div>
  </div>
</div>

