import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'review-perfil',
  templateUrl: './review-perfil.component.html',
  styleUrls: ['./review-perfil.component.css']
})
export class ReviewPerfilComponent implements OnInit {

  public id: string //viene del parametro id url
  public id_perfil: string; // id del perfil 

  public formSubmitted = false;
  public  reseniaForm= this.fb.group({
    message: ['', [Validators.required]],
    id_ref: ['', [Validators.required]],
  });
  

  public cuentaActual: boolean = false;
  public id_user1: string;
  public auxUsuario: boolean = false;
  public log: boolean = false;
  
  public review: any;

  public page: number = 1;
  public limit: number = 2;
  public Data: Array<any> = [];
  public isLoading = true;
  public totalPages: number;
  public totalDocuments: number;
  public currentPage: number;

  constructor(
    private usuarioService: UsuarioService,
    private reseniasService: ReseniasService,
    private perfilService: PerfilService,
    private ar: ActivatedRoute,
    private fb: FormBuilder,

  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');
    this.reseniaForm.setValue({ message: '', id_ref: this.id });
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user1 = usuarioService.usuarios._ids } 
  }

  ngOnInit() {
    this.perfilSeleccionado();
    this.perfiLogueado();
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => {
        this.id_perfil = resp.data[0]._id;
        this.resenias(this.id_perfil);
      }
    )
  }

  perfiLogueado() {
    if (this.id_user1 == this.id) {
      this.cuentaActual = true;
    } else {
      this.cuentaActual = false;
    }
  }

  onCrearResenia() {
    this.formSubmitted = true;
    this.reseniasService.crearResenias(this.reseniaForm.value, 'profile').subscribe(
      resp => {
        this.reseniaForm.setValue({ message: '', id_ref: this.id_perfil });
        this.page = 1;
        this.Data = [];
        this.resenias(this.id_perfil);
      }
    )
  }

  resenias(id:string) {  
    this.isLoading = true;   
    this.reseniasService.listarResenias(this.id_perfil, this.page, this.limit, 'profile').subscribe(
      resp => {
        resp.data.reviews.forEach((e:any) => {
          this.Data.push(e);
        });
        this.totalPages = resp.data.totalPages;
        this.totalDocuments = resp.data.totalDocuments;
        this.currentPage = resp.data.currentPage;
        this.isLoading = false; 
        this.reseniaForm.setValue({ message: '', id_ref: this.id_perfil });
      }
    )

  }

  resetearListado(valor: boolean) {
    if (valor == true) {
      this.page = 1;
      this.Data = [];
      this.resenias(this.id_perfil);
    }
  }

  cambioValorHijo(actualizar) {
    if (actualizar) {
      this.page = 1;
      this.Data = [];
      this.resenias(this.id_perfil);
    }
  }

  campoNoValido(campo: string): boolean {
    if (this.reseniaForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  onScroll() {
    this.page += 1;
    this.resenias(this.id_perfil);
  }

}
