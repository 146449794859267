<div class="mt-3">
    <div class="flex flex-col">
        <div class="w-full mr-2 order-2">
            <div class="grid grid-cols-3 gap-1 mt-2">
                <div *ngFor="let i of images" class="relative img-b col-span-1">
                    <label *ngIf="auxUsuario" class="absolute top-1.5 right-9 bg-white rounded-full w-7 h-7  flex shadow-xl cursor-pointer botones">
                        <i class="icon-edit p-1.5 flex content-center items-center text-md text-dark-400"></i>
                        <input type="file" multiple="true" class="hidden" id="file-upload" (change)="uUpdateImagen($event.target.files[0], i._id)">
                    </label>
                    <span *ngIf="auxUsuario" (click)="delete(i._id)" class="absolute top-1.5 right-1 bg-white rounded-full w-7 h-7  flex shadow-xl cursor-pointer botones">
                        <i class="bx bx-trash p-1.5 flex content-center items-center text-md text-dark-400"></i>
                    </span>
                    <img [src]="i.thumbnail" alt="" class="object-center object-cover w-full h-30 rounded-2xl border mb-1">
                </div>

                <div *ngIf="count <= 2 && auxUsuario" class="h-30 col-span-1">
                    <label class="cursor-pointer hover:shadow-lg hover:bg-white flex rounded-2xl h-30 transition duration-500 ease-in-out">
                        <div class="hover:border-transparent hover:shadow-xs w-full  rounded-lg border-4 border-dashed border-gray-200 py-1">
                            <div class="h-full w-full flex flex-col items-center justify-center items-center">
                                <span *ngIf="!imgTemp" class="icon-img text-5xl text-dark-400 -mt-2"></span>
                                <div *ngIf="!imgTemp"  class="pointer-none text-sm font-bold text-dark-300 mt-1">Nueva imagen</div>
                            </div>
                            <input type="file" multiple="true" class="hidden" id="file-upload" (change)="cambiarImagen($event.target.files[0])">
                        </div>  
                    </label>
                </div>
            </div>
        </div>

        <div class="w-full  order-1">
            <ngx-slick-carousel class="carousel z-0" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem class="slide" *ngFor="let i of images">
                    <div class="h-80">
                        <img [src]="i.thumbnail" alt="" class="object-center object-cover w-full h-80  rounded-2xl">
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>