import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecomendacionesService } from 'src/app/core/services/recomendaciones.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'response-tip',
  templateUrl: './response-tip.component.html',
  styleUrls: ['./response-tip.component.css']
})
export class ResponseTipComponent implements OnInit {

  @Input() id: string;
  @Input() log: boolean;

  @Output() resetear: EventEmitter<boolean> = new EventEmitter();

  public formSubmitted = false;
  

  public tipResponseForms = this.fb.group(
    {
      id : ['',  [ Validators.required ]],
      message : ['',  [ Validators.required, Validators.minLength(10) ]],
    }
  );

  constructor(
    private recomendaService: RecomendacionesService,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.tipResponseForms = this.fb.group(
      {
        id : [this.id,  [ Validators.required ]],
        message : ['',  [ Validators.required, Validators.minLength(3) ]],
      }
    );
  }

  onResponseTip() {
    if (this.log == true) {
      
      this.recomendaService.responderRecomendacion(this.tipResponseForms.value).subscribe(
        resp => {
          if (resp.success) {
            this.resetear.emit(true);
          }
        }
      );

    } else {
      this.onModal();
    } 
  }

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para dar una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  campoNoValido(campo: string): boolean {
    if (this.tipResponseForms.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

}
