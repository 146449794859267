import { DescripcionComponent } from "./components/descripcion/descripcion.component";
import { FinalizarComponent } from "./components/finalizar/finalizar.component";
import { LogoComponent } from "./components/logo/logo.component";

// pagina
import { CreacionNecesidadComponent } from "./necesidad/creacion-necesidad.component";

export const components : any [] =[
    DescripcionComponent,
    LogoComponent,
    FinalizarComponent,
    CreacionNecesidadComponent,
]

export * from "./components/descripcion/descripcion.component";
export * from "./components/finalizar/finalizar.component";
export * from "./components/logo/logo.component";

export * from "./necesidad/creacion-necesidad.component";

