<form [formGroup]="filtroForm" autocomplete="off" class="w-full  mb-3">
  <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm mb-3">
      <h2 class="text-sm font-bold text-dark-300 mb-3">Según su clasificación</h2>
      <div class="w-full">
          <label class="font-bold  text-dark-300 mb-4">Categoría</label>
          <select class="input-3 w-full px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2" [(ngModel)]="selectedCtg._id" (change)="listadoSubcategoria($event.target.value)" [ngModelOptions]="{standalone: true}">
              <option value=""> --- Seleccione el Categoría --- </option>
              <option *ngFor="let c of ctgItems" value="{{c._id}}"> {{ c.name }}</option>
          </select>
      </div>

      <div class="w-full">
          <label class="font-bold text-sm text-dark-300 mb-2">Sub-Categoría</label>
          <select formControlName="subcategory" (click)="nuevaBusqueda()" class="input-3 px-3  w-full   focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
              <option value=""> --- Seleccione el Sub-Categoría --- </option>
              <option *ngFor="let s of subItems" [value]="s._id"> <i class="{{s.icon}}"></i> {{ s.name }}</option>
          </select>
      </div>
  </div>

  <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm mb-3">
      <h2 class="text-sm font-bold text-dark-300 mb-3">Según su ubicación</h2>
      <div class="w-full">
          <label class="font-bold text-dark-300 mb-2">Departamento</label>
          <select class="input-3 w-full px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2" [(ngModel)]="selectedDept._id" (change)="listadoMunicipio($event.target.value)" [ngModelOptions]="{standalone: true}">
                      <option value=""> --- Seleccione el departamento --- </option>
                      <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                  </select>
      </div>
      <div class="w-full">
          <label class="font-bold text-dark-300 mb-2">Municipio</label>
          <select formControlName="municipality" (click)="nuevaBusqueda()" class="input-3 px-3  w-full   focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                  <option value=""> --- Seleccione el municipio --- </option>
                  <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
              </select>
      </div>
  </div>

  <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm">
      <h2 class="text-sm font-bold text-dark-300 mb-3">Según su rango de precio</h2>
      <div class="flex flex-col">
          <label class="font-bold text-dark-300 mb-1">Precio mínimo ({{min | currency: 'COP' }})</label>
          <input formControlName="min_price" (click)="nuevaBusqueda()" step="10000" type="range" min="50000" max="50000000" [(ngModel)]="min" (ngModelChange)="min = $event" >

      </div>
      <div class="flex flex-col">
          <label class="font-bold text-dark-300 mb-1">Precio máximo ({{max | currency: 'COP' }})</label>
          <input formControlName="max_price" (click)="nuevaBusqueda()" step="10000" type="range" min="50000" max="50000000" [(ngModel)]="max" (ngModelChange)="max = $event" >

      </div>
  </div>
</form>
