import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  @Output() crear: EventEmitter<any> = new EventEmitter();
  @Output() propuestas: EventEmitter<any> = new EventEmitter();
  @Output() pago: EventEmitter<any> = new EventEmitter();
  @Output() pasarela: EventEmitter<any> = new EventEmitter();
  @Output() detalle: EventEmitter<any> = new EventEmitter();

constructor() { }

}
