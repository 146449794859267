import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from 'src/app/core/services/perfil.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'forms-laboral',
  templateUrl: './laboral.component.html',
  styleUrls: ['./laboral.component.css']
})
export class LaboralComponent implements OnInit {

  public study: any;
  public expectative: any;

  public deleteStudy: FormGroup
  
  public id: string;
  
  public formSubmitted = false;
  public studyForm = this.fb.group(
    {
      estudio: ['',  [ Validators.required, Validators.minLength(10), Validators.maxLength(100) ]],
      academia : ['',  [ Validators.required, Validators.minLength(10), Validators.maxLength(100) ]],
    }
  );

  public formSubmitted1 = false;
  public expeForm = this.fb.group(
    {
      experiencia: ['',  [ Validators.required, Validators.minLength(10), Validators.maxLength(100) ]],
      anio : ['',  [ Validators.required, Validators.minLength(3), Validators.pattern(/^([0-9])*$/) ]],
    }
  );

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private perfilServices: PerfilService
  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');

  }

  ngOnInit() {
    this.perfilSeleccionado();

    this.deleteStudy = this.DeleteFormStudyBuilder(this.fb)
  }

  DeleteFormStudyBuilder ( formBuilder: FormBuilder ) {
    return formBuilder.group( {
      id_perfil: [localStorage.getItem( "id_perfil" ), [Validators.required, Validators.minLength( 3 )]],
    } )
  }

  aggStudy() {

    this.formSubmitted = true;

    if (this.studyForm.invalid) {
      return;
    }
    
    this.perfilServices.agregarStudies(this.studyForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: 'success', title: 'Listo, Estudio creado correctamente' });
        this.perfilSeleccionado();
        this.studyForm.reset();
      }
    )
  }


  onDeleteStudy(id){

    this.perfilServices.deleteStudy(this.deleteStudy.value, id ).subscribe(
      resp => {
        this.Toast.fire({ icon: 'info', title: resp.msg });
      }
    )
  }

  campoNoValido1(campo: string): boolean {
    if (this.studyForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }

  }

  aggExperiencia() {
    this.formSubmitted1 = true;

    if (this.expeForm.invalid) {
      return;
    }
    
    this.perfilServices.agregarExperiencia(this.expeForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: 'success', title: 'Listo, Experiencia agregada correctamente' });
        this.perfilSeleccionado();
        this.expeForm.reset();
      }
    )
  }

  perfilSeleccionado() {
    this.perfilServices.perfilSeleccionado(this.id).subscribe(
      resp => {
        
        this.study = resp.data[0].study;
        this.expectative = resp.data[0].experience;
        
      }
    )
  }


  
  campoNoValido2(campo: string): boolean {
    if (this.expeForm.get(campo).invalid && this.formSubmitted1) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
}
