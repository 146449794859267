import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from './components';
import { SharedPipesModule } from 'src/app/shared.pipes/shared.pipes.module';
import { SharedModule } from 'src/app/shared.component/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule.forRoot(),
    SharedModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    HttpClientModule,

  ],
  declarations: [
    ...fromComponents.components,
  ]
})
export class WebchatModule { }
