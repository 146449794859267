<div *ngFor="let p of resultado">
    <div class="flex items-center justify-center mt-3 xl:mt-0">
        <!-- <div role="progressbar" class="w-48 h-48 xl:w-72 xl:h-72 " aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style="--value:{{progress | number :'1.0-0'}}"> -->
        <div class="cont relative w-44 h-44 xl:w-72 xl:h-72">
            <img *ngIf="p.imagen" [src]="p.imagen.thumbnail" alt="" class="object-cover object-center w-44 h-44 xl:w-72 xl:h-72 rounded-full shadow-xl">
            <img *ngIf="!p.imagen" src="assets/imagen/general/perfil-1.svg" alt="" class="object-cover object-center w-44 h-44 xl:w-72 xl:h-72 rounded-full shadow-xl">
            <label *ngIf="validacion" class="absolute bottom-2  right-2 xl:bottom-6 xl:right-6 bg-white rounded-full w-8 lg:w-10 h-8 lg:h-10  flex shadow-xl cursor-pointer botones">
                <i class="icon-edit p-2 content-center items-center text-md lg:text-2xl text-dark-400"></i>
                <input type="file" multiple="true" class="hidden" id="file-upload" (change)="cambiarUpdateImagen($event.target.files[0])">
            </label>
        </div>
        <!-- </div> -->
    </div>
    <!-- <div class="flex items-center justify-between w-full">
        <div class="mx-auto" role="numberProgress" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style="--value:{{progress | number :'1.0-0'}}"></div>
    </div> -->
    <div class="flex flex-col text-center mt-3 mb-2">
        <h2 class="font-bold text-dark-300 text-xl"> {{p.name}} </h2>
        <h3 class="font-medium text-dark-300 text-md"> {{p.email}} </h3>
    </div>
</div>