import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';

@Component({
  selector: 'app-subcategoria',
  templateUrl: './subcategoria.component.html',
  styleUrls: ['./subcategoria.component.css']
})
export class SubcategoriaComponent implements OnInit {

  public id: any;
  public count: number;
  public categoria: any;
  public subItems: any;

  constructor(
    private ar: ActivatedRoute,
    private subcategoriaService: SubcategoriaService,
  ) { }

  ngOnInit() {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.listadoSubcategoria(this.id);
  }


  listadoSubcategoria(id: string) {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => {
        this.count = resp.data.subcategory.length
        this.categoria = resp.data.name;
        this.subItems = resp.data.subcategory;
      }
    )
  }

  refrescar(id: string) {
    this.listadoSubcategoria(id);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

}