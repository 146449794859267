<div class="w-full">
    <div class="flex flex-col items-center justify-center content-center h-screen">

        <div class="">
            <img src="assets/imagen/logo/logo-tras.png" alt="" class="w-40">
        </div>

        <h3 class="font-bold text-2xl text-dark-300">Cambiar contraseña</h3>


        <div class="mt-5 w-3/5	 xl:w-1/4">
            <form [formGroup]="passForm" (ngSubmit)="cambiarPassword()" class="grid gap-6">
                <div class="flex flex-col">
                    <label for="Codigo" class="font-bold text-base text-dark-300">Contraseña nueva  *</label>
                    <input type="password" formControlName="password" placeholder="0000000" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-1">
                    <alerts-auth *ngIf="campoNoValido('password')" [msg]="'La constraseña es obligatorio, y debe tener minimo 8 caracter'" [alert]="'error'"></alerts-auth>
                </div>
                <div class="flex flex-col">
                    <label for="Codigo" class="font-bold text-base text-dark-300">Confirmar contraseña     *</label>
                    <input type="password" formControlName="password2" placeholder="0000000" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-1">
                    <alerts-auth *ngIf="campoNoValido('password2')" [msg]="' La constraseña es obligatorio, y debe tener minimo 8 caracter'" [alert]="'error'"></alerts-auth>
                </div>
                <div>
                    <input type="submit" value="Cambiar contraseña" class="input-1 bg-aply-600 w-full text-white hover:shadow-inner">
                </div>
            </form>

            <div class="text-center col-span-12 mt-10">
                ¿Ya recordastes la contraseña? <a routerLink="/login" class="font-bold hover:underline">Iniciar sesión</a>
            </div>
        </div>

    </div>


</div>