import { Component, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/core/services/servicios.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  public service: any;
  public id_user: string;
  public cont: number;
  
  constructor(
    private serviciosService: ServiciosService
  ) { }

  ngOnInit() {
    this.listarServicios();
  }

  listarServicios() {
    this.serviciosService.listarUltimosServicios().subscribe(
      resp => {
        this.service = resp.data; 
        var aux = 0;
        this.service.forEach(e => { aux = aux + 1 });
        this.cont = aux;
      }
    )
  }

  slideConfig = {
    "slidesToShow": 3.1,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 1050,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 2,
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 4,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 425,
        "settings": {
          "slidesToShow": 1.1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 1.1,
          "slidesToScroll": 1,
        }
      }
    ]
  };


  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) {}

}
