
<div class="h-20 w-full">

</div>
  <!-- search compt -->
  <section  (window:resize)="onResize($event)"  class="flex items-center flex-grow h-16 px-4 mb-">
    <div class="min-w-full ">
        <div class="relative ">
            <div class="absolute left-3 top-2 text-xl text-dark-400 ">
                <i class='bx bx-search-alt'></i>
            </div>
        </div>
       
        <input type="search"  placeholder="Busqueda" class="w-12 md:w-96 min-w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 border-2 border-gray-200 rounded-lg focus:bg-gray-100 "
            aria-label="" aria-describedby="basic-addon1" #textsearch
            (keyup)="onSearch(textsearch.value)"
            >

    </div>
</section>

<div class="w-full h-10 flex text-blue-400 mb-2">
  <div [ngClass]="{'border-b-2 border-blue-400': view == 'chat'}" (click)="view = 'chat'" class="w-full h-full flex justify-center items-center hover:bg-blue-100 
   cursor-pointer" >
    <i class='bx bxs-conversation text-lg'></i>
  </div>
  <div [ngClass]="{'border-b-2 border-blue-400': view == 'contact'}" (click)="view = 'contact'"  class="w-full h-full flex justify-center items-center hover:bg-blue-100 cursor-pointer" >
    <i class='bx bxs-contact text-lg'></i>
  </div>
</div>
  <!-- end search compt -->
 <!-- user list -->
 <!-- listando las conversaciones  -->
 <div *ngIf="view == 'chat'"  >

  <div  *ngFor="let conver of _converation | seachChat:search "   class="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-100">
    <div class=" relative w-1/4">
     <img [routerLink]="['/perfil', conver.id]" *ngIf="conver.image"
       [src]="conver.image.thumbnail" alt=""
       class="object-cover h-12 w-12 rounded-full"
       alt=""/>
        <img [routerLink]="['/perfil', conver.id]" *ngIf="!conver.image"
        src="assets/imagen/general/no-imagen.png" alt=""
        class="object-cover h-12 w-12 rounded-full"
        alt=""/>
       <a *ngIf="conver.id | onLineUsers:onlineUsers" class="absolute left-8 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
        <div class="w-2 h-2"></div>
        </a>
    </div>
    <div  (click)="id_conversacion(conver)" class="w-full">
      <div class="w-full">
        <div class="text-lg font-semibold">{{conver.name}}</div>
        <span class="text-gray-500">{{conver.id_conversacion | lastMessage | async }}</span>
      </div>
  
    </div>
  </div>

 </div>

 <div *ngIf="view == 'contact'"  >

  <div  *ngFor="let conver of _converation | seachChat:search "   class="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-100">
    <div class=" relative w-1/4">
     <img [routerLink]="['/perfil', conver.id]" *ngIf="conver.image"
       [src]="conver.image.thumbnail" alt=""
       class="object-cover h-12 w-12 rounded-full"
       alt=""/>
        <img [routerLink]="['/perfil', conver.id]" *ngIf="!conver.image"
        src="assets/imagen/general/no-imagen.png" alt=""
        class="object-cover h-12 w-12 rounded-full"
        alt=""/>
       <a *ngIf="conver.id | onLineUsers:onlineUsers" class="absolute left-8 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
        <div class="w-2 h-2"></div>
        </a>
    </div>
    <div  (click)="id_conversacion(conver)" class="w-full">
      <div class="w-full">
        <div class="text-lg font-semibold">{{conver.name}}</div>
        <span *ngIf="conver.id | onLineUsers:onlineUsers" class="text-gray-500">Conectado</span>
      </div>
  
    </div>
  </div>
  
 </div>
 

 




<!-- end user list -->
