import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aplicaciones',
  templateUrl: './aplicaciones.component.html',
  styleUrls: ['./aplicaciones.component.css']
})
export class AplicacionesComponent implements OnInit {
  aplicaciones: any;
  n_menu:boolean = false;
  id_aplicacion: any;

  public suscription: Subscription;

  constructor(
    private noti: NotificacionesService
  ) { }

  ngOnInit() {
    this.onAplicaciones();

    this.suscription = this.noti.refresh.subscribe(() => {
      this.onAplicaciones();
     })
  }


  onAplicaciones(){
    
    this.noti.aplicaciones().subscribe( 
      data => {
        this.aplicaciones = data.data;
        console.log("aplicaciones", data.data.length);
      } );

  }

  menu(valor){
    this.id_aplicacion = valor;
    this.n_menu = !this.n_menu;
  }


   //metodo para envia el veredicto a una aplicacion de una necesidad

 veredicto(id, veredicto){

  Swal.fire({
   
    title: "Vas a "+ veredicto +' la aplicacion!',
    text: `¿Estas seguro de `+ veredicto + ' la aplicacion?',
    width: '330px',
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: `Aceptar`,
    confirmButtonColor:'#2784FE',
    denyButtonText: `Cancelar`,
    denyButtonColor: '#F62326',
    

  }).then((result) => {
    if (result.isConfirmed) {
      
      this.noti.veredicto_necesidad(id, veredicto).subscribe( 
        data => {
          console.log("estado", data);
        } )
      //window.location.href= this.link;
    }else if(result.isDenied){

      result.isDenied
    }
  })
}


public Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {

    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})



}
