<div class="absolute w-96 h-auto max-h-96 right-0 -top-32 lg:right-9 lg:top-20  border rounded-bl-lg rounded-tl-lg rounded-br-lg bg-white">

    <div class="w-full px-6">
        <div class="flex w-full h-auto px-2 py-2">
            <div class="w-1/5 pr-4 lg:mr-4 py-2">
                <img *ngIf="user?.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="user.imagen.thumbnail" alt="">
                <img *ngIf="!user?.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
            </div>
            <div class="w-4/5 ">
                <p class="text-base font-semibold text-gray-700">{{user?.name}}</p>
                <p class="text-sm text-gray-500 font-semibold">Prestador de servicio</p>

            </div>
        </div>
    </div>

    <div (click)="LogOfPerfil()" class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
        <div class="w-1/5 py-2 flex justify-center">
            <i class='bx bxs-user-circle text-2xl text-gray-600'></i>
        </div>
        <div class="w-4/5 py-1">
            <p class="text-sm font-semibold text-gray-600">Perfil</p>
            <p class="text-sm font-normal text-gray-600">Ir a perfil</p>
        </div>
    </div>
    <div (click)="salir()" class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
        <div class="w-1/5 py-2 flex justify-center">
            <i class='bx bxs-log-out-circle text-2xl text-gray-600'></i>
        </div>
        <div class="w-4/5 py-1">
            <p class="text-sm font-semibold text-gray-600">Salir</p>
            <p class="text-sm font-normal text-gray-600">Salir de deuna</p>
        </div>
    </div>

</div>