import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'card-review',
  templateUrl: './card-review.component.html',
  styleUrls: ['./card-review.component.css']
})
export class CardReviewComponent implements OnInit {

  @Input() r: any;
  @Input() id_user: any;
  @Input() log: any;
  @Output() actualizar: EventEmitter<boolean> = new EventEmitter();

  public id_necesidad: string;
  public activacion: boolean = false;
  public time: boolean;

  public formSubmitted = false;
  public reseniaForm= this.fb.group({ message: ['', [Validators.required]] });

  constructor(
    private reseniasService: ReseniasService,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
  ) {
    this.id_necesidad = this.ar.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.tiempo();
  }

  upResponse(id: string) { 
    if (this.time) {
      this.formSubmitted = true;
      if (this.reseniaForm.invalid) { return }
      this.reseniasService.updateResenia(this.reseniaForm.value, id, 'necessity').subscribe(
        resp => {
          this.Toast.fire({ icon: resp.alert, title: resp.msg })
          if (resp.alert == 'success') { this.actualizar.emit(true) }
        }
      )
    }
  }

  deleteResenia(id: string) {
    if (this.time) {
      Swal.fire({
        title: 'Estas seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.reseniasService.eliminarResenia(id, this.id_necesidad, 'necessity').subscribe(
            resp => {
              this.Toast.fire({ icon: resp.alert, title: resp.msg })
              if (resp.alert == 'success') {
                this.actualizar.emit(true);
              }
            }
          )
        }
      })
    }
  }

  editar() {
    if (this.time) {
      if (this.activacion == true) {
        this.activacion = false;
        this.reseniaForm.setValue({ message: '' });
      } else {
        this.activacion = true;
        this.reseniaForm.setValue({ message: this.r.message });
     }
    }
  }

  tiempo() {
    if (this.log == true) {      
      this.reseniasService.tiempoTranscurrido(this.r._id, 'necessity').subscribe(
        resp => { this.time = resp.data }
      )
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}