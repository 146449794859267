<div class="w-full">
    <div class="flex flex-col items-center justify-center content-center h-screen">

        <div class="">
            <img src="assets/imagen/logo/logo-tras.png" alt="" class="w-40">
        </div>

        <h3 class="font-bold text-2xl text-dark-300">Codigo de confirmación</h3>
        <div class="mt-5 w-3/5	 xl:w-1/4 text-center">
            <p>
                Pronto recibirás un correo electrónico... {{email}} para confirmacion de correo. Revisa la carpeta de spam y la papelera si no encuentras el correo electrónico.
            </p>
        </div>

        <div class="mt-5 w-3/5	 xl:w-1/4">
            <form [formGroup]="confiCodeForm" (ngSubmit)="confirmacionCodigo()" class="grid gap-6">
                <div class="flex flex-col">
                    <label for="Codigo" class="font-bold text-base text-dark-300">Codigo *</label>
                    <input type="text" formControlName="code" placeholder="0000000" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('code')" [msg]="'El codigo es obligatorio'" [alert]="'error'"></alerts-auth>
                </div>
                <div>
                    <input type="submit" value="Confirmacion de correo" class="input-1 bg-aply-600 w-full text-white hover:shadow-inner">
                </div>
            </form>


        </div>


    </div>
</div>
<!-- <div class="text-center col-span-12 mt-10">
        ¿Ya recordastes la contraseña? <a routerLink="/login" class="font-bold hover:underline">Iniciar sesión</a>
    </div> -->