<div class="absolute w-72 h-auto max-h-72 right-20 -top-28 lg:right-24 lg:top-20  border rounded-bl-lg rounded-tl-lg rounded-br-lg bg-white">
    <a routerLink="/servicios" class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
        <div class="w-1/5 py-2 flex justify-center">
            <i class='bx bxs-user-circle text-2xl text-gray-600'></i>
        </div>
        <div class="w-4/5 py-1">
            <p class="text-sm font-semibold text-gray-600">Servicio</p>
            <p class="text-sm font-normal text-gray-600">Buscar servicio</p>
        </div>
    </a>
    <a routerLink="/necesidades" class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
        <div class="w-1/5 py-2 flex justify-center">
            <i class='bx bxs-log-out-circle text-2xl text-gray-600'></i>
        </div>
        <div class="w-4/5 py-1">
            <p class="text-sm font-semibold text-gray-600">Necesidades</p>
            <p class="text-sm font-normal text-gray-600">Buscar necesidades</p>
        </div>
    </a>
</div>