<div class="contenedor mb-2 overflow-y-scroll" >
    <div *ngFor="let rr of respuestas; let i=index">
        <div *ngIf="i<num">
            <div class="flex space-x-3 items-center content-center my-2">
                <img (click)="irAlPerfil(rr.user._id)" *ngIf="!rr.user.imagen" src="assets/imagen/general/perfil-1.svg " alt=" " class="cursor-pointer w-6 h-6 rounded-full object-center object-cover ">
                <img (click)="irAlPerfil(rr.user._id)" *ngIf="rr.user.imagen" [src]="rr.user.imagen.thumbnail " alt=" " class="cursor-pointer w-6 h-6 rounded-full object-center object-cover ">
                <div class=" ">
                    <h6 (click)="irAlPerfil(rr.user._id)" class="cursor-pointer font-bold text-dark-400 text-md leading-4 ">
                        {{rr.user.name}}
                    </h6>
                    <h6 class="font-semibold text-dark-300 text-xs">
                        {{ rr.createdAt | date }}
                    </h6>
                </div>
            </div>
            <div class="">
                <p class="text-dark-300 leading-tight text-base mb-4">
                    {{rr.message}}
                </p>
                <div class="flex pt-2 text-md font-semibold space-x-4 text-sm font-semibold text-dark-300 ">
                    <rating-like [likes]="rr.like " [id_ref]="rr._id " [type_model]=" 'RecommendationResponse' "></rating-like>
                    <!-- <rating-start [quality]="rr.qualification " [id_ref]="rr._id " [type_model]=" 'RecommendationResponse' "></rating-start> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex justify-between ">
    <span *ngIf="!activacion && contador > 1" (click)="masomenos('mas', id)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer ">
      ver mas respuesta
  </span>
    <span *ngIf="activacion" (click)="masomenos('menos', id)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer ">
      ver menos respuesta
  </span>
</div>