import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: Socket;

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers_() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

constructor(
  private http:HttpClient,
) {
  this.socket=io('http://127.0.0.1:3333');

  this.socket.on('news', (data) => {
    console.log(data, "desde el servicio")
  });

 }

 user() {

  const url = `${base_url}/user/show`;
  return this.http.get(url, this.headers_).pipe(
    tap((resp: any) => {})
  );
}

 emit(event:string, data:any){
  this.socket.emit(event,data);
}





on(event:string){
  console.log("aqui");
  return Observable.create(observer=>{
   this.socket.on(event,data=>{
    observer.next(data);
   });
  })
 }

}
