import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seachChat'
})
export class SeachChatPipe implements PipeTransform {

  transform(value: any, search:any): any {
   
    if (!value) {
      return [];
    }
    if (!search) {
      return value
    }

    console.log("desde el pipe ",value)
    search = search.toLocaleLowerCase();

    return value.filter((it: { name: string; }) => {
      return it.name.toLocaleLowerCase().includes(search);
    });
  }
  

}
