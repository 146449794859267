import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class CreacionesService {
  
  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

  createdPerfil( data: { accountType: string, town: string, description : string, subcategory: any, company_name: string, ocupation: string, }) {   
    const url = `${base_url}/profile`
    return this.http.post(url, data, this.headers).pipe(
      tap((resp: any) => { })
    );
  }

  // http://127.0.0.1:3333/api/services
  creacionServicios(
    data: {
      name: string,
      description : string,
      min_price: number,
      max_price: number,
      subcategory: any,
      town: string,
    }
  ) {
    const url = `${base_url}/services`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp: any) => {  })
    )
  }

  publicar(
    id: string,
    tipo: 'service' | 'necessity'
  ) {
    const url = `${base_url}/${tipo}/status/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any ) => {})
    )
  }

  // http://127.0.0.1:3333/api/necessities
  creacionNecesidad(
    data: {
      title: string,
      description : string,
      min_price: number,
      max_price: number,
      subcategory: any,
      municipality: string,
    }
  ) {
    const url = `${base_url}/necessities`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )    
  }


}
