import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ReseniasService } from 'src/app/core/services/resenias.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'review-necesidad',
  templateUrl: './review-necesidad.component.html',
  styleUrls: ['./review-necesidad.component.css']
})
export class ReviewNecesidadComponent implements OnInit {
  
  public formSubmitted = false;
  public reseniaForm= this.fb.group({ message:['',[Validators.required]],id_ref:['',[Validators.required]] });
  
  public id: string //viene del parametro id url necesidad
  public id_user1: string;
  public auxUsuario: boolean = false;
  public log: boolean = false;
  
  public review: any;
  public page: number = 1;
  public limit: number = 5;
  
  public isLoading = true;
  public Data: Array<any> = [];
  public totalPages: number;
  public totalDocuments: number;
  public currentPage: number;

  constructor(
    private usuarioService: UsuarioService,
    private reseniasService: ReseniasService,
    private ar: ActivatedRoute,
    private fb: FormBuilder,

  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');
    this.reseniaForm.setValue({ message: '', id_ref: this.id });
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user1 = usuarioService.usuarios._ids } 
  }

  ngOnInit() {
    this.resenias();
  }

  onResponse() {
    this.formSubmitted = true;
    this.reseniasService.crearResenias(this.reseniaForm.value, 'necessity').subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg})
        if (resp.alert == 'success') {     
          this.reseniaForm.setValue({ message: '', id_ref: this.id });
          this.page = 1;
          this.Data = [];
          this.resenias();
        }
      }
    )
  }

  resenias() {
    this.isLoading = true;   
    this.reseniasService.listarResenias(this.id, this.page, this.limit, 'necessity').subscribe(
      (resp: any) => {
        resp.data.reviews.forEach((e:any) => { this.Data.push(e) });
        this.totalPages = resp.data.totalPages;
        this.totalDocuments = resp.data.totalDocuments;
        this.currentPage = resp.data.currentPage;
        this.isLoading = false;          
      }
    )
  }

  resetearListado(valor: boolean) {
    if (valor == true) {
      this.page = 1;
      this.Data = [];
      this.resenias();
    }
  }

  cambioValorHijo(actualizar) {
    if (actualizar) {
      this.page = 1;
      this.Data = [];
      this.resenias();
    }
  }

  campoNoValido(campo: string): boolean {
    if (this.reseniaForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  onScroll() {
    this.page += 1;
    this.resenias();
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}