<div class="w-full">
  
  <div  class="contenedor altura overflow-y-scroll pt-32 pb-24 w-full bg-gray-50 sm:justify-center items-center sm:pt-0">
    
    <div class="px-4 h-screen">
      <div class="w-full h-auto px-12 flex justify-center pt-9">
        <img class="w-4/5 lg:w-3/5" src="assets/imagen/general/no-pago.png" alt="">
      </div>
      <div class="w-full h-auto flex justify-center mb-9 pt-8 lg:pt-2">
        <p class="text-2xl font-semibold text-gray-700">¡No hay facturas aun!</p>
      </div>
      <div class="w-full h-auto flex justify-center">
        <p class="text-xl font-medium text-gray-700 text-center">para generar una factura primero debe haber un acuerdo de precio cliente - prestador de servicio por medio de las propuestas de precio</p>
      </div>
      <div class="w-full h-auto flex justify-center pt-8">
        <div (click)="crear_propuesta(true)" class="w-auto px-4 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-red-500/50 cursor-pointer hover:bg-blue-400">
          <p class="text-lg text-white font-semibold pb-1">Crear propuesta</p>
        </div>
      </div>
    </div>

    <div class="block w-full h-auto bg-gray-50 border py-2 ">
      <div class="flex w-full" (click)="pasarela()"> 
        <div class="w-auto flex justify-center items-center px-2"> 
          <div class="w-4 h-4 bg-blue-500 rounded-full">
  
          </div>
        </div>
        <div class="w-full pl-4">
          <div class="flex justify-between w-full h-10 pr-2">
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">120000</p>
            </div>
            <div class="flex justify-center items-center">
              <div class="espera px-2  rounded-full flex cursor-pointer">
                <div class="pt-1">
                  <i class='bx bx-loader-circle text-xl text-xl mr-2'></i>
                </div>
                <p class="text-lg font-semibold">espera</p>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="w-auto pr-2 py-2"> 
              <img class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/perfil/maria.jpg" alt="">
            </div>
            <div>
              <p class="text-lg font-semibold text-gray-700 mb-2">Maria mondragon</p>
              <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
            </div>
          </div>
          <div class="flex justify-start  w-full h-10 mb-4 ">
            <div class="block justify-center items-center pl-2">
              <p class="text-lg  text-gray-600">Pago de la factura 45789658</p>
              <p class="text-lg  text-gray-500">Mantenimiento de redes lan</p>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="block w-full h-auto bg-gray-50 border py-2 ">
      <div class="flex w-full" (click)="detalle()">
        <div class="w-auto flex justify-center items-center px-2"> 
          <div class="w-4 h-4 bg-blue-500 rounded-full">
  
          </div>
        </div>
        <div class="w-full pl-4">
          <div class="flex justify-between w-full h-10 pr-2">
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">120000</p>
            </div>
            <div class="flex justify-center items-center">
              <div class="pagada px-2  rounded-full flex cursor-pointer">
                <div class="pt-1">
                  <i class='bx bxs-check-circle text-xl mr-2'></i>
                </div>
                <p class="text-lg font-semibold">Pagada</p>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="w-auto pr-2 py-2"> 
              <img class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/perfil/maria.jpg" alt="">
            </div>
            <div>
              <p class="text-lg font-semibold text-gray-700 mb-2">Maria mondragon</p>
              <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
            </div>
          </div>
          <div class="flex justify-start  w-full h-10 mb-4 ">
            <div class="block justify-center items-center pl-2">
              <p class="text-lg  text-gray-600">Pago de la factura 45789658</p>
              <p class="text-lg  text-gray-500">Mantenimiento de redes lan</p>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="block w-full h-auto bg-gray-50 border py-2 ">
      <div class="flex w-full">
        <div class="w-auto flex justify-center items-center px-2"> 
          <div class="w-4 h-4 bg-blue-500 rounded-full">
  
          </div>
        </div>

        <div class="w-full pl-4">
          <div class="flex justify-between w-full h-10 pr-2">
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">120000</p>
            </div>
            <div class="flex justify-center items-center">
              <div class="cancelada px-2  rounded-full flex cursor-pointer">
                <div class="pt-1">
                  <i class='bx bxs-no-entry text-xl mr-2'></i>
                </div>
                <p class="text-lg font-semibold">Cancelada</p>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="w-auto pr-2 py-2"> 
              <img class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/perfil/maria.jpg" alt="">
            </div>
            <div>
              <p class="text-lg font-semibold text-gray-700 mb-2">Maria mondragon</p>
              <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
            </div>
          </div>
          <div class="flex justify-start  w-full h-10 mb-4 ">
            <div class="block justify-center items-center pl-2">
              <p class="text-lg  text-gray-600">Pago de la factura 45789658</p>
              <p class="text-lg  text-gray-500">Mantenimiento de redes lan</p>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="block w-full h-auto bg-gray-50 border py-2 ">
      <div class="flex w-full">
        <div class="w-auto flex justify-center items-center px-2"> 
          <div class="w-4 h-4 bg-blue-500 rounded-full">
  
          </div>
        </div>
        
        <div class="w-full pl-4">
          <div class="flex justify-between w-full h-10 pr-2">
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">120000</p>
            </div>
            <div class="flex justify-center items-center">
              <div class="cancelada px-2  rounded-full flex cursor-pointer">
                <div class="pt-1">
                  <i class='bx bxs-no-entry text-xl mr-2'></i>
                </div>
                <p class="text-lg font-semibold">Cancelada</p>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="w-auto pr-2 py-2"> 
              <img class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/perfil/maria.jpg" alt="">
            </div>
            <div>
              <p class="text-lg font-semibold text-gray-700 mb-2">Maria mondragon</p>
              <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
            </div>
          </div>
          <div class="flex justify-start  w-full h-10 mb-4 ">
            <div class="block justify-center items-center pl-2">
              <p class="text-lg  text-gray-600">Pago de la factura 45789658</p>
              <p class="text-lg  text-gray-500">Mantenimiento de redes lan</p>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
    
  
  
  
  </div>
  
  </div>
