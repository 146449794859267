import { Component, Input, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/core/services/servicios.service';

@Component({
  selector: 'footer-card',
  templateUrl: './footer-card.component.html',
  styleUrls: ['./footer-card.component.css']
})
export class FooterCardComponent implements OnInit {

  @Input() r: any;
  @Input() id: string;

  public contTotal: number;
  public usuarios: any;

  constructor(
    private serviciosService: ServiciosService
  ) { }

  ngOnInit() {
    this.contar();
    this.listarUsuarios();
  }

  contar() {
    let cont2 = 0;
    if (this.r) {
      for (let date2 of this.r) { cont2 = 1 + cont2 }
    }
    this.contTotal = (cont2);    
  }

  listarUsuarios() {
    this.serviciosService.listarUsuariosLikes(this.id).subscribe(
      resp => { this.usuarios = resp.data }
    )
  }

}