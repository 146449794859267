import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  public id: string;
  public sv: any = [];
  public review: any;

  public log: boolean;
  public id_user: string;
  public id_userLogueado: string;
  public auxUsuario: boolean = false;
  public min_price: number;
  public max_price: number;

  constructor(
    private ar: ActivatedRoute,
    private servicioService: ServiciosService,
    private reseniasService: ReseniasService,
    private usuarioService: UsuarioService,
    private fb: FormBuilder,
    private router: Router,
  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');
    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;  
        if (this.log == true) {
          this.id_userLogueado = this.usuarioService.usuarios._ids; //id del usuario logueado        
        }   
      }
    )
  }

  ngOnInit() {
    this.servicio();   
  }

  servicio() {
    this.servicioService.servicioSeleccionado(this.id)
      .subscribe(resp => {              
          this.sv = resp.data;         
          this.id_user = resp.data[0].profile.user._id; //id del dueño del servicio
          this.min_price = resp.data[0].min_price; //id del dueño del servicio
          this.max_price = resp.data[0].max_price; //id del dueño del servicio
          this.verificacionCuenta();
      })
  }

  solicitudServicio() {
    if (this.log == true) {
      this.servicioService.solicitarServicio(this.id).subscribe(
        resp => { this.Toast.fire({ icon: resp.alert, title: resp.msg }) }
      )
    } else { this.onModal() }
  }

  verificacionCuenta() {    
    if (this.log == true) {
      if (this.id_user == this.id_userLogueado) { this.auxUsuario = true  }
    }   
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }




  public edit: string;
  public formSubmitted = false;
  public  nameForm = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
  });
  public  descriptionForm = this.fb.group({
    description: ['', [Validators.required, Validators.minLength(3)]],
  });
  public  rangoForm = this.fb.group({
    min_price: ['', [Validators.required, Validators.minLength(3)]],
    max_price: ['', [Validators.required, Validators.minLength(3)]],
  });
  public  subcategoryForm = this.fb.group({
    subcategory: ['', [Validators.required, Validators.minLength(3)]],
  });
  public  townForm = this.fb.group({
    town: ['', [Validators.required, Validators.minLength(3)]],
  });


  updateName() {
    this.formSubmitted = true;
    if (this.nameForm.invalid) {
      return;
    }
    this.servicioService.updateNameServicio(this.nameForm.value, this.id).subscribe(
      resp => {
        if (resp.success) {
          this.edit = '';
          this.Toast.fire({ icon: 'success', title: 'Listo, ' + resp.success })
          this.servicio();
        }
      }
    )
  }

  updateDescripcion() {
    this.formSubmitted = true;
    if (this.descriptionForm.invalid) {
      return;
    }
    this.servicioService.updateDescriptionServicio(this.descriptionForm.value, this.id).subscribe(
      resp => {
        if (resp.success) {
          this.edit = '';
          this.Toast.fire({ icon: 'success', title: 'Listo, ' + resp.success })
          this.servicio();
        } 
      }
    )
  }

  updateRango() {
    this.formSubmitted = true;
    if (this.rangoForm.invalid) {
      return;
    }
    this.servicioService.updateRangoServicio(this.rangoForm.value, this.id).subscribe(
      resp => {
        if (resp.success) {
          this.edit = '';
          this.Toast.fire({ icon: 'success', title: 'Listo, ' + resp.success })
          this.servicio();
        }  
      }
    )
  }

  updateSubcategory() {
    this.formSubmitted = true;
    if (this.nameForm.invalid) {
      return;
    }
    this.servicioService.updateNameServicio(this.nameForm.value, this.id).subscribe(
      resp => {
        console.log(resp);  
      }
    )
  }

  updateTown() {
    this.formSubmitted = true;
    if (this.nameForm.invalid) {
      return;
    }
    this.servicioService.updateNameServicio(this.nameForm.value, this.id).subscribe(
      resp => {
        console.log(resp);  
      }
    )
  }

  
}
