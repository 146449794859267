import { Component, Input, OnInit } from '@angular/core';
import { ReseniasService } from 'src/app/core/services/resenias.service';

@Component({
  selector: 'total-response',
  templateUrl: './card-total-response.component.html',
  styleUrls: ['./card-total-response.component.css']
})
export class CardTotalResponseComponent implements OnInit {

  @Input() id: string;
  public count: number;

  constructor(  
    private reseniasService: ReseniasService
  ) { }

  ngOnInit() {
    this.total();
  }

  total() {
    this.reseniasService.totalRespuestas(this.id).subscribe(
      resp => {
        this.count = resp.count;
      }
    )
  }
}
