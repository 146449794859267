import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'forms-perfil',
  templateUrl: './forms-perfil.component.html',
  styleUrls: ['./forms-perfil.component.css']
})
export class FormsPerfilComponent implements OnInit {

  public formSubmitted = false;
  public perfilForm = this.fb.group(
    {
      ocupation : ['',  [ Validators.required, Validators.minLength(5), Validators.maxLength(100) ]],
      description : ['',  [ Validators.required, Validators.minLength(100), Validators.maxLength(300) ]],
    }
  );

  public id: string;
  public id_perfil: string;
  public rep: any;

  public usuario: Usuarios;
  public log: boolean;
  public id_user: string;
  
  constructor(
    private usuarioService: UsuarioService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private perfilService: PerfilService,
    private route: Router,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
  ) {
    
    this.id = this.ar.snapshot.paramMap.get('id');

    this.usuario = usuarioService.usuarios;

    if (usuarioService.getLog) {
      this.log = usuarioService.getLog();
    }

    if (this.log == true) {
      this.id_user = usuarioService.usuarios._ids;
    }  
   }

  ngOnInit() {
      this.perfilSeleccionado();
  }
  
  updatePerfil() {
    this.formSubmitted = true;
  
    if (this.perfilForm.invalid) {
      return;
    }
      
    this.perfilService.updatePerfil(this.perfilForm.value, this.id_perfil).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })   
      }
    )
  }
  
  campoNoValido(campo: string): boolean {
    if (this.perfilForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }
  

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => {
        
        this.id_perfil = resp.data[0]._id;
        localStorage.setItem( "id_perfil", this.id_perfil )
        const ocupation = resp.data[0].ocupation;
        const description = resp.data[0].description;
        this.perfilForm.setValue({ ocupation: ocupation, description: description })
     }
    ) 
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}
