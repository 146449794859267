import { Component } from '@angular/core';
import { NotificacionesService } from './core/services/notificaciones.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'plataforma gestion pecuaria';

    

   
}
