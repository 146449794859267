import { Component, HostListener } from '@angular/core';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';


@Component({
  selector: 'app-mods',
  templateUrl: './modulos.component.html'
})

export class ModulosComponent  {

  constructor(
    private noti : NotificacionesService
  ){

  }

  cerrar(){
    console.log("cerrando");
    this.noti.cerrar_menu.emit(
      {data : false},
    )
  }


}
