<div class="relative w-full mt-20">


<!-- search compt -->
<section class="flex items-center flex-grow h-16 px-4 mb-9">
  <div class="min-w-full ">
      <div class="relative ">
          <div class="absolute left-3 top-2 text-xl text-dark-400 ">
              <i class='bx bx-search-alt'></i>
          </div>
      </div>
     
      <input type="search"  placeholder="Busqueda" class="w-12  min-w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 rounded-full shadow-md focus:bg-gray-100 focus:ring ring-blue-300 focus:outline-none"
          aria-label="" aria-describedby="basic-addon1">

  </div>
</section>
<!-- end search compt -->
<!-- user list -->

<div class="contenedor w-full overflow-y-scroll h-screen">

  <!-- listando las conversaciones  -->
  <div *ngFor="let conver of conversacion " class="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-100">
    <div [ngClass]="{'contacto': user==conver.members[0]._id }" class=" relative w-1/4 cursor-pointer">
      <img [routerLink]="['/perfil', conver.members[0]._id]" *ngIf="conver.members[0].imagen"
        [src]="conver.members[0].imagen.thumbnail" alt=""
        class="object-cover h-12 w-12 rounded-full"
        alt=""/>
         <img [routerLink]="['/perfil', conver.members[0]._id]" *ngIf="!conver.members[0].imagen"
         src="assets/imagen/general/no-imagen.png" alt=""
         class="object-cover h-12 w-12 rounded-full"
         alt=""/>
        <a class="absolute left-8 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
         <div class="w-2 h-2"></div>
         </a>
     </div>
     <div [ngClass]="{'contacto': user==conver.members[1]._id }"  class=" relative w-1/4 cursor-pointer">
      <img [routerLink]="['/perfil', conver.members[1]._id]" *ngIf="conver.members[1].imagen"
        [src]="conver.members[1].imagen.thumbnail" alt=""
        class="object-cover h-12 w-12 rounded-full"
        alt=""/>
      <img [routerLink]="['/perfil', conver.members[1]._id]" *ngIf="!conver.members[1].imagen"
      src="assets/imagen/general/no-imagen.png" alt=""
        class="object-cover h-12 w-12 rounded-full"
        alt=""/>
        <a class="absolute left-8 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
         <div class="w-2 h-2"></div>
         </a>
     </div>
    <div  (click)="id_conversacion(conver)" class="w-full">
     <div [ngClass]="{'contacto': user==conver.members[0]._id }" class="text-base font-semibold">{{conver.members[0].name}}</div>
     <div [ngClass]="{'contacto': user==conver.members[1]._id }" class="text-base font-semibold">{{conver.members[1].name}}</div>
    </div>
  </div>
    

  
</div>




</div>

<!-- end user list -->
