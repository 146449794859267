import { Component, Input, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/core/services/fileUpload.service';
import { PerfilService } from 'src/app/core/services/perfil.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'cuenta-perfil',
  templateUrl: './cuenta-perfil.component.html',
  styleUrls: ['./cuenta-perfil.component.css']
})
export class CuentaPerfilComponent implements OnInit {

  @Input() id: string;
  @Input() validacion: boolean;

  public resultado: any;
  public progress: number; 
  public id_perfil: string;

  public imagenUpdate: File;
  public imgUpdate: any = null;

  constructor(
    private perfilService: PerfilService,
    private fileService: FileUploadService,
  ) { }

  ngOnInit() {
    this.perfilSeleccionado();
    this.progressPerfil();
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => {
        this.id_perfil = resp.data[0]._id;
        this.resultado = resp.data;
      }
    )
  }

  progressPerfil() {
    this.perfilService.progressPerfil().subscribe(
      resp => { this.progress = resp.data }
    )
  }

  cambiarUpdateImagen(file: File) {
    this.imagenUpdate = file;
    if (!file) { return this.imgUpdate = null }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.updateImagen();
  }

  updateImagen() {
    if (this.imagenUpdate == null) { return } 
    this.fileService.uploadUpdateImagenProfile(this.imagenUpdate)
      .then(resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == "success") {
          setTimeout(() => {
            this.imagenUpdate = null;
            this.perfilSeleccionado();
          }, 1500);
        }             
      }
    )    
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}