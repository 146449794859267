import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public id: string;  // id del usuario proviene de la url 
  public servicios: any = [];
  public totalDocuments: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 0;

  public page: number = 1;

  public usuario: Usuarios;
  public cuentaActual: boolean = false;
  public verificacion: boolean = false;
  public log: boolean;
  public id_user: string;

  constructor(
    private ar: ActivatedRoute,
    private usuarioService: UsuarioService,
    private perfilService: PerfilService,
    private serviciosService: ServiciosService
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.usuario = usuarioService.usuarios;
    if (usuarioService.getLog) { this.log = usuarioService.getLog(); }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids; }   
  }

  ngOnInit() {
    this.verficacionPerfil();
    this.serviciosListado(this.id);
  }

    // metodo para verficar si la cuenta tiene un perfil o no 
  verficacionPerfil() {
    this.perfilService.verificacionPerfil(this.id).subscribe(
      resp => {
        this.verificacion = resp.result;
        this.perfiLogueado();
      }
    )
  }
  
  // metodo para verificar si la actual cuenta pertenece a la cuenta logueado o no 
  perfiLogueado() {
    if (this.id_user == this.id) { this.cuentaActual = true; }
    else { this.cuentaActual = false; }
  }

  serviciosListado(id: string) {
    this.serviciosService.serviciosDelUsuarioSeleccionado(id, this.page, 10).subscribe(
      resp => {
        if (resp.msg == 'Tiene perfil') {
          this.servicios = resp.data.services;
          this.totalDocuments = resp.data.totalDocuments;
          this.totalPages = resp.data.totalPages;
          this.currentPage = resp.data.currentPage;
          this.paginars();
        }
      }
    )
  }

  public paginaMas: number = 0;
  public paginaMasMas: number = 0;

  public paginaMenos: number = 0;
  public paginaMM: number = 0;

  cambiarPagina(valor: number) {
    this.page += valor;
    if (this.page < 1) {
      this.page = 1;
    } else if (this.page > this.totalPages) {
      this.page -= valor;
    }
    this.serviciosListado(this.id);
  }


  cambiarPaginaNumero(valor: number) {
    this.page = valor;
    this.paginars();
    this.serviciosListado(this.id);
  }

  paginars() {
    if (this.currentPage < 1) {
      this.paginaMenos = 0;
      this.paginaMM = 0;
    } else if( this.currentPage < 2 ) {
      this.paginaMenos = this.currentPage - 1;
    } else {
      this.paginaMenos = this.currentPage -1;
      this.paginaMM = this.currentPage -2;
    }

    if (this.currentPage == this.totalPages-1) {
      this.paginaMas = (this.totalPages);
      this.paginaMasMas = 0;
    } else if (this.currentPage == this.totalPages) {
      this.paginaMas = 0;
      this.paginaMasMas = 0;
    } else {
      this.paginaMas = this.paginaMenos + 2;
      this.paginaMasMas = this.paginaMenos + 3;
    }
  }

}
