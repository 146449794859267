<div class="h-20 w-full">

</div>
<div class="container mx-auto mt-2 lg:mt-24 mb-20 lg:mt-5">
    <div class="grid lg:grid-cols-4 gap-3">

        <div class="lg:col-span-1">
            <menu-perfil [id]="id" [tipo]="'p'" class="lg:hidden"></menu-perfil>
            <info-perfil></info-perfil>
        </div>

        <div class="lg:col-span-3">
            <menu-perfil [id]="id" [tipo]="'p'" class="hidden lg:block"></menu-perfil>

            <div *ngIf="cuentaActual ">
                <alert-verificacion [id]="id"></alert-verificacion>
            </div>

            <!-- informacion de perfil  -->
            <div *ngIf="verificacion" class="mt-2 lg:mt-3 mx-3">
                <div class="text-dark-400 text-justify">
                    <h2 class="text-xl font-bold">
                        <i class="icofont-caret-right"></i> Información de perfil
                    </h2>

                    <div *ngFor="let p of resultado" class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                        <p class="text-dark-300 text-md">
                            <span class="font-bold text-dark-300"> Compañia o Ocupación: </span> {{p.ocupation}} {{p.company_name}}
                        </p>
                        <p class="text-dark-300 text-md">
                            <span class="font-bold text-dark-300"> Tipo de usuario: </span>{{p.type}}
                        </p>
                        <p>{{p.description}}</p>
                        <hr class="my-2">
                        <div class="text-dark-300 text-md">
                            <span class="font-bold text-dark-300"> Experiencias: </span>
                            <span class="ml-3" *ngIf="p.experience.length <= 0"> No tiene ninguna experiencia 
                                <a [routerLink]="['/perfil/configuracion/', id]" *ngIf="cuentaActual" class="italic font-bold text-sm text-dark-300 hover:underline cursor-pointer">
                                    <i class="icofont-plus-circle"></i> agrega experiencia
                                </a>
                            </span>
                            <ul class="list-inside" *ngFor="let e of p.experience">
                                <li class="ml-3"> <i class="icon-villeta-circle-alt text-dark-400 mr-3"></i>{{e.experience}} {{e.year}}</li>
                            </ul>
                        </div>
                        <div class="text-dark-300 text-md">
                            <span class="font-bold text-dark-300"> Estudios: </span>
                            <span class="ml-3" *ngIf="p.study.length <= 0"> No tiene ningun estudio realizado 
                                    <a [routerLink]="['/perfil/configuracion/', id]" *ngIf="cuentaActual" class="italic font-bold text-sm text-dark-300 hover:underline cursor-pointer">
                                        <i class="icofont-plus-circle"></i> agrega estudio
                                    </a>
                                </span>

                            <ul class="list-inside" *ngFor="let s of p.study">
                                <li class="ml-3"> <i class="icon-villeta-circle-alt text-dark-400 mr-3"></i>{{s.study}} - {{s.academy}}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div *ngIf="!verificacion" class="mt-2 lg:mt-8 mx-3">
                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                    <div class="flex items-center content-center text-dark-400 text-xl">
                        <span class="icon-info-circle mr-2"></span> Este usuario no tiene un perfil creado
                        <a routerLink="/creacion/perfil" class="font-bold ml-2 italic text-sm text-dark-300 hover:underline cursor-pointer">
                            <i class="icofont-plus-circle"></i> Crear mi perfil
                        </a>
                    </div>
                </div>
            </div>


            <review-perfil *ngIf="verificacion" [id_perfil]="id_perfil"></review-perfil>
        </div>
    </div>
</div>