<div class="h-20 w-full">

</div>
<div class="mb-24 container mx-auto xl:mt-28 mt-4" *ngFor="let n of necesidad">
    <div class="mx-8">
        <div class="xl:flex justify-between">
            <div class="xl:w-1/4 mr-8 hidden xl:block">
                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white py-4 mb-3">
                    <div class="flex items-center justify-center mt-3 xl:mt-0 mb-4">
                        <a [routerLink]="['/perfil', n.user._id]" class="cursor-pointer">
                            <img *ngIf="n.user.imagen" [src]="n.user.imagen.thumbnail" class="object-cover object-center w-48 h-48 rounded-full shadow-xl">
                            <img *ngIf="!n.user.imagen" src="assets/imagen/general/perfil-1.svg" class="object-cover object-center w-48 h-48 rounded-full shadow-xl">
                        </a>
                    </div>
                    <div class="text-center">
                        <a [routerLink]="['/perfil', n.user._id]" class="cursor-pointer">
                            <h2 class="font-bold text-dark-300 text-base">
                                {{n.user.name | higherlower}}
                            </h2>
                        </a>
                        <h3 class="font-medium text-dark-300 text-xs">
                            {{n.user.email}}
                        </h3>
                    </div>
                </div>
                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-2 mb-3">
                    <div class="text-xs font-bold  text-dark-400 mb-2">
                        Información de la necesidad
                    </div>
                    <div class="flex justify-between items-center content-center">
                        <div>
                            <small class="text-aply-100 text-md">
                                <i class="icon-ubicacion-alt"></i> 
                                {{n.municipality.name | higherlower}}
                            </small>
                        </div>
                        <div class="text-xs text-dark-300">
                            {{n.createdAt | date}}
                        </div>
                    </div>
                    <hr class="my-2">

                    <div class="pb-7">
                        <div class="flex justify-between items-center content-center">
                            <div class="text-xs font-bold mt-3 text-dark-400">
                                rango de precio
                            </div>
                        </div>
                        <div class="py-1 relative min-w-full my-2">
                            <div class="h-2 bg-gray-200 rounded-full">
                                <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                <div class="absolute text-dark-300 text-md  ml-1 bottom-0 left-0 -mb-7">
                                    ${{n.min_price}}
                                </div>
                                <div class="absolute text-dark-300 text-md  mr-1 bottom-0 right-0 -mb-7">
                                    ${{n.max_price}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="xl:w-3/4">
                <div class="flex justify-between items-center content-center w-full">
                    <div class="flex items-center content-center">
                        <div class="text-5xl lg:text-6xl mr-2 text-dark-400">
                            <i [class]="n.subcategory.icon"></i>
                        </div>
                        <div>
                            <div>
                                <h2 class="font-bold text-dark-400 text-xl lg:text-3xl">
                                    {{n.tittle | higherlower}}
                                </h2>
                            </div>
                            <div>
                                <h3 class="font-semibold text-dark-300 text-md lg:text-xl">
                                    {{n.subcategory.name}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="text-right mt-1 space-x-1">
                        <a (click)="aplicarNecesidad()" data-title="Aplicar a esta necesidad" class="btn-s cursor-pointer text-lg text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <i class="bx bxs-bookmark-alt-plus mr-1"></i>
                        </a>
                        <a *ngIf="auxUsuario" data-title="Editar esta necesidad" class="btn-s cursor-pointer text-lg text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <i class='bx bxs-edit'></i>
                        </a>
                    </div>
                </div>
                <nn-imagens [id_necesidad]="id"></nn-imagens>
                <div class="xl:hidden mt-4">
                    <div class="flex">
                        <div class="mr-2 w-12 h-12">
                            <a [routerLink]="['/perfil', n.user._id]">
                                <img *ngIf="n.user.imagen" [src]="n.user.imagen.thumbnail" class="object-cover object-center w-10 h-10 rounded-full shadow-xl">
                                <img *ngIf="!n.user.imagen" src="assets/imagen/general/perfil-1.svg" class="object-cover object-center w-10 h-10 rounded-full shadow-xl">
                            </a>
                        </div>
                        <div class="w-full">
                            <a [routerLink]="['/perfil', n.user._id]">
                                <h2 class="font-bold  text-dark-300 text-lg">
                                    {{n.user.name}}
                                </h2>
                            </a>
                            <h3 class=" text-dark-200 text-xs font-bold mb-1">
                                {{n.user.email}}
                            </h3>
                        </div>
                    </div>
                </div>
                <p class="text-dark-300 text-base my-3">{{n.description | higherlower}}</p>
                <hr class="my-2">
                <div class="xl:hidden">
                    <div class="lg:flex lg:space-x-2">
                        <div class="lg:w-1/2 xl:w-4/12 border-4 border-gray-100 border-dashed rounded-2xl bg-white p-2">
                            <div class="flex justify-between items-center content-center">
                                <small class="text-aply-100 text-md"><i class="icon-ubicacion-alt"></i> 
                                  {{n.municipality.name | higherlower}}
                              </small>
                                <div class="text-xs text-dark-300">
                                    {{n.createdAt | date}}
                                </div>
                            </div>
                            <hr class="my-2">
                            <div class="pb-7">
                                <div class="text-xs font-bold mt-3 text-dark-400">
                                    rango de precio
                                </div>
                                <div class="py-1 relative min-w-full">
                                    <div class="h-2 bg-gray-200 rounded-full">
                                        <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                        <div class="absolute text-dark-300 text-md  ml-1 bottom-0 left-0 -mb-7">${{n.min_price}}
                                        </div>
                                        <div class="absolute text-dark-300 text-md  mr-1 bottom-0 right-0 -mb-7">${{n.max_price}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <review-necesidad [log]='log'></review-necesidad>

            </div>
        </div>
    </div>
</div>