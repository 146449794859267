import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onLineUsers'
})
export class OnLineUsersPipe implements PipeTransform {

  transform(value: any, onlineUsers: any): any {
  

    console.log("usuarios pipe ", onlineUsers)
    const line = onlineUsers.find((user: { userId: any; }) => user.userId == value);
    console.log("pipe consultadndo si esta conectado...", line)

    if(line){
      return true
    }else{
      return false
    }

  }

}
