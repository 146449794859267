import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


// Declaraciones
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';


// import 
import { SharedPipesModule } from '../shared.pipes/shared.pipes.module';
import { AuthModule_ } from '../modulos/auth/auth.module';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { CalificacionStartComponent } from './calificacion-start/calificacion-start.component';
import { CalificacionLikeComponent } from './calificacion-like/calificacion-like.component';
import { Notificacion_menuComponent } from './notificacion_menu/notificacion_menu.component';
import { Perfil_menuComponent } from './perfil_menu/perfil_menu.component';

import { ModalComponent } from './modal/modal.component';
import { ResponseTipComponent } from './response-tip/response-tip.component';
import { ResponseReviewComponent } from './response-review/response-review.component';
import { AlertVerificacionComponent } from './alert-verificacion/alert-verificacion.component';
import { Crear_menuComponent } from './crear_menu/crear_menu.component';
import { Buscador_menuComponent } from './buscador_menu/buscador_menu.component';

/////

import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';

import {MatNativeDateModule} from '@angular/material/core';
import { MaterialModule } from '../material/material.module';
import { MenuComponent } from './menu/menu.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    AlertsComponent,
    CalificacionStartComponent,
    CalificacionLikeComponent,
    Notificacion_menuComponent,
    Perfil_menuComponent,
    ModalComponent,
    ResponseTipComponent,
    ResponseReviewComponent,
    AlertVerificacionComponent,
    Crear_menuComponent,
    Buscador_menuComponent,
    MenuComponent
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    AlertsComponent,
    CalificacionStartComponent,
    CalificacionLikeComponent,
    Notificacion_menuComponent,
    Perfil_menuComponent,
    ModalComponent,
    ResponseTipComponent,
    ResponseReviewComponent,
    AlertVerificacionComponent,
    Crear_menuComponent,
    Buscador_menuComponent,
    MaterialModule

  ],
  imports: [
    SharedPipesModule.forRoot(),
    CommonModule,
    AuthModule_,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MaterialModule
    
  ]
})

export class SharedModule { }
