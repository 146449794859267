import { CardResponseComponent } from "./components/card-response/card-response.component";
import { CardReviewComponent } from "./components/card-review/card-review.component";
import { CardboardComponent } from "./components/cardboard/cardboard.component";
import { ImagensComponent } from "./components/imagens/imagens.component";
import { ResponseReviewComponent } from "./components/response-review/response-review.component";
import { ReviewNecesidadComponent } from "./components/review-necesidad/review-necesidad.component";

// pages
import { DetalleComponent } from "./detalle/detalle.component";
import { NecesidadesComponent } from "./necesidades/necesidades.component";


export const components: any[] = [
    NecesidadesComponent,
    DetalleComponent,
    CardboardComponent,
    ImagensComponent,
    ReviewNecesidadComponent,
    ResponseReviewComponent,
    CardReviewComponent,
    CardResponseComponent
]

export * from "./detalle/detalle.component";
export * from "./necesidades/necesidades.component";