import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriasRoutes } from './categorias/categorias.routing';
import { ChatRoutes } from './chat/chat.routing';
import { CreacionNecesidadRoutes } from './creacion-necesidad/creacion-necesidad.routing';

import { CreacionServiciosRoutes } from './creacion-servicios/creacion-servicios.routing';
import { CreadorPerfilRoutes } from './creador-perfil/creador-perfil.routing';
import { HomeRoutes } from './home/home.routing';
import { NotificationsRoutes } from './notifications/notifications.routing';
import { PerfilRoutes } from './perfil/perfil.routing';
import { WebchatRoutes } from './webchat/webchat.routing';
import { NecesidadesRoutes } from './necesidades/necesidades.routing';
import { RecomendacionesRoutes } from './recomendaciones/recomendaciones.routing';
import { ServiciosRoutes } from './servicios/servicios.routing';
import { PrestadoresRoutes } from './prestadores/prestadores.routing';

// import 


const routes: Routes = [];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
		HomeRoutes,
		PerfilRoutes,
		CreadorPerfilRoutes,
		CreacionServiciosRoutes,
		CreacionNecesidadRoutes,
		CategoriasRoutes,
		ChatRoutes,
		WebchatRoutes,
		NotificationsRoutes,
		ServiciosRoutes,
		NecesidadesRoutes,
		PrestadoresRoutes,
		RecomendacionesRoutes,
	],
	exports: [RouterModule]
})


export class ModulosRoutes {}
