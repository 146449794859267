//TOD0: Cambiar el url de los serivicios en prod
import { domain, clientId } from '../../auth_config.json';

export const environment = {
  production: true,
  base_url: 'https://api.d3una.com/api',
  socket: 'https://api.d3una.com/',
  auth: {
    domain,
    clientId,
    redirectUri: window.location.origin,
  },
};
