<nav class="bg-white border-gray-200 dark:bg-gray-900">
  <div
    class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1"
  >
    <a
      [routerLink]="['/']"
      class="flex items-center space-x-3 rtl:space-x-reverse"
    >
      <img
        style="height: 60px; padding: 4px"
        src="assets/imagen/logo/logo-white.png"
        class="h-8"
        alt="Flowbite Logo"
      />
    </a>
    <div
      class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse"
    >
      <button
        *ngIf="!id_user"
        [routerLink]="['/login']"
        style="position: absolute; top: 14px; right: 180px"
        type="button"
        class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Entrar
      </button>
      <button
        *ngIf="id_user"
        type="button"
        class="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        data-dropdown-toggle="user-dropdown"
        data-dropdown-placement="bottom"
      >
        <span class="sr-only">Open user menu</span>
        <img
          class="w-8 h-8 rounded-full"
          src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
          alt="user photo"
        />
      </button>
      <!-- Dropdown menu -->
      <div
        class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
        id="user-dropdown"
      >
        <div class="px-4 py-3">
          <span class="block text-sm text-gray-900 dark:text-white"
            >Andrew Castro.</span
          >
          <span class="block text-sm text-gray-500 truncate dark:text-gray-400"
            >a.castro@d3una.com</span
          >
        </div>
        <ul class="py-2" aria-labelledby="user-menu-button">
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Billetera</a
            >
          </li>
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Bandeja de entrada</a
            >
          </li>
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Conexiones</a
            >
          </li>
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Perfil</a
            >
          </li>
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Configuraciones</a
            >
          </li>
          <li>
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >Salir</a
            >
          </li>
        </ul>
      </div>
      <button
        data-collapse-toggle="navbar-user"
        type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-user"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
    </div>
    <div
      class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
      id="navbar-user"
    >
      <ul
        class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
      >
        <li>
          <a
            href="#"
            [routerLink]="['/necesidades']"
            class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
            aria-current="page"
            >Necesidades</a
          >
        </li>
        <li>
          <a
            href="#"
            [routerLink]="['/personas']"
            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >Personas</a
          >
        </li>
        <li>
          <a
            href="#"
            [routerLink]="['/servicios']"
            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >Servicios</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<!--
<div [@menuInOut]  *ngIf="view" class=" hidden z-10 w-full h-20 flex fixed bg-white shadow-md lg:hidden">
  <app-menu></app-menu>

  <div class="grow h-full flex justify-center p-2">
    <img routerLink="/" src="assets/imagen/logo/logo-tras.png" alt="" class="h-full cursor-pointer">
  </div>
  <div *ngIf="conversacion != '' && sms === true && log" class="flex w-16 h-full justify-center p-4 cursor-pointer relative">
    <div *ngIf="n_msm > 0"  class="w-6 h-6 bg-red-500 rounded-full absolute top-2 right-2">
      <p  class="text-white text-md text-center">{{n_msm}} </p>
    </div>
    <i routerLink="/chat/contacto" class='bx bxs-message-detail text-4xl text-blue-500 mt-1'></i>
  </div>
</div>
<div [@footercitoInOut]  *ngIf="view" class=" hidden z-20 flex w-full bg-white h-20 fixed bottom-0 left-0 justify-between border px-2 lg:hidden">
  <div  class="flex w-14 h-full justify-center p-4 ">
    <i routerLink="/" class='bx bx-home text-3xl text-gray-400 cursor-pointer'></i>
  </div>
  <div class="flex w-18 h-full justify-center p-4 ">
      <i [routerLink]="[ '/servicios']" class='bx bx-briefcase text-3xl text-gray-400 cursor-pointer '></i>
  </div>
  <div class="flex w-18 h-full justify-center p-4 ">
      <i  class='bx bx-hard-hat text-3xl text-gray-400 cursor-pointer '></i>
  </div>
  <div class="flex w-14 h-full justify-center p-4 relative">
    <i [routerLink]="[ '/notificaciones/all' ]" class='bx bx-bell text-3xl text-gray-400 cursor-pointer'></i>
    <div *ngIf="n_notificacion > 0" class="w-6 h-6 bg-red-500 rounded-full absolute -top-1 -right-1 flex justify-center">
      <p class="text-base text-white font-semibold">{{n_notificacion}}</p>
    </div>
  </div>
  <div class="flex w-14 h-full justify-center p-4 ">
    <i (click)="LogOfPerfil()" class='bx bx-user-circle text-3xl text-gray-400 cursor-pointer'></i>
  </div>
</div>

<div  class="hidden w-full justify-between h-16 shadow-md lg:flex fixed bg-white px-2 z-10">
  <div class="flex w-auto h-full justify-center p-2 cursor-pointer">
    <img [routerLink]="[ '/']"  src="assets/imagen/logo/logo-tras.png" alt="" class="w-20 object-cover">
  </div>
  
  <div class="flex w-full h-full justify-end p-4 text-semibold">
    <span [routerLink]="[ '/servicios']" class="mt-1 font-sans font-semibold text-gray-500 cursor-pointer hover:text-blue-400 mr-7">Servicios</span>
    <span [routerLink]="[ '/necesidades']" routerLinkActive="active" class="mt-1 font-sans font-semibold text-gray-500 cursor-pointer hover:text-blue-400 mr-7">Necesidades</span>
    <span [routerLink]="[ '/prestadores']" routerLinkActive="active" class="mt-1 font-sans font-semibold text-gray-500 cursor-pointer hover:text-blue-400 mr-7">Prestadores</span>
  </div>
  <div class="flex w-1/2 h-full p-2 flex justify-center items-center">  
  

    <div *ngIf="log" class="flex w-14 h-full justify-center items-center relative">
      <i [routerLink]="[ '/notificaciones/all' ]" class='bx bx-bell text-3xl text-gray-400 cursor-pointer'></i>
      <div *ngIf="n_notificacion > 0" class="w-5 h-5 bg-red-500 rounded-full absolute top-1 right-1 flex justify-center">
        <p class="text-sm text-white font-semibold">{{n_notificacion}}</p>
      </div>
    </div>
    <app-menu ></app-menu>
    <button  *ngIf="!log" [routerLink]="['/login']"  type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Entrar</button>
  </div>
  
</div>

-->
