<div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-2 mt-2">
    <div class="flex justify-between items-center text-xs">
        <div class="font-bold  text-dark-400">
            Horario de atención
        </div>
        <div *ngIf="auxUsuario">
            <a (click)="openModalCrear('crear')" class="font-bold italic text-dark-300 hover:underline cursor-pointer">agregar </a>
        </div>
    </div>

    <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-7 lg:grid-cols-5 xl:grid-cols-3 gap-4 my-2 text-xs font-bold  text-dark-400 place-items-center">
        <div *ngFor="let h of atencion">


            <div class="shadow-md rounded-lg bg-white">
                <div class="flex flex-col items-center content-center bg-blue-50 p-2 w-20 relative rounded-t-lg">
                    <i class="icon-relog text-3xl"></i>
                    <span class="font-medium text-dark-300">{{h.start_time}}</span>
                    <span class="font-medium text-dark-300">{{h.end_time}}</span>
                    <span class="text-dark-400">{{h.day | higherlower}}</span>
                </div>
                <div *ngIf="auxUsuario" class="flex justify-between items-center text-dark-300 content-center text-md bg-white text-center rounded-b-lg">
                    <div (click)="openModalEditar('editar', h)" class="w-full p-1 border-r cursor-pointer">
                        <i class="icon-edit"></i>
                    </div>
                    <div (click)="deleteHorario(h)" class="w-full p-1 cursor-pointer">
                        <i class="bx bx-trash"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<jw-modal *ngIf="auxUsuario" id="crear">
    <div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-1">
        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 ">
            <div class="border-b px-5 py-2 flex justify-between items-center ">
                <h3 class="font-semibold text-sm text-dark-300 ">Nuevo horario de atencion 2</h3>
                <button class="text-base px-1 focus:ring-none " (click)="closeModal('crear'); "> 
                    <i class='bx bx-x'></i> 
                </button>
            </div>

            <div class="py-5">
                <form [formGroup]="horarioForms" (submit)="guardarHorario() ">
                    <div class="px-5 py-1 ">

                        <!-- <sh-alerts *ngIf="campoNoValido('message')" [msg]="'La recomendacion debe tener minimo 10 caracteres' " [alert]=" 'error' "></sh-alerts> -->

                        <div class="flex justify-center items-center  space-x-2">

                            <select class="input-3 p-2">
                                <option *ngFor="let d of dias" [value]="d">{{d}}</option>
                            </select>

                            <span class="font-bold text-sm text-dark-300 mb-2">
                                desde
                            </span>
                            <input type="time" name="eta" class="input-3 p-2 border" min="12:00" pattern="[0-9]{2}:[0-9]{2}">

                            <span class="font-bold text-sm text-dark-300 mb-2">
                                  hasta
                            </span>
                            <input type="time" name="eta" class="input-3 p-2 border">

                        </div>

                    </div>
                    <div class="px-5 py-1 flex space-x-2 justify-end ">
                        <span class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300 " (click)="closeModal( 'crear-tip'); ">Cancel</span>
                        <button type="submit " class="btn-1 focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100 "> 
                        <i class="icofont-save "></i> Guardar
                    </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal *ngIf="auxUsuario" id="editar">
    <div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-1">
        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 ">

            <div class="border-b px-5 py-2 flex justify-between items-center ">
                <h3 class="font-semibold text-sm text-dark-300 ">Editar horario de atencion</h3>
                <button class="text-base px-1 focus:ring-none " (click)="closeModal('editar'); "> 
                    <i class='bx bx-x'></i> 
                </button>
            </div>

            <div class="py-5">
                <form [formGroup]="horarioUpdtaForms" (submit)="updateHorario() ">
                    <div class="px-5 py-1 ">

                        <div class="flex justify-center items-center  space-x-2">

                            <select formControlName="day" class="input-3 p-2">
                                <option *ngFor="let d of dias" [value]="d">{{d}}</option>
                            </select>

                            <span class="font-bold text-sm text-dark-300 mb-2">
                                desde
                            </span>

                            <input formControlName="start_time" type="time" name="eta" class="input-3 p-2 border" min="12:00" pattern="[0-9]{2}:[0-9]{2}">

                            <span class="font-bold text-sm text-dark-300 mb-2">
                                  hasta
                            </span>
                            <input formControlName="end_time" type="time" name="eta" class="input-3 p-2 border">

                        </div>

                    </div>
                    <div class="px-5 py-1 flex space-x-2 justify-end ">
                        <span class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300 " (click)="closeModal('editar'); ">Cancel</span>
                        <button type="submit " class="btn-1 focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100 "> 
                            <i class="icofont-save "></i> Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</jw-modal>