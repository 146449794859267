<form [formGroup]="perfilForm" (ngSubmit)="updatePerfil()" autocomplete="off" class="mb-3">
    
    <div class="grid grid-cols-2 gap-5">
        <div class="lg:col-span-2 col-span-2">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Ocupación</label>
                <input formControlName="ocupation" type="text" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                <div *ngIf="perfilForm.get('ocupation').invalid && (perfilForm.get('ocupation').dirty || perfilForm.get('ocupation').touched)">
                    <sh-alerts *ngIf="perfilForm.get('ocupation').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                    <sh-alerts *ngIf="perfilForm.get('ocupation').errors.minlength" [msg]="'minimo 5 caracteres'" [alert]="'error'"></sh-alerts>
                    <sh-alerts *ngIf="perfilForm.get('ocupation').errors.maxlength" [msg]="'maximo 100 caracteres'" [alert]="'error'"></sh-alerts>
                </div>
               
            </div>
        </div>
    </div>

    <div class="lg:col-span-12 col-span-12">
        <div class="flex flex-col">
            <label class="font-bold text-base text-dark-300 mb-4">Descripción</label>
            <textarea formControlName="description" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" rows="3"></textarea>
            <div *ngIf="perfilForm.get('description').invalid && (perfilForm.get('description').dirty || perfilForm.get('description').touched)">
                <sh-alerts *ngIf="perfilForm.get('description').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                <sh-alerts *ngIf="perfilForm.get('description').errors.minlength" [msg]="'minimo 100 caracteres'" [alert]="'error'"></sh-alerts>
                <sh-alerts *ngIf="perfilForm.get('description').errors.maxlength" [msg]="'maximo 300 caracteres'" [alert]="'error'"></sh-alerts>
            </div>
        </div>
    </div>

    <div class="w-full xl:w-48 text-right mb-1 mt-7">
        <button class="w-16 h-8 rounded-xl btn-2 focus:ring text-white  hover:bg-green-600 active:bg-green-700 focus:bg-green-600 bg-green-600"><i class='bx bxs-save'></i></button>
    </div>

</form>