import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';


import { ModulosComponent } from '../modulos.component';
import { CuentaComponent, PerfilComponent, ServiciosComponent, NecesidadComponent, RecomendacionesComponent, ConfiguracionComponent } from './page';


const routes: Routes = [
  	{ 
		path: 'perfil',
		component: ModulosComponent,
		canActivate: [AuthGuard],
		children: [
		  {path:':id', component: PerfilComponent},
		  {path:'servicios/:id', component: ServiciosComponent},
		  {path:'necesidades/:id', component: NecesidadComponent},
		  {path:'recomendaciones/:id', component: RecomendacionesComponent},
		  {path:'cuenta/:id', component: CuentaComponent},
		  {path:'configuracion/:id', component: ConfiguracionComponent},
		]
	},
];

@NgModule({
	imports: [
    RouterModule.forChild(routes),
  ],
	exports: [RouterModule]
})


export class PerfilRoutes { }

