import { CategoriasComponent } from "./components/categorias/categorias.component";
import { NecesityComponent } from "./components/necesity/necesity.component";
import { ServicesComponent } from "./components/services/services.component";
// pagina 
import { SubcategoriaComponent } from "./subcategoria/subcategoria.component";

export const component: any[] = [
    SubcategoriaComponent,
    CategoriasComponent,
    ServicesComponent,
    NecesityComponent
]

export * from "./subcategoria/subcategoria.component";
