<!-- This is an example component -->
<div *ngIf="alert == 'info'" class="container" id="alertbox">
    <div class="container rounded-xl bg-blue-300 flex items-center space-x-3 text-dark-400 text-sm font-bold px-4 py-3 relative" role="alert">
        <span class="icon-info-circle-i text-2xl"></span>
        <p>{{msg}}</p>
    </div>
</div>

<div *ngIf="alert == 'success'" class="container" id="alertbox">
    <div class="container rounded-xl bg-green-300 flex items-center space-x-3 text-dark-400 text-sm font-bold px-4 py-3 relative" role="alert">
        <span class="icon-success-circle-i text-2xl"></span>
        <p>{{msg}}</p>
    </div>
</div>

<div *ngIf="alert == 'error'" class="container" id="alertbox">
    <div class="container rounded-xl bg-red-300 flex items-center space-x-3 text-dark-400 text-sm font-bold px-4 py-3 relative" role="alert">
        <span class="icon-error-circle text-2xl"></span>
        <p>{{msg}}</p>
    </div>
</div>

<div *ngIf="alert == 'warning'" class="container" id="alertbox">
    <div class="container rounded-xl bg-yellow-300 flex items-center space-x-3 text-dark-400 text-sm font-bold px-4 py-3 relative" role="alert">
        <span class="icon-warning-i text-2xl"></span>
        <p>{{msg}}</p>
    </div>
</div>