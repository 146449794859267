import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/core/services/chat.service';
import Swal from 'sweetalert2';
import { DetailsPropuestaComponent } from './components/detailsPropuesta/detailsPropuesta.component';

@Component({
  selector: 'app-propuestas',
  templateUrl: './propuestas.component.html',
  styleUrls: ['./propuestas.component.css']
})
export class PropuestasComponent implements OnInit {

  public propuesta:any;
  public id:any;

  //aqui una suscripcion para refrescar lista de propuestas cuando haya un cambio
  public suscription: Subscription;
  user: any;

  constructor(
    private router: Router,
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.id = this.activerouter.snapshot.paramMap.get('id');
      this.propuestas(this.id);

    this.suscription = this.chat.refresh.subscribe(() => {
      this.propuestas(this.id);
    });

    this.i();
  }

  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.user._id
        console.log("usuario logueado", this.user);
      }
    )

  }

  propuestas(valor){
    
    this.chat.propuestas(valor).subscribe( 
      data => {
        this.propuesta = data;
        console.log("propuestas", data);
      } )
  }

  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 5.5,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 415,
        "settings": {
          "slidesToShow": 2.2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 2.2,
          "slidesToScroll": 1,
        }
      }
    ]
  };

 //metodo para envia el estado de la propuesta

 selec_estado(id, veredicto, icon){

  Swal.fire({
    imageUrl: 'assets/imagen/icons/'+icon+'.png',
    imageHeight: 60,
    title: icon+'!',
    text: `Puedes cancelar y elegir otro estado`,
    width: '330px',
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: `Aceptar`,
    confirmButtonColor:'#2784FE',
    denyButtonText: `Cancelar`,
    denyButtonColor: '#F62326',
    

  }).then((result) => {
    if (result.isConfirmed) {
      
      this.chat.veredicto(id, veredicto, this.id).subscribe( 
        data => {
          console.log("estado", data);
        } )
      //window.location.href= this.link;
    }else if(result.isDenied){

      result.isDenied
    }
  })
}


  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {

      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  detailsPropuesta(data) {

    this.dialog.open(DetailsPropuestaComponent, {
      data:{data}
    })
  }

}
