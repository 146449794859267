import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web_pasarela',
  templateUrl: './web_pasarela.component.html',
  styleUrls: ['./web_pasarela.component.css']
})
export class Web_pasarelaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  credito():void{
    this.psee = false;
    this.credi = !this.credi;
    this.debi = false;
  }
  debito():void{
    this.debi = !this.debi;
    this.credi = false;
    this.psee = false;
  }
  pse():void{
    this.credi = false;
    this.debi = false
    this.psee = !this.psee;
  }

  credi = false;
  debi = false;
  psee = false;

}
