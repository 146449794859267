import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ModulosComponent } from '../modulos.component';
import { Crear_propuestaComponent, Detalle_facturaComponent, PagoComponent, PropuestasComponent } from './components';
import { ChatComponent } from './components/chat/chat.component';
import { MessageComponent } from './components/message/message.component';
import { PasarelaComponent } from './components/pasarela/pasarela.component';

const routes: Routes = [
  { 

    path: 'chat',
		component: ModulosComponent,
		canActivate: [AuthGuard],
		children: [
		  	{path:'contacto', component:  ChatComponent },
      		{path:'mensajes/:id/:user', component:  MessageComponent },
			{path:'propuesta/create/:id/:user', component: Crear_propuestaComponent},
			{path:'propuestas/:id/:user', component: PropuestasComponent},
			{path:'pago/:id/:user', component:PagoComponent},
			{path:'pago/pasarela/:id/:user', component:PasarelaComponent},
			{path:'pago/factura/:id/:user', component:Detalle_facturaComponent},
			// {path:'maps/:id/:user', component:MapsComponent}

		]

   },
];

export const ChatRoutes = RouterModule.forChild(routes);
