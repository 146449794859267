import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class ReseniasService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: { 'auth-token': this.token }
    }
  }
  


   // Ruta para verificar el tiempo transcurido en una reseña /?type/time/review/:id -> necessity
  tiempoTranscurrido(id:string, type: string) {
    const url = `${base_url}/${type}/time/review/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }


  
  // http://127.0.0.1:3333/api/review/time/response/6216d4bddcefead1fe2b024a
  tiempoTranscurridoResponse(id: string) {
    const url = `${base_url}/review/time/response/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // /count/review/:id/responses
  totalRespuestas(id: string) {
    const url = `${base_url}/count/review/${id}/responses`;
    return this.http.get(url).pipe( tap((resp:any) => {}) )
  }

  // ruta para listar reseñas /:type/review -> type => service, necessity, profile
  listarResenias(id:string, page: number, limit: number, type: string) {
    const url = `${base_url}/${type}/${id}/review?page=${page}&limit=${limit}`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    );
  }

  // ruta para crear resenias /:type/review -> type => service, necessity, profile
  crearResenias( data: { message: string, id_ref: string }, type: string, ) {
    const url = `${base_url}/${type}/review`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // ruta para actualizar la reseña /:type/review -> type => service, necessity, profile
  updateResenia( data: { message:string }, id: string, type: string ) {
    const url = `${base_url}/${type}/review/${id}`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // ruta para eliminar la reseña /type/:id_ref/review/:id -> type => service, necessity, profile
  eliminarResenia(id: string, id_ref: string, type: string) {
    const url = `${base_url}/${type}/${id_ref}/review/${id}`;
    return this.http.delete(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // Respuestas 
  // ruta para editar la respuesta de la reseña
  updateResponseReview( data: { message_response:string }, id: string ) {
    const url = `${base_url}/review/response/${id}`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }  

  // ruta para eliminar la respuesta de la reseña /review/:id_ref/response/:id
  eliminarResponseReview(id:string, id_ref:string) {
    const url = `${base_url}/review/${id_ref}/response/${id}`;
    return this.http.delete(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // listado de respuesta de las reseñas (id_review)
  // /review/61f9b379a7ff4a5b33a6f519/responses?page=2&limit=1
  listarIdReviewResponse(id: string, page: number, limit: number) {
    const url = `${base_url}/review/${id}/responses?page=${page}&limit=${limit}`;
    return this.http.get(url).pipe(
      tap((resp:any) => {})
    )
  }

  // review/response/6216dbe97194a130cac8dd6a
  responseResenia( data: { message_response: string, id_review: string }) {
    const url = `${base_url}/review/response`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
}