import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {
  solicitudes: any;
  id_solicitud: any;
  n_menu: boolean;

  public suscription: Subscription;
  n_solicitud: any;

  constructor(
    private noti: NotificacionesService
  ) { }

  ngOnInit() {
    this.onSolicitudes();

    this.suscription = this.noti.refresh.subscribe(() => {
      this.onSolicitudes();
     })
  }

  onSolicitudes(){
    
    this.noti.solicitudes().subscribe( 
      data => {
        this.solicitudes = data.data;
        console.log("solicitudes", data.data);
        this.n_solicitud = this.solicitudes.filter(element => element.status == 'Espera')
        console.log(this.n_solicitud);
      } );

  }

  menu(valor){
    this.id_solicitud = valor;
    this.n_menu = !this.n_menu;
  }

  //metodo para enviar veredicto del la solicitud de servicio a un prestador de servicio
  veredicto(id, veredicto){

    Swal.fire({
     
      title: "Vas a "+ veredicto +' la solicitud!',
      text: `¿Estas seguro de `+ veredicto + ' la solicitud?',
      width: '330px',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Aceptar`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Cancelar`,
      denyButtonColor: '#F62326',
      
  
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.noti.veredicto_servicio(id, veredicto).subscribe( 
          data => {
            console.log("estado", data);
          } )
        //window.location.href= this.link;
      }else if(result.isDenied){
  
        result.isDenied
      }
    })
  }
  

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {

      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}
