import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ModulosComponent } from '../modulos.component';
import { BodyComponent } from './components';

const routes: Routes = [
  { 
    
    path: 'web',
		component: ModulosComponent,
		canActivate: [AuthGuard],
		children: [
		  	{path:'chat', component:  BodyComponent },
			{path:'chat/:id/:user', component: BodyComponent}
		]
   },
];

export const WebchatRoutes = RouterModule.forChild(routes);
