<div class="w-full">

<div class="contenedor altura overflow-y-scroll w-full pt-9   bg-gray-50 flex flex-col sm:justify-center items-center sm:pt-0 pb-48">
  
  <div class="w-full sm:max-w-md p-5 mx-auto mt-36">
    <h2 class="mb-4 text-center text-xl font-bold text-gray-500">Creando Propuesta</h2>
   
  </div>

  <div class="flex w-full border h-12 cursor-pointer mb-5">
    <div (click)="capturarmodel('Service')" (click)="servicio()" [ngClass]="{'select': serv, 'noselect': !serv}" class="w-1/2 h-full py-4 flex justify-center items-center">
      <a  [ngClass]="{'select_text': serv, 'noselect_text': !serv}" class="text-3x1 text-gray-500">Servicios</a>
    </div>
    <div (click)="capturarmodel('Necessity')" (click)="necesidad()" [ngClass]="{'select': nece, 'noselect': !nece}" class="w-1/2 h-full py-4 bg-blue-500 flex justify-center items-center">
      <a [ngClass]="{'select_text': nece, 'noselect_text': !nece}" class="text-3x1 text-gray-500">Necesidades</a>
    </div>
  </div>

  <div *ngIf="serv" class="w-full h-auto flex px-4">
    <div class="relative inline-block w-12 mr-2  align-middle select-none transition duration-200 ease-in">
      <input type="checkbox" name="toggle" id="toggle"  (click)="service_my()"
        class="bg-gray-500 border-gray-600 mr-1 focus:ring-transparent toggle-checkbox absolute block w-6 h-6 rounded-full border-2 appearance-none cursor-pointer"/>
      <label for="toggle"
        class="toggle-label block h-8 -ml-1 -mt-1 rounded-full bg-gray-300 cursor-pointer">
      </label>
    </div>
    <p *ngIf="!service_me" class="text-lg text-gray-500 font-semibold -mt-1 ml-2">Servicios de usuario</p>
    <p *ngIf="service_me" class="text-lg text-gray-500 font-semibold -mt-1 ml-2">Mis Servicios</p>
  </div>

 

  <div [ngClass]="{'display_necesidades': nece, 'no_display_necesidades': !nece}" class="w-full sm:max-w-md p-5 mx-auto">
    <h2 class="mb-5 text-left  text-gray-500 text-2xl font-semibold">Necesidades</h2>

    <div *ngIf="!necesidades || necesidades == ''" class="w-full h-12 border border-red-500 p-2">
      <p class="text-sm font-semibold -mt-1 text-red-500">No tienes necesidades creadas asi no podrar enviar propuestas de tu necesidad.</p>
    </div>

    <div class="w-full h-auto max-h-72 overflow-y-scroll">
      <div *ngFor="let necesidad of necesidades" [ngClass]="{'seleccionar': necesidad._id == captura}" class="w-full h-auto rounded-lg h-auto border flex p-2 mb-2">
        <div class="w-1/5 h-auto rounded-lg ">
          <img *ngIf="necesidad.images[0]" class="rounded-lg" [src]="necesidad.images[0].thumbnail" alt="">
          <img *ngIf="!necesidad.images[0]" class="rounded-lg" src="assets/imagen/general/no-imagen.png" alt="">
        </div>
        <div class="pl-4 w-3/5">
          <p class="text-base font-semibold text-gray-700 truncate ">{{necesidad.tittle}}</p>
          <p class="text-sm text-gray-500 font-semibold -mt-1">Necesidad</p>
        </div>
        <div class="w-1/5 flex justify-end pr-4">
          <input (click)="capturarvalor(necesidad._id)" class=" my-auto transform scale-125" type="radio" name="sfg" />
        </div>
        
      </div>
      
    </div>

  </div>

   <!-- servicios del prestador de servicio -->
   <div [ngClass]="{'display_servicios': serv, 'no_display_servicios': !serv}" class="w-full sm:max-w-md p-5 mx-auto">
    <h2 class="mb-12 text-left  text-gray-500 text-2xl font-semibold">Servicios</h2>

    <div *ngIf="!servici" class="w-full h-12 border border-red-500 p-2">
      <p class="text-sm font-semibold -mt-1 text-red-500">Usuario no cuenta con servicios</p>
    </div>

    <div class="w-full h-auto max-h-72 overflow-y-scroll">
      <div *ngFor="let ser of servici" [ngClass]="{'seleccionar': ser._id == captura}" class="w-full h-auto rounded-lg h-auto border flex p-2 mb-2">
        <div class="w-1/5 h-auto rounded-lg cursor-pointer " [routerLink]="['/servicios', ser._id]" >
          <img *ngIf="ser.images[0]" class="rounded-lg" [src]="ser.images[0].thumbnail" alt="">
          <img *ngIf="!ser.images[0]" class="rounded-lg" src="assets/imagen/general/no-imagen.png" alt="">
          </div>
        <div class="pl-4 w-3/5">
          <p [routerLink]="['/servicios', ser._id]" class="text-base font-semibold text-gray-700 truncate cursor-pointer ">{{ser.name}}</p>
          <p class="text-sm text-gray-500 font-semibold -mt-1">Servicio</p>
        </div>
        <div class="w-1/5 flex justify-end pr-4">
          <input (click)="capturarvalor(ser._id)" class=" my-auto transform scale-125" type="radio" name="sfg" />
        </div>
        
      </div>
      
    </div>

  </div>
  
  <form [formGroup]="proposalForm">
    <input formControlName="conversation_id" type="hidden" [(ngModel)]="conversacion">
    <input formControlName="id_property" type="hidden" [ngModel]="captura">
    <input formControlName="property_model" type="hidden" [(ngModel)]="model">
    <input formControlName="receiver" type="hidden" [(ngModel)]="id">
  <div class="w-full sm:max-w-md p-5 mx-auto">
    <h2 class="mb-12 text-left  text-gray-500 text-2xl font-semibold">Tu propones el precio</h2>
    <input [ngClass]="{'campo': value.errors?.min}"  formControlName="value" type="text" placeholder="100.000" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
    <div [ngClass]="{'campo': value.errors.required}" class="text-danger mt-2" *ngIf="value.invalid && (value.dirty || value.touched)">
      <p *ngIf="value.errors.required">campo es requerido</p>
      <p *ngIf="value.errors?.min">El monto minimo son 50.000</p>
     </div>
  </div>

</form>

  <button (click)="onCreateProposal()" class="bg-blue-500 mb-12 hover:bg-blue-700 text-white text-2x1 font-bold py-2 px-4 rounded">
    Enviar propuesta
  </button>


</div>

</div>

<script>
  let Checked = null;
//The class name can vary
for (let CheckBox of document.getElementsByClassName('only-one')){
	CheckBox.onclick = function(){
  	if(Checked!=null){
      Checked.checked = false;
      Checked = CheckBox;
    }
    Checked = CheckBox;
  }
}
</script>