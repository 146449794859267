import { Component, Input, OnInit } from '@angular/core';
import { PerfilService } from 'src/app/core/services/perfil.service';

@Component({
  selector: 'menu-perfil',
  templateUrl: './menu-perfil.component.html',
  styleUrls: ['./menu-perfil.component.css']
})
export class MenuPerfilComponent implements OnInit {

  @Input() tipo: string;
  @Input() id: string;

  public perfil: boolean = false;
  public servicio: boolean = false;
  public necesidad: boolean = false;
  public recome: boolean = false;
  public cuenta: boolean = false;

  public verificacion: boolean = false;

  constructor(
    private perfilService: PerfilService
  ) { }

  ngOnInit() {
    this.actualVista();
    this.verficacionPerfil();
  }


  actualVista() {
    if (this.tipo == "p") {
      this.perfil = true;
    } else if (this.tipo == "s") {
      this.servicio = true;
    } else if (this.tipo == "n") {
      this.necesidad = true;
    } else if (this.tipo == "r") {
      this.recome = true;
    } else if (this.tipo == "c") {
      this.cuenta = true;
    }

  }

    // metodo para verficar si la cuenta tiene un perfil o no 
    verficacionPerfil() {
      this.perfilService.verificacionPerfil(this.id).subscribe(
        resp => { this.verificacion = resp.data }
      )
    }
}
