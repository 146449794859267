<div class="h-20 w-full">

</div>
<div class="container mx-auto mt-0">
    <div class="mx-5 xl:mx-0">
        <div class="flex flex-col items-center justify-center content-center">
            <div class="w-full mt-10 lg:mt-28">
                <h1 class="text-3xl font-bold text-center text-aply-100">
                    Registro de Usuario o Empresa
                </h1>
                <h3 class="text-xl text-center text-dark-400 mt-5">
                    Crea tu perfil de usuario o de empresa para poder solicitar u ofrecer servicios.
                </h3>
            </div>
            <div class="w-full lg:w-3/4 mx-5 mt-10">
                <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-6" (click)="onSelection('enterprise')">
                        <div class="cuadros p-4 {{e}}">
                            <span class="font-bold">Empresa</span>
                        </div>
                    </div>
                    <div class="col-span-6" (click)="onSelection('individual')">
                        <div class="cuadros p-4 {{i}}">
                            <span class="font-bold">Persona</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full lg:w-3/4 mx-5 mt-5">
                <c-empresa *ngIf="enterprise"></c-empresa>
                <c-individual *ngIf="individual"></c-individual>
            </div>

        </div>
    </div>
</div>