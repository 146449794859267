import { Component, OnInit } from '@angular/core';
import { NecesidadesService } from 'src/app/core/services/necesidades.service';

@Component({
  selector: 'app-necesity',
  templateUrl: './necesity.component.html',
  styleUrls: ['./necesity.component.css']
})
export class NecesityComponent implements OnInit {

  public necesidad: any;
  public cont: number;

  constructor(
    private necesidadServices: NecesidadesService
  ) { }

  ngOnInit() {
    this.listarNecesidad();
  }

  listarNecesidad() {
    this.necesidadServices.listarUltimasNecesidades().subscribe(
      resp => {
        this.necesidad = resp.data;
        var aux = 0;
        this.necesidad.forEach(e => {  aux = aux + 1 });
        this.cont = aux;
      }
    )
  }

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 3,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2,
        }
      },
      {
        "breakpoint": 1050,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2,
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 500,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 200,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      }
    ]
  };

  
  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) { }
  
  beforeChange(e) {}

}