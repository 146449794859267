import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url


@Injectable({
  providedIn: 'root'
})  
export class UbicacionService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

  listadoDepartamento() {
    const url = `${base_url}/departments`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => {})
    );
  }

  listadoMunicipio(
    id: string
  ) {
    const url = `${base_url}/departments/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => {})
    );
  }


  

}
