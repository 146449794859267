<div class="container mx-auto my-10">
    <div class="mx-3 xl:mx-0">
        <h1 *ngIf="cont > 0" class="text-dark-400 font-bold text-xl mb-2">Ultimos necesidades</h1>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let n of necesidad" class="slide">
                <div class="grid gap-5 grid-cols-1 sm:mx-1">
                    <div class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-80 mb-5">
                        <div class="p-3">

                            <div class="flex flex-col space-y-3">
                                <div class="flex space-x-4 pb-3">
                                    <div class="w-24 md:w-44">
                                        <img *ngIf="n.images != ''" [src]="n.images[0].thumbnail" title="p1" class="object-center object-cover w-24 md:w-44 h-36 rounded-2xl border flex-1">
                                        <img *ngIf="n.images == ''" src="assets/imagen/general/no-imagen.png" title="p2" class="object-center object-cover w-24 md:w-44  h-36 rounded-2xl border flex-1">
                                    </div>
                                    <div class="flex flex-col ">
                                        <span class="font-bold text-dark-400 text-md md:text-xl" [title]="n.tittle">
                                            {{n.tittle | slice:0:16}} {{ (n.tittle.length> 16) ? '...': '' }}
                                        </span>
                                        <small class="text-dark-300 font-medium"> {{n.subcategory.name}}</small>
                                        <small class="text-aply-100 font-medium mb-4"><i class="icofont-location-pin"></i> {{n.municipality.name}}</small>
                                        <div class="w-full h-full">
                                            <span class="input-1 text-sm bg-green-600 px-2 w-24 text-white">Disponible</span>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div class="py-1 relative min-w-full">
                                        <div class="h-2 bg-gray-200 rounded-full">
                                            <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;" [title]="n.min_price"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;" [title]="n.max_price"></div>
                                            <div class="absolute text-gray-800 text-md ml-1 bottom-0 left-0 -mb-7">$ {{n.min_price}}</div>
                                            <div class="absolute text-gray-800 text-md mr-1 bottom-0 right-0 -mb-7">$ {{n.max_price}}</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="flex justify-between pt-5 text-sm font-semibold">
                                    <div class="flex items-center content-center"></div>
                                    <div class="flex items-center content-center">
                                        <i class="icon-msg-espera-alt text-aply-100 text-xl mr-1"> </i> {{n.review.length}} Reseña
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="p-3 bg-dark-100 rounded-b-2xl text-dark-300">
                            <div class="flex justify-between items-center">
                                <div class="flex items-center">
                                    <a [routerLink]="['/perfil', n.user._id]" class="cursor-pointer">
                                        <img [src]="n.user.imagen.thumbnail" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                                    </a>

                                    <div class="ml-2">
                                        <a [routerLink]="['/perfil', n.user._id]" class="cursor-pointer">
                                            <h4 class="font-bold capitalize ">{{n.user.name}}</h4>
                                        </a>
                                    </div>

                                </div>
                                <div class="font-normal uppercase text-right">
                                    {{n.updatedAt | date:"mediumDate"}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>