import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: { 'auth-token': this.token  }
    }
  }


  ratingStart( value: number, propertyModel: string, property: string, type: string ) {
    const url = `${base_url}/calificar/${value}/model/${propertyModel}/${property}/${type}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => {  })
    )
  }


  // api/like/Review/61c235c1fe2d24fd6a9e34f9
  ratingLikes(id: string, type: string) {
    const url = `${base_url}/like/${type}/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }


  // like de perfil = Review
  // like de servicios = Service
  // like de recomendaciones = RecommendationResponse
  // like de Respuestas de reseñas = ReviewResponse
  
}
