<div class="w-full">
    <div class="flex flex-col items-center justify-center content-center h-screen">

        <div class="">
            <img src="assets/imagen/logo/logo-tras.png" alt="" class="w-40">
        </div>

        <h3 class="font-bold text-2xl text-dark-300">Recuperar contraseña</h3>
        <div class="mt-5 w-3/5	 xl:w-1/4 text-center">
            <p>Por favor, ingrese el email (correo electronico) con el que se encuentra registrado.</p>
        </div>

        <div class="mt-5 w-3/5	 xl:w-1/4">
            <form [formGroup]="emailForm" (ngSubmit)="emailMsg()" class="grid gap-6">
                <div class="flex flex-col">
                    <label for="Correo" class="font-bold text-base text-dark-300">Email *</label>
                    <input type="email" formControlName="email" placeholder="Ejemplo@gmail.com" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('email')" [msg]="'El email es obligatorio y tiene que ser valido'" [alert]="'error'"></alerts-auth>
                </div>
                <div>
                    <input type="submit" value="Enviar" class="input-1 bg-aply-600 w-full text-white hover:shadow-inner">
                </div>
            </form>
            <!-- <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off" class="grid gap-6">
                <div class="flex flex-col">
                    <label for="Correo" class="font-bold text-base text-dark-300">Correo</label>
                    <input type="text" formControlName="email" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('email')" [msg]="'El email es obligatorio y tiene que ser valido'" [alert]="'error'"></alerts-auth>
                </div>

                <div class="flex flex-col">
                    <label for="" class="font-bold text-base text-dark-300">Contraseña</label>
                    <input type="password" formControlName="password" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('password')" [msg]="'La constraseña es obligatoria y tiene que ser mayor a 8 caracteres'" [alert]="'error'"></alerts-auth>
                </div>

                <div class="flex items-start mt-1">
                    <div class="flex items-center h-5">
                        <input id="comments" type="checkbox" class="h-4 w-4 rounded-lg border-0 bg-gray-700 text-primary-300" formControlName="remember">
                    </div>
                    <div class="ml-3 text-md">
                        <label for="comments" class="font-bold text-gray-700">
                                Recuerdame
                        </label>
                    </div>
                </div>

                <div>
                    <button type="submit" class="input-1 bg-aply-600 w-full text-white hover:shadow-inner">Iniciar sesión</button>
                </div>
            </form> -->


            <div class="text-center col-span-12 mt-10">
                ¿Ya recordastes la contraseña? <a routerLink="/login" class="font-bold hover:underline">Iniciar sesión</a>
            </div>
        </div>

    </div>


</div>



<!-- <div class="modal neon">
    <div class="modal-container max-w-lg">
        <div class="bg-degrado rounded-t-lg h-8"></div>
        <div class="w-4/5 mx-auto pt-3 pb-10  font-nuni">
            <div class="mb-10">
                <a [routerLink]="['/home']">
                    <img src="./assets/img/logo/log2-06.png" alt="" class="">
                </a>
            </div>


            <form class="grid grid-cols-1 md:grid-cols-12 gap-2 px-4 pt-5" id="loginForm" [formGroup]="emailForm" (ngSubmit)="emailMsg()" autocomplete="off">
                <div class="col-span-12 mb-8">
                    <h1 class="text-center text-md text-gray-700 font-bold ">
                        ¿Haz olvidado tu contraseña?
                    </h1>
                </div>
                <div class="col-span-12 mb-8">
                    <label class="label-1">
                      Correo                        
                  </label>
                    <input type="email" class="sm:text-sm input-1" placeholder="Email" formControlName="email">
                    <div class="text-danger">
                        <p *ngIf="campoNoValido('email')">
                            El email es obligatorio y tiene que ser valido
                        </p>
                    </div>
                </div>


                <div class="col-span-12 text-center flex items-center space-x-4">
                    <button type="submit" class="rounded-full py-2 px-5 font-bold bg-primary-300 text-white shadow-lg hover:shadow-md w-full focus:outline-none">
                  Restablecer contraseña
                </button>
                    <span class="text-gray-900 mx-2">ó</span>
                    <a [routerLink]="['/login']" class="font-bold hover:underline px-2 w-1/2 cursor-pointer">
                        Iniciar sesión
                    </a>I
                </div>

            </form>

        </div>

        <div class="bg-degrado rounded-b-lg h-8"></div>

    </div>
</div> -->