import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'buscador_menu',
  templateUrl: './buscador_menu.component.html',
  styleUrls: ['./buscador_menu.component.css']
})
export class Buscador_menuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
