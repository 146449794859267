import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from 'src/app/shared.pipes/shared.pipes.module';
import { SharedModule } from 'src/app/shared.component/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

// import { AgmCoreModule } from '@agm/core';

import * as fromComponents from './components';

@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule.forRoot(),
    SharedModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDknqry9aRDk3voPPW-_SYGDhegAjiEAQM'
    // })
    
  ],
  declarations: [
    ...fromComponents.components,
  ]
})
export class ChatModule { }
