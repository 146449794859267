import { CardResponseComponent } from "./components/cards/card-response/card-response.component";
import { CardReviewComponent } from "./components/cards/card-review/card-review.component";
import { CardTotalResponseComponent } from "./components/cards/card-total-response/card-total-response.component";
import { CardboardComponent } from "./components/cards/cardboard/cardboard.component";
import { HorarioComponent } from "./components/horario/horario.component";
import { ImagensComponent } from "./components/imagens/imagens.component";
import { ResponseReviewComponent } from "./components/response-review/response-review.component";
import { ReviewServiceComponent } from "./components/review-service/review-service.component";
import { FilterServiceComponent } from "./components/filterService/filterService.component";

// pages
import { DetalleComponent } from "./detalle/detalle.component";
import { ServiciosComponent } from "./servicios/servicios.component";


export const components: any[] = [
    ServiciosComponent,
    DetalleComponent, 
    ReviewServiceComponent,
    ResponseReviewComponent,
    ImagensComponent,
    HorarioComponent,
    CardboardComponent,
    CardReviewComponent,
    CardResponseComponent,
    CardTotalResponseComponent,
    FilterServiceComponent,

]
    
export * from "./servicios/servicios.component";
export * from "./detalle/detalle.component";
