<div class="text-xl h-9" [ngClass]="{'accion2': verdadero, 'accion': !verdadero}">

    <!-- estado general de calificaciones -->
    <div class="inactivo">
        <div class="flex items-center content-center space-x-2 ml-1">
            <div class="relative text-xl w-100">
                <div class="absolute crop overflow-hidden" [style.width.px]="starWidth">
                    <i class='icon-estrella'></i>
                    <i class='icon-estrella'></i>
                    <i class='icon-estrella'></i>
                    <i class='icon-estrella'></i>
                    <i class='icon-estrella'></i>
                </div>
                <div class="text-dark-200">
                    <i class='icon-estrella' title="No me ayuda"></i>
                    <i class='icon-estrella' title="Esta mal :C"></i>
                    <i class='icon-estrella' title="Buena"></i>
                    <i class='icon-estrella' title="¡Casi perfecta!"></i>
                    <i class='icon-estrella' title="¡Excelente!"></i>
                </div>
            </div>
            <div>
                <span>{{rating}} <span class="vots">({{totalQuality}} votos)</span></span>
            </div>
        </div>

        <div *ngIf="verdadero == true" class="vots -mt-3 text-center text-dark-200 font-medium">
            <span>tu valoracion es: {{valoracion}}Pts</span>
        </div>
    </div>

    <!-- califica -->
    <div *ngIf="verdadero == false" class="activo">
        <div class="center flex items-center content-center space-x-2">
            <div class="stars">
                <input type="radio" id="five-{{id_ref}}" name="rate-{{id_ref}}">
                <label for="five-{{id_ref}}" class="icon-estrella" (click)="clickRating(5)"></label>
                <input type="radio" id="four-{{id_ref}}" name="rate-{{id_ref}}">
                <label for="four-{{id_ref}}" class="icon-estrella" (click)="clickRating(4)"></label>
                <input type="radio" id="three-{{id_ref}}" name="rate-{{id_ref}}" value="3">
                <label for="three-{{id_ref}}" class="icon-estrella" (click)="clickRating(3)"></label>
                <input type="radio" id="two-{{id_ref}}" name="rate-{{id_ref}}" value="2">
                <label for="two-{{id_ref}}" class="icon-estrella" (click)="clickRating(2)"></label>
                <input type="radio" id="one-{{id_ref}}" name="rate-{{id_ref}}" value="1">
                <label for="one-{{id_ref}}" class="icon-estrella" (click)="clickRating(1)"></label>
            </div>
            <div>
                <span class="text-sm font-semibold text-dark-300">¡Califica!</span>
            </div>
        </div>
    </div>
</div>