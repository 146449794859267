<div class="grid grid-cols-1 gap-3">
    <div class="col-span-1">
        <form [formGroup]="studyForm" (ngSubmit)="aggStudy()" autocomplete="off" class="mb-3">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Estudio</label>
                <div class="flex flex-col items-center content-center">
                    <input formControlName="estudio" type="text" class="input-3 w-full focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Ejemplo: Ingenieria, Enfermeria">
                    <div class="w-full mb-2" *ngIf="studyForm.get('estudio').invalid && (studyForm.get('estudio').dirty || studyForm.get('estudio').touched)">
                        <sh-alerts *ngIf="studyForm.get('estudio').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="studyForm.get('estudio').errors.minlength" [msg]="'minimo 10 caracteres'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="studyForm.get('estudio').errors.maxlength" [msg]="'maximo 100 caracteres'" [alert]="'error'"></sh-alerts>
                    </div>
                    <input formControlName="academia" type="text" class="input-3 w-full focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Ejemplo: Institucion Superior...">
                    <div class="w-full mb-2" *ngIf="studyForm.get('academia').invalid && (studyForm.get('academia').dirty || studyForm.get('academia').touched)">
                        <sh-alerts *ngIf="studyForm.get('academia').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="studyForm.get('academia').errors.minlength" [msg]="'minimo 10 caracteres'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="studyForm.get('academia').errors.maxlength" [msg]="'maximo 100 caracteres'" [alert]="'error'"></sh-alerts>
                    </div>
                </div>
                <div class="w-full text-right mb-1">
                    <button class="btn-1 focus:ring text-white  hover:bg-green-600 active:bg-green-700 focus:bg-green-600 bg-green-600"><i class='bx bxs-save'></i> guardar</button>
                </div>
            </div>
        </form>

        <ul *ngFor="let s of study" class="list-disc max-h-44 bg-gray-50 list-min text-dark-300 font-medium flex justify-between ">
            <li class="flex flex-col text-left p-2">
                <span class="">
                    {{s.study | higherlower}} 
                </span>
                <span class="text-xs">
                    {{s.academy | higherlower}}
                </span>
            </li>
            <li class="w-5 h-7 rounded-full bg-blue-400 flex justify-center tems-center content-center mt-3 relative cursor-pointer " (click)="onDeleteStudy(s._id)">
                <span class="text-center text-white">x</span>
            </li>
        </ul>

    </div>

    <div class="col-span-1 mb-24">
        <form [formGroup]="expeForm" (ngSubmit)="aggExperiencia()" autocomplete="off" class="mb-3">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Experiencias</label>
                <div class="flex flex-col items-center content-center">
                    <input formControlName="experiencia" type="text" class="input-3 w-full focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Ejemplo: Asesor en..., Vendedor en...">
                    <div class="w-full mb-2" *ngIf="expeForm.get('experiencia').invalid && (expeForm.get('experiencia').dirty || expeForm.get('experiencia').touched)">
                        <sh-alerts *ngIf="expeForm.get('experiencia').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="expeForm.get('experiencia').errors.minlength" [msg]="'minimo 10 caracteres'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="expeForm.get('experiencia').errors.maxlength" [msg]="'maximo 100 caracteres'" [alert]="'error'"></sh-alerts>
                    </div>
                    <input formControlName="anio" type="text" class="input-3 w-full focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Ejemplo: 2019...">
                    <div class="w-full mb-2" *ngIf="expeForm.get('anio').invalid && (expeForm.get('anio').dirty || expeForm.get('anio').touched)">
                        <sh-alerts *ngIf="expeForm.get('anio').errors.required" [msg]="'Campo es requerido'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="expeForm.get('anio').errors.minlength" [msg]="'minimo 10 caracteres'" [alert]="'error'"></sh-alerts>
                        <sh-alerts *ngIf="expeForm.get('anio').errors.pattern" [msg]="'solo numeros'" [alert]="'error'"></sh-alerts>
                    </div>
                </div>
                <div class="w-full text-right mb-1">
                    <button class="btn-1 focus:ring text-white  hover:bg-green-600 active:bg-green-700 focus:bg-green-600 bg-green-600"><i class='bx bxs-save'></i> guardar</button>
                </div>
            </div>
        </form>

        <ul *ngFor="let e of expectative" class="list-disc max-h-44 bg-gray-50 list-min text-dark-300 font-medium flex justify-between">
            <li class="flex flex-col text-left p-2">
                <span class="">
                    {{e.experience | higherlower}} 
                </span>
                <span class="text-xs">
                    {{e.year}}
                </span>
            </li>
            <li class="w-5 h-7 rounded-full bg-blue-400 flex justify-center tems-center content-center mt-3 relative cursor-pointer" (click)="onDeleteStudy(s._id)">
                <span class="text-center text-white">x</span>
            </li>
        </ul>

    </div>
</div>