import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';


import { RecomendacionesComponent } from './components';

// import { RecomendacionesComponent } from '../home/component';

const routes: Routes = [
  { 
		path: 'recomendaciones',
		component: ModulosComponent,
		// canActivate: [AuthGuard],
		children: [
		  {path:'', component: RecomendacionesComponent},
		]
	},
];


@NgModule({
	imports: [
    RouterModule.forChild(routes),
  ],
	exports: [RouterModule]
})


export class RecomendacionesRoutes { }
