<label class="font-bold text-base text-dark-300 mb-4">{{muni}}</label>

<form [formGroup]="perfilForm" (ngSubmit)="updateUbicacion()" autocomplete="off" class="mb-3">
    <div class="flex flex-col xl:flex-row justify-between items-center content-center xl:space-x-3">

        <div class="w-full">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Departamento</label>
                <select class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" name="selectedDept" [(ngModel)]="selectedDept._id" (change)="onSelect($event.target.value)" [ngModelOptions]="{standalone: true}">
                    <option value=""> ----- Seleccione el departamento ----- </option>

                    <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                </select>
            </div>
        </div>

        <div class="w-full">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Ciudad</label>
                <select formControlName="town" [(ngModel)]="town_id"  class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                    <option value=""> ----- Seleccione el Ciudad ----- </option>
                    <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                </select>
                <div *ngIf="perfilForm.get('town').invalid && (perfilForm.get('town').dirty || perfilForm.get('town').touched)">
                    <sh-alerts *ngIf="perfilForm.get('town').errors.required" [msg]="'Seleccione ciudad'" [alert]="'error'"></sh-alerts>
                </div>
            </div>
        </div>

        <div class="w-full xl:w-48 text-right mb-1 mt-7">
            <button class="btn-2 focus:ring text-white  hover:bg-green-600 active:bg-green-700 focus:bg-green-600 bg-green-600"><i class='bx bxs-save'></i></button>
        </div>

    </div>
</form>