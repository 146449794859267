import { Pipe, PipeTransform } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';


@Pipe({
  name: 'showUser'
})
export class ShowUserPipe implements PipeTransform {
  messages: any;
  participants: any;
  last: any;
  data: any;

  constructor(
    private user: UsuarioService,
    private message: ChatService
  ){

  }
  transform(value: any, args?: any): any {

    return this.onUser(value, args)
  
    console.log("aver ", this.messages)

  }

  async onUser(valor:any, args) {
    await this.user.showUser(valor).toPromise().then((response) => {
      if(args == 'name'){

        this.data = response.data.name
      }

      if(args == 'image'){

        this.data = response.data.imagen.thumbnail

      }

    }).catch(e => console.error(e));
    
    return this.data
  }


}


