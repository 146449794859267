import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';

@Component({
  selector: 'app-notificacion_menu',
  templateUrl: './notificacion_menu.component.html',
  styleUrls: ['./notificacion_menu.component.css']
})
export class Notificacion_menuComponent implements OnInit {
  
  public suscription: Subscription;
  
  notificaciones: any;
  id_notificacion: any;
  n_menu: boolean=false;
  notificacion: boolean = false;
  n_notificacion: number = 0;

  constructor(
    private noti: NotificacionesService,
  ) { }

  ngOnInit() {
    
    this.onNotificaciones();

    this.suscription = this.noti.refresh.subscribe(() => {
      this.onNotificaciones();
     });

    this.noti.push_notificacion.subscribe(
      data => {
        console.log("dato emitido", data);
        if(this.n_notificacion < 1){
          this.onNotificaciones();
        }else{
          this.n_notificacion = this.n_notificacion + 1;
          this.notificaciones.push(data.data.data);
          console.log("con push", this.notificaciones);
        }
        
        
      }
    );
    
    this.noti.cerrar_menu.subscribe(
      data => {
        this.n_menu = false;
        this.notificacion = false;
      }
    )
  }

  onNotificaciones(){
    this.noti.notificaciones().subscribe( 
      data => {

        this.notificaciones = data.success;
        this.n_notificacion = data.success.length;
        console.log("numero de notificaciones", data.success.length);
         console.log("notificaciones menu", this.notificaciones);
    });
  }

  menu(valor) {
    this.onNotificaciones();
    this.id_notificacion = valor;
    this.n_menu = !this.n_menu;
  }

  //menu
  onNotificacion(){
    this.notificacion = !this.notificacion;
  }



}
