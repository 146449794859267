import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';

const routes: Routes = [
  { 

    path: 'notificaciones',
		component: ModulosComponent,
		// canActivate: [AuthGuard],
		children: [
		  	{path:'all', component:  NotificacionesComponent },
		]

   },
];

export const NotificationsRoutes = RouterModule.forChild(routes);
