import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'review-service',
  templateUrl: './review-service.component.html',
  styleUrls: ['./review-service.component.css']
})
export class ReviewServiceComponent implements OnInit {
  
  public formSubmitted = false;
  public  reseniaForm= this.fb.group({
    message: ['', [Validators.required]],
    id_ref: ['', [Validators.required]],
  });
  
  public id: string //viene del parametro id url
  
  public id_user1: string;
  public auxUsuario: boolean = false;
  public log: boolean = false;
  
  public review: any;

  public page: number = 1;
  public limit: number = 5;
  public Data: Array<any> = [];
  public isLoading = true;
  public totalPages: number;
  public totalDocuments: number;
  public currentPage: number;

  constructor(
    private usuarioService: UsuarioService,
    private reseniasService: ReseniasService,
    private servicioService: ServiciosService,
    private ar: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { 
    this.id = this.ar.snapshot.paramMap.get('id');
    this.reseniaForm.setValue({ message: '', id_ref: this.id });
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user1 = usuarioService.usuarios._ids } 
  }

  ngOnInit() {
    this.resenias();
  }

  onResponse() {
    if (this.log == true) {
      this.formSubmitted = true;
      this.reseniasService.crearResenias(this.reseniaForm.value, 'service').subscribe(
        resp => {
          this.reseniaForm.setValue({ message: '', id_ref: this.id });
          this.page = 1;
          this.Data = [];
          this.resenias();
        }
      )
    } else {
      this.onModal();
    }
  }

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  resenias() {
    this.isLoading = true;   

    this.reseniasService.listarResenias(this.id, this.page, this.limit, 'service').subscribe(
      (resp: any) => {
        resp.data.reviews.forEach((e:any) => {
          this.Data.push(e);
        });
        this.totalPages = resp.data.totalPages;
        this.totalDocuments = resp.data.totalDocuments;
        this.currentPage = resp.data.currentPage;
        this.isLoading = false;  
      }
    )
  }
  
  resetearListado(valor: boolean) {
    if (valor == true) {
      this.page = 1;
      this.Data = [];
      this.resenias();
    }
  }

  cambioValorHijo(actualizar) {
    if (actualizar) {
      this.page = 1;
      this.Data = [];
      this.resenias();
    }
  }

  campoNoValido(campo: string): boolean {
    if (this.reseniaForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  onScroll() {
    this.page += 1;
    this.resenias();
  }

}