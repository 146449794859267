import { EmpresaComponent } from "./components/empresa/empresa.component";
import { IndividualComponent } from "./components/individual/individual.component";
import { FormsComponent } from "./forms/forms.component";

// paginas 

export const components : any [] =[
    FormsComponent,
    EmpresaComponent,
    IndividualComponent
]


export * from "./components/empresa/empresa.component";
export * from "./components/individual/individual.component";
export * from "./forms/forms.component";
