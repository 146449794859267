<div class="h-20 w-full">

</div>
<div class="mb-24 container mx-auto xl:mt-28 mt-4" *ngFor="let s of sv">
    <div class="mx-8">
        <div class="lg:flex justify-between">

            <div class="lg:w-1/4 mr-8 hidden lg:block">
                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white py-4 mb-3">
                    <div class="flex items-center justify-center mt-3 xl:mt-0 mb-4">
                        <a [routerLink]="['/perfil', s.profile.user._id]">
                            <img *ngIf="s.profile.user.imagen" [src]="s.profile.user.imagen.thumbnail" class="object-cover object-center w-40 h-40 rounded-full shadow-xl">
                            <img *ngIf="!s.profile.user.imagen" src="assets/imagen/general/perfil-1.svg" class="object-cover object-center w-40 h-40 rounded-full shadow-xl">
                        </a>
                    </div>
                    <div class="text-center">
                        <h2 class="font-bold  text-dark-300 text-base">
                            {{s.profile.user.name}}
                        </h2>
                        <h3 class="font-medium text-dark-300 text-xs">
                            {{s.profile.user.email}}
                        </h3>
                        <h3 class="font-medium text-dark-300 text-xs">
                            <span *ngFor="let c of s.profile.subcategory"> 
                                {{c.name  | higherlower  }}
                             </span>
                        </h3>
                        <small class="text-aply-100 text-xs"><i class="icon-ubicacion-alt"></i> 
                            {{s.profile.town.name | higherlower}}
                        </small>
                    </div>
                </div>

                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-2 mb-3">
                    <div class="text-xs font-bold  text-dark-400 mb-2">
                        Información del servicio
                    </div>
                    <div class="flex justify-between items-center content-center">
                        <div>
                            <small class="text-aply-100 text-md"><i class="icon-ubicacion-alt"></i> 
                                {{s.town.name | higherlower}} 
                            </small>
                        </div>
                        <div class="text-xs text-dark-300">
                            {{s.createdAt | date}}
                        </div>
                    </div>
                    <hr class="my-2">
                    <div>
                        <div class="flex justify-between items-center content-center">
                            <div class="text-xs font-bold mt-3 text-dark-400">
                                rango de precio
                            </div>
                        </div>
                        <div class="py-1 relative min-w-full my-2">
                            <div class="h-2 bg-gray-200 rounded-full">
                                <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                <div class="absolute text-dark-300 text-md  ml-1 bottom-0 left-0 -mb-7">${{s.min_price}}
                                </div>
                                <div class="absolute text-dark-300 text-md  mr-1 bottom-0 right-0 -mb-7">${{s.max_price}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-8">
                    <div class="flex justify-between pt-2 text-sm font-semibold ">
                        <rating-like [likes]="s.like" [id_ref]="s._id" [type_model]="'Service'"></rating-like>
                        <div class="flex items-center content-center">
                            <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                        </div>
                    </div>
                    <rating-start class="mx-auto" [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>
                </div>
                <!-- <att-horario [id]="id"></att-horario> -->
            </div>


            <div class="w-full xl:w-3/4">
                <div class="flex justify-between items-center ">
                    <div class="flex items-center content-center">
                        <div class="text-4xl lg:text-6xl mr-2 text-dark-400">
                            <i [class]="s.subcategory.icon"></i>
                        </div>
                        <div>
                                <h2 class="font-bold text-dark-400 text-lg lg:text-2xl">
                                    {{s.name | higherlower}}
                                </h2>
                                <h3 class="font-semibold text-dark-300 text-md lg:text-md">
                                    {{s.subcategory.name}}
                                </h3>
                        </div>
                    </div>
                    <div class="text-right mt-1">
                        <a (click)="solicitudServicio()" data-title="Solicitar este servicio" class="btn-s cursor-pointer text-lg text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <i class="bx bxs-bookmark-alt-plus mr-1"></i>
                        </a>
                        <a *ngIf="auxUsuario" data-title="Editar este servicio" class="btn-s cursor-pointer text-lg text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <i class='bx bxs-edit'></i>
                        </a>
                    </div>
                </div>
                <ss-imagens [id_service]="id "></ss-imagens>
                <div class="lg:hidden mt-4 ">
                    <div class="flex ">
                        <div class="mr-2 w-12 h-12 ">
                            <a [routerLink]="[ '/perfil', s.profile.user._id] ">
                                <img *ngIf="s.profile.user.imagen " [src]="s.profile.user.imagen.thumbnail " class="object-cover object-center w-10 h-10 rounded-full shadow-xl ">
                                <img *ngIf="!s.profile.user.imagen " src="assets/imagen/general/perfil-1.svg " class="object-cover object-center w-10 h-10 rounded-full shadow-xl ">
                            </a>
                        </div>
                        <div class="w-full ">
                            <a [routerLink]="[ '/perfil', s.profile.user._id] ">
                                <h2 class="font-bold  text-dark-300 text-lg ">
                                    {{s.profile.user.name}}
                                </h2>
                            </a>
                            <h3 class="font-medium text-dark-300 text-sm -mt-2 ">
                                <span *ngFor="let c of s.profile.subcategory ">
                                    {{c.name | higherlower}}
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>

                <p class="text-dark-300 text-base my-3 ">
                    {{s.description | higherlower}}
                </p>
                <hr class="my-2 ">
                <div class="lg:hidden ">
                    <div class="lg:flex lg:space-x-2 ">
                        <div class="w-full lg:w-1/2 xl:w-4/12 border-4 border-gray-100 border-dashed rounded-2xl bg-white p-2 ">
                            <div class="flex justify-between items-center content-center ">
                                <small class="text-aply-100 text-md "><i class="icon-ubicacion-alt "></i> 
                                    {{s.town.name | higherlower}}
                                </small>
                                <div class="text-xs text-dark-300 ">
                                    {{s.createdAt | date}}
                                </div>
                            </div>
                            <hr class="my-2 ">
                            <div>
                                <div class="text-xs font-bold mt-3 text-dark-400 "> rango de precio </div>
                                <div class="py-1 relative min-w-full ">
                                    <div class="h-2 bg-gray-200 rounded-full ">
                                        <div class="absolute h-2 rounded-full bg-aply-100 w-0 " style="width: 75.1935%; left: 11.2903%; "></div>
                                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer " unselectable="on " onselectstart="return false; " style="left: 11.2903%; "></div>
                                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer " unselectable="on " onselectstart="return false; " style="left: 85.4839%; "></div>
                                        <div class="absolute text-dark-300 text-md  ml-1 bottom-0 left-0 -mb-7 ">${{s.min_price}}
                                        </div>
                                        <div class="absolute text-dark-300 text-md  mr-1 bottom-0 right-0 -mb-7 ">${{s.max_price}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-8 ">
                            <div class="flex justify-between pt-2 text-sm font-semibold ">
                                <rating-like [likes]="s.like " [id_ref]="s._id " [type_model]=" 'Service' "></rating-like>
                                <div class="flex items-center content-center ">
                                    <i class="icon-msg-espera-alt text-aply-100 mr-1 "> </i> {{s.review.length}} Reseña
                                </div>
                            </div>
                            <rating-start class="mx-auto " [quality]="s.qualification " [id_ref]="s._id " [type_model]=" 'Service' "></rating-start>
                        </div>
                        <!-- <att-horario class="lg:w-1/2 xl:w-full " [id]="id "></att-horario> -->
                    </div>
                </div>
                <review-service [log]='log'></review-service>
            </div>

        </div>
    </div>
</div>