import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Horario } from '../models/horario.models';
import { Servicio } from '../models/servicio.models';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

  listarUltimosServicios() {
    const url = `${base_url}/servicios/publicos`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }
  
  
  // http://127.0.0.1:3333/api/service/show/619baceaad250e275fb9182e?page=2
  serviciosDelUsuarioSeleccionado(id: string, page: number = 2, limit: number = 10) {
    const url = `${base_url}/service/show/${id}?page=${page}&limit=${limit}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => { })
    )
  }

  // extraer/usuarios/61a7f90135322f7c63b31e72
  listarUsuariosRating(id_servicios: string) {
    const url = `${base_url}/extraer/usuarios/${id_servicios}`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  // extraer/usuarios/61a7f90135322f7c63b31e72
  listarUsuariosLikes(id_servicios: string) {
    const url = `${base_url}/like/extraer/usuarios/${id_servicios}`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  Listado(page:number, limit:number) {
    const url = `${base_url}/services?page=${page}&limit=${limit}`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  search(
    data: {
      subcategory: string,
      municipality: string,
      min_price: string,
      max_price: string,
      word: string,
    },
    page: number,
    limit: number,
  ) {
    const url = `${base_url}/services/search?page=${page}&limit=${limit}`;
    return this.http.post(url, data).pipe(
      tap((resp: any) => { })
    )
  }

  // 61a7f6e01bd4ba7b558d5dd0
  servicioSeleccionado(id: string) {
    const url = `${base_url}/services/${id}`;
    return this.http.get(url)
      .pipe(
        tap((resp: any) => { })
      )
  }

  cargarHorarios(id: string) {
    const url = `${base_url}/service/${id}/show/horarios`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  agregarHorario(
    data: {
      id_service: string,
      day: string,
      start_time: string,
      end_time: string
    }
  ) {
    const url = `${base_url}/attention`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  eliminarHorario(id_hora:string) {
    const url = `${base_url}/attention/${id_hora}`;
    return this.http.delete(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  updateHorario(
    data: {
      day: string,
      start_time: string,
      end_time: string
    },
    id_hora: string
  ) {
    const url = `${base_url}/attention/${id_hora}`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }


  // http://127.0.0.1:3333/api/service/61a7f90135322f7c63b31e72/usuario
  idUsuario(id:string) {
    const url = `${base_url}/service/${id}/usuario`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  // solicitar/61a7f90135322f7c63b31e72 //id del servicio
  solicitarServicio(id: string) {
    const url = `${base_url}/solicitar/${id}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => { })
    )
  }


  // updates 
  // /service/:id/update/name
  updateNameServicio( data: { name:string }, id: string ) {
    const url = `${base_url}/service/${id}/update/name`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
  // /service/:id/update/description
  updateDescriptionServicio( data: { description:string }, id: string ) {
    const url = `${base_url}/service/${id}/update/description`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
  // /service/:id/update/rango
  updateRangoServicio( data: { min_price:number, max_price:number }, id: string ) {
    const url = `${base_url}/service/${id}/update/rango`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
  // /service/:id/update/subcategory
  updateSubcategoryServicio( data: { subcategory:string }, id: string ) {
    const url = `${base_url}/service/${id}/update/subcategory`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
  // /service/:id/update/town
  updateTownServicio( data: { town:string }, id: string ) {
    const url = `${base_url}/service/${id}/update/town`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
}
