import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecomendacionesService } from 'src/app/core/services/recomendaciones.service';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'response-review',
  templateUrl: './response-review.component.html',
  styleUrls: ['./response-review.component.css']
})
export class ResponseReviewComponent implements OnInit {

  @Input() id: string;
  @Input() log: boolean;
  @Output() resetear: EventEmitter<boolean> = new EventEmitter();

  public formSubmitted = false;
  public responseForms = this.fb.group({
    id_review : ['',  [ Validators.required ]],
    message_response : ['',  [ Validators.required, Validators.minLength(10) ]],
  });

  constructor(
    private reseniaService: ReseniasService,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.responseForms.setValue({id_review: this.id, message_response: ''})
  }

  onResponse() {
    if (this.log == true) {
      this.reseniaService.responseResenia(this.responseForms.value).subscribe(
        resp => {
          this.Toast.fire({ icon: resp.alert, title: resp.msg });
          if (resp.alert == 'success') { this.resetear.emit(true) }
        }
      );
    } else {
      this.onModal();
    } 
  }

  campoNoValido(campo: string): boolean {
    if (this.responseForms.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para dar una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

}
