import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web_detalle_factura',
  templateUrl: './web_detalle_factura.component.html',
  styleUrls: ['./web_detalle_factura.component.css']
})
export class Web_detalle_facturaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
