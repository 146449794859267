<div class="w-full h-24" >

</div>
<div class="mb-24 container mx-auto search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="200" (scrolled)="onScroll()" [scrollWindow]="true">
    
    <div class="mx-8">

        <div class="flex">
            <div (click)="btnFiltrar = !btnFiltrar" class="w-80 px-4 py-2 bg-white mr-2 border rounded-2xl hidden lg:block cursor-pointer">
                <div  class="flex justify-between text-dark-300 text-sm">
                    <div class="font-bold ">Filtrar</div>
                    <div class="icon-1"><i class="icon-filtro"></i></div>
                </div>
            </div>

            <div  class="w-10 order-2 lg:hidden ml-2">
                <div (click)="openDialog()" class="border rounded-2xl bg-white p-2 text-dark-300">
                    <div class="icon-1"><i class="icon-filtro"></i></div>
                </div>
            </div>

            <div (click)="limpiar('todo')" class="w-10 p-2 bg-white mr-4 border rounded-2xl hidden lg:block cursor-pointer">
                <div class="flex justify-between text-dark-300 text-sm">
                    <div class="icon-1"><i class='bx bx-trash'></i></div>
                </div>
            </div>

            <div (click)="limpiar('todo')" class="w-10 order-2 lg:hidden ml-2">
                <div class="border rounded-2xl bg-white p-2 text-dark-300">
                    <div class="icon-1"><i class='bx bx-trash'></i> </div>
                </div>
            </div>

            <form [formGroup]="filtroForm" (ngSubmit)="nuevaBusqueda()" autocomplete="off" class="w-full">
                <div class=" col-span-2 lg:col-span-3 order-1 cursor-pointer">
                    <div class="relative text-gray-600 ">
                        <input formControlName="word" type="search" name="serch" placeholder="Buscador" class="border bg-white w-full h-10 px-5 pl-10 rounded-full text-sm focus:outline-none">
                        <button type="submit" class="absolute left-0 top-0 mt-2 ml-4">
                            <i class="icon-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="md:flex mt-6">
            <form *ngIf="!btnFiltrar" [formGroup]="filtroForm" autocomplete="off" class="w-1/2 md:w-52 lg:w-52 mb-3">
                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm mb-3">
                    <h2 class="text-sm font-bold text-dark-300 mb-3">Según su clasificación</h2>
                    <div class="w-full">
                        <label class="font-bold  text-dark-300 mb-4">Categoría</label>
                        <select class="input-3 w-full px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2" [(ngModel)]="selectedCtg._id" (change)="listadoSubcategoria($event.target.value)" [ngModelOptions]="{standalone: true}">
                            <option value=""> --- Seleccione el Categoría --- </option>
                            <option *ngFor="let c of ctgItems" value="{{c._id}}"> {{ c.name }}</option>
                        </select>
                    </div>

                    <div class="w-full">
                        <label class="font-bold text-sm text-dark-300 mb-2">Sub-Categoría</label>
                        <select formControlName="subcategory" (click)="nuevaBusqueda()" class="input-3 px-3  w-full   focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                            <option value=""> --- Seleccione el Sub-Categoría --- </option>
                            <option *ngFor="let s of subItems" [value]="s._id"> <i class="{{s.icon}}"></i> {{ s.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm mb-3">
                    <h2 class="text-sm font-bold text-dark-300 mb-3">Según su ubicación</h2>
                    <div class="w-full">
                        <label class="font-bold text-dark-300 mb-2">Departamento</label>
                        <select class="input-3 w-full px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2" [(ngModel)]="selectedDept._id" (change)="listadoMunicipio($event.target.value)" [ngModelOptions]="{standalone: true}">
                                    <option value=""> --- Seleccione el departamento --- </option>
                                    <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                                </select>
                    </div>
                    <div class="w-full">
                        <label class="font-bold text-dark-300 mb-2">Municipio</label>
                        <select formControlName="municipality" (click)="nuevaBusqueda()" class="input-3 px-3  w-full   focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                                <option value=""> --- Seleccione el municipio --- </option>
                                <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                            </select>
                    </div>
                </div>

                <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-3 text-sm">
                    <h2 class="text-sm font-bold text-dark-300 mb-3">Según su rango de precio</h2>
                    <div class="flex flex-col">
                        <label class="font-bold text-dark-300 mb-1">Precio mínimo ({{min | currency: 'COP' }})</label>
                        <input formControlName="min_price" (click)="nuevaBusqueda()" step="10000" type="range" min="50000" max="50000000" [(ngModel)]="min" (ngModelChange)="min = $event" [ngModelOptions]="{standalone: true}">

                    </div>
                    <div class="flex flex-col">
                        <label class="font-bold text-dark-300 mb-1">Precio máximo ({{max | currency: 'COP' }})</label>
                        <input formControlName="max_price" (click)="nuevaBusqueda()" step="10000" type="range" min="50000" max="50000000" [(ngModel)]="max" (ngModelChange)="max = $event" [ngModelOptions]="{standalone: true}">

                    </div>
                </div>
            </form>

            <div class="w-full md:ml-5">
                <div class="font-bold text-xl text-dark-400 mb-3">
                    <h2>Prestadores ({{totalDocuments}})</h2>
                </div>


                <div *ngIf="totalDocuments > 0" class="grid gap-3 h-4/5 overflow-y-auto ">
                    <servicio-cardboard *ngFor="let foo of prestadores" [servicio]="foo"></servicio-cardboard>
                </div>

                <div *ngIf="isLoading" class="flex items-center justify-center w-full h-full">
                    <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
                        <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                            <path clip-rule='evenodd'
                                d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                fill='currentColor' fill-rule='evenodd' />
                        </svg>
                        <div>Cargando...</div>
                    </div>
                </div>


                <div *ngIf="totalDocuments <=  0" class="mt-8">
                    <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                        <div class="flex items-center content-center text-dark-400 text-xl">
                            <span class="icon-info-circle mr-2"></span>
                            <span class="mr-2" *ngIf="word != ''">No se encuentra el prestador {{word}} en estos momentos </span>
                            <span class="mr-2" *ngIf="word == ''">No se encuentra prestador en estos momentos </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>