<div class="home-search-form" style="display: none;">
    <form class="search-form">
        <div class="font-sans text-black min-h-screen bg-white flex items-center justify-center">
            <div class="border rounded overflow-hidden flex">
                <input type="text" class="px-4 py-2" placeholder="Search...">
                <button class="flex items-center justify-center px-4 border-l">
                    <svg class="h-4 w-4 text-grey-dark" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                </button>
            </div>
        </div>
    </form>
</div>
<div class="main-wrapper">
    <div class="w-full" *ngFor="let is of categoriaItems">
        <h1 style="display: none;" class="text-dark-400 font-semibold text-xl text-center">{{is.name}}
            ({{is.subcategory.length}})</h1>
    </div>
    <ngx-slick-carousel style="top:-40px;" class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)">
        <div ngxSlickItem *ngFor="let sub of subcategoria; let i=index" class="col-span-1 my-4" title="{{sub.name}}">
            <div class="cuadros-home  bg-white rounded-2xl mx-2 ">
                <div
                    class="h-32 md:h-24 xl:h-32 items-relative p-2 flex flex-col content-center items-center justify-center ">
                    <span class="{{sub.icon}} text-5xl md:5xl lg:5xl xl:text-5xl 4xl:text-6xl"></span>
                    <h3 class="text-base font-bold mt-2 text-center ">
                        {{sub.name | slice:0:15}}{{ (sub.name.length> 15) ? '...': '' }}
                    </h3>
                </div>
                <div class="h-32 md:h-24 xl:h-32 items p-2 flex flex-col content-center items-center justify-center  ">
                    <span class="{{sub.icon}} text-4xl md:5xl lg:5xl xl:text-5xl 2xl:text-6xl"></span>
                    <h3 class="font-bold mt-2 text-center ">
                        {{sub.name | slice:0:15}}{{ (sub.name.length> 15) ? '...': '' }}
                    </h3>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
    <div class="w-full text-center browser" *ngFor="let is of categoriaItems">
        <div class="catalog-start">
            <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Encuentra la mejor solución para ti.</h1>
            <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Explorar nuestro cátalogo de personas y empresas.</p>
            <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                Cátalogo
                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
            </a>
        </div>
    </div>
</div>