import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NecesidadesService } from 'src/app/core/services/necesidades.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  public id: string;
  public necesidad: any;

  public log: boolean;
  public id_user: string;
  public id_userLogueado: string; 
  public auxUsuario: boolean = false;

  constructor(
    private necesidadService: NecesidadesService,
    private usuarioService: UsuarioService,
    private ar: ActivatedRoute,
    private router: Router,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;  
        if (this.log == true) {
          this.id_userLogueado = this.usuarioService.usuarios._ids; //id del usuario logueado        
        }   
      }
    )
  }

  ngOnInit() {
    this.cargaNecesidad();
  }

  cargaNecesidad() {
    this.necesidadService.cargarNecesidadPorId(this.id).subscribe(
      resp => {
        this.necesidad = resp.data;
        this.id_user = resp.data[0].user._id; //id del dueño de la necesidad
        this.verificacionCuenta();
      }
    )
  }

  aplicarNecesidad() {
    if (this.log == true) {
      this.necesidadService.aplicarNecesidad(this.id).subscribe(
        resp => { this.Toast.fire({ icon: resp.alert, title: resp.msg }) }
      )
    } else { this.onModal() }
  }

  verificacionCuenta() {    
    if (this.log == true) {      
      if (this.id_user == this.id_userLogueado) { this.auxUsuario = true; }
    }       
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

}