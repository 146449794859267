import { BodyComponent } from "./body/body.component";
import { ContactosComponent } from "./contactos/contactos.component";
import { MessageComponent } from "./message/message.component";
import { PropuestasComponent } from "./propuestas/propuestas.component";
import { Web_cabeceraComponent } from "./web_cabecera/web_cabecera.component";
import { Web_crear_propuestasComponent } from "./web_crear_propuestas/web_crear_propuestas.component";
import { Web_detalle_facturaComponent } from "./web_detalle_factura/web_detalle_factura.component";
import { Web_pagoComponent } from "./web_pago/web_pago.component";
import { Web_pasarelaComponent } from "./web_pasarela/web_pasarela.component";


export const components : any [] =[
    BodyComponent,
    ContactosComponent,
    MessageComponent,
    PropuestasComponent,
    Web_cabeceraComponent,
    Web_crear_propuestasComponent,
    Web_pagoComponent,
    Web_pasarelaComponent,
    Web_detalle_facturaComponent
]


export * from "./body/body.component";
export * from "./contactos/contactos.component";
export * from "./message/message.component";
export * from "./propuestas/propuestas.component";
export * from "./web_cabecera/web_cabecera.component";
export * from "./web_crear_propuestas/web_crear_propuestas.component";
export * from "./web_pago/web_pago.component";
export * from "./web_pasarela/web_pasarela.component";
export * from "./web_detalle_factura/web_detalle_factura.component";

