import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cI, sI } from 'src/app/core/interfaces/categorias.form.interfaces';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { CreacionesService } from 'src/app/core/services/creaciones.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'c-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css']
})
  
export class IndividualComponent implements OnInit {

  public usuario: Usuarios;
  public log: boolean;
  public id_user: string;

  public formSubmitted = false;
  public perfilForm = this.fb.group({
    accountType: ['individual',  [ Validators.required ]],
    ocupation: ['',  [ Validators.required, Validators.minLength(10), Validators.maxLength(60) ]],
    description : ['',  [ Validators.required, Validators.minLength(100), Validators.maxLength(300) ]],
    town: ['',  [ Validators.required ]],
    subcategories: ['',  [ Validators.required ]]
  });

  public selectedCat: cI = {_id: "", name: ""};
  public categoriaItems: cI;
  public subItems: sI;

  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  constructor(
    private usuarioService: UsuarioService,
    private ubicacionService: UbicacionService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private creacionService: CreacionesService,
    private route: Router,
    private fb: FormBuilder,
  ) { 
    this.usuario = usuarioService.usuarios;
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }   
  }

  validation_perfil = {

    'ocupation': [
        { type: 'required', message: 'campo es requerido' },
        { type: 'minlength', message: 'minimo 5 caracteres' },
        { type: 'maxlength', message: 'maximo 100 caracteres' },
      ],

    'description': [
        { type: 'required', message: 'campo es requerido' },
        { type: 'minlength', message: 'minimo 100 caracteres' },
        { type: 'maxlength', message: 'maximo 300 caracteres' },
      ],

    'subcategories': [
        { type: 'required', message: 'Selecciona la subcategoria' },
      ],

    'town': [
        { type: 'required', message: 'Selecciona la ciudad' },
      ],

  } 

  ngOnInit() {
    this.listarCategoria();
    this.listadoDept();
  }

  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => { this.categoriaItems = resp.data }
    )
  }

  listarSubcategoria(id: string) {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => { this.subItems = resp.data.subcategory }
    )
  }

  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data }
    )
  }

  listadoMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities }
    )
  }

  crearPerfilIndividual() {
    this.formSubmitted = true;
    if (this.perfilForm.invalid) { return }
    this.creacionService.createdPerfil(this.perfilForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        this.route.navigate(['perfil', this.id_user]);  //id del perfil
      }
    )
  }

  campoNoValido(campo: string): boolean {
    if (this.perfilForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}