<div *ngIf="contTotal != 0" class="flex items-center">

    <div class="sg-flex">
        <div *ngFor="let u of usuarios" class="avatar">
            <div class="sg-avatar">
                <img *ngIf="u.user.imagen != ''" [src]="u.user.imagen.thumbnail" alt="" class="object-cover object-center w-8 h-8 rounded-full">
                <img *ngIf="u.user.imagen == ''" src=" assets/imagen/general/perfil-1.svg " alt=" " class="object-cover object-center w-8 h-8 rounded-full ">
            </div>
        </div>
    </div>

    <div class="ml-2 text-xs ">
        <h6 class="font-semibold ">
            <span *ngFor="let u of usuarios; let i=index "> <span *ngIf="i<1 " >{{u.user.name}}</span> </span>
            y {{contTotal}} personas mas recomiendan tu servicio
        </h6>
    </div>

</div>

<div *ngIf="contTotal == 0" class="ml-2 text-xs">
    <h6 class=" font-semibold ">Este servicio no tiene ninguna valoración</h6>
</div>