import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoveryService } from 'src/app/core/services/recovery.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
})
  
export class ConfirmacionComponent implements OnInit {

  public email: any;
  public formSubmitted = false;
  public confiCodeForm = this.fb.group({
    code: [ '' , [ Validators.required, Validators.minLength(5)]],   
    email: [ '' , [ Validators.required, Validators.email ]],   
  });

  constructor(
    private ar: ActivatedRoute,
    private router: Router, 
    private fb: FormBuilder,
    private recoveryServices: RecoveryService,
    private usuarioServices: UsuarioService
  ) { }

  ngOnInit() {
    this.email = this.ar.snapshot.paramMap.get('email');
    this.confiCodeForm.setValue({ code: '', email: this.email });
  }

  confirmacionCodigo() {
    this.formSubmitted = true;
    if (this.confiCodeForm.invalid) { return }
    this.usuarioServices.confirmacionCorreo(this.confiCodeForm.value)
     .subscribe(resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == 'success') {
          this.router.navigate( ['/perfil', resp.data.id] );
        }
   })
  }

  campoNoValido(campo: string): boolean {
    if (this.confiCodeForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}