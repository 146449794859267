import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs-compat/operator/filter';
import { ChatService } from 'src/app/core/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit {

  visible: boolean = false;
  breakpoint_: number = 1000;

  public id:any;
  public id_user:any;
  public mensaje:any;
  public user:any;
  public contacto:any;
  public prueba = "joserr";

  public select:number;
  miembros: Object;
  create_pro: boolean = false;
  propuestas: boolean = false;
  pago: boolean = false;
  mensa: boolean = false;
  testArray: any;
  //data_carrucel: { num: number; name: string; icon: string; ruta:string }[];
  data_carrucel_1: void[];

  data_carrucel = [
    {
      'num' : 1,
      'name': 'Conversacion',
      'icon': 'bx bx-message-square-detail',
      'ruta': '/chat/mensajes',
    },
    {
      'num' : 2,
      'name': 'Crear propuesta',
      'icon': 'bx bx-plus-circle',
      'ruta': '/chat/propuesta/create',
    },
    {
      'num' : 3,
      'name': 'Propuestas',
      'icon': 'bx bx-check-circle',
      'ruta': '/chat/propuestas',
    },
    {
      'num' : 4,
      'name': 'Pago',
      'icon': 'bx bx-dollar-circle',
      'ruta': '/chat/pago',
    }
    
  ];
  onlineUsers: any;

  constructor(
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.id      = this.activerouter.snapshot.paramMap.get('id');
    this.id_user = this.activerouter.snapshot.paramMap.get('user');
    this.chat.id_conversacion2.subscribe(
      data => {
        
        this.contacto = data.data.members[0];
        
        console.log("estara en cabecera", this.contacto);
      }
    )

    this.i();
    this.members();


    this.chat.onLineUsers.subscribe(
      resp => {
        this.onlineUsers = resp.data
      }
    )

  }

  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
        console.log("usuario logueado", resp.user._id);
      }
    )
  }

  members(){
    this.chat.members(this.id).subscribe(
      resp => {
        this.miembros = resp.members;
        console.log("miembros", this.miembros);
      }
    )
  }

  menu_carrucel(){

 

  }

  ordenar(valor2){

    function search(valor2, myArray){
      for (var i=0; i < myArray.length; i++) {
          if (myArray[i].num === valor2) {
              return myArray[i];
          }
      }
  }
  
  var resultObject = search(valor2, this.data_carrucel);

  this.data_carrucel.unshift(resultObject);
  this.data_carrucel = this.data_carrucel.filter((item, index)=>{
    return this.data_carrucel.indexOf(item) === index;
  });


  }


  ubicacion(){

    Swal.fire({
      imageUrl: 'assets/imagen/icons/png',
      imageHeight: 60,
      title:'Compartir ubicacion!',
      text: `Asi el prestador de servicio podra llear a tu casa, tambien puedes digitarla manual si asi lo prefieres`,
      width: '330px',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Aceptar`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Cancelar`,
      denyButtonColor: '#F62326',
      
  
    }).then((result) => {
      if (result.isConfirmed) {
        let id      = this.activerouter.snapshot.paramMap.get('id');
        let id_user = this.activerouter.snapshot.paramMap.get('user');
        this.router.navigate(['/chat/maps',id, id_user]);
      }else if(result.isDenied){
        result.isDenied
      }
    })
  }

  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {

      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  
  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 5.5,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 2.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 567,
        "settings": {
          "slidesToShow": 2.1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 425,
        "settings": {
          "slidesToShow": 1.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 1.1,
          "slidesToScroll": 1,
        }
      }
    ]
  };


  
  onResize(event) {
    const w = event.target.innerWidth;
    if (w >= this.breakpoint_) {
      this.visible = true;
      console.log('pantalla esta grande');
      this.router.navigate(['web/chat']);
    } else {
      // whenever the window is less than 768, hide this component.
      console.log('pantalla esta pequeña');
      this.visible = false;
    }
  }
}
