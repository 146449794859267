import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-web_cabecera',
  templateUrl: './web_cabecera.component.html',
  styleUrls: ['./web_cabecera.component.css']
})
export class Web_cabeceraComponent implements OnInit {

  public dato:boolean = false;
  id: string;
  id_user: string;
  miembros: any;

  propuesta:boolean = true;
  crear_:boolean = false;
  pago_:boolean = false;

  constructor(
    private menuservice: MenuService,
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.id      = this.activerouter.snapshot.paramMap.get('id');
    this.id_user = this.activerouter.snapshot.paramMap.get('user');

    this.chat.crear_propuesta.subscribe(
      data => {
        this.crear();
      }
    )

    this.members();
  }

  members(){
    this.chat.members(this.id).subscribe(
      resp => {
        this.miembros = resp.members;
        console.log("miembros", this.miembros);
      }
    )
  }

  crear(){
    this.propuesta= false;
    this.crear_= true;
    this.pago_ = false;
    this.menuservice.crear.emit(
      {data:this.dato})
    console.log('tocando aqui');
  }

  propuestas(){
    this.propuesta= true;
    this.crear_= false;
    this.pago_ = false;
    this.menuservice.propuestas.emit(
      {data:this.dato})
    console.log('tocando aqui');
  }

  pago(){
    this.propuesta= false;
    this.crear_= false;
    this.pago_ = true;
    this.menuservice.pago.emit(
      {data:this.dato})
    console.log('tocando aqui');
  }



  

  
  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 1.8,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 2.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 415,
        "settings": {
          "slidesToShow": 1.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 1.5,
          "slidesToScroll": 1,
        }
      }
    ]
  };

}
