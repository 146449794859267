import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

import { cI, sI } from 'src/app/core/interfaces/categorias.form.interfaces';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterServiceComponent } from '../components/filterService/filterService.component';


@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public services: Array<any> = [];
  public page: number = 1;
  public limit: number = 3;
  public totalDocuments:number = 0;
  public totalPages:number = 0;
  public currentPage: number = 0;
  
  public formSubmitted = false;
  public filtroForm = this.fb.group(
    { word : [''], subcategory : [''], municipality : [''], min_price : [''], max_price : [''] }
  );

  public btnFiltrar: boolean = false;
  public ty: boolean = false;

  public min: number = 50000;
  public max: number = 50000;
  public word: string;
  public isLoading = true;

  constructor(
    private ubicacionService: UbicacionService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private serviciosService: ServiciosService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.cargaServicios();
    this.listadoDept();
    this.listarCategoria();
  }

  cargaServicios() {
    this.isLoading = true;   
    this.serviciosService.Listado(this.page, this.limit)
      .subscribe(resp => {
          resp.data.services.forEach((e:any) => { this.services.push(e) });
          this.totalDocuments = resp.data.totalDocuments;
          this.totalPages = resp.data.totalPages;
          this.currentPage = resp.data.currentPage;
          this.ty = false;
          this.isLoading = false;  
      })
  }

  nuevaBusqueda() {
    this.page = 1;
    this.services = [];
    this.formSubmitted = true;
    if (this.filtroForm.invalid) { return }
    this.word = this.filtroForm.get('word').value;
    this.cargarServiciosBusqueda();
  }

  cargarServiciosBusqueda() {
    this.isLoading = true; 
    this.serviciosService.search(this.filtroForm.value, this.page, this.limit).subscribe(
      resp => {
        resp.data.services.forEach((e:any) => { this.services.push(e) });
        this.totalDocuments = resp.data.totalDocuments;
        this.totalPages = resp.data.totalPages;
        this.currentPage = resp.data.currentPage;
        this.ty = true;
        this.isLoading = false;  
      }
    )
  }

  onScroll() {
    this.page += 1;
    if (this.ty == false) {
      this.cargaServicios();
    } else {
      this.cargarServiciosBusqueda();
    }
  }

  limpiar(type:string) {
    if ('todo') {
      this.page = 1;
      this.services = [];
      this.cargaServicios();
      this.formulario();
    }
  }

  formulario() {
    this.filtroForm.setValue({ word: '', subcategory: '', municipality: '', min_price:'', max_price: '' });
  }

  public selectedCtg: cI = { _id: "", name: "" };
  public ctgItems: cI ;
  public subItems: sI;

  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => { this.ctgItems = resp.data }
    )
  }
  
  listadoSubcategoria(id: any): void {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => { this.subItems = resp.data.subcategory }
    )
  }

  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data }
    )
  }

  listadoMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities }
    )
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  openDialog() {
    this.dialog.open(FilterServiceComponent, {
      data: {}
    })
  }

}