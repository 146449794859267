<div class="conta">
    <svg class="contenedor" xmlns="http://www.w3.org/2000/svg" width="93" height="148" viewBox="0 0 93 148">
      <g id="undraw_social_life_re_x7t5" transform="translate(-181 -50)">
        <rect id="Rectángulo_1" data-name="Rectángulo 1" width="100" height="250" transform="translate(181 50)" fill="none"/>
        <g [@aniBoton]="state" id="like-1" transform="translate(4.015 -6)">
          <path id="Trazado_7" data-name="Trazado 7" d="M507.87,355.2a15,15,0,1,0,15,15,15,15,0,0,0-15-15Zm-3.592,21.549a.423.423,0,0,1-.423.423h-3.38a.423.423,0,0,1-.423-.423v-8.028a.423.423,0,0,1,.423-.423h3.38a.423.423,0,0,1,.423.423Zm11.409-6.338a1.268,1.268,0,0,1-1.268,1.268v.021a1.056,1.056,0,0,1,0,2.07v.444a.845.845,0,1,1,0,1.69h-8.451a.423.423,0,0,1-.422-.422v-6.761c0-2.113,3.38-5.07,3.38-5.07v-1.03a1.279,1.279,0,0,1,2.522-.3l.013.063a6.4,6.4,0,0,1-1.026,4.237h2.6c.039,0,.076-.012.115-.012h1.268a2.834,2.834,0,0,0,1.268,3.8Z" transform="translate(-277 -210.799)" fill="#5e90ff"/>
        </g>
        <g [@aniBoton]="state" id="like-2" transform="translate(0 3.577)">
          <path id="Trazado_8" data-name="Trazado 8" d="M529.87,262.2a21,21,0,1,0,21,21A21,21,0,0,0,529.87,262.2Zm-5.028,30.169a.592.592,0,0,1-.591.592h-4.732a.592.592,0,0,1-.592-.592V281.13a.592.592,0,0,1,.592-.592h4.732a.592.592,0,0,1,.592.592Zm15.972-8.873a1.775,1.775,0,0,1-1.775,1.775v.03a1.479,1.479,0,0,1,0,2.9v.621a1.183,1.183,0,1,1,0,2.366H527.208a.592.592,0,0,1-.592-.592v-9.465c0-2.958,4.732-7.1,4.732-7.1V272.59a1.79,1.79,0,0,1,3.531-.42q.01.043.018.088c.592,2.958-1.437,5.932-1.437,5.932H537.1c.054,0,.106-.016.161-.016h1.775a1.775,1.775,0,1,1,0,3.549,1.775,1.775,0,0,1,1.775,1.775Z" transform="translate(-284 -193.225)" fill="#5e90ff"/>
        </g>
        <g [@aniBoton]="state" id="corazon-1" transform="translate(1.732 -4)">
          <path id="Trazado_21" data-name="Trazado 21" d="M536.885,404.225a1.1,1.1,0,0,1-1.5,0l-.574-.519c-4.7-4.31-7.411-7.111-7.411-10.6,0-2.848,1.8-5.049,4.628-5.049a5.4,5.4,0,0,1,4.106,1.927,5.4,5.4,0,0,1,4.1-1.927c2.83,0,4.628,2.194,4.628,5.049,0,3.484-2.713,6.285-7.411,10.6Z" transform="translate(-284.267 -210.259)" fill="#ff6584"/>
        </g>
        <g [@aniBoton]="state" id="corazon-2" transform="translate(4.985 1.7)">
          <path id="Trazado_22" data-name="Trazado 22" d="M499.864,339.882a1.789,1.789,0,0,1-2.438,0l-.936-.847c-7.661-7.029-12.085-11.6-12.085-17.289,0-4.644,2.939-8.233,7.548-8.233a8.812,8.812,0,0,1,6.7,3.142,8.8,8.8,0,0,1,6.689-3.142c4.614,0,7.548,3.577,7.548,8.233,0,5.682-4.424,10.248-12.085,17.289Z" transform="translate(-293 -200.66)" fill="#ff6584"/>
        </g>
        <g [@aniBoton]="state" id="corazon-3" transform="translate(7 16.299)">
          <path id="Trazado_23" data-name="Trazado 23" d="M497.493,239.946a1.977,1.977,0,0,1-2.7,0l-1.035-.936c-8.468-7.769-13.358-12.817-13.358-19.11,0-5.133,3.249-9.1,8.343-9.1a9.74,9.74,0,0,1,7.4,3.473,9.726,9.726,0,0,1,7.394-3.473c5.1,0,8.343,3.954,8.343,9.1,0,6.28-4.89,11.328-13.358,19.11Z" transform="translate(-299.001 -166.799)" fill="#ff6584"/>
        </g>
      </g>
    </svg>
</div>
<div (click)="onLikes()" class="flex items-center content-center">
    <div class="px-2" [ngClass]="{'likes': verdadero, 'boton': !verdadero}">
        <span class="icon-mano-like text-aply-100 mr-1"></span> {{totalLikes}}
        <span *ngIf="type_model == 'Service'">Recomendacion</span>
        <span *ngIf="type_model != 'Service'">Me gusta</span>
    </div>
</div>