<div class="container mx-auto">
    <div class="mx-5 lg:mx-0">
        <h1 class="text-dark-400 font-semibold text-xl">Categorias</h1>
        <div class="w-full mt-2 text-sm">
            <div class="grid grid-cols-1 gap-4 border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                <div *ngFor="let c of categoriaItems">
                    <div (click)="listadoSubcategoria(c._id)" class="cursor-pointer hover:text-aply-100">
                        <span class="font-bold">{{c.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>