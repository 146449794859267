<!-- canecera de mensajes -->
<app-cabecera></app-cabecera>

<div  class="w-full altura overflow-y-scroll pt-32 w-full bg-gray-50 sm:justify-center items-center sm:pt-0">
    
  <div *ngIf="!invoice" class="w-full h-screen">
    <div class="w-full h-auto px-12 flex justify-center pt-12 pb-10 lg:pb-10">
      <img class="w-4/5 lg:w-3/5" src="assets/imagen/general/no-pago.png" alt="">
    </div>
    <div class="w-full h-auto flex justify-center pt-8 lg:pt-2">
      <p class="text-2xl font-semibold text-gray-700">¡No hay factura aun!</p>
    </div>
    <div class="w-full h-auto flex justify-center">
      <p class="text-xl font-medium text-gray-700 text-center">para generar una factura primero debe haber un acuerdo de precio cliente - prestador de servicio por medio de las propuestas de precio</p>
    </div>
    <div class="w-full h-auto flex justify-center pt-8">
      <div (click)="crear_propuesta()" class="w-auto px-4 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg">
        <p class="text-lg text-white font-semibold pb-1">Crear propuesta</p>
      </div>
    </div>
  </div>

  <div *ngFor="let item of invoice.invoices" class="block w-full h-auto bg-gray-50 border py-2 ">
    <div class="flex w-full" > 
      <div class="w-auto flex justify-center items-center px-2"> 
        <div class="w-4 h-4 bg-blue-500 rounded-full">

        </div>
      </div>
      <div class="w-full pl-4">
        <div class="flex justify-between w-full h-auto pr-2">
          <div class="flex justify-center items-center">
            <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
            <p class="text-xl font-semibold text-gray-700">{{item.total}} </p>
          </div>

          <!-- botones de estado de la factura -->

          <div *ngIf="item.status == 'Espera'" class="flex justify-center items-center">
            <a [routerLink]="[ '/chat/pago/pasarela', id, id_user]">
            <div class="espera px-2  rounded-full flex cursor-pointer">
              <div class="pt-1">
                <i class='bx bx-loader-circle text-xl text-xl mr-2'></i>
              </div>
              <p class="text-lg font-semibold">espera</p>
            </div>
            </a>
          </div>

          <div *ngIf="item.status == 'Pagada'" class="flex justify-center items-center">
            <a [routerLink]="[ '/chat/pago/factura', id, id_user]">
            <div class="pagada px-2  rounded-full flex cursor-pointer">
              <div class="pt-1">
                <i class='bx bxs-check-circle text-xl mr-2'></i>
              </div>
              <p class="text-lg font-semibold">Pagada</p>
            </div>
            </a>
          </div>

          <div *ngIf="item.status == 'Cancelada'" class="flex justify-center items-center">
            <div class="cancelada px-2  rounded-full flex cursor-pointer">
              <div class="pt-1">
                <i class='bx bxs-no-entry text-xl mr-2'></i>
              </div>
              <p class="text-lg font-semibold">Cancelada</p>
            </div>
          </div>

          <!-- fin botones de estado de la factura -->


        </div>
        <div class="flex">
          <div class="w-auto pr-2 py-2"> 
            <img class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="item.sender | showUser:'image' | async" alt="">
          </div>
          <div>
            <p class="text-lg font-semibold text-gray-700 mb-2">{{item.sender | showUser:'name' | async}} </p>
            <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
          </div>
        </div>
        <div class="flex justify-start  w-full h-auto mb-4 ">
          <div class="block justify-center items-center pl-2">
            <p class="text-lg  text-gray-600">Pago de la factura {{item.serial}} </p>
            <p class="text-lg  text-gray-500">{{item.description}} </p>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>



  



</div>
