import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'forms-pass',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.css']
})
export class PassComponent implements OnInit {

  public id: string;
  public name: string;

  public formSubmitted = false;
  public passForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    new_password: ['', [Validators.required, Validators.minLength(8)]],
    confirm_password: ['', [Validators.required, Validators.minLength(8)]],
  });

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private usuarioService: UsuarioService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
  }

  ngOnInit() {
  }

  passUpdate() {
    this.formSubmitted = true;
    if (this.passForm.invalid) { return }
    this.usuarioService.passUpdate(this.passForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg });
        if (resp.alert == 'success') {
          this.passForm.setValue({password: '', new_password: '', confirm_password: ''})
        }
      }
    )
  }

  campoNoValido(campo: string): boolean {
    if (this.passForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}