import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detailsPropuesta',
  templateUrl: './detailsPropuesta.component.html',
  styleUrls: ['./detailsPropuesta.component.css']
})
export class DetailsPropuestaComponent implements OnInit {
  propuesta: any;
  id: string;

  constructor(
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    @Inject( MAT_DIALOG_DATA ) public data: any,
  ) { }

  ngOnInit() {

    this.id = this.activerouter.snapshot.paramMap.get('id');

    this.propuesta = this.data.data

    console.log("details pro", this.data.data)
  }

  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 5.5,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 415,
        "settings": {
          "slidesToShow": 1.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 2.2,
          "slidesToScroll": 1,
        }
      }
    ]
  };

 //metodo para envia el estado de la propuesta

 selec_estado(id, veredicto, icon){

  Swal.fire({
    imageUrl: 'assets/imagen/icons/'+icon+'.png',
    imageHeight: 60,
    title: icon+'!',
    text: `Puedes cancelar y elegir otro estado`,
    width: '330px',
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: `Aceptar`,
    confirmButtonColor:'#2784FE',
    denyButtonText: `Cancelar`,
    denyButtonColor: '#F62326',
    

  }).then((result) => {
    if (result.isConfirmed) {
      
      this.chat.veredicto(id, veredicto, this.id).subscribe( 
        data => {
          console.log("estado", data);
        } )
      //window.location.href= this.link;
    }else if(result.isDenied){

      result.isDenied
    }
  })
}

}
