import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public log: boolean = false;
  public id_user: string;

  constructor(
    private usuarioService: UsuarioService

  ) {
    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;        
      }
    )
   }

  ngOnInit() {
      
  }

  
  
}
