<div class="w-full">

<div class="contenedor altura overflow-y-scroll pt-44 z-10 w-full bg-gray-50 flex flex-col sm:justify-center items-center pb-48">
  <div class="w-full mx-auto">
    <h2 class="mb-4 text-left text-xl text-gray-600 font-bold">¿Como quieres pagar?</h2>
    <div class="w-full">

      <div class="paypal w-full bg-gray-50 pl-8 flex mb-2 pt-4 pb-6" [ngClass]="{'select': credi, 'noselect': !credi}">
          <label class="inline-flex items-center mt-3 ">
          <input (click)="credito()" class="my-auto transform scale-125" type="radio" name="sfg" /> 
          <i class='bx bx-credit-card-alt text-2xl text-blue-500 ml-2'></i>
          <span class="ml-2 font-semibold text-gray-700">Targeta de credito</span>
        </label>
      </div>
      <div class="pse w-full bg-gray-50 pl-8 flex mb-2 pt-4 pb-6" [ngClass]="{'select': debi, 'noselect': !debi}">
          <label class="inline-flex items-center mt-3 ">
        <input (click)="debito()" class="my-auto transform scale-125" type="radio" name="sfg" />
        <i class='bx bx-credit-card text-2xl text-blue-500 ml-2'></i>  
        <span class="ml-2 font-semibold text-gray-700">Targeta debito</span>
        </label>
      </div>
      <div class="pse w-full bg-gray-50 pl-8 flex mb-2 pt-4 pb-6" [ngClass]="{'select': psee, 'noselect': !psee}">
        <label class="inline-flex items-center mt-3 ">
      <input (click)="pse()" class="my-auto transform scale-125" type="radio" name="sfg" />
      <i class='bx bxs-bank text-2xl text-blue-500 ml-2'></i>  
      <span class="ml-2 font-semibold text-gray-700">Transferencia PSE</span>
      </label>
    </div>
      </div>
   
  </div>

  

  <div class="w-full mx-auto mt-6">
    
    <div class="block w-full h-auto bg-gray-50 border py-2 ">
      <div class="flex w-full">
        <div class="w-2/5 pr-2 py-2 pl-4"> 
          <img class="object-cover w-full rounded-lg shadow-md border-md" src="assets/imagen/servicios/electrician-working.jpg" alt="">
        </div>
        <div class="w-3/5" >
          <p class="text-base font-semibold text-gray-700 text-left ">Aqui va el Nombre del servicio</p>
          
        </div>
      </div>
      <div class="flex w-full h-10 mb-4 ">
        <div class="w-1/2 flex justify-center items-center">
          <p class="text-base font-semibold text-gray-700">Pagas:</p>
        </div>
        <div class="w-1/2 flex justify-center items-center">
          <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
          <p class="text-lg font-semibold text-gray-700">75.000</p>
        </div>
      </div>
      <div class="w-11/12 h-16 mx-2 center flex justify-center items-center border">
        <!-- estados -->
        <svg class="h-6 w-6 text-blue-600"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"/>
        </svg>
        <p class="text-lg font-semibold text-gray-700 ml-2">Descuento con puntos</p>

       
      </div>
  
    </div>

  </div>


  

  <button class="w-11/12 bg-blue-500 hover:bg-blue-700 text-white text-3x1 font-bold py-2 px-4 rounded">
    Continuar
  </button>


</div>

</div>
