import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


// Declaraciones e importaciones 

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RecoveryComponent } from './components/recovery/recovery.component';
import { ResetComponent } from './components/reset/reset.component';
import { VerificacionComponent } from './components/verificacion/verificacion.component';
import { AlertsAuthComponent } from './components/alerts.auth/alerts.auth.component';
import { ConfirmacionComponent } from './components/confirmacion/confirmacion.component';

//import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../../../environments/environment';

import {
  SocialLoginModule, 
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

@NgModule({
  declarations: [
    RegisterComponent,
    ConfirmacionComponent,
    LoginComponent,
    AlertsAuthComponent,
    RecoveryComponent,
    ResetComponent,
    VerificacionComponent
  ],
  exports: [
    RegisterComponent,
    ConfirmacionComponent,
    LoginComponent,
    AlertsAuthComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    SocialLoginModule,
    /*AuthModule.forRoot({
      ...env.auth,
      cacheLocation:'localstorage'
    }),*/
    
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
})
export class AuthModule_ { }
