import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { VerificacionCode } from '../models/verficacion.models';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
  
export class RecoveryService {

  public verify: VerificacionCode;

  constructor(
    private http: HttpClient
  ) { }

  //metodo para loguearse
  mail(email:string) {

    const url = `${base_url}/password/recover`;
    
    return this.http.post(url, email).pipe(
      tap((resp: any) => {})
    );

  }


  verificarCodigo(
    forData: {
      email: string,
      code: string
    }
  ) {
    const url = `${base_url}/password/check/code`;
    return this.http.post(url, forData).pipe(
      map( ( resp:any ) => {

        const { msg } = resp;

        if (msg == 'true') {
          const { code, email } = resp;
          this.verify = new VerificacionCode(email, code);  
          return true;   
        } else {
          return false;   
        }
      
      }),
      catchError( error => of(false) )
    );
  }

  cambiarPass(
    formData: {
      email: string,
      code: string,
      password: string
    }
  ) {
    const url = `${base_url}/password/reset`;
    return this.http.post(url, formData).pipe(
      tap((resp: any) => { })
    )
  }

  // /password/recover
  // /password/check
  // /password/reset
}
