import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'response',
  templateUrl: './response-recomendacion.component.html',
  styleUrls: ['./response-recomendacion.component.css']
})
export class ResponseRecomendacionComponent implements OnInit {

  @Input() respuestas: any;
  @Input() id: string;
  @Input('c') contador: number; 
  @Input() log: boolean;
  
  public activacion: boolean = false;
  public id_ref: string;
  public num: number = 1;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }


  masomenos(type: string, id: string) {
    if (id) {
      this.id_ref = id;
      if (type == 'mas') {
        this.activacion = true;
        this.num = this.contador;
      } else if (type == 'menos') {
        this.activacion = false;
        this.num = 1
      }
    }
  }

  irAlPerfil(id:string) {
    if (this.log == true) {
      this.router.navigate(['/perfil', id]);
    } else {
      this.onModal();
    }
  }


  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


}
