// import all
import { CuentaPerfilComponent } from "./components/informacion/cuenta-perfil/cuenta-perfil.component";
import { CuentaSinPerfilComponent } from "./components/informacion/cuenta-sin-perfil/cuenta-sin-perfil.component";
import { FooterCardComponent } from "./components/informacion/footer-card/footer-card.component";
import { InformacionPerfilComponent } from "./components/informacion/informacion-perfil/informacion-perfil.component";
import { MenuPerfilComponent } from "./components/informacion/menu-perfil/menu-perfil.component";

import { ReviewPerfilComponent } from "./components/resenias/review-perfil/review-perfil.component";
import { CardResponseComponent } from "./components/resenias/card-response/card-response.component";
import { CardReviewComponent } from "./components/resenias/card-review/card-review.component";
import { ResponseReviewComponent } from "./components/resenias/response-review/response-review.component";
import { ResponseRecomendacionComponent } from "./components/resenias/response-recomendacion/response-recomendacion.component";

import { NombreComponent } from "./components/configuracions/nombre/nombre.component";
import { PassComponent } from "./components/configuracions/pass/pass.component";
import { FormsPerfilComponent } from "./components/configuracions/forms-perfil/forms-perfil.component";
import { LaboralComponent } from "./components/configuracions/laboral/laboral.component";
import { UbicacionComponent } from "./components/configuracions/ubicacion/ubicacion.component";

// page 
import { NecesidadComponent } from "./necesidad/necesidad.component";
import { PerfilComponent } from "./perfil/perfil.component";
import { RecomendacionesComponent } from "./recomendaciones/recomendaciones.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { CuentaComponent } from "./cuenta/cuenta.component";
import { ConfiguracionComponent } from "./configuracion/configuracion.component";


export const components: any[] = [
    MenuPerfilComponent,
    InformacionPerfilComponent,
    FooterCardComponent,
    CuentaPerfilComponent,
    CuentaSinPerfilComponent,
    ResponseRecomendacionComponent,
    ReviewPerfilComponent,
    ResponseReviewComponent,
    CardReviewComponent,
    CardResponseComponent,
    NombreComponent,
    PassComponent,
    FormsPerfilComponent,
    LaboralComponent,
    UbicacionComponent,
    
    PerfilComponent,
    ServiciosComponent,
    NecesidadComponent,
    RecomendacionesComponent,
    CuentaComponent,
    ConfiguracionComponent
];


// export all components
export * from "./necesidad/necesidad.component";
export * from "./recomendaciones/recomendaciones.component";
export * from "./servicios/servicios.component";
export * from "./perfil/perfil.component";
export * from "./cuenta/cuenta.component";
export * from "./configuracion/configuracion.component";



