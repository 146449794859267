import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from './page';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.component/shared.module';

import { CurrencyPipe } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    ...fromComponents.components,  
  ],
  providers: [
    CurrencyPipe
  ],
})
export class CreacionNecesidadModule { }
