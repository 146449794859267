import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaService } from 'src/app/core/services/categoria.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {

  @Input() nameCategoria: string;
  @Output() recargar: EventEmitter<string> = new EventEmitter();

  public categoriaItems: any;

  constructor(
    private categoriaServices: CategoriaService,
    private router: Router
  ) { }

  ngOnInit() {
    this.listarCategoria();
  }

   // listadoCategoria
  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => {  this.categoriaItems = resp.data; }
    ) 
  }

  listadoSubcategoria(id:string){
    this.router.navigate( ['/categoria', id, 'subcategoria'] );
    this.recargar.emit(id);
  }


}