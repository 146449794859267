export class Usuarios{

    constructor(
        public email: string,
        public name: string, 
        public role: string,
        public imagen: string,
        public _id: string,
    ){}

    get _ids() {
        return this._id;
    }

    get emails() {
        return this.email;
    }

    get names() {
        return this.name;
    }

    get roles() {
        return this.role;
    }

    get imagenes() {
        return this.imagen;
    }
       
}