import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { SocialService } from 'src/app/core/services/social.service';
import { validarQueSeanIguales } from './register.validators';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css' ]
})
export class RegisterComponent  {

  public formSubmitted = false;
  /* public registerForm = this.fb.group({
    name: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(60), Validators.pattern('[A-zÀ-ú, ,.]*') ]],
    email: ['', [ Validators.required, Validators.email ]],
    password: ['', [ Validators.required, Validators.minLength(8) ]],
    password2: ['', [ Validators.required, Validators.minLength(8), this.passwordsAreEquals ]],
    termino: [ false , [ Validators.required ]],
  },{
    validators: validarQueSeanIguales
  }
  ); */

  public newUserForm!: FormGroup
  public registerForm!: FormGroup;
  user: any;
  type: string;
  constructor(
    private router: Router, 
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private authService: SocialAuthService,
    private readonly _authService: SocialAuthService,
    private social : SocialService
  ) {}

  ngOnInit() {
    this.registerForm = this.createFormRegisterBuilder( this.fb )
  }

  

  createFormRegisterBuilder ( formBuilder: FormBuilder ) {
      return formBuilder.group( {
        name                  : ['', [Validators.required, Validators.minLength( 6 ), Validators.maxLength( 40 ), Validators.pattern('[A-zÀ-ú, ,.]*')]],
        email                 : ['', [Validators.required, Validators.email]],
        password              : ['', [Validators.required, Validators.minLength( 8 ), Validators.maxLength( 36 )]],
        password2             : ['', [Validators.required, Validators.minLength( 8 ), Validators.maxLength( 36 )]],
        termino               : [ false , [ Validators.required ]],
      },{
        validators: validarQueSeanIguales
      } )
  }

  crearUsuario() {
    this.formSubmitted = true;
    if (this.registerForm.invalid) { return }

    console.log("formualrio ", this.registerForm.value)
    this.usuarioService.registro(this.registerForm.value).subscribe(
      resp => {
        const email = this.registerForm.get('email').value;        
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert = 'success') {
          this.router.navigate( ['/confirmacion/correo', email] );
        }
      },

      (err) => { this.Toast.fire({ icon: 'error', title: err }) }
    );
  }
  
  campoNoValido(campo: string): boolean {
    if (this.registerForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  aceptaTerminos(){
    return !this.registerForm.get('termino').value && this.formSubmitted;
  }

  passNoValidas() {
    const pass1 = this.registerForm.get('password').value;
    const pass2 = this.registerForm.get('password2').value;
    if ((pass1 === pass2) && this.formSubmitted == false) {
      return false;
    } else {
      return true;
    }
  }

  passwordsAreEquals(control: AbstractControl): ValidationErrors | null {
    const pass1 = control.parent?.get('password').value;
    const pass2 = control.value;
    return !pass1 || !pass2 || pass1 !== pass2 ? { isNotEqual: true } : null;
  }

  checarSiSonIguales(): boolean {
    return this.registerForm.hasError('noSonIguales') &&
      this.registerForm.get('password')!.dirty &&
      this.registerForm.get('password2')!.dirty;
  }


  createUserBuilder ( formBuilder: FormBuilder ) {
    return formBuilder.group( {
      name: [this.user.name, [Validators.required]],
      email: [this.user.email , [Validators.required]],
      photo: [this.user.photoUrl, [Validators.required] ],
      id : [ this.user.id, [Validators.required]],
      type : [ this.type ],
    } )
  }

  signInWithGoogle(): void {

    this.type = 'Google'
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this._authService.authState.subscribe((user) => {
      this.user = user;
  
      this.newUserForm = this.createUserBuilder( this.fb )
      console.log("formulario", this.newUserForm.value)
    });

    this.onRegisterSocial()
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  onRegisterSocial(){

    this.social.registro(this.newUserForm.value).subscribe(
      resp => {
        if (resp.data.token) { this.router.navigate( ['/perfil', resp.data.id] ) }
        console.log("retornado reguster", resp)
      }
    )

  }

  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  get name() {return this.registerForm.get('name');}
  get email() {return this.registerForm.get('email');}
  get password() {return this.registerForm.get('password');}
  get password2() {return this.registerForm.get('password2');}

}