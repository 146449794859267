import { AbstractControl } from '@angular/forms';

export class MinMaxValidation {

    static minVal(control: AbstractControl){

        const val = control.value;

        if(val == null) return null;

        if(Number(val.replace(/\./g, '')) < 50000){
            return { minVal: true }
        }

        return null;
    }   

    static maxVal(control: AbstractControl){

        const val = control.value;

        if(val == null) return null;

        if(Number(val.replace(/\./g, '')) > 50000000){
            return { maxVal: true }
        }

        return null;

    }

}