import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  @Output() push_notificacion: EventEmitter<any> = new EventEmitter();
  @Output() cerrar_menu: EventEmitter<any> = new EventEmitter();
 

private _refresh = new Subject<void>();
  
constructor(
  private http:HttpClient
) { }

get refresh(){
  return this._refresh;
}

get token(): string {
  return localStorage.getItem('token') || '';
}

get headers() {
  return {
    headers: {
      'auth-token': this.token
    }
  }
}

user() {
  const url = `${base_url}/user/show`;
  return this.http.get(url, this.headers).pipe(
    tap((resp: any) => {})
  );
}

notificaciones():Observable<any>{
  const direccion = `${base_url}/notifications/show`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {})
  );

}

//listar todas las notificaciones
notificaciones_all():Observable<any>{
  const direccion = `${base_url}/notifications/show/all`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {})
  );

}

//marcar como leidad la notificacion
mark_read_notification(id:any):Observable<any>{
  const direccion = `${base_url}/notifications/show/${id}`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {
      this._refresh.next();
    })
  );

}

//listar aplicaciones de necesidades al user autenticado
aplicaciones():Observable<any>{
  const direccion = `${base_url}/aplicar`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {})
  );

}

//metodo para aceptar una aplicacion al usuarioa atenticada
veredicto_necesidad(id:any, veredicto:any):Observable<any>{
  const direccion = `${base_url}/veredicto/aplicar/${id}/${veredicto}`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {
      this._refresh.next();
    })
  );

}

//listar las solicitudes de un prestador de servicio atenticado
solicitudes():Observable<any>{
  const direccion = `${base_url}/solicitar`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {})
  );

}

//metodo para aceptar una solicitud a tus servicios del usuario autenticado
veredicto_servicio(id:any, veredicto:any):Observable<any>{
  const direccion = `${base_url}/veredicto/solicitar/${id}/${veredicto}`;
  return this.http.get(direccion, this.headers).pipe(
    tap( (resp)=> {
      this._refresh.next();
    })
  );

}

}
