import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';
import { SubcategoriaComponent } from './page';

const routes: Routes = [
  	{ 
		path: 'categoria',
		component: ModulosComponent,
		children: [
		  {path:':id/subcategoria', component: SubcategoriaComponent}
		]
	},
];

@NgModule({
	imports: [
    RouterModule.forChild(routes),
  ],
	exports: [RouterModule]
})

export class CategoriasRoutes { }