<div class="flex justify-between">
    <span *ngIf="limit == 5" (click)="cambiarPagina(-1)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer ">
        ver anterior respuesta
    </span>
</div>

<div *ngFor="let rr of responses; let i=index" class="btn-efecto-2">
    <card-response (actualizar)="cambioValorHijo($event)" [rr]="rr" [id_ref]="id" [log]="log" [id_user]="id_user"></card-response>
</div>

<div class="flex justify-between">

    <span *ngIf="limit == 5 && page != totalPages" (click)="cambiarPagina(1)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer">
        ver mas respuesta
    </span>
    <span *ngIf="limit == 1 && totalDocuments >= 2" (click)="paginar(1)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer">
        ver comentarios
    </span>

</div>