import { LastMessagePipe } from "../pipe/lastMessage.pipe";
import { OnLineUsersPipe } from "../pipe/onLineUsers.pipe";
import { SeachChatPipe } from "../pipe/seachChat.pipe";
import { ShowUserPipe } from "../pipe/showUser.pipe";
import { CabeceraComponent } from "./cabecera/cabecera.component";
import { ChatComponent } from "./chat/chat.component";
import { ContactosComponent } from "./contactos/contactos.component";
import { Crear_propuestaComponent } from "./crear_propuesta/crear_propuesta.component";
import { Detalle_facturaComponent } from "./detalle_factura/detalle_factura.component";
// import { MapsComponent } from "./maps/maps.component";
import { MessageComponent } from "./message/message.component";
import { PagoComponent } from "./pago/pago.component";
import { PasarelaComponent } from "./pasarela/pasarela.component";
import { DetailsPropuestaComponent } from "./propuestas/components/detailsPropuesta/detailsPropuesta.component";
import { PropuestasComponent } from "./propuestas/propuestas.component";


export const components : any [] =[
    ChatComponent,
    ContactosComponent,
    MessageComponent,
    Crear_propuestaComponent,
    PropuestasComponent,
    PagoComponent,
    CabeceraComponent,
    PasarelaComponent,
    Detalle_facturaComponent,
    OnLineUsersPipe,
    SeachChatPipe,
    DetailsPropuestaComponent,
    LastMessagePipe,
    ShowUserPipe
    // MapsComponent
]


export * from "./chat/chat.component";
export * from "./contactos/contactos.component";
export * from "./message/message.component";
export * from "./crear_propuesta/crear_propuesta.component";
export * from "./propuestas/propuestas.component";
export * from "./pago/pago.component";
export * from "./cabecera/cabecera.component";
export * from "./pasarela/pasarela.component";
export * from "./detalle_factura/detalle_factura.component";
export * from "../pipe/onLineUsers.pipe";
export * from "../pipe/lastMessage.pipe";
export * from "./propuestas/components/detailsPropuesta/detailsPropuesta.component";