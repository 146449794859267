<!-- canecera de mensajes -->
<app-cabecera></app-cabecera>


<div  class="altura overflow-y-scroll pt-32 pb-24 w-full bg-gray-50 sm:justify-center items-center sm:pt-0">
    
  <div class="block w-full h-auto bg-gray-50 border py-2 px-4">
    <div class="flex w-full" > 

      <div class="w-full justify-center ">
        <div class="w-full pb-4 pt-6">
          <h4 class="mb-4 text-center text-xl font-bold text-gray-500">Factura N° 42324</h4>
        </div>
        <div class="w-full flex">
          <h4 class="mb-4 text-xl text-gray-500">Mantenimiento de redes erectricas</h4>
        </div>
        <div class="w-full px-2 pt-4">
          <div class="w-full flex justify-between pb-2">
            <div>
              <h4 class="text-center text-xl text-gray-500">Total</h4>
            </div>
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">120000</p>
            </div>
          </div>
          <div class="w-full flex justify-between pb-2">
            <div>
              <h4 class="text-center text-xl text-gray-500">Comision</h4>
            </div>
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">12000</p>
            </div>
          </div>
          <div class="w-full flex justify-between pb-2">
            <div>
              <h4 class="text-center text-xl text-gray-500">Recibe</h4>
            </div>
            <div class="flex justify-center items-center">
              <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
              <p class="text-xl font-semibold text-gray-700">100000</p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <div class="w-full bg-gray-200 h-auto mt-2">
    <div class="flex justify-center items-center py-5">
      <i class='bx bx-dollar text-yellow-500 text-4xl font-semibold'></i>
      <p class="text-4xl font-semibold text-gray-500">120.000</p>
    </div>
  </div>

  <div class="block w-full h-auto  border py-2 px-4">
    <div class="w-full rounded-lg h-auto border flex p-2">
      <div class="w-12 h-12 rounded-lg">
        <img class="rounded-lg" src="assets/imagen/perfil/maria.jpg" alt="">
      </div>
      <div class="pl-4">
        <p class="text-base font-semibold text-gray-700">Maria mondragon</p>
        <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
      </div>
    </div>

    <div class="w-full rounded-lg h-auto border border-yellow-100 flex p-2 mt-4">
      <p class="text-sm text-gray-500 font-semibold -mt-1">Antes de dar su veredicto verifique que le
        prestado de servicio haya cumplido con lo establecido en el acuerdo
      </p>
    </div>

    <div class="w-full rounded-lg h-auto border p-2 mt-4">
      <div class="w-full pb-4 pt-6 grid place-content-center">
        <div>
          <svg class="h-24 w-24 text-blue-300 text-center items-center"  
          width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" 
          fill="none" stroke-linecap="round" stroke-linejoin="round">  
          <path stroke="none" d="M0 0h24v24H0z"/>  
          <circle cx="12" cy="12" r="1" />  <circle cx="12" cy="12" r="5" />  
          <circle cx="12" cy="12" r="9" />
        </svg>
        </div>
       
      </div>
      <div class="w-full">
        <h4 class="mb-4 text-base font-semibold text-gray-700 text-center">Maria mondragon</h4>
      </div>
      <div class="w-full">
        <h4 class="mb-4 text-sm font-semibold text-gray-700 text-center">Prestador de servicio esta indicando que cumplio con el trabajo</h4>
      </div>
      <div class="w-full pb-4">
        <button class="w-full bg-blue-500 hover:bg-blue-700 text-white text-2x1 font-bold py-2 px-4 rounded">
          Terminar trabajo
        </button>
      </div>
      
    </div>
  </div>

  



</div>
