import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  public suscription: Subscription;
  
  n_menu:boolean = false;
  notificaciones: any;
  id_notificacion: any;
  user: any;

  btn_n:boolean = true;
  btn_a:boolean = false;
  btn_s:boolean = false;
  location: any;
  n_notificacion: any;
  solicitudes: any;
  n_solicitud: any;
  aplicaciones: any;
  n_aplicacion: any;

  constructor(
    private activerouter: ActivatedRoute,
    private router: Router,
    private noti: NotificacionesService
  ) { }

  ngOnInit() {

    this.onNotificaciones();
    this.onNotificaciones_no_read();
    this.onSolicitudes();
    this.onAplicaciones();
    this.i();

    this.suscription = this.noti.refresh.subscribe(() => {
     this.onNotificaciones();
     this.onNotificaciones_no_read();
     this.onSolicitudes();
     this.onAplicaciones();
    });

    this.noti.push_notificacion.subscribe(
      data => {
        console.log("dato emitido", data);
        if(this.n_notificacion < 1){
          this.onNotificaciones();
        }else{
          this.n_notificacion = this.n_notificacion + 1;
          this.notificaciones.unshift(data.data.data);
          console.log("con push", this.notificaciones);
        }
        
        
      }
    )

    

    document.getElementById( "atras" ).addEventListener("click",function() {
      window.history.back();
    }
    ,false);
  }

  i(){

    this.noti.user().subscribe(
      resp => {
        this.user = resp.data
      }
    )
    //emitir un evento del nuevo usuario con su id
    //this.socket.emit('new:user', this.user );
  }

  onNotificaciones(){
    this.noti.notificaciones_all().subscribe( 
      data => {
        this.notificaciones = data.success;
        console.log("notioficationes", data.success)
      });
  }

  //notificaciones sin leer
  onNotificaciones_no_read(){
    this.noti.notificaciones().subscribe( 
      data => {
        this.n_notificacion = data.warning ? null : data.success.length;
    });
  }

  onRead_notificacion(valor){
    this.noti.mark_read_notification(valor).subscribe(
      data => {
      }
   )
  }

  //nuemro de solicitudes
  onSolicitudes(){
    
    this.noti.solicitudes().subscribe( 
      data => {
        this.solicitudes = data.data;
        console.log("solicitudes", data.data);
        this.n_solicitud = this.solicitudes.filter(element => element.status == 'Espera')
      } );

  }

  //numero de aplicaciones
  onAplicaciones(){
    
    this.noti.aplicaciones().subscribe( 
      data => {
        this.aplicaciones = data.data;
        console.log("aplicaciones", data.data.length);
        this.n_aplicacion = this.aplicaciones.filter(element => element.status == 'Espera')
      } );

  }

  btn_notificacion(){

    this.btn_n = true;
    this.btn_a = false;
    this.btn_s = false;
  }

  btn_solicitud(){
    this.btn_n = false;
    this.btn_a = false;
    this.btn_s = true;
  }
  btn_aplicar(){
    this.btn_n = false;
    this.btn_a = true;
    this.btn_s = false;
  }

  menu(valor){
    this.id_notificacion = valor;
    this.n_menu = !this.n_menu;
  }

  cerrar(){
    this.n_menu = false;
  }

 
    
  

}
