<div class="xl:container xl:mx-auto mb-8">
    <div class="mx-3 lg:mx-0">
        <div class="flex justify-between items-start">
            <h1 class="ml-3 font-bold text-dark-400 text-xl mb-4 d3una-home-heading ">Recomendaciones</h1>
            <div class="lg:hidden flex mr-3 px-2 transition text-dark-300  cursor-pointer rounded-2xl">
                <div class="text-xl">
                    <i class='bx bxs-plus-circle' (click)="openModal('crear-tip')"></i>
                </div>
            </div>
        </div>
        <div
            class="w-full alto  bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 lg:bg-green-500 rounded-lg border border-gray-200">
            <div class="hidden lg:flex w-full lg:w-2/5 overflow-y-scroll contenedor">
                <div class=" p-4">
                    <div class="">
                        <div class="mb-2">
                            <form [formGroup]="newRecomendationForm" (ngSubmit)="createRecomendation()">

                                <div class="w-full mt-2 relative">

                                    <div class="w-full">
                                        <label class="font-bold text-base text-dark-300 mb-2">Departamento</label>
                                        <select
                                            class="w-full input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2"
                                            [(ngModel)]="selectedDept._id"
                                            (change)="listadoMunicipio($event.target.value)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option value=""> ----- Seleccione el departamento ----- </option>
                                            <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                                        </select>
                                    </div>
                                    <div class="w-full">
                                        <label class="font-bold text-base text-dark-300 mb-2">Municipio</label>
                                        <select formControlName="municipality"
                                            class="w-full input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                                            <option value=""> ----- Seleccione el municipio ----- </option>
                                            <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                                        </select>
                                    </div>

                                </div>

                                <div class="w-full">
                                    <textarea formControlName="message"
                                        class="text-dark-300 leading-tight text-base mt-2 rounded-lg w-full " name=""
                                        id="" cols="30" rows="3"></textarea>
                                </div>

                                <div class="w-full h-auto grid grid-cols-3 gap-2 my-4 ">
                                    <div *ngFor="let i of imagenes; let j = index" class="w-full relative">
                                        <div (click)="delete(j)"
                                            class="w-6 h-6 rounded-full bg-red-500 absolute -top-1 -right-1 flex justify-center items-center ">
                                            <i class='bx bxs-trash text-white'></i>
                                        </div>
                                        <img class="w-full" [src]="i.url" alt="">
                                    </div>

                                </div>

                                <div class="w-full h-auto flex justify-between ">
                                    <div *ngIf="count <= 2"
                                        class="w-full h-9 lg:h-7 rounded-lg mr-2 cursor-pointer flex justify-center items-center">
                                        <div class="w-full h-full" [ngClass]="isLoading ? 'animate-pulse' : ''">
                                            <label
                                                class="cursor-pointer hover:shadow-lg hover:bg-white rounded-2xl  transition duration-500 ease-in-out">
                                                <div
                                                    class="hover:border-transparent hover:shadow-xs w-full h-full  rounded-lg border-4 border-dashed border-gray-200">

                                                    <div
                                                        class="h-full w-full flex items-center justify-center items-center">
                                                        <span *ngIf="!imgTemp"
                                                            class="icon-img text-xl lg:text-xl text-dark-400 mr-2 -mt-2"></span>
                                                        <div *ngIf="!imgTemp"
                                                            class="pointer-none text-xs lg:text-sm font-bold text-dark-300 mt-1 text-center">
                                                            Nueva imagen</div>
                                                    </div>

                                                    <input type="file" multiple="true" class="hidden" id="file-upload"
                                                        (change)="cambiarImagen($event.target.files[0])">
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit"
                                        class="w-full h-9 lg:h-7 rounded-lg bg-blue-500 ml-2 cursor-pointer flex justify-center items-center">
                                        <i class='bx bxs-send text-white text-lg mr-2'></i>
                                        <span class="text-white text-lg">Publicar</span>
                                    </button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>


            <div class="md:block w-full lg:w-3/5 col-span-2 overflow-y-scroll contenedor">
                <div *ngFor="let item of recomendacions" class="bg-white p-4">
                    <div class="">
                        <div class="mb-2">
                            <div class="flex justify-between items-center content-center">
                                <div class="flex space-x-3 items-center content-center mb-1">
                                    <img [src]="item.user.imagen.thumbnail" alt=""
                                        class="w-10 h-10 rounded-full object-center object-cover">
                                    <div class="">
                                        <h3 class="font-bold text-dark-400 text-md  leading-4 cursor-pointer">
                                            {{item.user.name}}
                                        </h3>
                                        <h4 class="font-semibold text-dark-300 text-xs">
                                            {{item.createdAt | date }} <span> - {{item.municipality.name}}</span>
                                        </h4>
                                    </div>
                                </div>
                                <div *ngIf="item.recomendation_response.length > 0"
                                    class="flex items-center content-center text-md text-dark-300 font-semibold">
                                    <i class="icon-msg-espera-alt text-aply-100 text-xl mr-1"> </i>
                                    {{item.recomendation_response.length}}
                                </div>
                            </div>
                            <p class="text-dark-300 leading-tight text-base mt-2">
                                {{item.message}}
                            </p>
                        </div>
                        <div *ngIf="item.images.length > 0" class="flex">
                            <img *ngFor="let img of item.images" class="w-1/2 lg:w-1/4" [src]="img.thumbnail" alt="">
                        </div>
                        <div class="flex pt-2 text-md font-semibold space-x-4 text-sm font-semibold text-dark-300 ">
                            <label>Responder</label>
                        </div>
                        <div *ngIf="item.recomendation_response.length > 0" class="pl-4 lg:pl-6">
                            <response class="ml-4" [respuestas]="item.recomendation_response"></response>
                        </div>


                        <hr class="my-3">

                    </div>
                </div>

            </div>


        </div>
    </div>
</div>







<jw-modal id="crear-tip">
    <div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ">
        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 ">
            <div class="border-b px-5 py-2 flex justify-between items-center ">
                <h3 class="font-semibold text-lg text-dark-300 ">Nueva recomendacion</h3>
                <button class="text-base px-1 focus:ring-none " (click)="closeModal( 'crear-tip'); ">
                    <i class='bx bx-x'></i>
                </button>
            </div>

            <div class=" p-4">
                <div class="">
                    <div class="mb-2">
                        <!-- <div class="flex justify-between items-center content-center">
                            <div class="flex space-x-3 items-center content-center mb-1">
                                
                                <img [routerLink]="[ '/perfil', id_user ]" *ngIf="user?.imagen" class="object-cover h-10 w-10 rounded-full shadow-md border-md  cursor-pointer " [src]="user.imagen.thumbnail" alt="">
                                <img [routerLink]="[ '/perfil', id_user ]" *ngIf="!user?.imagen" class="object-cover h-10 w-10 rounded-full shadow-md border-md cursor-pointer" src="assets/imagen/general/no-imagen.png" alt="">
                            
                                <div class="">
                                    <h3  class="font-bold text-dark-400 text-md font-mont leading-4 cursor-pointer">
                                        {{user.name}}
                                    </h3>
                                    <h4 class="font-semibold text-dark-300 text-xs">
                                        <span>Prestador de servicio</span>
                                    </h4>

                                </div>
                            </div>
                            <div class="flex items-center content-center text-md text-dark-300 font-semibold">
                                <i class="icon-msg-espera-alt text-aply-100 text-xl mr-1"> </i> 23
                            </div>
                        </div> -->

                        <form [formGroup]="newRecomendationForm" (ngSubmit)="createRecomendation()">

                            <div class="w-full mt-2 relative">

                                <div class="w-full">
                                    <label class="font-bold text-base text-dark-300 mb-2">Departamento</label>
                                    <select
                                        class="w-full input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2"
                                        [(ngModel)]="selectedDept._id" (change)="listadoMunicipio($event.target.value)"
                                        [ngModelOptions]="{standalone: true}">
                                        <option value=""> ----- Seleccione el departamento ----- </option>
                                        <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                                    </select>
                                </div>
                                <div class="w-full">
                                    <label class="font-bold text-base text-dark-300 mb-2">Municipio</label>
                                    <select formControlName="municipality"
                                        class="w-full input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                                        <option value=""> ----- Seleccione el municipio ----- </option>
                                        <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                                    </select>
                                </div>

                            </div>

                            <div class="w-full">
                                <textarea formControlName="message"
                                    class="text-dark-300 leading-tight text-base mt-2 rounded-lg w-full " name="" id=""
                                    cols="30" rows="3"></textarea>
                            </div>



                            <div class="w-full h-auto grid grid-cols-3 gap-2 my-4 ">
                                <div *ngFor="let i of imagenes; let j = index" class="w-full relative">
                                    <div (click)="delete(j)"
                                        class="w-6 h-6 rounded-full bg-red-500 absolute -top-1 -right-1 flex justify-center items-center ">
                                        <i class='bx bxs-trash text-white'></i>
                                    </div>
                                    <img class="w-full" [src]="i.url" alt="">
                                </div>

                            </div>

                            <div class="w-full h-auto flex justify-between ">
                                <div *ngIf="count <= 2"
                                    class="w-full h-9 lg:h-7 rounded-lg mr-2 cursor-pointer flex justify-center items-center">
                                    <div class="w-full h-full" [ngClass]="isLoading ? 'animate-pulse' : ''">
                                        <label
                                            class="cursor-pointer hover:shadow-lg hover:bg-white rounded-2xl  transition duration-500 ease-in-out">
                                            <div
                                                class="hover:border-transparent hover:shadow-xs w-full h-full  rounded-lg border-4 border-dashed border-gray-200">

                                                <div
                                                    class="h-full w-full flex items-center justify-center items-center">
                                                    <span *ngIf="!imgTemp"
                                                        class="icon-img text-xl lg:text-xl text-dark-400 mr-2 -mt-2"></span>
                                                    <div *ngIf="!imgTemp"
                                                        class="pointer-none text-xs lg:text-sm font-bold text-dark-300 mt-1 text-center">
                                                        Nueva imagen</div>
                                                </div>

                                                <input type="file" multiple="true" class="hidden" id="file-upload"
                                                    (change)="cambiarImagen($event.target.files[0])">
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <button type="submit"
                                    class="w-full h-9 lg:h-7 rounded-lg bg-blue-500 ml-2 cursor-pointer flex justify-center items-center">
                                    <i class='bx bxs-send text-white text-lg mr-2'></i>
                                    <span class="text-white text-lg">Publicar</span>
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</jw-modal>