<div class="h-20">

</div>
<form [formGroup]="serviciosForm" (ngSubmit)="crearServicio()" autocomplete="off">
    
    <div *ngIf="compCa" class="container mx-auto xl:my-0 mt-5 lg:mt-24 mb-24">
        <div class="mx-5 xl:mx-0">
            <div class="flex flex-col items-center justify-center content-center mt-5 lg:mt-0 lg:h-screen">
                <div class="w-full">
                    <h1 class="text-3xl font-bold text-center text-aply-100">Creacion de Servicio</h1>
                    <h2 class="text-xl text-base text-center text-dark-400 mt-5">¿En que categoría esta tu servicio?</h2>
                </div>

                <div class="w-full mx-5 mt-5 mb-5">
                    <div class="grid grid-cols-12 gap-6">
                        <div *ngFor="let c of categoriaItems" class="col-span-12 lg:col-span-6 xl:col-span-3">
                            <div class="cuadros p-4"  (click)="listadoSubcategoria(c._id)">
                                <!-- c._id -->
                                <span class="font-bold text-base">{{c.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- categoria  -->
                <div *ngIf="compCa" class="container mx-auto lg:hidden mb-5">
                    <div class="mx-5 mt-3">
                        <div class="flex justify-center">
                            <a [routerLink]="['/perfil', idUser]" class="btn-1 focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">
                                Cancelar
                            </a>
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-center my-10 space-x-3 hidden lg:block">
                    <a [routerLink]="['/perfil', idUser]" class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300">Cancelar</a>
                </div>
            </div>
        </div>

    </div>
    <!-- fin categoria  -->

    <div *ngIf="compSub" class="container mx-auto xl:my-0 mt-5 lg:mt-24 pb-5">
        <div class="mx-5 xl:mx-0">
            <div class="flex flex-col items-center justify-center content-center lg:h-screen">

                <div class="w-full">
                    <h1 class="text-3xl font-bold text-center text-aply-100">Creación de Servicio</h1>
                    <h2 class="text-xl text-base text-center text-dark-400 mt-5">{{categoria}}</h2>
                </div>

                <div class="mt-5 mb-5">
                    <sh-alerts *ngIf="subcas" [msg]="'Es necesario seleccionar una sub-categoria'" [alert]="'error'"></sh-alerts>
                </div>

                <div class="grid xl:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-4">
                    <div *ngFor="let s of subItems" (click)="onSelectCategoria(s._id)">
                        <div class="cuadros">
                            <div [ngClass]="{'bg-blue-100 rounded-lg': s._id == sub}" class="flex flex-col content-center items-center justify-center sm:w-full">
                                <span [ngClass]="{'text-blue-500': s._id == sub}" class="{{s.icon}} text-9xl mt-5"></span>
                                <h3 [ngClass]="{'text-blue-500': s._id == sub}" class="text-base font-bold mt-1 mx-5 mb-5 text-center">{{s.name}}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-center my-10 space-x-3 hidden lg:block">
                    <a [routerLink]="['/perfil', idUser]" class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300">Cancelar</a>
                    <button (click)="atras('categoria')" class="btn-1 focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">Atras</button>
                    <button (click)="siguiente('descripcion')" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">Siguiente</button>
                </div>
            </div>
        </div>
    </div>
    <!-- fin subcategoria  -->

    <!-- subcategoria buttons -->
    <div *ngIf="compSub" class="container mx-auto lg:hidden pb-5">
        <div class="mx-5 mt-3">
            <div class="flex justify-around">
                <button (click)="atras('categoria')" class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    <i class='bx bx-chevron-left text-xl'></i>
                </button>
                <a [routerLink]="['/perfil', idUser]" class="btn-sm focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">
                    <h2 class="text-base">Cancelar</h2>
                </a>
                <button (click)="siguiente('descripcion')" class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    <i class='bx bx-chevron-right text-xl'></i>
                </button>
            </div>
        </div>
    </div>

    <!-- descripcion  -->
    <div *ngIf="compDes" class="container lg:px-96 mx-auto xl:my-0 mt-5 lg:mt-24">
        <div class="mx-0 xl:mx-0">
            <div class="flex flex-col items-center justify-center content-center lg:min-h-screen lg:mt-24 mt-4">
                <div class="w-full mb-5">
                    <h1 class="text-3xl font-bold text-center text-aply-100">
                        Creacion de servicios
                    </h1>
                </div>

                <span class="icon-file text-8xl text-dark-400"></span>

                <div class="mt-3 xl:w-1/2 w-full">
                    <h2 class="text-xl text-base text-center text-dark-400 mx-5">
                        Dale un nombre a tu servicio y describe la actividad que prestaras en el..
                    </h2>
                </div>

                <div class="mt-3 xl:w-1/2 w-full">
                    <div class="flex flex-col mx-5 xl:mx-0 space-y-3">

                        <div class="flex flex-col">
                            <label class="font-bold text-base text-dark-300 mb-2">Etiqueta tu servicio </label>
                            <input formControlName="name" type="text" class="input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Logistica">
                            
                            <!-- validación reactiva -->
                            <div *ngIf="serviciosForm.get('name').invalid && (serviciosForm.get('name').dirty || serviciosForm.get('name').touched)">
                                <div *ngFor="let validation of validation_create_service.name">
                                    <sh-alerts  *ngIf="serviciosForm.get('name').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                                </div>
                            </div>

                        </div>

                        <div class="flex flex-col">
                            <label class="font-bold text-base text-dark-300 mb-2">Descripcion</label>
                            <textarea formControlName="description" rows="5" class="w-full input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" placeholder="Pequeña descripcion de quienes son"></textarea>
                            
                            <!-- validación reactiva -->
                            <div *ngIf="serviciosForm.get('description').invalid && (serviciosForm.get('description').dirty || serviciosForm.get('description').touched)">
                                <div *ngFor="let validation of validation_create_service.description">
                                    <sh-alerts  *ngIf="serviciosForm.get('description').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="mt-8 xl:w-1/2 w-full">
                    <h2 class="text-xl text-base text-center text-dark-400 mx-5">
                        ¿Ubicación de tu servicio?
                    </h2>
                </div>

                <!-- <div class="mt-3 xl:w-1/2 w-full"> -->
                <div class="mt-3 xl:w-1/2  w-full">
                    <div class="flex flex-col md:flex-row mx-8 lg:mx-0 space-y-3 md:space-y-0 md:space-x-4">
                        
                        <div class="flex flex-col md:w-1/2">
                            <label class="font-bold text-base text-dark-300 mb-2">Departamento</label>
                            <select class="input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2" [(ngModel)]="selectedDept._id" (change)="listadoMunicipio($event.target.value)" [ngModelOptions]="{standalone: true}">
                                <option value=""> ----- Seleccione el departamento ----- </option>
                                <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                            </select>
                        </div>
                        <div class="flex flex-col md:w-1/2">
                            <label class="font-bold text-base text-dark-300 mb-2">Municipio</label>
                            <select formControlName="town" class="input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent mb-2">
                                <option value=""> ----- Seleccione el municipio ----- </option>
                                <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                            </select>
                        </div>

                    </div>
                    <div class="mx-5 lg:mx-0">
                        <sh-alerts *ngIf="campoNoValido('town')" [msg]="'La ubicación es requerida'" [alert]="'error'"></sh-alerts>
                    </div>
                </div>

                <div class="mt-8 xl:w-1/2 w-full">
                    <h2 class="text-xl text-base text-center text-dark-400 mx-5">
                        Rango de precio
                    </h2>
                </div>

                <div class="mt-3 xl:w-1/2 w-full">

                    <div class="flex flex-col md:flex-row mx-5 lg:mx-0 space-y-3 md:space-y-0 md:space-x-4">

                        <div class="flex flex-col md:w-1/2">
                            <label class="font-bold text-base text-dark-300 mb-4">Precio minino</label>
                            <input 
                            formControlName="min_price"
                            type="text"
                            class="input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-trasparent mb-2">

                            <!-- validación reactiva -->
                            <div *ngIf="serviciosForm.get('min_price').invalid && (serviciosForm.get('min_price').dirty || serviciosForm.get('min_price').touched)">
                                <div class="mb-2" *ngFor="let validation of validation_create_service.min_price">
                                    <sh-alerts *ngIf="serviciosForm.get('min_price').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col md:w-1/2">
                            <label class="font-bold text-base text-dark-300 mb-4">Precio maximo</label>
                            <input 
                            formControlName="max_price"
                            type="text"
                            class="input-3 px-3 focus:ring-2 focus:ring-aply-100 focus:border-trasparent mb-2">
                            
                            <!-- validación reactiva -->
                            <div *ngIf="serviciosForm.get('max_price').invalid && (serviciosForm.get('max_price').dirty || serviciosForm.get('max_price').touched)">
                                <div class="mb-2" *ngFor="let validation of validation_create_service.max_price">
                                    <sh-alerts *ngIf="serviciosForm.get('max_price').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="flex items-center justify-center my-10 space-x-3 hidden lg:block">
                    <a [routerLink]="['/perfil', idUser]" class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300">Cancelar</a>
                    <button (click)="atras('subcategoria')" class="btn-1 focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">Atras</button>
                    <button (click)="siguiente('imagen')" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">Siguiente</button>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="compDes" class="container mx-auto lg:hidden pb-5">
        <div class="mx-5 mt-3">
            <div class="flex justify-between">
                <button (click)="atras('subcategoria')" class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    <i class='bx bx-chevron-left text-xl'></i>
                </button>
                <a [routerLink]="['/perfil', idUser]" class="btn-sm focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">
                    <h2 class="text-base">Cancelar</h2>
                </a>
                <button class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    <i class='bx bx-chevron-right text-xl'></i>
                </button>
            </div>
        </div>
    </div>
    <!-- fin descripcion  -->

</form>