<div class="h-20 w-full">

</div>
<div class="container mx-auto">
    <div class="mx-8 xl:mx-0 mt-6 xl:mt-32 mb-24">

        <div class="grid xl:grid-cols-4  gap-8">

            <div class="xl:col-span-1">
                <menu-perfil [id]="id" [tipo]="'r'" class="xl:hidden"></menu-perfil>
                <info-perfil></info-perfil>
            </div>

            <div class="xl:col-span-3">
                <menu-perfil [id]="id" [tipo]="'r'" class="hidden xl:block"></menu-perfil>

                <div class="mt-8">

                    <div class="flex justify-between mb-2">
                        <h1 class="text-dark-400 font-bold text-xl ">Mis recomendaciones ({{totalDocuments}}) </h1>
                        <div>
                            <a (click)="openModal('crear-tip')" *ngIf="cuentaActual" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                                <i class="icofont-plus-circle"></i> Crear recomendacion
                            </a>
                        </div>
                    </div>

                    <div *ngIf="totalDocuments > 0" class="flex justify-between items-center content-center mb-3">
                        <div class="text-dark-400 font-semibold  text-sm">
                            Entrada {{currentPage}} de {{totalPages}} paginas
                        </div>
                        <div>
                            <ul class="flex">
                                <li (click)="cambiarPagina(-1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Anterior</span>
                                    </a>
                                </li>
                                <li *ngIf="paginaMM > 0" (click)="cambiarPaginaNumero(paginaMM)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMM}}</a>
                                </li>
                                <li *ngIf="paginaMenos > 0" (click)="cambiarPaginaNumero(paginaMenos)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMenos}}</a>
                                </li>
                                <li class="mx-1 px-3 py-2 bg-aply-100 text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{currentPage}}</a>
                                </li>
                                <li *ngIf="paginaMas != 0" (click)="cambiarPaginaNumero(paginaMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMas}}</a>
                                </li>
                                <li *ngIf="paginaMasMas != 0" (click)="cambiarPaginaNumero(paginaMasMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMasMas}}</a>
                                </li>

                                <li (click)="cambiarPagina(1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Siguiente</span>
                                    </a>
                                </li>
                            </ul>


                        </div>
                    </div>

                    <div *ngIf="totalDocuments > 0">
                        <div *ngFor="let r of recomendar" class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 gap-5 mb-2">

                            <div class="border-4 border-dotted rounded-2xl bg-white p-4">
                                <div class="">

                                    <div class="mb-2">
                                        <div class="flex justify-between items-center content-center">
                                            <div class="flex space-x-3 items-center content-center mb-1">
                                                <img *ngIf="r.user.imagen" [src]="r.user.imagen.thumbnail" alt="" class="w-10 h-10 rounded-full object-center object-cover">
                                                <img *ngIf="!r.user.imagen" src="assets/imagen/general/perfil-1.svg" alt="" class="w-10 h-10 rounded-full object-center object-cover">
                                                <div class="">
                                                    <h3 class="font-bold text-dark-400 text-md  leading-4">
                                                        {{r.user.name}}
                                                    </h3>
                                                    <h4 class="font-semibold text-dark-300 text-xs">
                                                        {{r.createdAt | date }} <span *ngIf="r.municipality"> - {{r.municipality.name}}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class="flex items-center content-center text-md text-dark-300 font-semibold">
                                                <i class="icon-msg-espera-alt text-aply-100 text-xl mr-1"> </i> {{r.recomendation_response.length}}
                                            </div>
                                        </div>
                                        <p class="text-dark-300 leading-tight text-base">
                                            {{r.message}}
                                        </p>
                                    </div>
                                    <hr class="my-3">

                                    <div class="ml-10">
                                        <div class="flex space-x-3 mb-2">
                                            <img src="assets/imagen/general/perfil-1.svg" alt="" class="w-8 h-8 rounded-full object-center object-cover">
                                            <div class="w-full">
                                                <response-tip (resetear)="resetearListado($event)" [id]="r._id" [log]="log"></response-tip>
                                            </div>
                                        </div>
                                        <div *ngIf="r.recomendation_response.length > 0">
                                            <response [respuestas]="r.recomendation_response" [id]="r._id" [c]=r.recomendation_response.length></response>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>


                    <div *ngIf="totalDocuments > 0" class="flex justify-between items-center content-center mt-3">
                        <div class="text-dark-400 font-semibold  text-sm">
                            Entrada {{currentPage}} de {{totalPages}} paginas
                        </div>
                        <div>
                            <ul class="flex">
                                <li (click)="cambiarPagina(-1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Anterior</span>
                                    </a>
                                </li>
                                <li *ngIf="paginaMM > 0" (click)="cambiarPaginaNumero(paginaMM)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMM}}</a>
                                </li>
                                <li *ngIf="paginaMenos > 0" (click)="cambiarPaginaNumero(paginaMenos)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMenos}}</a>
                                </li>
                                <li class="mx-1 px-3 py-2 bg-aply-100 text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{currentPage}}</a>
                                </li>
                                <li *ngIf="paginaMas != 0" (click)="cambiarPaginaNumero(paginaMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMas}}</a>
                                </li>
                                <li *ngIf="paginaMasMas != 0" (click)="cambiarPaginaNumero(paginaMasMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMasMas}}</a>
                                </li>

                                <li (click)="cambiarPagina(1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Siguiente</span>
                                    </a>
                                </li>
                            </ul>


                        </div>
                    </div>

                    <div *ngIf="totalDocuments <=  0" class="mt-8">
                        <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                            <div class="flex items-center content-center text-dark-400 text-xl">
                                <span class="icon-info-circle mr-2"></span>
                                <span class="mr-2">Este usuario no tiene ninguna recomendacion creada, en estos momentos </span>
                                <a (click)="openModal('crear-tip')" *ngIf="cuentaActual" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                                    <i class="icofont-plus-circle"></i> Crea una recomendacion
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<jw-modal id="crear-tip">
    <div class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ">
        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 ">
            <div class="border-b px-5 py-2 flex justify-between items-center ">
                <h3 class="font-semibold text-lg text-dark-300 ">Nueva recomendacion</h3>
                <button class="text-base px-1 focus:ring-none " (click)="closeModal( 'crear-tip'); "> 
                    <i class='bx bx-x'></i> 
                </button>
            </div>

            <div class="py-5">
                <form [formGroup]="tipForms " (submit)="onTip() ">
                    <div class="px-5 py-1 ">
                        <textarea formControlName="message" type="text" class="input-3 w-full" placeholder="Pide una recomendacion "></textarea>
                        <sh-alerts *ngIf="campoNoValido( 'message')" [msg]="'La recomendacion debe tener minimo 10 caracteres' " [alert]=" 'error' "></sh-alerts>
                    </div>
                    <div class="px-5 py-1 flex space-x-2 justify-end ">
                        <span class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300 " (click)="closeModal( 'crear-tip'); ">Cancel</span>
                        <button type="submit " class="btn-1 focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100 "> 
                        <i class="icofont-save "></i> Guardar
                    </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</jw-modal>