import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class NecesidadesService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

  listarUltimasNecesidades() {
    const url = `${base_url}/necesidades/publicas`;
    return this.http.get(url).pipe(
      tap((resp:any) => {})
    )
  }


  // http://127.0.0.1:3333/api/necesidad/show/61a55731f7800bc3f49a0214?page=1
  necesidadDelUsuarioSeleccionado(id: string, page: number = 2, limit:number = 10) {
    const url = `${base_url}/necesidad/show/${id}?page=${page}&limit=${limit}`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // http://127.0.0.1:3333/api/necessities?page=1&limit=1
  cargarNecesidadesTodas(page:number, limit:number) {
    const url = `${base_url}/necessities?page=${page}&limit=${limit}`;
    return this.http.get(url).pipe(
      tap((resp:any) => {})
    )
  }
  // http://127.0.0.1:3333/api/necessities/search?page=1&limit=2
  searchNecesidades(
    data: {
      subcategory: string,
      municipality: string,
      min_price: string,
      max_price: string,
      word: string,
    },
    page: number, limit: number
  ) {
    const url = `${base_url}/necessities/search?page=${page}&limit=${limit}`;
    return this.http.post(url, data).pipe(
      tap((resp:any) => {})
    )
  }


  // http://127.0.0.1:3333/api/necessities/61b357d54a57b8325beb6401
  cargarNecesidadPorId(id:string) {
    const url = `${base_url}/necessities/${id}`;
    return this.http.get(url).pipe(
      tap((resp:any) => {})
    )
  }

  // http://127.0.0.1:3333/api/necesidad/61b35ce2f4a5e3b44da7ac2c/usuario
  idUsuario(id:string) {
    const url = `${base_url}/necesidad/${id}/usuario`;
    return this.http.get(url).pipe(
      tap((resp: any) => { })
    )
  }

  // /aplicar/:necessity //id del la necesidad
  aplicarNecesidad(id: string) {
    const url = `${base_url}/aplicar/${id}`;
    return this.http.get(url, this.headers).pipe( tap((resp: any) => { }) )
  }

}
