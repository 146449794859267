import { Component, Input, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/core/services/fileUpload.service';
import { PerfilService } from 'src/app/core/services/perfil.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'cuenta-sin-perfil',
  templateUrl: './cuenta-sin-perfil.component.html',
  styleUrls: ['./cuenta-sin-perfil.component.css']
})
export class CuentaSinPerfilComponent implements OnInit {

  @Input() id: string;
  @Input() validacion: boolean;
  
  public resultado: any;
  public progress: number; 
  public imagenUpdate: File;
  public imgUpdate: any = null;

  constructor(
    private fileService: FileUploadService,
    private perfilService: PerfilService,
  ) { }

  ngOnInit() {
    this.perfilSeleccionado();
    // this.progressPerfil();
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => { this.resultado = resp.data }
    )
  }
  
  progressPerfil() {
    this.perfilService.progressPerfil().subscribe(
      resp => {this.progress = resp.data }
    )
  }

  cambiarUpdateImagen(file: File) {
    this.imagenUpdate = file;
    if (!file) { return this.imgUpdate = null }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.updateImagen();
  }

  updateImagen() {
    if (this.imagenUpdate == null) { return } 
    this.fileService.uploadUpdateImagenProfile(this.imagenUpdate)
      .then(resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == "success") {
          setTimeout(() => {
            this.imagenUpdate = null;
            this.perfilSeleccionado();
          }, 1500);
        }         
      }
    )    
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}