import { Component, Input, OnInit } from '@angular/core';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'rr-service',
  templateUrl: './response-review.component.html',
  styleUrls: ['./response-review.component.css']
})
export class ResponseReviewComponent implements OnInit {
  
  @Input() id: string;
  @Input() log: boolean;
  @Input() id_user: string;

  public page: number = 1;
  public totalPages: number;
  public totalDocuments: number;

  public limit: number = 1;
  public id_review: string;
  public responses: any;

  constructor(
    private reseniasService: ReseniasService
  ) { }

  ngOnInit() {
    this.response()
  }

  cambiarPagina(page: number) {

    this.page += page;
    this.limit = 5;

    if (this.page < 1) {
      this.page = 1;
      this.limit = 1;      
    } else if (this.page > this.totalPages) {
      this.page -= page;
    }

    this.response();
  }

  paginar() {
    this.page = 1; 
    this.limit = 5;
    this.response();
  }

  response() {
    this.reseniasService.listarIdReviewResponse(this.id, this.page, this.limit).subscribe(
      resp => {
        this.responses = resp.data.reviews_responses;
        this.totalPages = resp.data.totalPages;
        this.totalDocuments = resp.data.totalDocuments;
      }
    )
  }

  cambioValorHijo(actualizar) {
    if (actualizar) {
      this.response();
    }
  }

}
