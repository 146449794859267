import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-webcontactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {

  public conver;
  public conversacion;
  public user:any;
  public id:any;


  constructor(
    private router: Router,
    private chat: ChatService
  ) { }

  ngOnInit() {
    this.conversaciones();
    this.i();
  }

  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
        console.log("usuario logueado", resp.data._id);
      }
    )
  }

  conversaciones(){
    this.chat.conversaciones().subscribe(
      resp => {
        this.conver = resp;
        this.conversacion = this.conver.conversations;
        
        if(this.conversacion[0].members[0]._id == this.user){

          this.router.navigate(['web/chat',this.conversacion[0]._id,this.conversacion[0].members[1]._id]);
          console.log("id del otro usuario", this.conversacion[0].members[0]._id);
        }else{
          this.router.navigate(['web/chat',this.conversacion[0]._id,this.conversacion[0].members[0]._id]);
          console.log("id del otro usuario", this.conversacion[0].members[1]._id);
        }
      }
    )
  }

  id_conversacion(valor:any){

    this.chat.id_conversacion.emit(
      {data : valor},
    )

    console.log('valores', valor._id);

    this.i();

    if(valor.members[0]._id == this.user){
      this.id = valor.members[1]._id;
    }else{
      this.id = valor.members[0]._id;
    }

    this.chat.id_conversacion.subscribe(
      data => {
        console.log("loco", data.data._id);
        
      });

      console.log("loco", this.id);

    this.router.navigate(['web/chat/'+valor._id+'/'+this.id]);
   
    console.log("el id conversacion", valor);

  }

}
