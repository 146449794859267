import { Pipe, PipeTransform } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';


@Pipe({
  name: 'lastMessage'
})
export class LastMessagePipe implements PipeTransform {
  messages: any;
  participants: any;
  last: any;

  constructor(
    private message: ChatService
  ){

  }
  transform(value: any, args?: any): any {

    return this.onlast(value)
  
    console.log("aver ", this.messages)

  }

  async onlast(valor:any) {
    await this.message.lastMensaje(valor).toPromise().then((response) => {
     
      console.log("ultimo mensaje", response.lastMessge)
      this.last = response.lastMessge.text

    }).catch(e => console.error(e));
    
    return this.last
  }


}


