<div class="relative w-full pt-20">

<!-- canecera de mensajes -->
<div class="w-full h-auto">
  <div class="w-full h-auto shadow-md border-md bg-white">
    <div *ngFor="let miembro of miembros" [ngClass]="{'contacto': user==miembro._id }"  class="flex flex-row py-2 px-2 items-center">
      
      <div class=" relative w-auto mr-5 cursor-pointer" [routerLink]="['/perfil', miembro._id]" >
        <img *ngIf="miembro.imagen"
          [src]="miembro.imagen.thumbnail"
          class="object-cover h-10 w-10 rounded-full"
          alt=""/>
          <img *ngIf="!miembro.imagen"
          src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-12 w-12 rounded-full"
            alt=""/>
          <a class="absolute left-6 -bottom-1 border border-white text-white p-1 text-xs bg-green-500 hover:bg-green-900 font-medium tracking-wider rounded-full transition ease-in duration-300">
            <div class="w-2 h-2"></div>
            </a>
      </div>
      <div class="w-full">
        <div [routerLink]="['/perfil', miembro._id]" class="text-lg font-semibold left-2 cursor-pointer">{{miembro.name}}</div>
        <span class="text-gray-500 font-semibold">Prestador de servicio</span>
      </div>
     </div>
  </div>


</div>


<!-- foi canecera de mensajes -->

<!-- message -->
<div id="sms" class="contenedor relative w-full h-screen pb-48 flex flex-col justify-between overflow-y-scroll">
  <div  class="px-5 flex flex-col mt-5 ">

    <div *ngIf="!mensaje || mensaje == ''" class="w-full h-screen px-4 px-32">
      <div class="w-full flex justify-center pt-9">
        <img class="w-1/2" src="assets/imagen/general/no-mensaje.png" alt="">
      </div>
      <div class="w-full h-auto flex justify-center pt-8 lg:pt-2 mt-3">
        <p class="text-2xl font-semibold text-gray-700">¡No hay Mensajes aun!</p>
      </div>
      <div class="w-full h-auto flex justify-center mt-4">
        <p class="text-xl font-medium text-gray-700 text-center">Ya puedes comenzar a enviar mensajes a tu contacto</p>
      </div>
      
      
    </div>

      <div  *ngFor="let men of mensaje">
        <div  class="flex  mb-4" [ngClass]="{'mensaje_mio': user==men.sender._id, 'mensaje_no_mio': user != men.sender._id }">
          <img [routerLink]="['/perfil', men.sender._id]" *ngIf="men.sender.imagen" [ngClass]="{'foto': user != men.sender._id }"
          [src]="men.sender.imagen.thumbnail"
          class="hidden object-cover h-6 w-6 rounded-full cursor-pointer"
          alt=""
          />
          <img [routerLink]="['/perfil', men.sender._id]" *ngIf="!men.sender.imagen" [ngClass]="{'foto': user != men.sender._id }"
            src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-6 w-6 rounded-full hidden cursor-pointer"
            alt=""/>
            <div *ngIf="!men.images[0]" [ngClass]="{'mensaje_color_mio': user==men.sender._id, 'mensaje_color': user!= men.sender._id}" class="mx-2 py-2 px-4  text-white">
              {{men.text}}
            </div>
            <div *ngIf="men.images[0]" [ngClass]="{'mensaje_color_mio': user==men.sender._id, 'mensaje_color': user!= men.sender._id}" class="w-1/2 mx-2 py-2 px-2  text-white">
              
              <img  src="{{men.images[0].thumbnail}}"  alt="" class="w-full rounded-lg">
              {{men.text}}
            </div>
          <img [routerLink]="['/perfil', men.sender._id]" *ngIf="men.sender.imagen"  [ngClass]="{'foto_mia': user == men.sender._id }"
            [src]="men.sender.imagen.thumbnail"
            class="object-cover h-6 w-6 rounded-full hidden cursor-pointer"
            alt=""
          />
          <img [routerLink]="['/perfil', men.sender._id]" *ngIf="!men.sender.imagen" [ngClass]="{'foto_mia': user == men.sender._id }"
            src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-6 w-6 rounded-full hidden cursor-pointer"
            alt=""/>
        </div>
      </div>

  </div>

  <div class="flex w-1/2 fixed bottom-0">
      <!-- input mesage -->
    <div *ngIf="subir==false" class="w-full h-auto shadow-md border-md">
      <section class="w-full flex items-center flex-grow h-16 px-4">
        <div click="subir()"  (click)="hiddenfileinput.click()" class="flex-none w-10 h-10 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-image text-3xl text-blue-500 mt-1 ml-1'></i>
          <input type="file" class="hidden" id="file-upload" #hiddenfileinput (change)="cambiarImagen($event.target.files[0])">
        </div>
        <div class="w-full mx-2">
          <form [formGroup]="messageForm">

            <input formControlName="conversation_id" type="hidden" [(ngModel)]="id">
            <input formControlName="text" type="text"  placeholder="Aqui va el mensaje" class="w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 rounded-full shadow-md focus:bg-gray-100 focus:ring ring-blue-500 focus:outline-none"
                aria-label="" aria-describedby="basic-addon1">
          </form>
        </div>
        <div (click)="onSend()" class="w-10 h-10 pl-2 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-send text-3xl text-blue-500 mt-1 mr-2'></i>
        </div>


      </section>
    </div>
  </div>

</div>



<div *ngIf="subir==true" class="w-full h-screen absolute top-36 z-50  fondo_subir">
  <div class="w-full h-12 px-4 py-4">
    <i class='bx bx-x left-text text-4xl font-bold text-white opacity-100' (click)="cerrar()"></i>
  </div>
  <div class="w-full h-auto px-48">
    <div class="w-full h-auto border-dashed border-2 border-white rounded-lg">
      <img *ngIf="imgTemp" [src]="imgTemp" alt="" class="w-full rounded-lg object-cover object-center">
    </div>
    <div class="w-full px-4 pt-2 text-white justify-center">
      <p class="text-center text-xl">Comentario de la imagen</p>
    </div>
    <div class="w-full px-4 pt-4 text-white flex justify-center">
      <i class='bx bxs-chevrons-down text-center text-5xl animate-bounce'></i>
    </div>

  </div>

  <div class="flex w-1/2 fixed bottom-0">
    <div class="relative z-50 w-full h-full inset-x-0 shadow-md border-md bg-white">
      <section class="flex items-center flex-grow h-16 px-4 ">
        <div class="flex-none w-10 h-10 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-image text-3xl text-blue-500 mt-1 ml-1'></i>
        </div>
        <div class="w-full mx-2">
          <form [formGroup]="messageForm">
    
            <input formControlName="conversation_id" type="hidden" [(ngModel)]="id">
            <input formControlName="text" type="text"  placeholder="Aqui va el mensaje" class="w-12 md:w-96 min-w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 rounded-full shadow-md focus:bg-gray-100 focus:ring ring-blue-500 focus:outline-none"
                aria-label="" aria-describedby="basic-addon1">
          </form>
        </div>
        <div type="submit" (click)="subirImagen()"  class="w-10 h-10 pl-2 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-send text-3xl text-blue-500 mt-1 mr-2'></i>
        </div>
    
    
      </section>
    </div>
  </div>
 

</div>
<!-- input mesage -->


      



 

<!-- end message -->

</div>