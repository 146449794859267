import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'jw-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  public id_user: string;
  public log: boolean;
  public verificacion: boolean = false;

  constructor(
    private usuarioService: UsuarioService,
    private perfilService: PerfilService,
    private router: Router
  ) {
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) {
      this.id_user = usuarioService.usuarios._ids;
      this.perfilService.verificacionPerfil(this.id_user).subscribe(
        resp => { this.verificacion = resp.data; }
      )
    }
  }

  ngOnInit() {
  }

  irCrearServicio() {
    if (this.log) {
      console.log(this.verificacion);
      
      if (this.verificacion) {
        this.router.navigate( ['/crear/servicio'] );
      } else {
        this.router.navigateByUrl('/creacion/perfil');      
      }
    } else {
      this.router.navigateByUrl('/login');      
    }
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1
  };
  
  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  
}
