<!-- canecera de mensajes -->

<!-- canecera de mensajes -->
<div class="w-full h-auto mt-20">
  <div class="w-full h-auto  bg-white">
    <div class="flex flex-row py-2 px-2 items-center shadow-md border-md">
      <div title="Crear Propuesta" [ngClass]="{'select': crear_ == true}" (click)="crear()" class="flex justify-center w-full py-3 rounded-lg hover:bg-gray-50 hover:shadow-md focus:outline-none focus:ring focus:ring-blue-300 cursor-pointer">
        <i class='bx bx-plus-circle text-3xl text-blue-500 mr-2'></i>
      </div>
    
      <div title="Propuestas" [ngClass]="{'select': propuesta == true}" (click)="propuestas()" class="flex justify-center w-full py-3 rounded-lg hover:bg-gray-50 hover:shadow-md cursor-pointer">
        <i class='bx bx-check-circle text-3xl text-blue-500 mr-2'></i>
      </div>
      <div title="facturas" [ngClass]="{'select': pago_ == true}" (click)="pago()" class="flex justify-center w-full py-3 rounded-lg hover:bg-gray-50 hover:shadow-md cursor-pointer">
        <i class='bx bx-dollar-circle text-3xl text-blue-500 mr-2'></i>
      </div>
     </div>
  </div>


</div>


<!-- foi canecera de mensajes -->
