<div class="mx-3 mt-5 search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="200" (scrolled)="onScroll()" [scrollWindow]="true">


    <h1 *ngIf="totalDocuments > 1" class=" font-bold text-dark-400 text-xl mb-2">
        <i class="icofont-caret-right"></i> Reseñas ({{totalDocuments}})
    </h1>

    <!-- informacion de reseñas  -->
    <div *ngIf="!cuentaActual" class="mt-8">
        <form [formGroup]="reseniaForm" (ngSubmit)="onCrearResenia()">
            <div class="flex space-x-2">

                <div class="w-full">
                    <textarea formControlName="message" class="input-3 w-full" rows="1" placeholder="Haga una reseña del servicio"></textarea>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn-2 focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                        <i class='bx bxs-send'></i>
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div *ngFor="let r of Data" class="border-4 border-dotted rounded-2xl bg-white p-4 mb-3 btn-efecto">
        <div class="xl:my-3 xl:mx-3">

            <card-review (actualizar)="cambioValorHijo($event)" [r]="r" [id_user]="id_user1" [log]="log"></card-review>

            <div class="ml-9 mt-2">
                <div class="flex space-x-3 mb-2">
                    <img src="assets/imagen/general/perfil-1.svg" alt="" class="w-8 h-8 rounded-full object-center object-cover">
                    <div class="w-full">
                        <response-review (resetear)="resetearListado($event)" [id]="r._id" [log]="log"></response-review>
                    </div>
                </div>
                <rr-service [id]="r._id" [log]="log" [id_user]="id_user1"></rr-service>
            </div>
        </div>
    </div>


    <div *ngIf="isLoading" class="flex items-center justify-center w-full h-full">
        <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
            <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                <path clip-rule='evenodd'
                    d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                    fill='currentColor' fill-rule='evenodd' />
            </svg>
            <div>Cargando...</div>
        </div>
    </div>
    <div *ngIf="totalDocuments < 1" class="mt-8">
        <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
            <div class="flex items-center content-center text-dark-400 text-xl">
                <span class="icon-info-circle mr-2"></span> No tiene ninguna reseña
            </div>
        </div>
    </div>
</div>