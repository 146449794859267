import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

  visible: boolean = false;
  breakpoint: number = 1000;

  constructor(
    private router: Router,
    private menuservice: MenuService,
  ) { }

  propuestas = true;
  crear = false;
  pago = false;
  pasarela = false;
  detalle = false;

  ngOnInit() {

    this.menuservice.crear.subscribe(data =>{
      this.crear = true;
      this.propuestas = false;
      this.pago = false;
      this.pasarela;
      this.detalle;
    });

    this.menuservice.propuestas.subscribe(data =>{
      this.crear = false;
      this.propuestas = true;
      this.pago = false;
      this.pasarela = false;
      this.detalle = false
    });

    this.menuservice.pago.subscribe(data =>{
      this.crear = false;
      this.propuestas = false;
      this.pago = true;
      this.pasarela = false;
      this.detalle = false;
    });

    this.menuservice.pasarela.subscribe(data =>{
      this.crear = false;
      this.propuestas = false;
      this.pago = false;
      this.pasarela = true;
      this.detalle = false;

    });

    this.menuservice.detalle.subscribe(data =>{
      this.crear = false;
      this.propuestas = false;
      this.pago = false;
      this.pasarela = false;
      this.detalle = true;

    });
  }

  onResize(event) {
    const w = event.target.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
      console.log('pantalla esta grande');
      this.router.navigate(['web/chat']);
    } else {
      // whenever the window is less than 768, hide this component.
      console.log('pantalla esta pequeña');
      this.router.navigate(['chat/contacto']);
      this.visible = false;
    }
  }

}
