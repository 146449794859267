<div class="w-full h-auto pb-4"> 
  <div class="w-full h-12 items-center flex justify-center" >
    <span class="text-gray-700 font-semibold " >Propuesta</span>
  </div>
  <div class="w-full h-auto flex items-center " >
   
    <div class="w-24 py-2 pr-2">
      <img *ngIf="propuesta.sender.imagen" class="object-cover h-14 w-14 rounded-full shadow-md border-md" [src]="propuesta.sender.imagen.thumbnail" alt="">
      <img *ngIf="!propuesta.sender.imagen" class="object-cover h-14 w-14 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
    </div>
    <div class="w-full" >
      <p class="text-xl font-semibold text-gray-700">{{propuesta.sender.name}}</p>
      <p class="text-lg text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
      
    </div>
  </div>

  
  <div class="w-full h-12 flex py-2" >

    <span class="font-medium">Estado: </span>
    <div [ngClass]="{'caro': propuesta.status == 'Costoso', 
        'barato': propuesta.status == 'Barato',
        'bien': propuesta.status == 'Confirmar',
        'cancelar': propuesta.status == 'Cancelar',
        'espera': propuesta.status == 'Espera'}" class="w-3/4 h-7 flex justify-center items-center px-2">
        <div [ngClass]="{'icon_espera': propuesta.status == 'Espera'}" class="hidden"> 
        <i  class="bx bx-loader-circle text-2xl mr-2 hidden"></i>
        </div>
        <div [ngClass]="{'icon_caro': propuesta.status == 'Costoso'}" class="hidden">
          <i  class='bx bx-sad text-2xl mr-2'></i>
        </div>
        <div [ngClass]="{'icon_barato': propuesta.status == 'Barato'}" class="hidden">
          <i  class='bx bx-happy-beaming text-2xl mr-2 hidden'></i>
        </div>
        <div [ngClass]="{'icon_bien': propuesta.status == 'Confirmar'}" class="hidden">
          <i  class='bx bx-happy-heart-eyes text-2xl mr-2 hidden'></i>
        </div>
        <div [ngClass]="{'icon_cancelar': propuesta.status == 'Cancelar'}" class="hidden">
          <i  class='bx bx-dizzy text-2xl mr-2 hidden'></i>
        </div>
        
          <p class="text-xl font-semibold">{{propuesta.status}}</p>
    </div>
  </div>

  <div class="w-full h-12 flex py-2" >
    <span class="text-gray-700 font-medium " >Servicio o necesidad</span>
  </div>

  <div class="w-full h-auto mb-4 " >
    <div class="w-full h-auto rounded-lg h-auto border p-2 mb-2">
      <div class="flex">
        <div class="w-1/5 h-auto rounded-lg ">
          <!-- <img *ngIf="ser.images[0]" class="rounded-lg" [src]="ser.images[0].thumbnail" alt=""> -->
          <img  class="rounded-lg" src="assets/imagen/general/no-imagen.png" alt="">
        </div>
        <div class="pl-4 w-3/5">
          <p *ngIf="propuesta.onModel == 'Service'"class="text-base font-semibold text-gray-700 truncate ">{{propuesta.property.name}}</p>
          <p *ngIf="propuesta.onModel == 'Necessity'"class="text-base font-semibold text-gray-700 truncate ">{{propuesta.property.tittle}}</p>
          <p *ngIf="propuesta.onModel == 'Service'" class="text-sm text-gray-500 font-semibold -mt-1">Servicio</p>
          <p *ngIf="propuesta.onModel == 'Necessity'" class="text-sm text-gray-500 font-semibold -mt-1">Necesidad</p>
        </div>
        <div class="w-1/5 flex justify-end pr-4">
          
        </div>
      </div>
    
      <div class="w-full h-auto ">
        <p>{{propuesta.property.description}}</p>
      </div>
    </div>
    
  </div>

  <div class="w-full h-auto flex justify-center">
    <span class="text-xl font-semibold text-gray-600" >Te esta proponiendo</span>
  </div>

  <div class="w-full h-auto flex justify-center mt-4">
    <span class="text-2xl text-gray-700 font-semibold">{{propuesta.value}}</span>
  </div>


  <div class="w-full h-12">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
      <div ngxSlickItem class="w-80 flex" (click)="selec_estado(propuesta._id, 'Costoso','costoso')">
         <div class="w-40 h-9 bg-red-300 rounded-full flex justify-center items-center">
           <i class='bx bx-sad text-2xl text-red-600 mr-2'></i>
           <p class="text-2xl text-red-600 font-semibold pb-1">Costoso</p>
         </div>
       </div>
        <div ngxSlickItem class="w-80 flex" (click)="selec_estado(propuesta._id,'Barato', 'barato')" >
         <div class="w-40 h-9 bg-blue-200  rounded-full flex justify-center items-center">
           <i class='bx bx-happy-beaming text-2xl text-blue-500 mr-2'></i>
           <p class="text-2xl text-blue-500 font-semibold pb-1">Barato</p>
         </div>
       </div>
       <div ngxSlickItem class="w-80 flex" (click)="selec_estado(propuesta._id,'Confirmar', 'confirmar')">
         <div class="w-40 h-9 bg-green-200 rounded-full flex justify-center items-center">
           <i class='bx bx-happy-heart-eyes text-2xl text-green-500 mr-2'></i>
           <p class="text-2xl text-green-500 font-semibold pb-1">Confirmar</p>
         </div>
       </div>
       <div ngxSlickItem class="w-80 flex" (click)="selec_estado(propuesta._id,'Cancelar', 'cancelar')" >
         <div class="w-40 h-9 bg-gray-300 rounded-full flex justify-center items-center">
           <i class='bx bx-dizzy text-2xl text-gray-600 mr-2'></i>
           <p class="text-2xl text-gray-600 font-semibold pb-1">Cancelar</p>
         </div>
       </div>
       
       
      </ngx-slick-carousel>
  </div>
</div>
