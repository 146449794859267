import { Component, Input, OnInit } from '@angular/core';
import { animate, state, trigger, style, transition, keyframes } from '@angular/animations';

import { Usuarios } from 'src/app/core/models/usuarios.models';
import { RatingService } from 'src/app/core/services/rating.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'rating-like',
  templateUrl: './calificacion-like.component.html',
  styleUrls: ['./calificacion-like.component.css'],
  animations: [
    trigger('aniBoton', [
      state('inactive', style({
        animation: '1s .8s both',
        transform: 'translateY(160px)'
      })),
      state('active', style({
        animation: '1s .8s both',
        transform: 'translateY(160px)'
      })),
      transition('inactive => active', animate(1200, keyframes([
        style({transform: 'translateY(25px)', offset:0}),
        style({transform: 'translateY(0px)', offset:0.25}),
        style({transform: 'translateY(-50px)', offset:0.50}),
        style({transform: 'translateY(-100px)', offset:0.75}),
        style({transform: 'translateY(-150px)', offset:1}),
      ]))),
    ]),
  ]
})
  
export class CalificacionLikeComponent implements OnInit {

  public state: string = "inactive";

  @Input() likes: any;
  @Input() id_ref: string;
  @Input() type_model: string;
  
  public id_user: string;
  public totalLikes: number = 0;
  public verdadero: boolean = false;
  public log: boolean;
  
  constructor(
    private ratingService: RatingService,
    private usuarioService: UsuarioService,
    private router: Router
  ) {

    if (usuarioService.getLog) {
      this.log = usuarioService.getLog();
    }

    if (this.log == true) {
      this.id_user = usuarioService.usuarios._ids;
    }
    
  }

  ngOnInit() {
    this.contador();
    this.verificarLikes();
  }

  onLikes() {
    if (this.log == true) {
      if (this.verdadero == true) {
        return;
      }

      this.ratingService.ratingLikes(this.id_ref, this.type_model).subscribe(
        resp => {
          this.totalLikes = this.totalLikes + 1
          this.verdadero = true;
          this.state = this.state === 'active' ? 'inactive' : 'active';
        }
      )
    } else {
      Swal.fire({
        imageUrl: 'assets/imagen/logo/logo-blue.png',
        imageHeight: 100,
        title: 'Veo que no has iniciado sesion!',
        text: `Para dar like necesitas una cuenta en D3Una.com`,
        showCloseButton: true,
        showDenyButton: true,
        confirmButtonText: `Registrarme`,
        confirmButtonColor:'#2784FE',
        denyButtonText: `Iniciar Sesion`,
        denyButtonColor: '#2784FE',

      }).then((result) => {
        if (result.isConfirmed) {
          
          this.router.navigate(['/register']);
          //window.location.href= this.link;
        }else if(result.isDenied){

          this.router.navigate(['/login']);
        }
      })
      console.log('no esta logeado');
    }
  }

  verificarLikes() {
    if (this.log == true) {
      if (this.id_user) {
        if (this.totalLikes > 0) {          
          for (let date of this.likes) {
            if(this.id_user == date.user){
              this.verdadero = true;    
            }
          }
        }
      }
    }
  }

  contador() {
    let suma = 0;
    for (let date of this.likes) { 
      suma = suma + 1;
    }
    this.totalLikes = suma;
  }
  
  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  // like de perfil = Review
  // like de servicios = Service
  // like de recomendaciones = RecommendationResponse
  // like de Respuestas de reseñas = ReviewResponse

}
