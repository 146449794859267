<footer class="d3una-footer">
  <div class="px-4 py-16 mx-auto sm:px-6 lg:px-8" style="display: flex;
    flex-direction: column;margin: 0;">
    <div class="grid grid-cols-1 gap-20 lg:grid-cols-3" style="display: flex;align-items: center;justify-content: center;">
      <div>
        <img src="assets/imagen/logo/logo-tras.png" class="mr-5 w-24 h-24 " alt="logo" />
        <p class="max-w-xs mt-4 text-sm d3una-footer-text">
          En deuna puedes trabajar y hacer que
          todos conozcan, lo que haces o buscar
          los mejores para que hagan el trabajo por ti..
        </p>
        <div class="flex mt-8 space-x-6 text-gray-700">
          <a class="hover:opacity-75" href target="_blank" rel="noreferrer">
            <span class="sr-only"> Facebook </span>
            <img class="w-8 h-8" src="assets/imagen/icons/facebook.png" alt="">
            <!-- <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg> -->
          </a>
          <a class="hover:opacity-75" href target="_blank" rel="noreferrer">
            <span class="sr-only"> Instagram </span>
            <img class="w-8 h-8" src="assets/imagen/icons/instagram.png" alt="">
            <!-- <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
              </svg> -->
          </a>
          <a class="hover:opacity-75" href target="_blank" rel="noreferrer">
            <span class="sr-only"> Twitter </span>
            <img class="w-8 h-8" src="assets/imagen/icons/whatsapp.png" alt="">
            <!-- <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg> -->
          </a>
          <a class="hover:opacity-75" href target="_blank" rel="noreferrer">
            <span class="sr-only"> Twitter </span>
            <img class="w-8 h-8" src="assets/imagen/icons/twitter.png" alt="">
            <!-- <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg> -->
          </a>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4 text-gray-700 ">
        <div>
          <p class="font-medium font-semibold d3una-footer-heading">
            <svg style="margin-right: 10px;color:#959511" class="d3una-footer-arrow" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"
              class="rtl:rotate-180 w-3.5 h-3.5 ms-2">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"></path>
            </svg>
            Empresa
          </p>
          <nav class="flex flex-col mt-4 space-y-2 text-sm ">
            <a class="hover:opacity-75" href> Quines somos? </a>
            <a class="hover:opacity-75" href> Nuestra misión </a>
            <a class="hover:opacity-75" href> Visión </a>
            <a class="hover:opacity-75" href> Trabaja con nosotros </a>
          </nav>
        </div>
        <div>
          <p class="font-medium font-semibold d3una-footer-heading">
            <svg style="margin-right: 10px;color:#959511" class="d3una-footer-arrow" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"
              class="rtl:rotate-180 w-3.5 h-3.5 ms-2">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"></path>
            </svg>
            Servicios
          </p>
          <nav class="flex flex-col mt-4 space-y-2 text-sm ">
            <a class="hover:opacity-75" href> Más populares </a>
            <a class="hover:opacity-75" href> Servicios Premiun </a>
            <a class="hover:opacity-75" href> Asistencia profesional </a>
          </nav>
        </div>
        <div>
          <p class="font-medium font-semibold d3una-footer-heading">
            <svg style="margin-right: 10px;color:#959511" class="d3una-footer-arrow" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"
              class="rtl:rotate-180 w-3.5 h-3.5 ms-2">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"></path>
            </svg>
            Ayuda
          </p>
          <nav class="flex flex-col mt-4 space-y-2 text-sm">
            <a class="hover:opacity-75" href> Contacto </a>
            <a class="hover:opacity-75" href> FAQs </a>
            <a class="hover:opacity-75" href> Chat de Soporte </a>
          </nav>
        </div>
        <div>
          <p class="font-medium font-semibold d3una-footer-heading">
            <svg style="margin-right: 10px;color:#959511" class="d3una-footer-arrow" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"
              class="rtl:rotate-180 w-3.5 h-3.5 ms-2">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"></path>
            </svg>
            Legal
          </p>
          <nav class="flex flex-col mt-4 space-y-2 text-sm 0">
            <a class="hover:opacity-75" href> Politica de privacidad </a>
            <a class="hover:opacity-75" href> Términos &amp; Condiciones </a>
            <a class="hover:opacity-75" href> Tratamiento de datos </a>
            <a class="hover:opacity-75" href> PQRS </a>
          </nav>
        </div>
       
      </div>
    </div>
    <p style="display: flex;
    justify-content: flex-end;
    padding-right: 130px;
    padding-left: 40px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="48" viewBox="0 0 146 48" fill="none">
        <path fill="darkgray" d="M37.4401 4.37726C38.7232 4.10169 40.0223 3.9639 41.3375 3.9639C42.6847 3.9639 43.9838 4.10169 45.2348 4.37726L36.9589 34.5523C35.5155 34.7973 34.1041 34.9197 32.7248 34.9197C31.4738 34.9197 30.0463 34.7973 28.4425 34.5523L22.6686 14.2978L16.7985 34.5523C15.355 34.7973 13.9757 34.9197 12.6606 34.9197C11.4095 34.9197 9.98212 34.7973 8.37826 34.5523L0.0542603 4.37726C1.36942 4.10169 2.66854 3.9639 3.95163 3.9639C5.26679 3.9639 6.56591 4.10169 7.84899 4.37726L13.2379 25.6422L18.4344 4.37726C19.7817 4.10169 21.1931 3.9639 22.6686 3.9639C24.1442 3.9639 25.5235 4.10169 26.8065 4.37726L33.0616 25.734L37.4401 4.37726Z" />
        <path fill="darkgray" d="M56.4601 34.9657C53.926 34.9657 51.7127 34.4758 49.8201 33.496C47.9276 32.5161 46.4681 31.123 45.4416 29.3165C44.4151 27.4793 43.9019 25.3666 43.9019 22.9783C43.9019 20.5594 44.4312 18.462 45.4897 16.6861C46.5482 14.8796 48.0238 13.4864 49.9163 12.5066C51.841 11.5268 54.0222 11.0369 56.4601 11.0369C58.9942 11.0369 61.2075 11.5421 63.1 12.5525C64.9926 13.5324 66.4521 14.9255 67.4785 16.732C68.505 18.5386 69.0182 20.6207 69.0182 22.9783C69.0182 25.4278 68.505 27.5559 67.4785 29.3624C66.4521 31.1689 64.9926 32.5621 63.1 33.5419C61.2075 34.4911 58.9942 34.9657 56.4601 34.9657ZM56.4601 28.995C58.1602 28.995 59.4593 28.4897 60.3574 27.4793C61.2877 26.4383 61.7528 24.9379 61.7528 22.9783C61.7528 21.0799 61.2877 19.6102 60.3574 18.5692C59.4272 17.5281 58.1281 17.0076 56.4601 17.0076C54.8241 17.0076 53.525 17.5434 52.5627 18.6151C51.6325 19.6562 51.1673 21.1106 51.1673 22.9783C51.1673 24.9379 51.6325 26.4383 52.5627 27.4793C53.4929 28.4897 54.7921 28.995 56.4601 28.995Z" />
        <path fill="darkgray" d="M98.6594 11.0828C101.354 11.0828 103.583 11.7258 105.347 13.0118C107.112 14.2978 107.994 16.0125 107.994 18.1558V34.5064H100.825V20.59C100.825 19.3959 100.424 18.4926 99.6217 17.8803C98.8518 17.2373 97.7452 16.9158 96.3017 16.9158C95.0186 16.9158 93.7837 17.1454 92.5968 17.6047C92.9817 18.6151 93.1742 19.7174 93.1742 20.9115V34.5064H86.005V20.59C86.005 19.3959 85.604 18.4926 84.8021 17.8803C84.0322 17.2373 82.9256 16.9158 81.4821 16.9158C80.3915 16.9158 79.365 17.0382 78.4027 17.2832V34.5064H71.1372L71.1853 14.0682C72.5967 13.119 74.3289 12.3841 76.3818 11.8636C78.4348 11.3431 80.4396 11.0828 82.3963 11.0828C83.8718 11.0828 85.2511 11.3125 86.5342 11.7718C87.8173 12.2004 88.94 12.8128 89.9023 13.6089C91.1533 12.8434 92.5647 12.231 94.1365 11.7718C95.7083 11.3125 97.2159 11.0828 98.6594 11.0828Z" />
        <path fill="darkgray" d="M121.972 11.0828C126.367 11.0828 129.799 12.1851 132.269 14.3897C134.771 16.5943 136.022 19.7174 136.022 23.7591C136.022 27.0966 135.156 29.8064 133.424 31.8885C131.724 33.9399 129.141 34.9657 125.677 34.9657C124.554 34.9657 123.416 34.7973 122.261 34.4605C121.106 34.093 120.031 33.5878 119.037 32.9448V41.4416C118.267 42.0234 117.112 42.5439 115.573 43.0032C114.065 43.4625 112.798 43.6921 111.772 43.6921V12.874C112.991 12.3229 114.498 11.8942 116.295 11.588C118.123 11.2512 120.015 11.0828 121.972 11.0828ZM122.598 29.1787C124.619 29.1787 126.158 28.6888 127.217 27.709C128.275 26.7292 128.805 25.137 128.805 22.9324C128.805 21.0953 128.259 19.5796 127.169 18.3855C126.11 17.1913 124.522 16.5943 122.405 16.5943C121.347 16.5943 120.224 16.6861 119.037 16.8698V28.8572C120.16 29.0715 121.347 29.1787 122.598 29.1787Z" />
        <path fill="darkgray" d="M142.2 8.00561C141.174 8.00561 140.292 7.6688 139.554 6.99518C138.848 6.29095 138.495 5.44892 138.495 4.46911C138.495 3.4893 138.864 2.66259 139.602 1.98897C140.34 1.28474 141.206 0.932617 142.2 0.932617C143.227 0.932617 144.109 1.28474 144.847 1.98897C145.584 2.66259 145.953 3.4893 145.953 4.46911C145.953 5.4183 145.584 6.24502 144.847 6.94926C144.109 7.6535 143.227 8.00561 142.2 8.00561ZM138.592 11.7258C139.65 11.2972 140.869 11.0828 142.248 11.0828C143.596 11.0828 144.799 11.2972 145.857 11.7258V34.5064H138.592V11.7258Z"/>
        <path fill="darkgray" d="M7.19 46.6465V41.9273C7.19 41.4533 7.53742 41.0466 8.0258 40.9609C10.4394 40.5377 17.6286 39.3721 23.5966 39.3721C29.5793 39.3721 36.9644 40.5434 39.4233 40.964C39.9142 41.048 40.2636 41.4558 40.2636 41.9318V46.671C40.2636 47.356 39.5502 47.861 38.8523 47.7019C36.61 47.1909 31.6451 46.3324 23.5966 46.3324C15.6435 46.3324 10.8268 47.1707 8.62399 47.6836C7.91988 47.8476 7.19 47.3383 7.19 46.6465Z"/>
      </svg>
    </p>
    <p class="mt-8 text-xs text-gray-700 text-center">
      © 2022 D3una
    </p>
  </div>
</footer>