import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-crear_propuesta',
  templateUrl: './crear_propuesta.component.html',
  styleUrls: ['./crear_propuesta.component.css']
})
export class Crear_propuestaComponent implements OnInit {

 

  public captura:any;
  public clase:any="clase";
  public necesidades:any;
  public servici:any;
  public id:any;
  public user:any;
  public conversacion:any;
  public model:any="Service";
  service_me: boolean = false;

  public formSubmitted = false;

  public proposalForm = this.fb.group({
    conversation_id: ['', [Validators.required], Validators.minLength(3)],
    id_property    : ['', [Validators.required]],
    property_model : ['', [Validators.required]],
    value          : [,[Validators.required, Validators.min(50000),]],
    concept        : ['ninguno', [Validators.required, Validators.minLength(3)]],
    receiver       : ['', [Validators.required]],
  });
 
 

  
  constructor(
    private router: Router,
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    private fb: FormBuilder,
  ) { }


  ngOnInit() {
  
    this.necesidade();
    this.id           = this.activerouter.snapshot.paramMap.get('user');
    this.conversacion = this.activerouter.snapshot.paramMap.get('id');

    this.servicios(this.id);
    this.i();
  }

  get value() {return this.proposalForm.get('value');}
  get id_property() {return this.proposalForm.get('id_property');}

  campoNoValido(campo: string): boolean {

    // if (this.proposalForm.get('value').invalid && this.proposalForm) {
    //   return true;
    // } else{
    //   return false;
    // }
    if (this.proposalForm.get(campo).hasError('minLength')) {
      console.log("aqui esta");
      return true;
    } else{
      return false;
    }
  }

  //f(campo:string){
  //  return this.proposalForm.get("value")
  //}

  
  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
      }
    )
  }

  capturarvalor(valor){
    this.captura = null;
     this.captura = valor;
     console.log("capturado", this.captura);
  }

  capturarmodel(valor){
    this.model = null;
     this.model = valor;
     console.log("model", this.model);
  }

  necesidade(){
    
    this.chat.necesidades().subscribe( 
      data => {
        this.necesidades = data.data;
        console.log("necesidades", data);
      } )
  }

  servicios(valor){
    this.chat.servicios(valor).subscribe(
      data => {
        this.servici = data.data;
        console.log("servicios", data);
      }
    )
  }


  onCreateProposal(){
    this.formSubmitted = true;
    console.log(this.proposalForm.value.value);

    if(!this.proposalForm.value.id_property){
      this.Toast.fire({ icon: 'info', title: 'Selecciona un Elemento!' });
    }else if(this.proposalForm.value.value >= 50000){
      this.chat.createProposal(this.proposalForm.value).subscribe(
        data => {
          this.Toast.fire({ icon: 'success', title: 'Propuesta enviada!' });
           this.proposalForm.controls['value'].reset();
           
           this.captura = 'xxx';
          console.log("se envio la propuesta", data);
  
        }
      )
    }else{
      this.Toast.fire({ icon: 'info', title: 'valor invalido!' });
    }
    
    
  }

  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {

      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  
  nece = false;
  serv = true;

  servicio():void{
    console.log("entro");
    this.nece = false;
    this.serv = true;
  }

  necesidad(){
    this.serv = false;
    this.nece = true;
    
  }

  service_my(){
    this.service_me = !this.service_me;
    console.log("el boolean", this.service_me)
    if(this.service_me == true){
      this.servici = null;
      this.servicios(this.user);
    }else{
      this.servici = null;
      this.servicios(this.id);
    }
  }


  





}
