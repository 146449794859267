import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class SocialService {

constructor(
  private http: HttpClient
) { }

//metodo para registrarse
registro(formData: any) {

  const url = `${base_url}/register/social`;
  return this.http.post(url, formData).pipe(
    tap((resp: any) => 
    { 
      localStorage.setItem('token', resp.data.token) 
    })
  )
}

}
