import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {

  public id: string;

  constructor(
    private ar: ActivatedRoute,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
  }

  ngOnInit(){}

}