import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { CreacionesService } from 'src/app/core/services/creaciones.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import { CurrencyPipe } from '@angular/common';
import Swal from 'sweetalert2';

import { MinMaxValidation } from '../../../../../../core/validations/min-max.validation';


@Component({
  selector: 'necesidad-descripcion',
  templateUrl: './descripcion.component.html',
  styleUrls: ['./descripcion.component.css']
})
export class DescripcionComponent implements OnInit {

  @Output() idNecesidad: EventEmitter<string> = new EventEmitter();
  @Input() idUser: string;

  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  public sub: string = "";
  public subcas: boolean = false;

  public categoriaItems: any;
  public subItems: any;
  public categoria: string = "";

  public compCa: boolean = true;
  public compSub: boolean = false;
  public compDes: boolean = false;

  public formSubmitted = false;

  public necesidadForm = this.fb.group({
    title        : ['',  [ Validators.required, Validators.minLength(3) ]],
    description  : ['',  [ Validators.required, Validators.minLength(10) ]],
    min_price    : ['',  [ Validators.required, MinMaxValidation.minVal, MinMaxValidation.maxVal ]],
    max_price    : ['',  [ Validators.required, MinMaxValidation.maxVal ]],
    subcategory  : ['',  [ Validators.required ]],
    municipality : ['',  [ Validators.required ]],
  });


  constructor(
    private fb: FormBuilder,
    private ubicacionService: UbicacionService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private creacionService: CreacionesService,
    private currencyPipe: CurrencyPipe
  ) { }

   //mensajes de validaciones de creación de servicio
  validation_create_necessity = {

    'title': [
      { type: 'required',  message: 'El nombre de la necesidad es requerido' },
      { type: 'minlength', message: 'El nombre debe contener al menos 3 caracteres' },
    ],

    'description': [
      { type: 'required', message: 'La descripción de la necesidad es requerida' },
      { type: 'minlength', message: 'La descripción debe contener al menos 10 caracteres' },
    ],

    'subcategories': [
      { type: 'required', message: 'Selecciona la subcategoria de la necesidad' },
    ],

    'town': [
      { type: 'required', message: 'Selecciona la ciudad se ubica tú necesidad' },
    ],

    'min_price': [
      { type: 'minVal', message: 'El precio mínino es de 50.000 COP' },
      { type: 'maxVal', message: 'El precio máximo es de 50.000.000 COP' },
      { type: 'required', message: 'El precio mínino es requerido' },
    ],

    'max_price': [
      { type: 'maxVal', message: 'El precio máximo es de 50.000.000 COP' },
      { type: 'required', message: 'El precio máximo es requerido' },
    ],

  } 

  ngOnInit() {
    this.listadoDept();
    this.listarCategoria();

    this.necesidadForm.valueChanges.subscribe( form => {

      if(form.min_price){
        this.necesidadForm.patchValue({
          min_price: this.currencyPipe.transform(form.min_price.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0' )
        }, { emitEvent: false });
      }

      if(form.max_price){
        this.necesidadForm.patchValue({
          max_price: this.currencyPipe.transform(form.max_price.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0' )
        }, { emitEvent: false });
      }

    });

  }

  onSelectCategoria(id: string) {
    if (id != "") {
      this.subcas = false;
      this.sub = id;
      this.necesidadForm.setValue({ title: '', description: '', min_price: '',  max_price: '', subcategory: id, municipality: ''})
    }
  }


  crearNecesidad() {
    if (this.sub == "") { return }
    this.formSubmitted = true;
    if (this.necesidadForm.invalid) { return }

    //TODO: reemplazar valores
    let min_p = this.necesidadForm.controls['min_price'].value;
    let max_p = this.necesidadForm.controls['max_price'].value;

    this.necesidadForm.value.min_price = min_p.replace(/\./g, '');
    this.necesidadForm.value.max_price = max_p.replace(/\./g, '');

    this.creacionService.creacionNecesidad(this.necesidadForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == 'success') { this.idNecesidad.emit(resp.id)  }
      }
    )
  }


  campoNoValido(campo: string): boolean {
    if (this.necesidadForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  atras(tipo: string) {
    if (tipo == "categoria") {
      this.compCa = true;
      this.compSub = false;
    } else if (tipo == "subcategoria") {
      this.sub = "";
      this.compSub = true;
      this.compDes = false;
    }
  }

  siguiente(tipo: string) {
    if (tipo == "descripcion") {
      if (this.sub == "") { this.subcas = true; return }
      this.compCa = false;
      this.compSub = false;
      this.compDes = true;
    }
    if(tipo == 'imagen'){
      //marcar formulario como tocado TODO: todos los inputs
      this.necesidadForm.markAllAsTouched();
    }
  }

  // listadoCategoria
  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => { this.categoriaItems = resp.data  }
    )
  }
  
  listadoSubcategoria(id: string) {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => {
        this.compCa = false;
        this.compSub = true;
        this.categoria = resp.data.name;
        this.subItems = resp.data.subcategory;
      }
    )
  }

  // listadoDepartamento
  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data }
    )
  }

  listadoMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities }
    )
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}