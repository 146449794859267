<form [formGroup]="passForm" (ngSubmit)="passUpdate()" autocomplete="off" class="mb-3">
    <div class="flex flex-col xl:flex-row justify-between items-center content-center xl:space-x-3">
        <div class="w-full">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Contraseña actual</label>
                <input formControlName="password" type="password" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                <sh-alerts *ngIf="campoNoValido('password')" [msg]="'La contraseña actual es requerida, y debe tener minimo 8 caracteres'" [alert]="'error'"></sh-alerts>
            </div>
        </div>
        <div class="w-full">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Nueva Contraseña</label>
                <input formControlName="new_password" type="password" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                <sh-alerts *ngIf="campoNoValido('new_password')" [msg]="'La nueva contraseña es requerida, y debe tener minimo 8 caracteres'" [alert]="'error'"></sh-alerts>
            </div>
        </div>
        <div class="w-full">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-2">Confirmar Contraseña</label>
                <input formControlName="confirm_password" type="password" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                <sh-alerts *ngIf="campoNoValido('confirm_password')" [msg]="'La confimacion contraseña es requerida, y debe tener minimo 8 caracteres'" [alert]="'error'"></sh-alerts>
            </div>
        </div>
        <div class="w-full xl:w-48 text-right mb-1 mt-7">
            <button class="btn-2 focus:ring text-white  hover:bg-green-600 active:bg-green-700 focus:bg-green-600 bg-green-600"><i class='bx bxs-save'></i></button>
        </div>
    </div>
</form>