import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RatingService } from 'src/app/core/services/rating.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'rating-start',
  templateUrl: './calificacion-start.component.html',
  styleUrls: ['./calificacion-start.component.css'],
})
  
export class CalificacionStartComponent implements OnInit {
 
  public state: string = "inactive";
  @Input() quality: any;
  @Input() id_ref: string;
  @Input() type_model: string;

  public totalQuality: number = 0;
  public id_user: string;
  public log: boolean;
  public verdadero: boolean = false;

  public rating: number;
  public starWidth: number;

  public valoracion: number = 0;

  constructor(
    private ratingService: RatingService,
    private usuarioService: UsuarioService,
    private router: Router
  ) {
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }
  }

  ngOnInit() {
    this.contador();
    this.verificarStart();
    this.onRating();    
  }

  onRating() {
    let suma = 0;
    if (this.quality == "") {
      suma = 0
      this.rating = 0;
      this.starWidth = 0;
    } else {
      for (let date of this.quality) {
        suma = (date.value + suma);
      }
      this.rating = suma / this.quality.length;
      this.starWidth = (this.rating * 100) / 5; 
    }
  }

  clickRating(value: number) {
    if (this.log == true) { 
      if (this.verdadero == true) { return }
      this.ratingService.ratingStart(value, this.type_model, this.id_ref, 'Atencion' ).subscribe(
        resp => {
          this.Toast.fire({ icon: resp.alert, title: resp.msg })
          this.totalQuality = this.totalQuality + 1;
          this.rating = (value + this.rating) / (this.totalQuality)          
          this.starWidth = (this.rating * 100) / 5;
          this.verdadero = true;
          this.valoracion = value;
          this.verificarStart();
        }
      ) 
    } else {  this.onModal() }
  }

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para calificar necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  verificarStart() {
    if (this.log == true) {
      if (this.id_user) {
        if (this.totalQuality > 0) {          
          for (let date of this.quality) {            
            if (this.id_user == date.user) {
              this.verdadero = true;    
              this.valoracion = date.value;
            }
          }
        }
      }
    }
  }

  contador() {
    let suma = 0;
    for ( let date of this.quality) { suma = suma + 1 }
    this.totalQuality = suma;
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  // calificacion por estrella de perfil = Profile
  // calificacion por estrella de servicios = Service
  // calificacion por estrella de recomendaciones = RecommendationResponse
  // calificacion por estrella de Respuestas de reseñas = Review

}


