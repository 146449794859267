import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modulos
import { SharedPipesModule } from 'src/app/shared.pipes/shared.pipes.module';


// Componentes
import { ModulosComponent } from './modulos.component';
import { HomeModule } from './home/home.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from 'src/app/shared.component/shared.module';


import { PerfilModule } from './perfil/perfil.module';
import { CreacionServiciosModule } from './creacion-servicios/creacion-servicios.module';
import { CreadorPerfilModule } from './creador-perfil/creador-perfil.module';
import { CreacionNecesidadModule } from './creacion-necesidad/creacion-necesidad.module';
import { CategoriasModule } from './categorias/categorias.module';

import { ChatModule } from './chat/chat.module';
import { WebchatModule } from './webchat/webchat.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ServiciosModule } from './servicios/servicios.module';
import { PrestadoresModule } from './prestadores/prestadores.module';
import { RecomendacionesModule } from './recomendaciones/recomendaciones.module';
import { NecesidadesModule } from './necesidades/necesidades.module';
/////
import {MatNativeDateModule} from '@angular/material/core';

import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
@NgModule({  
  declarations: [
    ModulosComponent,
  ],
  exports: [
    
  ],
  imports: [
    SharedPipesModule.forRoot(),
    SharedModule,
    SlickCarouselModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    HomeModule,
    PerfilModule,
    CreadorPerfilModule,
    CreacionServiciosModule,
    CreacionNecesidadModule,
    CategoriasModule,
    ChatModule,
    WebchatModule,
    NotificationsModule,
    ServiciosModule,
    PrestadoresModule,
    RecomendacionesModule,
    NecesidadesModule,
    MatNativeDateModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ]
})


export class ModulosModule { }