import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
// import axios, { AxiosResponse } from 'axios'
import { environment } from 'src/environments/environment';
const base_url = environment.base_url


@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(
    private http: HttpClient
  ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: 
      {'auth-token': this.token }
    }
  }
  
  perfilSeleccionado(id:string) {
    const url = `${base_url}/usuario/public/${id}`;
    return this.http.get(url).pipe( tap((resp: any) => { }) )
  }

  verificacionPerfil(id: string) {
    const url = `${base_url}/verificar/perfil/${id}`;
    return this.http.get(url).pipe( tap((resp:any) => {}) )
  }


  // /api/study
  agregarStudies(
    data: {
      estudio: string,
      academia: string
    }
  ) {
    const url = `${base_url}/study`;
    return this.http.post(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  deleteStudy(FormData:any, id:string){

    const url = `${base_url}/delete/study/${id}`;
    return this.http.put(url, FormData, this.headers).pipe(
      tap( ( resp:any ) => {

      })
    );
  }

  agregarExperiencia(
    data: {
      experiencia : string,
      año: string
    } 
  ) {
    const url = `${base_url}/experience`;
    return this.http.post(url, data,this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // profile/user/progress
  progressPerfil() {
    const url = `${base_url}/profile/user/progress`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  updatePerfil(
    data: {
      accountType:string,
      ocupation:string,
      description:string,
    },
    id: string,
  ) {
    const url = `${base_url}/profile/${id}`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }

  // user/update/61e5bc846e9768c20e4e8be4/ubicacion
  updateUbicacion(
    data: {
      town: string,
    },
    id: string
  ) {
    const url = `${base_url}/user/update/${id}/ubicacion`;
    return this.http.put(url, data, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
}
