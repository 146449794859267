<div class="contenedor mb-2 overflow-y-scroll" [ngClass]="{'altura': activacion, 'antes': !activacion}">
    <div *ngFor="let rr of respuestas; let i=index">
        <div *ngIf="i<num">
            <div class="flex space-x-3 items-center content-center my-2">
                <img *ngIf="!rr.user.imagen" src="assets/imagen/general/perfil-1.svg " alt=" " class="w-9 h-9 rounded-full object-center object-cover ">
                <img *ngIf="rr.user.imagen" [src]="rr.user.imagen.thumbnail " alt=" " class="w-9 h-9 rounded-full object-center object-cover ">
                <div class=" ">
                    <h3 class="font-bold text-dark-400 text-md leading-4 ">
                        {{rr.user.name}}
                    </h3>
                    <h4 class="font-semibold text-dark-300 text-xs ">
                        {{ rr.createdAt | date }}
                    </h4>
                </div>
            </div>
            <p class="text-dark-300 leading-tight text-base">
                {{rr.message}}
            </p>
            <div class="flex pt-2 text-md font-semibold space-x-4 text-sm font-semibold text-dark-300 ">
                <rating-like [likes]="rr.like " [id_ref]="rr._id " [type_model]=" 'RecommendationResponse' "></rating-like>
                <rating-start [quality]="rr.qualification " [id_ref]="rr._id " [type_model]=" 'RecommendationResponse' "></rating-start>
            </div>
        </div>
    </div>
</div>

<div class="flex justify-between ">
    <span *ngIf="!activacion && contador > 1" (click)="masomenos('mas', id)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer ">
      ver mas respuesta
  </span>
    <span *ngIf="activacion" (click)="masomenos('menos', id)" class="font-bold text-dark-300 text-sm hover:border-b cursor-pointer ">
      ver menos respuesta
  </span>
</div>