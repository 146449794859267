import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {
  id: string;
  id_user: string;
  invoice:{
    currentPage:number,
    invoices:any,
    totalDocuments:number,
    totalPages:number
  }

  constructor(
    private router: Router,
    private chat: ChatService,
    private activerouter: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.id      = this.activerouter.snapshot.paramMap.get('id');
    this.id_user = this.activerouter.snapshot.paramMap.get('user');

    this.onInvoice()
  }



  onInvoice()
  {
    this.chat.onInvoince(this.id).subscribe(
      resp => {
        this.invoice = resp.data
      }
    )
  }


  crear_propuesta(){
    this.router.navigate(['chat/propuesta/create/'+this.id+'/'+this.id_user]);
  }



}
