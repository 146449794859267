import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';

import { cI, sI } from 'src/app/core/interfaces/categorias.form.interfaces';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';

@Component({
  selector: 'app-filterService',
  templateUrl: './filterService.component.html',
  styleUrls: ['./filterService.component.css']
})
export class FilterServiceComponent implements OnInit {

  public formSubmitted = false;
  public filtroForm = this.fb.group(
    { word : [''], subcategory : [''], municipality : [''], min_price : [''], max_price : [''] }
  );

  constructor(
    private ubicacionService: UbicacionService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {

    this.listadoDept();
    this.listarCategoria();
  }

  formulario() {
    this.filtroForm.setValue({ word: '', subcategory: '', municipality: '', min_price:'', max_price: '' });
  }

  public selectedCtg: cI = { _id: "", name: "" };
  public ctgItems: cI ;
  public subItems: sI;

  public btnFiltrar: boolean = false;
  public ty: boolean = false;

  public min: number = 50000;
  public max: number = 50000;
  public word: string;

  public services: Array<any> = [];
  public page: number = 1;
  public limit: number = 3;
  public totalDocuments:number = 0;
  public totalPages:number = 0;
  public currentPage: number = 0;

  nuevaBusqueda() {
    this.page = 1;
    this.services = [];
    this.formSubmitted = true;
    if (this.filtroForm.invalid) { return }
    this.word = this.filtroForm.get('word').value;

  }

  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => { this.ctgItems = resp.data }
    )
  }
  
  listadoSubcategoria(id: any): void {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => { this.subItems = resp.data.subcategory }
    )
  }

  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data }
    )
  }

  listadoMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities }
    )
  }

}
