<div class="flex space-x-2 items-center content-center my-2">
    <img *ngIf="!rr.user.imagen" src="assets/imagen/general/perfil-1.svg " alt=" " class="w-7 h-7 rounded-full object-center object-cover ">
    <img *ngIf="rr.user.imagen" [src]="rr.user.imagen.thumbnail " alt=" " class="w-7 h-7 rounded-full object-center object-cover ">
    <div class=" ">
        <h3 class="font-bold text-dark-400 text-md leading-4 ">
            {{rr.user.name}}
        </h3>
        <h4 class="font-semibold text-dark-300 text-xs ">
            {{ rr.createdAt }}
        </h4>
    </div>
    <div *ngIf="rr.user._id == id_user && time == true" class="flex space-x-1">
        <span *ngIf="!activacion && time" (click)="editar()" class="hover:bg-gray-100 rounded-full w-7 h-7 flex cursor-pointer botones transition delay-150 duration-300 ease-in-out">
            <i class="icon-edit p-1.5 flex content-center items-center text-md text-dark-400 icons-2"></i>
        </span>
        <span (click)="deleteResponseReview(rr._id)" class="hover:bg-gray-100 rounded-full w-7 h-7 flex cursor-pointer botones transition delay-150 duration-300 ease-in-out">
          <i class="bx bx-trash p-1.5 flex content-center items-center text-md text-dark-4 icons-2"></i>
        </span>
    </div>
</div>

<div class="ml-8">
    <p *ngIf="!activacion" class="text-dark-300 leading-tight text-base">
        {{rr.message_response}}
    </p>
    <form *ngIf="activacion" [formGroup]="responseForm" (ngSubmit)="upResponseServicio(rr._id)">
        <div class="flex space-x-2 items-center content-center">
            <div class="w-full">
                <textarea (keyup.enter)="upResponseServicio(rr._id)" formControlName="message_response" class="input-3 w-full" rows="1"></textarea>
            </div>
            <div class="w-28 lg:w-24 text-right mb-3">
                <a *ngIf="activacion" (click)="editar()" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                    <i class='bx bx-x'> </i> Cancelar
                </a>
            </div>
        </div>
    </form>


</div>

<div class="ml-8">
    <div class="flex pt-2 pb-4 text-md font-semibold space+-x-2 text-sm font-semibold text-dark-300 ">
        <rating-like [likes]="rr.like " [id_ref]="rr._id " [type_model]=" 'Reviewresponse' "></rating-like>
        <rating-start [quality]="rr.qualification " [id_ref]="rr._id " [type_model]="'Reviewresponse'"></rating-start>
    </div>
</div>