import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url


@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(
    private http: HttpClient
  ) { }
  
  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }

  listadoCategoria() {
    // /api/category
    const url = `${base_url}/category`;
    return this.http.get(url, this.headers).pipe(
      tap((resp: any) => {})
    );  
  }

  // random/category
  randomCategoria() {
    const url = `${base_url}/random/category`;
    return this.http.get(url, this.headers).pipe(
      tap((resp:any) => {})
    )
  }
}
