import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { cI, sI } from 'src/app/core/interfaces/categorias.form.interfaces';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { CreacionesService } from 'src/app/core/services/creaciones.service';
import { SubcategoriaService } from 'src/app/core/services/subcategoria.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'c-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  public usuario: Usuarios;
  public log: boolean;
  public id_user: string;

  public formSubmitted = false;
  public perfilForm = this.fb.group({
    accountType: ['enterprise',  [ Validators.required ]],
    company_name: ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(30) ]],
    description : ['', [ Validators.required, Validators.minLength(50), Validators.maxLength(300) ]],
    town: ['', [ Validators.required ]],
    subcategories: ['', [ Validators.required ]]
  });

  public selectedCat: cI = {_id: "", name: ""};
  public categoriaItems: cI;
  public subItems: sI;
  
  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  constructor(
    private usuarioService: UsuarioService,
    private ubicacionService: UbicacionService,
    private categoriaServices: CategoriaService,
    private subcategoriaService: SubcategoriaService,
    private creacionService: CreacionesService,
    private router: Router, 
    private fb: FormBuilder,
  ) { 
    this.usuario = usuarioService.usuarios;
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }   
  }

  ngOnInit() {
    this.listarCategoria();
    this.listarDept();
  }

  listarCategoria() {
    this.categoriaServices.listadoCategoria().subscribe(
      resp => { this.categoriaItems = resp.data }
    )
  }

  listarSubcategoria(id: string) {
    this.subcategoriaService.listadoSubcategoria(id).subscribe(
      resp => { this.subItems = resp.data.subcategory }
    )
  }

  listarDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data }
    )
  }

  listarMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities }
    )
  }

  crearPerfilEmpresa() {
    this.formSubmitted = true;
    if (this.perfilForm.invalid) { return; }
    this.creacionService.createdPerfil(this.perfilForm.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        this.router.navigate(['perfil', this.id_user]);  //id del perfil
      }
    )
  }

  campoNoValido(campo: string): boolean {
    if (this.perfilForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}