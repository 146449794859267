<div class="container mx-auto mt-3 lg:mt-5 xl:mt-28 mb-24 xl:mb-8">
    <div class="mx-8 xl:mx-0">
        <div class="imagen-bg flex content-center items-center">
            <h1 class="ml-8 text-dark-400 font-semibold text-3xl">{{categoria}} ({{count}})</h1>
        </div>
        <div class="my-5">
            <div class="flex space-x-5">
                <app-categorias (recargar)="refrescar($event)"></app-categorias>
                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-2 place-items-stretch place-content-stretch">
                    <div *ngFor="let s of subItems" class="col-span-1">
                        <div class="cuadros-home bg-white rounded-xl">
                            <span class="items-relative p-4 flex flex-col content-center items-center justify-center ">
                                <span class="{{s.icon}} text-8xl xl:text-10xl"></span>
                            <h3 class="font-bold mt-2 text-center ">
                                {{s.name | slice:0:15}}{{ (s.name.length> 15) ? '...': '' }}
                            </h3>
                            </span>
                            <div class="items p-4 flex flex-col content-center items-center justify-center">
                                <span class="{{s.icon}} text-8xl xl:text-10xl"></span>
                                <h3 class="font-bold mt-2 text-center ">
                                    {{s.name | slice:0:15}}{{ (s.name.length> 15) ? '...': '' }}
                                </h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <app-services></app-services>
        <app-necesity></app-necesity>
    </div>
</div>