import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pasarela',
  templateUrl: './pasarela.component.html',
  styleUrls: ['./pasarela.component.css']
})
export class PasarelaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  targeta():void{
    this.psee = false;
    this.targe = !this.targe;
    this.pay = false;
  }
  paypal():void{
    this.pay = !this.pay;
    this.targe = false;
    this.psee = false;
  }
  pse():void{
    this.targe = false;
    this.pay = false
    this.psee = !this.psee;
  }

  targe = false;
  pay = false;
  psee = false;

}
