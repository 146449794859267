<div class="btn-efecto">

    <div class="flex justify-between ">
        <div class="flex space-x-2 items-center content-center mb-1">
            <a [routerLink]="['/perfil', r.user._id]">
                <img *ngIf="!r.user.imagen" src="assets/imagen/general/perfil-1.svg" alt="" class="w-8 h-8 rounded-full object-center object-cover">
                <img *ngIf="r.user.imagen" [src]="r.user.imagen.thumbnail" alt="" class="w-8 h-8 rounded-full object-center object-cover">
            </a>
            <div class="">
                <a [routerLink]="['/perfil', r.user._id]">
                    <h3 class="font-bold text-dark-400 text-md leading-4">{{r.user.name}}</h3>
                </a>
                <h4 class="font-semibold text-dark-300 text-xs">{{r.createdAt | date}}</h4>
            </div>
        </div>

        <div class="flex space-x-1">
            <div *ngIf="r.user._id == id_user && time == true" class="flex space-x-1">
                <span *ngIf="!activacion" (click)="editar()" class="hover:bg-gray-100 rounded-full w-7 h-7 flex cursor-pointer botones transition delay-150 duration-300 ease-in-out">
                    <i class="icon-edit p-1.5 flex content-center items-center text-md text-dark-400"></i>
                </span>
                <span (click)="deleteResenia(r._id)" class="hover:bg-gray-100 rounded-full w-7 h-7 flex cursor-pointer botones transition delay-150 duration-300 ease-in-out">
                    <i class="bx bx-trash p-1.5 flex content-center items-center text-md text-dark-4"></i>
                </span>
            </div>
            <!-- <total-response [id]="r._id"></total-response> -->
        </div>
    </div>


    <div class="ml-9">
        <p *ngIf="!activacion" class="text-dark-300 leading-tight text-base">
            {{r.message}}
        </p>
        <form *ngIf="activacion" [formGroup]="reseniaForm" (ngSubmit)="upResponse(r._id)">
            <div class="flex space-x-2 items-center content-center">
                <div class="w-full">
                    <textarea (keyup.enter)="upResponse(r._id)" formControlName="message" class="input-3 w-full" rows="1"></textarea>
                </div>
                <div class="w-28 lg:w-24 text-right mb-3">
                    <a *ngIf="activacion" (click)="editar()" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                        <i class='bx bx-x'> </i> Cancelar
                    </a>
                </div>
            </div>
        </form>

    </div>


    <div class="flex flex-col lg:flex-row pt-2 text-md font-semibold space-x-4 ml-9 text-right">
        <rating-like [likes]="r.like" [id_ref]="r._id" [type_model]="'Review'"></rating-like>
        <div class="flex items-center content-center">
            <rating-start [quality]="r.qualification" [id_ref]="r._id" [type_model]="'Review'"></rating-start>
        </div>
    </div>
</div>