import { Component, Input, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/core/services/fileUpload.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ss-imagens',
  templateUrl: './imagens.component.html',
  styleUrls: ['./imagens.component.css']
})
export class ImagensComponent implements OnInit {

  @Input() id_service: string;
  public id_user2: string;
  public id_user1: string;
  public auxUsuario: boolean = false;
  public log: boolean = false;

  public images: any;
  public count: number;

  public imagenSubir: File;
  public imgTemp: any = null;

  public imagenUpdate: File;
  public imgUpdate: any = null;
 
  constructor(
    private fileService: FileUploadService,
    private servicioService: ServiciosService,
    private usuarioService: UsuarioService
  ) {
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user1 = usuarioService.usuarios._ids } 
  }

  ngOnInit() {
    this.listadoImages();
    this.verificacionCuenta();
  }

  listadoImages() {
    this.fileService.obtenerImages(this.id_service, 'Service').subscribe(
      resp => {
        this.images = resp.data.imgs;
        this.count = resp.data.count;        
      }
    )
  }

  delete(id: string) {
    if (this.auxUsuario) {
      Swal.fire({
        title: 'Estas seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.fileService.deleteImagen(this.id_service, id, 'Service').subscribe(
            resp => {
              this.Toast.fire({ icon: resp.alert, title: resp.msg });
              if ( resp.alert == 'success' ) { this.listadoImages() }
            }
          )
        }
      })
    }
  }

  cambiarImagen(file: File) {
    this.imagenSubir = file;
    if (!file) { return this.imgTemp = null }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.subirImagen();
  }

  subirImagen() {   
    if (this.imagenSubir == null) { return } 
    this.fileService.uploadImagen(this.imagenSubir, this.id_service, 'Service')
      .then(resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg });
        if (resp.alert == "success") {
          setTimeout(() => {
            this.imagenSubir = null;
            this.listadoImages();
          }, 1000);
        }         
      }
    )    
  }

  uUpdateImagen(file: File, id:string) {
    this.imagenUpdate = file;
    if (!file) { return this.imgUpdate = null }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.updateImagen(id);
  }

  updateImagen(id: string) {
    if (this.imagenUpdate == null) { return } 
    this.fileService.uploadUpdateImagen(this.imagenUpdate, this.id_service, id, 'Service')
      .then(resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == "success") {
          setTimeout(() => {
            this.imagenUpdate = null;
            this.listadoImages();
          }, 1000);
        }          
      }
    )    
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  slideConfig = { "slidesToShow": 1,"slidesToScroll": 1 };

  verificacionCuenta() {
    if (this.log == false) { return }
    this.servicioService.idUsuario(this.id_service).subscribe(
      resp => {
        this.id_user2 = resp.data.profile.user._id;
        if (this.id_user1 == this.id_user2) {
          this.auxUsuario = true;
        }
      }
    )    
  }

  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) {}
}
