import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  public usuario: Usuarios;
  public cuentaActual: boolean = false;
  public verificacion: boolean = false;
  public log: boolean;
  public id_user: string;

  public resultado: any;

  public id: string;
  public resenias: any;

  public formSubmitted = false;

  // Perfil 
  public id_perfil: string;

  public page: number = 1;
  public limit: number = 1;

  public name: string;
  public studio: any;
  public expe: any;
  
  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private usuarioService: UsuarioService,
    private reseniaServices: ReseniasService,
    private perfilService: PerfilService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }   
  }

  ngOnInit() {
    this.verficacionPerfil();
    this.perfiLogueado();
    this.perfilSeleccionado();
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => {
        this.resultado = resp.data;
        this.id_perfil = resp.data[0]._id;
        this.studio = resp.data[0].study;
        this.expe = resp.data[0].experience;
      }
    )
  }

  // metodo para verificar si la actual cuenta pertenece a la cuenta logueado o no 
  perfiLogueado() {
    if (this.id_user == this.id) { this.cuentaActual = true }
    else { this.cuentaActual = false }
  }

  // metodo para verficar si la cuenta tiene un perfil o no 
  verficacionPerfil() {
    this.perfilService.verificacionPerfil(this.id).subscribe(
      resp => { this.verificacion = resp.data }
    )
  }

}