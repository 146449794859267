//componentes
import { DescripcionComponent } from "./components/descripcion/descripcion.component";
import { FinalizarComponent } from "./components/Finalizar/Finalizar.component";
import { LogoComponent } from "./components/logo/logo.component";

// paginas 
import { ServiciosComponent } from "./servicios/servicios.component";

export const components : any [] =[
    ServiciosComponent,
    DescripcionComponent,
    LogoComponent,
    FinalizarComponent,

]

export * from "./components/descripcion/descripcion.component";
export * from "./components/Finalizar/Finalizar.component";
export * from "./components/logo/logo.component";
export * from "./servicios/servicios.component";
