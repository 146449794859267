import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Img } from '../interfaces/images.interface';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor( 
    private http: HttpClient
  ) {}

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return  { 'auth-token': this.token }
  }

  get headers2() {
    return {
      headers: { 'auth-token': this.token }
    }
  }

  // showImages url -> /show/:type/images/:id_ref => Service, Necessity, Profile
  obtenerImages(id: string, type: string) {
    const url = `${base_url}/show/${type}/images/${id}`;
    return this.http.get(url).pipe(tap( (resp: any) => { }) )
  }

  // /remove/:type/:id_ref/image/:id_image
  deleteImagen(id_ref: string, id_image: string, type: 'Service' | 'Necessity' | 'Profile') {
    const url = `${base_url}/remove/${type}/${id_ref}/image/${id_image}`;
    return this.http.delete(url, this.headers2).pipe( tap((resp: any) => { }) )
  }
  
  //uploadImages url -> /upload/:type/image/:id_ref => Service, Necessity, Profile
  async uploadImagen( archivo: File, id_ref: string, tipo: 'Service' | 'Necessity' | 'Profile' ){
    try {
      const url = `${base_url}/upload/${tipo}/image/${id_ref}`;
      const formData = new FormData();
      formData.append('images', archivo);
      const resp = await fetch(url, {
          method: 'POST',
          headers: this.headers,
          body: formData
      });

      const data = await resp.json();
      return data;
      
    } catch (error) {
      return false;
    }
  }

  // uploadImages url -> /update/:type/:id_ref/image/:id_image => Service, Necessity, Profile
  async uploadUpdateImagen( archivo: File, id_ref: string, id_image: string, tipo: 'Service' | 'Necessity' | 'Profile' ) {
    try {
      const url = `${base_url}/update/${tipo}/${id_ref}/image/${id_image}`;
      const formData = new FormData();
      formData.append('imagen', archivo);
    
      const resp = await fetch(url, {
          method: 'POST',
          headers: this.headers,
          body: formData
      });

      const data = await resp.json();
      return data;
      
    } catch (error) {
      return false;
    }
  }

  // /profile/update/image
  async uploadUpdateImagenProfile( archivo: File ) {
    try {
      const url = `${base_url}/update/user/image`;
      const formData = new FormData();
      formData.append('imagen', archivo);
      const resp = await fetch(url, { method: 'POST', headers: this.headers, body: formData });
      const data = await resp.json();
      return data;
      
    } catch (error) {
      return false;
    }
  }

  //TODO: subir imagenes para perfiles , necesidades o servicios.
  uploadImages( archivos: Img[], id_ref: string, tipo: 'Service' | 'Necessity' | 'Profile' ){

      //TODO: construir la url para la petición
      const url = `${base_url}/upload/${tipo}/image/${id_ref}`;
      
      //TODO: crear el formData que contendrá las imagenes a subir
      const formData = new FormData();
      for (let i = 0; i < archivos.length; i++) {
        formData.append('images[]', archivos[i].file);
      }

      //TODO: Hacer petición al servidor
      return this.http.post(url, formData, this.headers2).pipe(
        tap((resp:any) => resp )
      );

  }

}