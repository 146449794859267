import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from './page';
import { SharedModule } from 'src/app/shared.component/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedPipesModule } from 'src/app/shared.pipes/shared.pipes.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SlickCarouselModule,
    SharedPipesModule.forRoot(),
    InfiniteScrollModule
  ],
  declarations: [
    ...fromComponents.components,
  ]
})
export class PrestadoresModule { }
