import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  public id: string;

  public usuario: Usuarios;
  public cuentaActual: boolean = false;
  public verificacion: boolean = false;
  public mailConfirmation: boolean = false;
  public log: boolean;
  public id_user: string;

  public resultado: any;

  constructor(
    private ar: ActivatedRoute,
    private usuarioService: UsuarioService,
    private perfilService: PerfilService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }   
  }

  ngOnInit() {
    this.verficacionPerfil();     
    this.perfiLogueado();
    this.perfilSeleccionado();
  }

  perfilSeleccionado() {
    this.perfilService.perfilSeleccionado(this.id).subscribe(
      resp => { this.resultado = resp.data }
    )
  }

  // metodo para verificar si la actual cuenta pertenece a la cuenta logueado o no 
  perfiLogueado() {
    if (this.id_user == this.id) { this.cuentaActual = true; }
    else { this.cuentaActual = false; }
  }

  // metodo para verficar si la cuenta tiene un perfil o no 
  verficacionPerfil() {
    this.perfilService.verificacionPerfil(this.id).subscribe(
      resp => { this.verificacion = resp.data }
    )
  }

}