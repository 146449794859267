import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaService } from 'src/app/core/services/categoria.service';

@Component({
  selector: 'categorias-random',
  templateUrl: './categorias.random.component.html',
  styleUrls: ['./categorias.random.component.css']
})
export class CategoriasRandomComponent implements OnInit {

  public categoriaItems: any;
  subcategoria: any;
  constructor(
    private categoriaService: CategoriaService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.listarCategoria();
  }

  listarCategoria() {
    this.categoriaService.randomCategoria().subscribe(
      resp => {        
        this.categoriaItems = resp.data;
        this.subcategoria = resp.data[0].subcategory;
        console.log("categorias", resp.data[0].subcategory)
      }
    )
  }


  listadoSubcategoria(id: string) {
    this.router.navigate(['/categoria', id, 'subcategoria' ]);
  }

  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 8,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1400,
        "settings": {
          "slidesToShow": 8,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 1280,
        "settings": {
          "slidesToShow": 8,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 770,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 567,
        "settings": {
          "slidesToShow": 3.1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 425,
        "settings": {
          "slidesToShow": 3.1,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 4.1,
          "slidesToScroll": 1,
        }
      }
    ]
  };
}
