<div class="w-full">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let s of service" class="slide">
                <div class="grid grid-cols-1 sm:mx-1">
                    <div class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-0 mb-5">
                        <div class="p-3">
                            <div class="flex flex-col space-y-3">
                                <div class="flex space-x-4 pb-3">
                                    <a [routerLink]="['/servicios', s._id]" class="w-24 md:w-44 h-36">
                                        <img *ngIf="s.images != ''" [src]="s.images[0].thumbnail" alt="" class="object-center object-cover w-full h-36 rounded-2xl border flex-1">
                                        <img *ngIf="s.images == ''" src="assets/imagen/general/no-imagen.png" title="no hay imagen" class="object-center object-cover w-full h-36 rounded-2xl border flex-1">
                                    </a>
                                    <div class="block">
                                        <h3 class="font-bold text-dark-400 text-md md:text-xl" [title]="s.name">
                                            <a [routerLink]="['/servicios', s._id]">
                                                {{s.name | slice:0:16}}{{ (s.name.length> 16) ? '...': '' }}
                                            </a>
                                        </h3>
                                        <small *ngIf="s.subcategory.name" class="text-dark-300 font-medium mb-4"> {{s.subcategory.name}}</small> <br>
                                        <small *ngIf="s.town.name" class="text-aply-100 font-medium mb-4"><i class="icofont-location-pin"></i> {{s.town.name}}</small>

                                        <rating-start [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>

                                    </div>
                                </div>
                                <div>
                                    <div class="py-1 relative min-w-full">
                                        <div class="h-2 bg-gray-200 rounded-full">
                                            <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                            <div class="absolute text-gray-800 text-md  ml-1 bottom-0 left-0 -mb-7">$ {{s.min_price}}</div>
                                            <div class="absolute text-gray-800 text-md  mr-1 bottom-0 right-0 -mb-7">$ {{s.max_price}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pt-5 text-sm font-semibold text-dark-300">
                                    
                                    <div class="flex items-center content-center">
                                        <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-3 bg-dark-100 rounded-b-2xl text-dark-300">
                            <div class="flex justify-between items-center">
                                <div class="flex items-center">
                                    <a [routerLink]="['/perfil', s.profile.user._id]" class="cursor-pointer">
                                        <img *ngIf="!s.profile.user.imagen" src="assets/imagen/general/no-imagen.png" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                                        <img *ngIf="s.profile.user.imagen" [src]="s.profile.user.imagen.thumbnail" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                                    </a>
                                    <div class="ml-2">
                                        <a [routerLink]="['/perfil', s.profile.user._id]" class="cursor-pointer">
                                            <h4 class="font-bold capitalize ">{{s.profile.user.name}}</h4>
                                        </a>
                                        <h6 class="font-semibold capitalize ">{{s.profile.type}}</h6>
                                    </div>

                                </div>
                                <div class="font-normal uppercase text-right">
                                    {{s.updatedAt | date:"mediumDate"}}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
    </ngx-slick-carousel>
</div>

<!-- <div class="container mx-auto ">
    <div class="mx-3 xl:mx-0">
        <h1 *ngIf="cont > 0" class="text-dark-400 font-bold text-xl  mb-2">Ultimos servicios</h1>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let s of service" class="slide">
                <div class="grid grid-cols-1 sm:mx-1">
                    <div class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-0 mb-5">
                        <div class="p-3">
                            <div class="flex flex-col space-y-3">
                                <div class="flex space-x-4 pb-3">
                                    <a [routerLink]="['/servicios', s._id]" class="w-24 md:w-44 h-36">
                                        <img *ngIf="s.images != ''" [src]="s.images[0].thumbnail" alt="" class="object-center object-cover w-full h-36 rounded-2xl border flex-1">
                                        <img *ngIf="s.images == ''" src="assets/imagen/general/no-imagen.png" title="no hay imagen" class="object-center object-cover w-full h-36 rounded-2xl border flex-1">
                                    </a>
                                    <div class="flex-1">
                                        <h3 class="font-bold text-dark-400 text-md md:text-xl" [title]="s.name">
                                            <a [routerLink]="['/servicios', s._id]">
                                                {{s.name | slice:0:16}}{{ (s.name.length> 16) ? '...': '' }}
                                            </a>
                                        </h3>
                                        <small class="text-dark-300 font-medium mb-4"> {{s.subcategory.name}}</small> <br>
                                        <small class="text-aply-100 font-medium mb-4"><i class="icofont-location-pin"></i> {{s.town.name}}</small>

                                        <rating-start [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>

                                    </div>
                                </div>
                                <div>
                                    <div class="py-1 relative min-w-full">
                                        <div class="h-2 bg-gray-200 rounded-full">
                                            <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                            <div class="absolute text-gray-800 text-md  ml-1 bottom-0 left-0 -mb-7">$ {{s.min_price}}</div>
                                            <div class="absolute text-gray-800 text-md  mr-1 bottom-0 right-0 -mb-7">$ {{s.max_price}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pt-5 text-sm font-semibold text-dark-300">
                                    <rating-like [likes]="s.like" [id_ref]="s._id" [type_model]="'Service'"></rating-like>
                                    <div class="flex items-center content-center">
                                        <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-3 bg-dark-100 rounded-b-2xl text-dark-300">
                            <div class="flex justify-between items-center">
                                <div class="flex items-center">
                                    <a [routerLink]="['/perfil', s.profile.user._id]" class="cursor-pointer">
                                        <img *ngIf="!s.profile.user.imagen" [src]="s.profile.user.imagen.thumbnail" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                                        <img *ngIf="s.profile.user.imagen" [src]="s.profile.user.imagen.thumbnail" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                                    </a>
                                    <div class="ml-2">
                                        <a [routerLink]="['/perfil', s.profile.user._id]" class="cursor-pointer">
                                            <h4 class="font-bold capitalize ">{{s.profile.user.name}}</h4>
                                        </a>
                                        <h6 class="font-semibold capitalize ">{{s.profile.type}}</h6>
                                    </div>

                                </div>
                                <div class="font-normal uppercase text-right">
                                    {{s.updatedAt | date:"mediumDate"}}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div> -->