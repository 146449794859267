<div class="h-20 w-full">

</div>
<form [formGroup]="perfilForm" (ngSubmit)="crearPerfilIndividual()" autocomplete="off">
    <div class="grid grid-cols-12 gap-5">

        <div class="lg:col-span-12 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Ocupación</label>
                <input formControlName="ocupation" type="text" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">

                <div *ngIf="perfilForm.get('ocupation').invalid && (perfilForm.get('ocupation').dirty || perfilForm.get('ocupation').touched)">
                    <div *ngFor="let validation of validation_perfil.ocupation">
                        <sh-alerts  *ngIf="perfilForm.get('ocupation').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                    </div>
                </div>

            </div>
        </div>

        <div class="lg:col-span-12 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Descripción</label>
                <textarea formControlName="description" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" rows="5"></textarea>
                <div *ngIf="perfilForm.get('description').invalid && (perfilForm.get('description').dirty || perfilForm.get('description').touched)">
                    <div *ngFor="let validation of validation_perfil.description">
                        <sh-alerts  *ngIf="perfilForm.get('description').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                    </div>
                </div>
            </div>
        </div>

        <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Categoría</label>
                <select class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" [(ngModel)]="selectedCat._id" (change)="listarSubcategoria($event.target.value)" [ngModelOptions]="{standalone: true}">
                    <option value="" > ----- Seleccione la categoría ----- </option>
                    <option *ngFor="let c of categoriaItems" value="{{c._id}}"> {{ c.name }}</option>
                </select>
            </div>
        </div>

        <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Sub-categoría</label>
                <select formControlName="subcategories" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                    <option value="" > ----- Seleccione la sub categoria ----- </option>
                    <option  *ngFor="let s of subItems" [value]="s._id"> {{ s.name }} </option>
                </select>
                <div *ngIf="perfilForm.get('subcategories').invalid && (perfilForm.get('subcategories').dirty || perfilForm.get('subcategories').touched)">
                    <div *ngFor="let validation of validation_perfil.subcategories">
                        <sh-alerts  *ngIf="perfilForm.get('subcategories').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                    </div>
                </div>

            </div>
        </div>
       
        <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Departamento</label>
                <select class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent" name="selectedDept" [(ngModel)]="selectedDept._id" (change)="listadoMunicipio($event.target.value)" [ngModelOptions]="{standalone: true}">
                    <option value="" > ----- Seleccione el departamento ----- </option>
                    <option *ngFor="let d of deptItems" value="{{d._id}}"> {{ d.name }}</option>
                </select>
            </div>
        </div>

        <div class="lg:col-span-6 col-span-12">
            <div class="flex flex-col">
                <label class="font-bold text-base text-dark-300 mb-4">Ciudad</label>
                <select formControlName="town" class="input-3 focus:ring-2 focus:ring-aply-100 focus:border-transparent">
                    <option value=""> ----- Seleccione el Ciudad ----- </option>
                    <option *ngFor="let m of muniItems" [value]="m._id"> {{ m.name }}</option>
                </select>

                <div *ngIf="perfilForm.get('town').invalid && (perfilForm.get('town').dirty || perfilForm.get('town').touched)">
                    <div *ngFor="let validation of validation_perfil.town">
                        <sh-alerts  *ngIf="perfilForm.get('town').hasError(validation.type)" [msg]="validation.message" [alert]="'error'"></sh-alerts>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="flex items-center justify-center mt-10 mb-20 space-x-3">
        <button type="reset" class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300">Cancelar</button>
        <input type="submit" class="btn-1 focus:ring text-white  hover:bg-aply-600 active:bg-blue-700 focus:bg-aply-100 bg-aply-600" value="Finalizar">
    </div>
</form>