import { Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { io } from "socket.io-client";
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';
import { environment } from 'src/environments/environment';
//import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { animate, style, transition, trigger } from '@angular/animations';

const socket = environment.socket;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('menuInOut', [
        transition('void => *', [
            style({
              transform: 'translate3d(0, -100%, 0)'
            }),
            animate('0.5s ease')
        ]),
        transition('* => void', [
            animate('0.5s ease', style({transform: 'translate3d(0, -100%, 0)'}))
        ])
    ]),
    trigger('footercitoInOut', [
      transition('void => *', [
          style({
            transform: 'translate3d(0, 120%, 0)'
          }),
          animate('0.5s ease')
      ]),
      transition('* => void', [
          animate('0.5s ease', style({transform: 'translate3d(0, 120%, 0)'}))
      ])
  ])
]
})
export class HeaderComponent implements OnInit  {
  

  socket = io(socket);

  view:boolean= true;
  scroll:number = 0;
  lastscroll:number = 0;

  public log: boolean;
  public id_user: string;

  notificacion:boolean = false;
  menu_perfil:boolean = false;
  crear_perfil:boolean = false;
  buscador_perfil:boolean = false;
  conversacion: any;
  sms: boolean = false;
  user: any;
  message: boolean = false;

  public profileJson: string = "";
  user_: SocialUser;
  loggedIn: boolean;
  n_notificacion: any;
  n_msm: number = 0;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private chat: ChatService,
    private noti: NotificacionesService,
    //public auth: AuthService,
    private authService: SocialAuthService,
    private readonly _authService: SocialAuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {  

    //console.log('user',this.auth.user$)
    

    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;
        if (this.log) {
          this.id_user = this.usuarioService.usuarios._id;
          this.socket.emit('new:user', this.id_user);
        }
      }
    );

  }
  
  ngOnInit(): void {
    

    if (this.log) { this.conversaciones() }
    
    if(this.router.url.indexOf('/chat')!== -1){
      this.sms = false;
    }else{
      this.sms = true;
    }

    this.chat.user().subscribe(
      resp => { this.user = resp.data._id }
    )

    this.socket.on('news', (data) => { });

    this.socket.on('new:notification', (data) => {

      //enviando datos de notificacion a los componentes
      this.noti.push_notificacion.emit(
        {data : data},
      )
      this.Notificacion_sound();

        console.log('llegando notificacion', data.data);
    });

    this.socket.on('new:message', (data) => {

      this.message = true;
      console.log("alerta de mensaje", this.n_msm);
      //enviando datos de mensaje a los componentes de mensaje
      this.chat.push_mensaje.emit({
        data: data.data
      });

      this.n_msm = this.n_msm + 1

      this.Mensaje_sound();
      

    });

    this.noti.cerrar_menu.subscribe(
      data => {
        this.menu_perfil = false;
        this.notificacion = false;
      }
    )

    this.authService.authState.subscribe((user) => {
      this.user_ = user;
      this.loggedIn = (user != null);
      console.log("usuario google", this.user_)
    });

    this.onlineaUser()
    this.onNotificaciones_no_read()

  } 

  onlineaUser(){

    this.socket.on( "getUsers", ( data ) => {
      this.chat.onLineUsers.emit( {
        data: data
      } )
      console.log( "usuariosss conectados ", data )
    } )
  }


  //notificaciones sin leer
  onNotificaciones_no_read(){
    this.noti.notificaciones().subscribe( 
      data => {
        this.n_notificacion = data.success.length;
    });
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.doc.documentElement.scrollTop || this.doc.body.scrollTop || 0;
    this.scroll = offset
    this.onScroll()
  }

  onScroll(){
    if(this.scroll > this.lastscroll){
      this.view = false
    }else if(this.scroll < this.lastscroll){
      this.view = true
    }

    this.lastscroll = this.scroll
  }


  Notificacion_sound() {
    const audio = new Audio('assets/audio/notificacion.mp3');
    audio.play();
  }

  Mensaje_sound() {
    const audio = new Audio('assets/audio/mensaje.mp3');
    audio.play();
  }

  


  

  LogOfPerfil() {

    if(this.log){

      this.router.navigate( ['/perfil', this.id_user] );

     }else{ 
      
      this.router.navigateByUrl('/login') 
    }
  }

  onNotificacion() {
    if (this.log) { this.notificacion = !this.notificacion }
    else { this.router.navigateByUrl('/login');  }
  }

  onMenuPefil() {
    if (this.log) { this.menu_perfil = !this.menu_perfil }
  }

  onCreacion() {
    if (this.log) { this.crear_perfil = !this.crear_perfil }
    else { this.router.navigateByUrl('/login') }
  }

  onBuscador() { this.buscador_perfil = !this.buscador_perfil }


  salirTodo(f: boolean) {
    this.log = false;
    this.menu_perfil = false;
    this.LogOfPerfil();
  }

  btn_sms(){ }

  conversaciones() {
    this.chat.conversaciones().subscribe(
      resp => { this.conversacion = resp.conversations }
    )
  }

  notification_coming(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.user._id
        console.log("usuario logueado", this.user);
        this.socket.emit('new:user', this.user );
      }
    )
    this.socket.emit('new:user', this.user );

    this.socket.on('news', (data) => {
      console.log(data)
    });

    this.socket.on('new:notification', (data) => {

      //enviando datos de notificacion a los componentes
      this.noti.push_notificacion.emit(
        {data : data},
      )
        console.log('llegando notificacion', data.data);

    });

  }

 

  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

}