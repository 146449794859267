import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'info-perfil',
  templateUrl: './informacion-perfil.component.html'
})
export class InformacionPerfilComponent implements OnInit {

  public id: string; 
  public verificacion: boolean = false;
  public cuentaActual: boolean = false;
  public usuario: Usuarios;

  public log: boolean;
  public id_user: string;


  constructor(
    private ar: ActivatedRoute,
    private perfilService: PerfilService,
    private usuarioService: UsuarioService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');

    if (usuarioService.getLog) {
      this.log = usuarioService.getLog();
    }

    if (this.log == true) {
      this.id_user = usuarioService.usuarios._ids;
    }   
  }

  ngOnInit() {
    this.verficacionPerfil();
    this.perfiLogueado();
  }

  verficacionPerfil() {
    this.perfilService.verificacionPerfil(this.id).subscribe(
      resp => { this.verificacion = resp.data }
    )
  }


  perfiLogueado() {
    if (this.id_user == this.id) {
      this.cuentaActual = true;
    } else {
      this.cuentaActual = false;
    }
  }


}
