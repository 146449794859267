import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { ModalService } from 'src/app/core/services/modal.service';
import { RecomendacionesService } from 'src/app/core/services/recomendaciones.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recomendaciones',
  templateUrl: './recomendaciones.component.html',
  styleUrls: ['./recomendaciones.component.css']
})
export class RecomendacionesComponent implements OnInit {

  public id: string;

  public recomendar: any = [];
  public totalDocuments: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 0;

  public page: number = 1;
  public limit: number = 5;

  public cuentaActual: boolean = false;
  public usuario: Usuarios;
  public log: boolean;
  public id_user: string;

  public formSubmitted = false;
  public tipForms = this.fb.group({ message : ['',  [ Validators.required, Validators.minLength(10) ]] });

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private recomendaService: RecomendacionesService,
    private modalService: ModalService,
    private usuarioService: UsuarioService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.usuario = usuarioService.usuarios;
    if (usuarioService.getLog) { this.log = usuarioService.getLog(); }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids; } 
  }

  ngOnInit() {
    this.listadoRecomendar(this.id);
    this.perfiLogueado();
  }

  listadoRecomendar(id:string) {
    this.recomendaService.recomendacionesDelUsuarioSeleccionado(id, this.page, this.limit).subscribe(
      resp => {
        this.recomendar = resp.data.recommendations;
        this.totalDocuments = resp.data.totalDocuments;
        this.totalPages = resp.data.totalPages;
        this.currentPage = resp.data.currentPage;
        this.paginars();     
      }
    )
  }
  
  resetearListado(valor: boolean) {
    if (valor == true) { this.listadoRecomendar(this.id); }
  }

  onTip() {
    this.formSubmitted = true;
    if (this.tipForms.invalid) { return; }
    this.recomendaService.crearRecomendacion(this.tipForms.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == 'success') {
          this.listadoRecomendar(this.id);
          this.closeModal('crear-tip')
        }
      }
    )
  }


  public paginaMas: number = 0;
  public paginaMasMas: number = 0;
  public paginaMenos: number = 0;
  public paginaMM: number = 0;

  cambiarPagina(valor: number) {
    this.page += valor;
    if (this.page < 1) { this.page = 1; }
    else if (this.page > this.totalPages) { this.page -= valor; }
    this.listadoRecomendar(this.id);
  }

  openModal(id: string) { this.modalService.open(id) }

  closeModal(id: string) { this.modalService.close(id) }


  // metodo para verificar si la actual cuenta pertenece a la cuenta logueado o no 
  perfiLogueado() {
    if (this.id_user == this.id) { this.cuentaActual = true; }
    else { this.cuentaActual = false; }
  }
  
  cambiarPaginaNumero(valor: number) {
    this.page = valor;
    this.paginars();
    this.listadoRecomendar(this.id);
  }

  paginars() {
    if (this.currentPage < 1) {
      this.paginaMenos = 0;
      this.paginaMM = 0;
    } else if( this.currentPage < 2 ) {
      this.paginaMenos = this.currentPage - 1;
    } else {
      this.paginaMenos = this.currentPage -1;
      this.paginaMM = this.currentPage -2;
    }

    if (this.currentPage == this.totalPages-1) {
      this.paginaMas = (this.totalPages);
      this.paginaMasMas = 0;
    } else if (this.currentPage == this.totalPages) {
      this.paginaMas = 0;
      this.paginaMasMas = 0;
    } else {
      this.paginaMas = this.paginaMenos + 2;
      this.paginaMasMas = this.paginaMenos + 3;
    }
  }

  
  campoNoValido(campo: string): boolean {
    if (this.tipForms.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}
