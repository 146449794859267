<!-- canecera de mensajes -->
<app-cabecera class="sm:hidden"></app-cabecera>
<div class="w-full h-24"></div>

<div class="z-10 pt-28 w-full bg-gray-50 sm:justify-center  items-center pt-6 sm:pt-32 pb-48  grid sm:grid-cols-2 gap-4">
  <div class=" overflow-y-scroll h-auto w-full px-2 py-5 mx-auto">
    <h2 class="mb-4 text-left text-xl text-gray-600 font-bold">¿Como quieres pagar?</h2>
    <div class="w-full">

      <div class=" w-full  pl-8 flex mb-2 pt-2 pb-4" [ngClass]="{'select': targe, 'noselect': !targe}">
          <label class="inline-flex items-center mt-3 ">
          <input (click)="targeta()" class="my-auto transform scale-125" type="radio" name="sfg" /> 
          <i class='bx bx-credit-card-alt text-4xl text-blue-500 ml-2'></i>
          <span class="ml-2 font-semibold text-gray-700">Targeta de credito/debito</span>
        </label>
      </div>
      <div class="pse w-full bg-gray-50 pl-8 flex mb-2 pt-2 pb-4" [ngClass]="{'select': pay, 'noselect': !pay}">
          <label class="inline-flex items-center mt-3 ">
        <input (click)="paypal()" class="my-auto transform scale-125" type="radio" name="sfg" />
        <svg class="h-8 w-8 text-blue-500 ml-2"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M10 13l2.538-.003c2.46 0 4.962-2.497 4.962-4.997 0-3-1.89-5-4.962-5H7c-.5 0-1 .5-1 1L4 18c0 .5.5 1 1 1h2.846L9 14c.089-.564.43-1 1-1zm7.467-5.837C19.204 8.153 20 10 20 12c0 2.467-2.54 4.505-5 4.505h.021-2.629l-.576 3.65a.998.998 0 01-.988.844l-2.712-.002a.5.5 0 01-.494-.578L7.846 19" />
        </svg> 
        <span class="ml-2 font-semibold text-gray-700">Paypal</span>
        </label>
      </div>
      <div class="pse w-full bg-gray-50 pl-8 flex mb-2 pt-2 pb-4" [ngClass]="{'select': psee, 'noselect': !psee}">
        <label class="inline-flex items-center mt-3 ">
      <input (click)="pse()" class="my-auto transform scale-125" type="radio" name="sfg" />
      <i class='bx bxs-bank text-4xl text-blue-500 ml-2'></i>  
      <span class="ml-2 font-semibold text-gray-700">Transferencia PSE</span>
      </label>
    </div>
      </div>
   

      <div *ngIf="targe" class=" w-full sm:px-5 sm:py-5 sm:border mt-4">
        <div class="w-full h-auto ">
          <div class="grid grid-cols-1 gap-1">
            <input  formControlName="value" type="text" placeholder="Nombre de la targeta" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full mb-4" />
          </div>
          <div class="grid grid-cols-1 gap-1">
            <input  formControlName="value" type="text" placeholder="Numero de la targeta" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full mb-4" />
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <input  formControlName="value" type="text" placeholder="MM / AA" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full mb-4" />
            <input  formControlName="value" type="text" placeholder="CVC" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full mb-4" />
            <input  formControlName="value" type="text" placeholder="Codigo postal" class="py-2 px-3 border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full mb-4" />
          </div>
        </div>
        <div class="w-full flex justify-end">
          <div class="flex text-gray-700">
            <i class='bx bxs-lock text-xl'></i>
            <p>Conexion segura</p>
          </div>
        </div>
      </div>

      <div *ngIf="pay" class="w-full sm:px-5 sm:py-5 sm:border mt-4">
        <div class="w-full h-auto ">
          <p>Para completar la transacción, te enviaremos a los servidores seguros de PayPal.</p>
        </div>
        <div class="w-full flex justify-end">
          <div class="flex text-gray-700">
            <i class='bx bxs-lock text-xl'></i>
            <p>Conexion segura</p>
          </div>
        </div>
      </div>

      <div *ngIf="psee" class="w-full sm:px-5 sm:py-5 sm:border mt-4">
        <div class="w-full h-auto ">
          <p>Para completar la transacción, te enviaremos a los servidores seguros de PSE.</p>
        </div>
        <div class="w-full flex justify-end pt-2">
          <div class="flex text-gray-700">
            <i class='bx bxs-lock text-xl'></i>
            <p>Conexion segura</p>
          </div>
        </div>
      </div>
  </div>



  <div class="w-full h-full">
    <div class="w-full p-5 mx-auto">
    
      <div class="block w-full h-auto bg-gray-50 border py-2 ">
        <div class="flex w-full">
          <div class="w-2/5 pr-2 py-2 pl-4"> 
            <img class="object-cover w-full rounded-lg shadow-md border-md" src="assets/imagen/servicios/electrician-working.jpg" alt="">
          </div>
          <div class="w-3/5" >
            <p class="text-xl font-semibold text-gray-700 text-left sm:text-3xl">Instalacion de redes electricas</p>
            
          </div>
        </div>
        <div class="flex w-full h-10 mb-4 ">
          <div class="w-1/2 flex justify-center items-center">
            <p class="text-xl font-semibold text-gray-700">Pagas:</p>
          </div>
          <div class="w-1/2 flex justify-center items-center">
            <i class='bx bx-dollar text-yellow-500 text-2xl font-semibold'></i>
            <p class="text-2xl font-semibold text-gray-700">75.000</p>
          </div>
        </div>
        
        <div class="w-full flex justify-center pb-4">
          <div class="w-11/12 h-16 mx-2 center flex justify-center items-center border">
            <!-- estados -->
            <svg class="h-8 w-8 text-blue-600"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"/>
            </svg>
            <p class="text-xl font-semibold text-gray-700 ml-2">Descuento con puntos</p>
          </div>
        </div>
        
        <div class="w-full flex justify-center ">
          <button class="w-11/12 bg-blue-500 hover:bg-blue-700 text-white text-3x1 font-bold py-2 px-4 rounded">
            Realizar pago
          </button>
        </div>
    
      </div>
  
      
     
      
  
    </div>
    
  </div>


</div>

