import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { tap } from 'rxjs/operators';

// servicios 
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class checkConfirmationMailGuard implements CanActivate {

  constructor(
    private usuarioService: UsuarioService,
    private router: Router
  ) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
      return this.usuarioService.checkConfirmationMail().pipe(
        tap( confirmado => { })
      );
  }
}
