import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  animations: [
    trigger('menuInOut', [
        transition('void => *', [
            style({
              transform: 'translate3d(0, -100%, 0)'
            }),
            animate('0.5s ease')
        ]),
        transition('* => void', [
            animate('0.5s ease', style({transform: 'translate3d(0, -100%, 0)'}))
        ])
    ]),
    trigger('footercitoInOut', [
      transition('void => *', [
          style({
            transform: 'translate3d(0, 120%, 0)'
          }),
          animate('0.5s ease')
      ]),
      transition('* => void', [
          animate('0.5s ease', style({transform: 'translate3d(0, 120%, 0)'}))
      ])
  ])
]
})
export class MessageComponent implements OnInit {

  //io recibe como parámetro la dirección del servidor y el puerto
  //socket = io('http://127.0.0.1:3333');
  //socket = io('http://core.d3una.com:3000');

  public id:any;
  public mensaje:any;
  public user:any;
  public contacto:any;

  public formSubmitted = false;

  public imagenSubir: File;
  public imgTemp: any = null;

  public subir:boolean = false;
  
  public messageForm = this.fb.group({
    conversation_id: ['', [Validators.required]],
    text: ['', [Validators.required] ]
  });

  _scroll:number = 0;
  _lastscroll:number = 0;

  view:boolean= true;
  
  constructor(
    private chat: ChatService,
    private usuario: UsuarioService,
    private router: Router,
    private fb: FormBuilder,
    private activerouter: ActivatedRoute,
    private socket: SocketService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  

  ngOnInit() {
    this.id = this.activerouter.snapshot.paramMap.get('id');
    this.mensajes(this.id);
    this.chat.id_conversacion2.subscribe(
      data => {
        this.id = this.activerouter.snapshot.paramMap.get('id');
        this.contacto = data.data.members;
        this.mensajes(this.id);
        console.log("llego id de contactos", data.data.members);
      }
    )

    this.i();

    this.chat.push_mensaje.subscribe(
      data => {
        console.log("mensaje emitido", data);
        
        if(data.data.conversationId == this.id){

          if(data.data.images != ''){
            setTimeout(() => {
              this.mensaje.push(data.data);
              this.scroll();
              this.Mensaje_sound();
            }, 8500);
          }else{
            this.mensaje.push(data.data);
            this.scroll();
            this.Mensaje_sound();
          }
        }
      }
    )


  }


  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
        console.log("usuario logueado", this.user);
      }
    )

  }

  mensajes(valor){
    
    this.chat.mensajes(valor).subscribe( 
      data => {
        this.mensaje = data.conversation;
        console.log("mensajes", data);
      } );
      this.scroll();
  }

  onSend(){

    this.chat.send(this.messageForm.value).subscribe(
      data => {
        this.mensaje.push(data.message);
        console.log("mensajes ",this.mensaje);
        this.messageForm.controls['text'].reset();
        console.log("return de sms", data);

      }
    )

    this.scroll();
  }

  scroll(){
    setTimeout(() => {
      //get container element
    var container = document.getElementById('sms');
    //scroll down
    container.scrollTop = container.scrollHeight;
     }, 900);
  }

  

  cambiarImagen(file: File) {
    this.subir = true;
    console.log("subiendo foto");
    this.imagenSubir = file;

    if (!file) { return this.imgTemp = null }
    
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.imgTemp = reader.result;
      
    }
  }

  subirImagen() {

    this.formSubmitted = true;
    
    if (this.imagenSubir == null) {
      
      return;
    }
    let id: any = this.activerouter.snapshot.paramMap.get('id');
    this.chat.uploadImagen(this.imagenSubir, id, this.messageForm.value.text )
      .then(resp => {
          console.log('fotoo', resp);

          if (resp.message) {
            setTimeout(() => {
              this.mensaje.push(resp.message);
              this.scroll();
             }, 8500);
            console.log("mensajes ",this.mensaje);
            this.imgTemp = null;
            this.imagenSubir = null;
            this.subir = false;
            this.Toast.fire({ icon: 'success', title: '' + 'imagen subio' }) 
            this.messageForm.controls['text'].reset();
          } else {
            //this.imgTemp = null;
            //this.imagenSubir = null;
            this.Toast.fire({ icon: 'error', title: 'Error, ' + resp[0].message})
          
          }
        }
      )    
  }

  cerrar(){

    this.subir = false;
  }

  Mensaje_sound() {
    const audio = new Audio('assets/audio/mensaje.mp3');
    audio.play();
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.doc.documentElement.scrollTop || this.doc.body.scrollTop || 0;
    this._scroll = offset
    this.onScroll()
  }

  onScroll(){
    if(this._scroll > this._lastscroll){
      this.view = false
    }else if(this._scroll < this._lastscroll){
      this.view = true
    }
    this._lastscroll = this._scroll
  }




  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 5.5,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 2.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 415,
        "settings": {
          "slidesToShow": 2.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 2.5,
          "slidesToScroll": 1,
        }
      }
    ]
  };
}
