<div class="h-20 w-full">

</div>
<div class="container mx-auto">
    <div class="mx-8 xl:mx-0 mt-6 xl:mt-32 mb-24">

        <div class="grid xl:grid-cols-4  gap-8">

            <div class="xl:col-span-1">
                <menu-perfil [id]="id" [tipo]="'s'" class="xl:hidden"></menu-perfil>
                <info-perfil></info-perfil>
            </div>

            <div class="xl:col-span-3">
                <menu-perfil [id]="id" [tipo]="'s'" class="hidden xl:block"></menu-perfil>

                <div class="mt-8">
                    <div class="flex justify-between">
                        <h1 *ngIf="totalDocuments > 0" class="text-dark-400 font-bold text-xl "> Mis servicios ({{totalDocuments}}) </h1>
                        <a routerLink="/crear/servicio" *ngIf="cuentaActual" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                            <i class="icofont-plus-circle"></i> Crea un servicio
                        </a>
                    </div>

                    <div *ngIf="totalDocuments > 0" class="mt-5 grid gap-5 grid-cols-1 lg:grid-cols-2">
                        <div *ngFor="let s of servicios" class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-0 mb-5">
                            <div class="p-3" [title]="s.name">
                                <div class="flex flex-col space-y-3">
                                    <div class="flex space-x-4 pb-3">
                                        <div class="w-40 md:w-44">
                                            <a [routerLink]="['/servicios', s._id]">
                                                <img *ngIf="s.images != ''" [src]="s.images[0].thumbnail" alt="" class="object-center object-cover w-40 lg:w-44 h-36 rounded-2xl border flex-1">
                                                <img *ngIf="s.images == ''" src="assets/imagen/general/no-imagen.png" title="no hay imagen" class="object-center object-cover w-40 lg:w-44 h-36 rounded-2xl border flex-1">
                                            </a>
                                        </div>
                                        <div class="flex-1">
                                            <a [routerLink]="['/servicios', s._id]">
                                                <h3 class="font-bold text-dark-400 text-md md:text-xl" [title]="s.name">
                                                    {{s.name | slice:0:20}}{{ (s.name.length> 20) ? '...': '' }}
                                                </h3>
                                            </a>
                                            <small class="text-dark-300 font-medium mb-4"> {{s.subcategory.name}}</small> <br>
                                            <small class="text-aply-100 font-medium mb-4"><i class="icofont-location-pin"></i> {{s.town.name}}</small>
                                            <rating-start [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>
                                        </div>
                                    </div>
                                    <div class="py-1 relative min-w-full">
                                        <div class="h-2 bg-gray-200 rounded-full">
                                            <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                                            <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                                            <div class="absolute text-gray-800 text-md  ml-1 bottom-0 left-0 -mb-7">$ {{s.min_price}}</div>
                                            <div class="absolute text-gray-800 text-md  mr-1 bottom-0 right-0 -mb-7">$ {{s.max_price}}</div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pt-5 text-sm font-semibold">
                                        <rating-like [likes]="s.like" [id_ref]="s._id" [type_model]="'Service'"></rating-like>
                                        <div class="flex items-center content-center">
                                            <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-3 bg-dark-100 rounded-b-2xl text-dark-300">
                                <div class="flex justify-between items-center">
                                    <footer-card [id]="s._id" [r]="s.like"></footer-card>
                                    <div class="font-normal uppercase text-right w-44 text-xs">
                                        {{s.updatedAt | date}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="totalDocuments > 0" class="flex justify-between items-center content-center">
                        <div class="text-dark-400 font-semibold  text-sm">
                            Entrada {{currentPage}} de {{totalPages}} paginas
                        </div>
                        <div>
                            <ul class="flex">
                                <li (click)="cambiarPagina(-1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Anterior</span>
                                    </a>
                                </li>
                                <li *ngIf="paginaMM > 0" (click)="cambiarPaginaNumero(paginaMM)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMM}}</a>
                                </li>
                                <li *ngIf="paginaMenos > 0" (click)="cambiarPaginaNumero(paginaMenos)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMenos}}</a>
                                </li>
                                <li class="mx-1 px-3 py-2 bg-aply-100 text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{currentPage}}</a>
                                </li>
                                <li *ngIf="paginaMas != 0" (click)="cambiarPaginaNumero(paginaMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMas}}</a>
                                </li>
                                <li *ngIf="paginaMasMas != 0" (click)="cambiarPaginaNumero(paginaMasMas)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="font-bold">{{paginaMasMas}}</a>
                                </li>

                                <li (click)="cambiarPagina(1)" class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 hover:bg-aply-100 hover:text-gray-200 rounded-lg cursor-pointer">
                                    <a class="flex items-center font-bold">
                                        <span class="mx-1">Siguiente</span>
                                    </a>
                                </li>
                            </ul>


                        </div>
                    </div>


                    <div *ngIf="totalDocuments <=  0" class="mt-8">
                        <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                            <div class="flex items-center content-center text-dark-400 text-xl">
                                <span class="icon-info-circle mr-2"></span>
                                <span class="mr-2">Este usuario no tiene ningun servicio, en estos momentos </span>
                                <a routerLink="/crear/servicio" *ngIf="cuentaActual" class="font-bold italic text-sm text-dark-300 hover:underline cursor-pointer">
                                    <i class="icofont-plus-circle"></i> Crea un servicio
                                </a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>