import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'forms',
  templateUrl: './forms.component.html',
})
  
export class FormsComponent implements OnInit {

  public type: string;
  public individual: boolean = false;
  public enterprise: boolean = false;

  public i: string;
  public e: string;

  constructor() { }

  ngOnInit() {}

  onSelection(type:string) {
    if (type == "individual") {
      this.i = "active";
      this.e = "";
      this.individual = true;
      this.enterprise = false;
    } else if(type == "enterprise") {
      this.e = "active";
      this.i = "";
      this.enterprise = true;
      this.individual = false;
    } else {
      this.enterprise = false;
      this.individual = false;
    }
  }
  
}