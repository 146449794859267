<div class="w-full h-full lg:flex">
    
    <div class=" h-full bg-white lg:w-2/5 lg:flex items-center ">
        <div class="w-full h-20 bg-gray-50 shadow-md lg:hidden"> 
            <img  src="assets/imagen/logo/logo-tras.png" alt="" class="h-full">
        </div>
        <div class="w-full p-4 cuerpo  ">
            <div class="w-full justify-center flex">
                <span class="font-semibold text-2xl text-dark-300 text-center">Inicia sesion</span>
            </div>
            <div class="w-full h-12 bg-blue-700 text-white rounded-full mt-4 flex justify-center items-center ">
                <i class='bx bxl-facebook-square text-2xl  mr-4'></i>
                <span>Inicia con Facebook</span>
            </div>
            <div (click)="signInWithGoogle()" class="w-full h-12 border border-gray-200 text-gray-700 rounded-full mt-4 flex justify-center items-center ">
                <i class='bx bxl-google text-2xl  mr-4'></i>
                <span>Inicia con Google</span>
            </div>
            <div class="w-full justify-center flex">
                <span class="font-semibold text-xl text-dark-300 text-center">o</span>
            </div>
            <div class="mt-5">
                <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off" class="grid gap-6">
                    <div class="flex flex-col">
                        <label for="Correo" class="font-bold text-base text-dark-300">Correo</label>
                        <input type="text" formControlName="email" class="shadow-xl bg-white rounded-lg px-3 py-2 mb-2">
                        <div class="text-danger italic " *ngIf="email.invalid && (email.dirty || email.touched)">
                            <p *ngIf="email.errors.required">campo es requerido</p>
                            <p *ngIf="email.errors?.email">debe digitar un correo</p>
                           </div>
                    </div>
    
                    <div class="flex flex-col">
                        <label for="" class="font-bold text-base text-dark-300">Contraseña</label>
                        <input type="password" formControlName="password" class="shadow-xl bg-white rounded-lg px-3 py-2 mb-2">
                        <div class="text-danger italic" *ngIf="password.invalid && (password.dirty || password.touched)">
                            <p *ngIf="password.errors.required">campo es requerido</p>
                            <p *ngIf="password.errors?.minlength">Minimo 8 caracteres</p>
                            <p *ngIf="password.errors?.maxlength">Maximo 20 caracteres</p>
                           </div>
                     </div>
    
                    <div class="flex items-start mt-1">
                        <div class="flex items-center h-5">
                            <input id="comments" type="checkbox" class="h-4 w-4 rounded-lg border-0 bg-gray-700 text-primary-300" formControlName="remember">
                        </div>
                        <div class="ml-3 text-md">
                            <label for="comments" class="font-bold text-gray-700">
                                    Recuerdame
                            </label>
                        </div>
                    </div>
    
                    <div>
                        <button type="submit" class="input-1 bg-aply-600 w-full h-12 text-white hover:shadow-inner">Iniciar sesión</button>
                    </div>
                </form>
               
    
                <div class="text-center col-span-12 mt-10">
                    ¿No tienes una cuenta? <a routerLink="/register" class="font-bold hover:underline">Registrate</a>
                </div>
            </div>
        </div>
        
    </div>
    <div class=" h-full bg-blue-500 hidden lg:flex lg:w-3/5 relative">
        <div class="absolute left-12 top-24 w-1/2">
            <span class="text-4xl font-bold font-mono text-white leading-relaxed ">En deuna trabajas o 
                los mejores hacen el trabajo por ti.</span>
        </div>
        <img  src="assets/imagen/general/photo-login.jpg" alt="" class="h-full hidden ">
        
    </div>
</div>
<!-- 
<div class="w-full">
    <div class="flex flex-col items-center justify-center content-center h-screen">

        <div class="">
            <img src="assets/imagen/logo/logo-tras.png" alt="" class="w-40">
        </div>

        <h3 class=" font-bold text-2xl text-dark-300">Iniciar sesión</h3>

        <div class="mt-10 w-3/5	 xl:w-1/4">
            <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off" class="grid gap-6">
                <div class="flex flex-col">
                    <label for="Correo" class="font-bold text-base text-dark-300">Correo</label>
                    <input type="text" formControlName="email" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('email')" [msg]="'El email es obligatorio y tiene que ser valido'" [alert]="'error'"></alerts-auth>
                </div>

                <div class="flex flex-col">
                    <label for="" class="font-bold text-base text-dark-300">Contraseña</label>
                    <input type="password" formControlName="password" class="shadow-xl bg-white rounded-2xl px-3 py-2 mb-2">
                    <alerts-auth *ngIf="campoNoValido('password')" [msg]="'La constraseña es obligatoria y tiene que ser mayor a 8 caracteres'" [alert]="'error'"></alerts-auth>
                </div>

                <div class="flex items-start mt-1">
                    <div class="flex items-center h-5">
                        <input id="comments" type="checkbox" class="h-4 w-4 rounded-lg border-0 bg-gray-700 text-primary-300" formControlName="remember">
                    </div>
                    <div class="ml-3 text-md">
                        <label for="comments" class="font-bold text-gray-700">
                                Recuerdame
                        </label>
                    </div>
                </div>

                <div>
                    <button type="submit" class="input-1 bg-aply-600 w-full text-white hover:shadow-inner">Iniciar sesión</button>
                </div>
            </form>
            <div>
                <button (click)="signInWithGoogle()" class="mt-4 input-1 bg-white w-full text-dark-300  hover:shadow-inner"> Google </button>
            </div>

            <div class="text-center col-span-12 mt-10">
                ¿No tienes una cuenta? <a routerLink="/register" class="font-bold hover:underline">Registrate</a>
            </div>
        </div>

    </div>


</div> -->