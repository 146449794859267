import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-web_crear_propuestas',
  templateUrl: './web_crear_propuestas.component.html',
  styleUrls: ['./web_crear_propuestas.component.css']
})
export class Web_crear_propuestasComponent implements OnInit {

  public captura:any;
  public clase:any="clase";
  public necesidades:any;
  public servici:any;
  public id:any;
  public user:any;
  public conversacion:any;
  public model:any="Service";
  service_me: boolean = false;
  public formSubmitted = false;
  
  public proposalForm = this.fb.group({
    conversation_id: ['', [Validators.required], Validators.minLength(3)],
    id_property    : ['', [Validators.required]],
    property_model : ['', [Validators.required]],
    value          : [, [Validators.required, Validators.min(50000),]],
    concept        : ['ninguno', [Validators.required, Validators.minLength(3)]],
    receiver       : ['', [Validators.required]], 
  });
  

  constructor(
    private router: Router,
    private chat: ChatService,
    private activerouter: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {

    this.necesidade();
    this.id           = this.activerouter.snapshot.paramMap.get('user');
    this.conversacion = this.activerouter.snapshot.paramMap.get('id');

    this.servicios(this.id);
    this.i();
  }

  get value() {return this.proposalForm.get('value');}
  
  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
        console.log("usuario logueado", resp.data._id);
      }
    )
  }

  capturarvalor(valor){
    this.captura = null;
     this.captura = valor;
     console.log("capturado", this.captura);
  }

  capturarmodel(valor){
    this.model = null;
     this.model = valor;
     console.log("model", this.model);
  }

  necesidade(){
    
    this.chat.necesidades().subscribe( 
      data => {
        this.necesidades = data.data;
        console.log("necesidades", data);
      } )
  }

  servicios(valor){
    this.chat.servicios(valor).subscribe(
      data => {
        this.servici = data.data;
        console.log("servicios", data.data);
      }
    )
  }


  onCreateProposal(){

    this.formSubmitted = true;

    
    if(!this.proposalForm.value.id_property){
      this.Toast.fire({ icon: 'info', title: 'Selecciona un Elemento!' });
    }else if(this.proposalForm.value.value >= 50000){
      this.chat.createProposal(this.proposalForm.value).subscribe(
        data => {
          this.Toast.fire({ icon: 'success', title: 'Propuesta enviada!' });
           this.proposalForm.controls['value'].reset();
           
           this.captura = 'xxx';
          console.log("se envio la propuesta", data);
  
        }
      )
    }else{
      this.Toast.fire({ icon: 'info', title: 'valor invalido!' });
    }
  
    
  }
  

  selec_estado(id, veredicto, icon){

    Swal.fire({
      imageUrl: 'assets/imagen/icons/'+icon+'.png',
      imageHeight: 60,
      title: icon+'!',
      text: `Puedes cancelar y elegir otro estado`+id,
      width: '330px',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Cancelar`,
      confirmButtonColor:'#F62326',
      denyButtonText: `Aceptar`,
      denyButtonColor: '#2784FE',
      

    }).then((result) => {
      if (result.isConfirmed) {
        
        //window.location.href= this.link;
      }else if(result.isDenied){

        // this.router.navigate(['/login']);
      }
    })
  }


  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {

      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  
  slickInit(e) {}
  
  breakpoint(e) {}
  
  afterChange(e) {}
  
  beforeChange(e) { }
  

  slideConfig = {
    "slidesToShow": 1.3,
    "slidesToScroll": 1,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 4.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 3.5,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 415,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 315,
        "settings": {
          "slidesToShow": 1.2,
          "slidesToScroll": 1,
        }
      }
    ]
  };

  nece = false;
  serv = true;
  servicio():void{
    console.log("entro");
    this.nece = false;
    this.serv = true;
  }

  necesidad(){
    this.serv = false;
    this.nece = true;
    

  }

  service_my(){
    this.service_me = !this.service_me;
    console.log("el boolean", this.service_me)
    if(this.service_me == true){
      this.servici = null;
      this.servicios(this.user);
    }else{
      this.servici = null;
      this.servicios(this.id);
    }
  }


}
