<div class="h-20">

</div>
<div class="container mx-auto mb-8">
    <div class="mx-2 xl:mx-0 mt-6 xl:mt-32 mb-22">
        <div class="grid lg:grid-cols-4 gap-8">
            <div class="lg:col-span-1">
                <menu-perfil [id]="id" [tipo]="'p'" class="lg:hidden"></menu-perfil>
                <info-perfil></info-perfil>
            </div>
            <div class=" lg:col-span-3 ">
                <menu-perfil [id]="id" [tipo]="'p'" class="hidden lg:block"></menu-perfil>
                informacion de perfil 
                <div class="xl:mt-8">
                    <div class="text-dark-400 mt-5 text-justify">

                        <h2 class="text-xl font-bold"> <i class="icofont-caret-right"></i>Configuración de la cuenta </h2>

                        <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                            <form-nombre></form-nombre>
                            <forms-pass></forms-pass>
                        </div>

                        <div *ngIf="verificacion">
                            <h2 class="text-xl font-bold mt-8"> <i class="icofont-caret-right"></i>Configuración del perfil </h2>

                            <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5 grid grid-cols-1 gap-4 place-items-stretch">
                                <forms-perfil></forms-perfil>
                                <forms-ubicacion></forms-ubicacion>
                            </div>
                        </div>

                        <div *ngIf="verificacion">
                            <h2 class="text-xl font-bold mt-8"> <i class="icofont-caret-right"></i>Academico y Laboral </h2>
                            <div class="border-4 border-gray-100 border-dashed rounded-2xl bg-white p-5">
                                <forms-laboral [s]="studio" [e]="expe"></forms-laboral>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>