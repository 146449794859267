import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReseniasService } from 'src/app/core/services/resenias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'card-response',
  templateUrl: './card-response.component.html',
  styleUrls: ['./card-response.component.css']
})
export class CardResponseComponent implements OnInit {

  @Input() rr: any;
  @Input() id_ref: string;
  @Input() id_user: any;
  @Input() log: boolean = false;
  @Output() actualizar: EventEmitter<boolean> = new EventEmitter();

  public time: boolean = false;
  public activacion: boolean = false;

  public formSubmitted = false;
  public  responseForm= this.fb.group({ message_response: ['', [Validators.required]] });

  constructor(
    private reseniaService: ReseniasService,
    private fb: FormBuilder,
  ) {  }

  ngOnInit() {
    this.tiempo();
  }

  deleteResponseReview(id:string) {
    if (this.time) {
     Swal.fire({
       title: 'Estas seguro?',
       text: "No podrás revertir esto!",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Si, eliminar!'
     }).then((result) => {
       if (result.isConfirmed) {
         this.reseniaService.eliminarResponseReview(id, this.id_ref).subscribe(
           resp => {
            this.Toast.fire({ icon: resp.alert, title: resp.msg })
            if (resp.alert == 'success') { this.actualizar.emit(true) }
           }
         )
       }
     })
   }
  }  

  upResponseReview(id:string) {
    if (this.time) {
      this.formSubmitted = true;
      if (this.responseForm.invalid) { return }
      this.reseniaService.updateResponseReview(this.responseForm.value, id).subscribe(
        resp => {
          this.Toast.fire({ icon: resp.alert, title: resp.msg })
          if (resp.alert == 'success') { this.actualizar.emit(true) }
        }
      )
    }
  }

  tiempo() {
    if (this.log == false) { return }
    this.reseniaService.tiempoTranscurridoResponse(this.rr._id).subscribe(
      resp => { this.time = resp.data }
    ) 
  }

  editar() {
    if (this.time) {
      if (this.activacion == true) {
        this.activacion = false;
        this.responseForm.setValue({ message_response: '' });
      } else {
        this.activacion = true;
        this.responseForm.setValue({ message_response: this.rr.message_response });
     }
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}