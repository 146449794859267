import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatNativeDateModule } from '@angular/material/core';

//modulos
import { AppRoutingModule } from './app.routing.module';

// Declaraciones
import { AppComponent } from './app.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { ModulosModule } from './modulos/modulos/modulos.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';

//Pipes

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEs, 'es');


@NgModule({
  declarations: [			
    AppComponent,
    NopagefoundComponent,
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModulosModule,
    CommonModule,
    FormsModule, 
    SlickCarouselModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es'
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
