<app-cabecera ></app-cabecera>
<div class="w-full h-36">

</div>

<!-- message -->
<div class="w-full flex flex-col justify-between pb-7">
  <div id="sms" class="contenedor relative w-full px-2 flex flex-col pb-28 overflow-y-scroll h-screen">
    
    <!-- <div class="">
      <div class="w-full h-auto px-12 flex justify-center pt-24 pb-28 lg:pb-16">
        <img class="w-4/5 lg:w-3/5" src="assets/imagen/general/no-mensaje.png" alt="">
      </div>
      <div class="w-full h-auto flex justify-center pt-8 lg:pt-2">
        <p class="text-2xl font-semibold text-gray-700">¡Aun no tienen mensajes!</p>
      </div>
      <div class="w-full h-auto flex justify-center">
        <p class="text-xl font-medium text-gray-700 text-center">Ya puedes iniciar una converacion con la otra persona</p>
      </div>
      <div class="w-full h-auto flex justify-center pt-8">
        
      </div>
    </div> -->
    <div *ngIf="!mensaje || mensaje == ''"  class="w-full h-screen px-4">
      <div class="w-full flex justify-center pt-9">
        <img class="w-1/2" src="assets/imagen/general/no-mensaje.png" alt="">
      </div>
      <div class="w-full h-auto flex justify-center pt-8 lg:pt-2 mt-3">
        <p class="text-2xl font-semibold text-gray-700">¡No hay Mensajes aun!</p>
      </div>
      <div class="w-full h-auto flex justify-center mt-4">
        <p class="text-xl font-medium text-gray-700 text-center">Ya puedes comenzar a enviar mensajes a tu contacto</p>
      </div>
      
      
    </div>
    
    <div  *ngFor="let men of mensaje">
        <div  class="flex  mb-4" [ngClass]="{'mensaje_mio': user==men.sender._id, 'mensaje_no_mio': user != men.sender._id }">
          <img *ngIf="men.sender.imagen" [ngClass]="{'foto': user != men.sender._id }"
          [src]="men.sender.imagen.thumbnail"
          class="hidden object-cover h-6 w-6 rounded-full"
          alt=""
          />
          <img *ngIf="!men.sender.imagen" [ngClass]="{'foto': user != men.sender._id }"
            src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-6 w-6 rounded-full hidden"
            alt=""/>
          <div *ngIf="!men.images[0]" [ngClass]="{'mensaje_color_mio': user==men.sender._id, 'mensaje_color': user!= men.sender._id}" class="mx-2 py-2 px-4  text-white">
            {{men.text}}
          </div>
          <div *ngIf="men.images[0]" [ngClass]="{'mensaje_color_mio': user==men.sender._id, 'mensaje_color': user!= men.sender._id}" class="mx-2 py-2 px-2  text-white message-img ">
            
            <img src="{{men.images[0].thumbnail}}"  alt="" class="w-full  rounded-lg">
            {{men.text}}
          </div>
          <img *ngIf="men.sender.imagen"  [ngClass]="{'foto_mia': user == men.sender._id }"
            [src]="men.sender.imagen.thumbnail"
            class="object-cover h-6 w-6 rounded-full hidden"
            alt=""
          />
          <img *ngIf="!men.sender.imagen" [ngClass]="{'foto_mia': user == men.sender._id }"
            src="assets/imagen/general/no-imagen.png" alt=""
            class="object-cover h-6 w-6 rounded-full hidden"
            alt=""/>
        </div>
    </div>

      <div >
        <div  class="flex  mb-4 mensaje_mio">
      
        </div>
      </div>

  </div>
  
  <div *ngIf="subir==true" class="w-full h-full fixed fondo_subir pb-4">
    <div class="w-full h-16 px-4 py-4">
      <i class='bx bx-x left-text text-4xl font-bold text-white opacity-100' (click)="cerrar()"></i>
    </div>
    <div class="w-full h-full px-4 pb-24 ">
      <div class="w-full h-48 border-dashed border-2 border-white rounded-lg body_img">
        <img *ngIf="imgTemp" [src]="imgTemp" alt="" class="w-full h-full rounded-lg object-cover   ">
      </div>
      <div class="w-full px-4 pt-6 text-white justify-center">
        <p class="text-center text-xl">Escribe un comentario de la foto</p>
      </div>
      <div class="w-full px-4 pt-6 text-white flex justify-center">
        <i class='bx bxs-chevrons-down text-center text-4xl animate-bounce'></i>
      </div>

    </div>

    
    <div class="fixed z-50 w-full h-auto inset-x-0 bottom-10 shadow-md border-md bg-white">
      <section class="flex items-center flex-grow h-16 px-4 mb-9">
        <div class="flex-none w-10 h-10 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-image text-3xl text-blue-500 mt-1 ml-1'></i>
        </div>
        <div class="w-full mx-2">
          <form [formGroup]="messageForm">
    
            <input formControlName="conversation_id" type="hidden" [(ngModel)]="id">
            <input formControlName="text" type="text"  placeholder="Aqui va el mensaje" class="w-12 md:w-96 min-w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 rounded-full shadow-md focus:bg-gray-100 focus:ring ring-blue-500 focus:outline-none"
                aria-label="" aria-describedby="basic-addon1">
          </form>
        </div>
        <div type="submit" (click)="subirImagen()"  class="w-10 h-10 pl-2 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
          <i class='bx bxs-send text-3xl text-blue-500 mt-1 mr-2'></i>
        </div>
    
    
      </section>
    </div>

  </div>
  <!-- input mesage -->
   
<div [@footercitoInOut] [ngClass]="{'bottom-0 menu': view == false}"  *ngIf="subir==false" class="fixed z-20 w-full h-auto inset-x-0 bottom-20 shadow-md border-md bg-white">
  <section  class="flex items-center flex-grow h-14 px-4">
    
    <div click="subir()"  (click)="hiddenfileinput.click()" class="flex-none w-10 h-10 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
      <i class='bx bxs-image text-3xl text-blue-500 mt-1 ml-1'></i>
      <input type="file" class="hidden" id="file-upload" #hiddenfileinput (change)="cambiarImagen($event.target.files[0])">
    </div>
    <div class="w-full mx-2">
      <form [formGroup]="messageForm">

        <input formControlName="conversation_id" type="hidden" [(ngModel)]="id">
        <input formControlName="text" type="text"  placeholder="Aqui va " class="w-12 md:w-96 min-w-full bg-gray-50 text-dark-400 pl-10 pr-4 py-2 rounded-lg shadow-md focus:bg-gray-100 focus:ring ring-blue-500 focus:outline-none"
            aria-label="" aria-describedby="basic-addon1">
      </form>
    </div>
    <div (click)="onSend()"  class="w-10 h-10 pl-2 bg-white rounded-full shadow-md focus:bg-gray-100 focus:ring focus:outline-none">
      <i class='bx bxs-send text-3xl text-blue-500 mt-1 mr-2'></i>
    </div>


  </section>
</div>
</div>
<!-- end message -->

