import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Img } from '../interfaces/images.interface';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class RecomendacionesService {

  constructor(
    private http: HttpClient
  ) { }
  
  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers_() {
    return {
      headers: {
        'auth-token': this.token
      }
    }
  }
  
  //para file
  get headers() {
    return  {
      'auth-token': this.token
    }
  }

  // /all/recommendations
  allRecomendaciones() {
    const url = `${base_url}/all/recommendations`;
    return this.http.get(url).pipe(
      tap((resp:any) => {})
    )
  }

  // http://127.0.0.1:3333/api/user/61a55731f7800bc3f49a0214/recomendations?page=1
  recomendacionesDelUsuarioSeleccionado(id: string, page: number = 2, limit: number) {
    const url = `${base_url}/user/${id}/recomendations?page=${page}&limit=${limit}`;
    return this.http.get(url, this.headers_).pipe(
      tap((resp:any) => {})
    )
  }

  // http://127.0.0.1:3333/api/recomendations
  crearRecomendacion( data: { message: string } ) {
    const url = `${base_url}/recomendations`;
    return this.http.post(url, data, this.headers_).pipe(
      tap((resp:any) => {})
    )
  }

  // /response/recomendations
  responderRecomendacion( data: { id: string, message: string, } ) {
    const url = `${base_url}/response/recomendations`;
    return this.http.post(url, data, this.headers_).pipe(
      tap( (resp:any) => {} )
    )
  }


  // upload/necessity/image
CreateConversation(archivo: Img[], id: string, text:string ) {

    
    const url = `${base_url}/recomendations`;
    const formData = new FormData();

      for (let i = 0; i < archivo.length; i++) {
        formData.append('images[]', archivo[i].file);
      }

    formData.append('municipality', id);
    formData.append('message', text);

    return this.http.post(url, formData, this.headers_).pipe(
      tap((resp:any) => resp )
    );
    

}


listRecomendacion() {
  const url = `${base_url}/recomendations?page=1&limit=100`;
  return this.http.get(url, this.headers_).pipe(
    tap((resp:any) => {})
  )
}

}