import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { messageForm, proposalForm } from '../interfaces/chat';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  //llega el id de la conversacion que se va emitir a componente mensajes
  @Output() id_conversacion: EventEmitter<any> = new EventEmitter();
  @Output() id_conversacion2: EventEmitter<any> = new EventEmitter();
  @Output() crear_propuesta: EventEmitter<any> = new EventEmitter();

  @Output() push_mensaje     : EventEmitter<any> = new EventEmitter();

  @Output() onLineUsers :   EventEmitter<any> = new EventEmitter();

//aqui se esta instanciando variable para mandar a refrescar 
//metodo despues de hacer un cambio en la base de datos
private _refresh = new Subject<void>();

constructor(
  private http:HttpClient
) { }

get refresh(){
  return this._refresh;
}

get token(): string {
  return localStorage.getItem('token') || '';
}

get headers_() {
  return {
    headers: {
      'auth-token': this.token
    }
  }
}

//para file
get headers() {
  return  {
    'auth-token': this.token
  }
}



// upload/necessity/image
async uploadImagen(archivo: File, id: string, text:string ) {
  try {
    
    const url = `${base_url}/messages`;
    const formData = new FormData();

    formData.append('images', archivo);
    formData.append('conversation_id', id);
    formData.append('text', text);

    const resp = await fetch(url, {
        method: 'POST',
        headers: this.headers,
        body: formData
    });

    const data = await resp.json();
    return data;
    
  } catch (error) {
    return false;
  }
}

user() {

  const url = `${base_url}/user/show`;
  return this.http.get(url, this.headers_).pipe(
    tap((resp: any) => {})
  );
}

members(id){
  const direccion = `${base_url}/members/conversation/${id}`;
  return this.http.get(direccion, this.headers_).pipe(
    tap( (resp:any) => {})
  );
}

conversaciones(){
  const direccion = `${base_url}/conversation`;
   return this.http.get(direccion, this.headers_).pipe(
     tap( (resp:any) => {})
   );
}

mensajes(id):Observable<any>{
  const direccion = `${base_url}/messages/${id}`;
  return this.http.get(direccion, this.headers_).pipe(
    tap( (resp)=> {})
  );

}

send(formData: messageForm){
  const direccion = `${base_url}/messages`;

  return this.http.post(direccion, formData, this.headers_).pipe(
    tap( (resp:any) => {})
  );
}

//ultimo mensaje de la conversacion
lastMensaje(id):Observable<any>{
  const direccion = `${base_url}/messages/last/${id}`;
  return this.http.get(direccion, this.headers_).pipe(
    tap( (resp)=> {})
  );

}

//metodo para crear una propuesta
createProposal(formData:proposalForm){

  console.log("formulario", formData);
  const direccion = `${base_url}/proposal`;
  return this.http.post(direccion, formData, this.headers_).pipe(
    tap((resp:any)=> {
    })
  );
}

//servicio para listar las propuestas

propuestas(id){

  const direccion = `${base_url}/proposal/${id}`;
  return this.http.get(direccion, this.headers_).pipe(
    tap( (resp:any)=> {})
  )
}

//metodo para cambiar de estado la propuesta

veredicto(id:any, veredicto:any, id_conversacion:any){

  const formData = new FormData();
      formData.append('conversation_id', id_conversacion);
  //proposal/61fc323c5f7a710a72cfc581/veredict/
  const direccion = `${base_url}/proposal/${id}/veredict/${veredicto}`;
  return this.http.post(direccion, formData, this.headers_).pipe(
    tap((resp)=> {
      this._refresh.next();
    })
  )
}

//lista necesidades del usuario autenticado, estas tambien estaran publicas
necesidades(){
  const direccion = `${base_url}/necesidades`;
  return this.http.get(direccion,  this.headers_).pipe(
    tap((resp:any)=> {})
  )
}

//listar servicios consultados por id del usuario con quien esta chateado en el momento estado publico
servicios(id:any){
  const direccion = `${base_url}/servicios/${id}`;
  return this.http.get(direccion, this.headers_).pipe(
    tap((resp: any)=> {})
  )
}


//listar las facturas de la conversacion
onInvoince(id)
{
  const direccion = `${base_url}/invoices/${id}?page=1&limit=100`;
  return this.http.get(direccion,  this.headers_).pipe(
    tap((resp:any)=> {})
  )
}

}