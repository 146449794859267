<div class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-0 mb-5">
    <div class="p-3">
        <div class="flex flex-col space-y-3" [title]="s.name">
            <div class="flex space-x-4 pb-3">
                <div class="w-40 md:w-44">
                    <a [routerLink]="['/prestadores', s._id]">
                        <img *ngIf="s.images != ''" [src]="s.images[0].thumbnail" alt=""
                            class="object-center object-cover w-40 md:w-44 h-36 rounded-2xl border flex-1">
                        <img *ngIf="s.images == ''" src="assets/imagen/general/no-imagen.png" title="no hay imagen"
                            class="object-center object-cover w-40 md:w-44 h-36 rounded-2xl border flex-1">
                    </a>
                </div>
                <div class="flex-1">
                    <a>
                        <h3 [title]="s.name" class="font-bold text-dark-400 text-md md:text-xl">
                            {{s.name}}{{ (s.name.length> 20) ? '...': '' }}
                        </h3>
                    </a>
                    <small class="text-dark-300 font-medium mb-4">
                        {{s.subcategory.name}}
                    </small> <br>
                    <small class="text-aply-100 font-medium mb-4">
                        <i class="icofont-location-pin"></i>
                        {{s.town.name}}
                    </small>
                    <rating-start [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>
                </div>
            </div>


            <p class="text-dark-300">
                {{s.description }}{{ (s.description.length> 120) ? '...': '' }} 
                <a [routerLink]="['/prestadores', s._id]" class="italic font-bold text-sm text-dark-300 hover:underline cursor-pointer">Leer mas</a>
            </p>


            <div class="flex justify-between pt-5 text-sm font-semibold">
                <rating-like [likes]="s.like" [id_ref]="s._id" [type_model]="'Service'"></rating-like>
                <div class="flex items-center content-center">
                    <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                </div>
            </div>
        </div>
    </div>
</div>