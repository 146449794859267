<div (window:resize)="onResize($event)"  class="fixed w-full h-auto flex">
  
  <!-- contactos  -->
  <div class="w-1/4 relative">
    <app-webcontactos></app-webcontactos>
  </div>
  <div class="w-1/2 relative">
    <web-app-message></web-app-message>
  </div>
  <div class="w-1/4 relative">
    <!-- canecera de mensajes -->
    <app-web_cabecera></app-web_cabecera>
    <app-webpropuestas *ngIf="propuestas"></app-webpropuestas>
    <app-web_crear_propuestas *ngIf="crear"></app-web_crear_propuestas>
    <app-web_pago *ngIf="pago"></app-web_pago>
    <app-web_pasarela *ngIf="pasarela" ></app-web_pasarela>
    <app-web_detalle_factura *ngIf="detalle"></app-web_detalle_factura>
  </div>
</div>
