import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

// servicios 
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { SocialService } from 'src/app/core/services/social.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent  {
  
  public newUserForm!: FormGroup
  public formSubmitted = false;
  public loginForm = this.fb.group({
    email: [ localStorage.getItem('email') || '' , [ Validators.required, Validators.email ]],
    password: ['', [ Validators.required, Validators.minLength(8) ]],
    remember: [ localStorage.getItem('remember') ]
  });
  user: any;
  type: string;

  constructor(
    private router: Router, 
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private authService: SocialAuthService,
    private readonly _authService: SocialAuthService,
    private social : SocialService
  ) { }
  
  login() {
    this.formSubmitted = true;
    if (this.loginForm.invalid) { return }
    this.usuarioService.login(this.loginForm.value)
      .subscribe( resp => {
        if( this.loginForm.get('remember').value ){
          localStorage.setItem('email', this.loginForm.get('email').value )
          localStorage.setItem('remember', this.loginForm.get('remember').value )
        }else{
          localStorage.setItem('email', '')
          localStorage.setItem('remember', '')
        }    
        if (resp.data.token) { this.router.navigate( ['/perfil', resp.data.id] ) }
      })

      
  }

  campoNoValido(campo: string): boolean {
    if (this.loginForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  createUserBuilder ( formBuilder: FormBuilder ) {
    return formBuilder.group( {
      name: [this.user.name, [Validators.required]],
      email: [this.user.email , [Validators.required]],
      photo: [this.user.photoUrl, [Validators.required] ],
      id : [ this.user.id, [Validators.required]],
      type : [ this.type ],
    } )
  }


  signInWithGoogle(): void {
    this.type = 'Google'
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this._authService.authState.subscribe((user) => {
      this.user = user;
  
      this.newUserForm = this.createUserBuilder( this.fb )
      console.log("formulario", this.newUserForm)
    });

   this.onRegisterSocial()
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  async onRegisterSocial(){

    this.social.registro(this.newUserForm.value).subscribe(
      resp => {
        if (resp.data.token) { this.router.navigate( ['/perfil', resp.data.id] ) }
        console.log("retornado reguster", resp)
      }
    )

  }

  get email() {return this.loginForm.get('email');}
  get password() {return this.loginForm.get('password');}

  
}
