import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'necesidades-card',
  templateUrl: './cardboard.component.html',
  styleUrls: ['./cardboard.component.css']
})
export class CardboardComponent implements OnInit {

  @Input('ncsds') n: any = {};

  public log;
  public id_user: string;

  constructor(
    private usuarioService: UsuarioService,
    private router: Router
  ) {
    this.usuarioService.validarToken().subscribe(
      resp => {
        this.log = resp;        
      }
    )
  }

  ngOnInit() {
  }

  profileOfLogin(id:string) {
    if (this.log) {     
      this.router.navigate(['/perfil', id]);
    } else {
      this.onModal();
    }
  }
  // [routerLink]="['/perfil', n.user._id]"

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

}