import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'form-nombre',
  templateUrl: './nombre.component.html',
  styleUrls: ['./nombre.component.css']
})
export class NombreComponent implements OnInit {

  public id: string;
  public name: string;
  public formSubmitted = false;
  public nombreForm = this.fb.group({ name: ['', [Validators.required, Validators.minLength(3)]] });

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private perfilServices: PerfilService,
    private usuarioService: UsuarioService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.nombre();
  }

  nombre() {
    this.usuarioService.name(this.id).subscribe(
      resp => {
        this.name = resp.data.name
        this.nombreForm.setValue({name: this.name})
      }
    )
  }

  updateNombre() {
    this.formSubmitted = true;
    if (this.nombreForm.invalid) { return }
    this.usuarioService.nameUpdate(this.nombreForm.value, this.id).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
      }
    )
  }

  campoNoValido(campo: string): boolean {
    if (this.nombreForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}
