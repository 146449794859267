import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ModulosComponent } from '../modulos.component';

import { ServiciosComponent } from './page';


const routes: Routes = [
  { 
		path: 'crear',
		component: ModulosComponent,
		canActivate: [AuthGuard],
		children: [
		  {path:'servicio', component: ServiciosComponent}
		]
	},
];



@NgModule({
	imports: [
    RouterModule.forChild(routes),
  ],
	exports: [RouterModule]
})


export class CreacionServiciosRoutes { }