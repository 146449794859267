<div class="mx-10 sm:mx-0 mb-3 mt-2">

    <cuenta-perfil *ngIf="verificacion == true" [id]="id" [validacion]="cuentaActual"></cuenta-perfil>
    <cuenta-sin-perfil *ngIf="verificacion == false" [id]="id" [validacion]="cuentaActual"></cuenta-sin-perfil>

    <div *ngIf="cuentaActual" class="grid grid-col-12 gap-2 mt-5">

        <a *ngIf="!verificacion" routerLink="/creacion/perfil" class="col-span-1">
            <div class="border-gray-200 border p-4 bg-white shadow-xl rounded-2xl content-center items-center cursor-pointer hover:shadow-none hover:bg-blue-50 transition duration-700 ease-in-ou ">
                <div class="text-center ">
                    <h4 class="font-bold text-dark-300 text-sm mt-1">
                        Crear perfil
                    </h4>
                </div>
            </div>
        </a>

        <a [routerLink]="['/perfil/configuracion', id]" class="col-span-1">
            <div class="border-gray-200 border p-4 bg-white shadow-xl rounded-2xl content-center items-center cursor-pointer hover:shadow-none hover:bg-blue-50 transition duration-700 ease-in-ou">
                <div class="text-center ">
                    <h4 class="font-bold text-dark-300 text-sm mt-1">
                        Configuración
                    </h4>
                </div>
            </div>
        </a>

        <a *ngIf="verificacion" routerLink="/crear/servicio" class="col-span-1">
            <div class="border-gray-200 border p-4 bg-white shadow-xl rounded-2xl content-center items-center cursor-pointer hover:shadow-none hover:bg-blue-50 transition duration-700 ease-in-ou ">
                <div class="text-center ">
                    <h4 class="font-bold text-dark-300 text-sm mt-1">
                        Crear servicio
                    </h4>
                </div>
            </div>
        </a>

        <a routerLink="/crear/necesidades" class="col-span-1">
            <div class=" border border-gray-200 p-4 bg-white shadow-xl rounded-2xl content-center items-center cursor-pointer hover:shadow-none hover:bg-blue-50 transition duration-700 ease-in-ou ">
                <div class="text-center ">
                    <h4 class="font-bold text-dark-300 text-sm mt-1">
                        Crear necesidad
                    </h4>
                </div>
            </div>
        </a>
        <a class="col-span-1">
            <div class=" border border-gray-200 p-4 bg-white shadow-xl rounded-2xl content-center items-center cursor-pointer hover:shadow-none hover:bg-blue-50 transition duration-700 ease-in-ou ">
                <div class="text-center ">
                    <h4 class="font-bold text-dark-300 text-sm mt-1">
                        Salir
                    </h4>
                </div>
            </div>
        </a>



    </div>
</div>