import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'response',
  templateUrl: './response-recomendacion.component.html',
  styleUrls: ['./response-recomendacion.component.css']
})
export class ResponseRecomendacionComponent implements OnInit {

  @Input() respuestas: any;
  @Input() id: string;
  @Input('c') contador: number; 
  
  public activacion: boolean = false;
  public id_ref: string;
  public num: number = 1;

  constructor() { }

  ngOnInit() { }


  masomenos(type: string, id: string) {
    if (id) {
      this.id_ref = id;
      if (type == 'mas') {
        this.activacion = true;
        this.num = this.contador;
      } else if (type == 'menos') {
        this.activacion = false;
        this.num = 1
      }
    }
  }

}
