import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';
import { DetalleComponent, PrestadoresComponent } from './page';

const routes: Routes = [
	{
		path: 'prestadores',
		component: ModulosComponent,
		children: [
			{ path: '', component: PrestadoresComponent },
			{ path: ':id', component: DetalleComponent },
		]
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	],
	exports: [RouterModule]
})

export class PrestadoresRoutes { }
