import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { NecesidadesService } from 'src/app/core/services/necesidades.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-necesidad',
  templateUrl: './necesidad.component.html',
  styleUrls: ['./necesidad.component.css']
})
export class NecesidadComponent implements OnInit {

  public id: string;

  public necesidads: any = [];
  public totalDocuments: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 0;

  public page: number = 1;

  public usuario: Usuarios;
  public cuentaActual: boolean = false;
  public log: boolean;
  public id_user: string;

  constructor(
    private ar: ActivatedRoute,
    private necesidadService: NecesidadesService,
    private usuarioService: UsuarioService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.usuario = usuarioService.usuarios;

    if (usuarioService.getLog) {
      this.log = usuarioService.getLog();
    }

    if (this.log == true) {
      this.id_user = usuarioService.usuarios._ids;
    }   
  }

  ngOnInit() {
    this.listadoNecesidads(this.id);
    this.perfiLogueado();
  }

  listadoNecesidads(id:string) {
    this.necesidadService.necesidadDelUsuarioSeleccionado(id, this.page, 10).subscribe(
      resp => {
        this.necesidads = resp.data.necessities;
        this.totalDocuments = resp.data.totalDocuments;
        this.totalPages = resp.data.totalPages;
        this.currentPage = resp.data.currentPage;
        this.paginars();
      }
    )
  }

    // metodo para verificar si la actual cuenta pertenece a la cuenta logueado o no 
  perfiLogueado() {
    if (this.id_user == this.id) {
      this.cuentaActual = true;
    } else {
      this.cuentaActual = false;
    }
  }
  
  public paginaMas: number = 0;
  public paginaMasMas: number = 0;

  public paginaMenos: number = 0;
  public paginaMM: number = 0;

  cambiarPagina(valor: number) {
    this.page += valor;
    if (this.page < 1) {
      this.page = 1;
    } else if (this.page > this.totalPages) {
      this.page -= valor;
    }
    this.listadoNecesidads(this.id);
  }

  cambiarPaginaNumero(valor: number) {
    this.page = valor;
    this.paginars();
    this.listadoNecesidads(this.id);
  }

  paginars() {
    if (this.currentPage < 1) {
      this.paginaMenos = 0;
      this.paginaMM = 0;
    } else if( this.currentPage < 2 ) {
      this.paginaMenos = this.currentPage - 1;
    } else {
      this.paginaMenos = this.currentPage -1;
      this.paginaMM = this.currentPage -2;
    }

    if (this.currentPage == this.totalPages-1) {
      this.paginaMas = (this.totalPages);
      this.paginaMasMas = 0;
    } else if (this.currentPage == this.totalPages) {
      this.paginaMas = 0;
      this.paginaMasMas = 0;
    } else {
      this.paginaMas = this.paginaMenos + 2;
      this.paginaMasMas = this.paginaMenos + 3;
    }
  }


}
