import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alerts-auth',
  templateUrl: './alerts.auth.component.html',
  styleUrls: ['./alerts.auth.component.css']
})
export class AlertsAuthComponent implements OnInit {

  @Input() alert: string;
  @Input() msg: string;

  constructor() { }

  ngOnInit() {
  }

}
