import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'alert-verificacion',
  templateUrl: './alert-verificacion.component.html',
  styleUrls: ['./alert-verificacion.component.css']
})
export class AlertVerificacionComponent implements OnInit {

  @Input() id: string;
  public mailConfirmation: boolean = false;
  public email: string;
  public log: boolean;
  
  constructor(
    private usuarioService: UsuarioService,
    private router: Router
  ) { 
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.email = usuarioService.usuarios.emails }   
  }

  ngOnInit() {
    this.checkConfirmationMail();
  }

  checkConfirmationMail() {
    this.usuarioService.checkConfirmationMail().subscribe(
      resp => { this.mailConfirmation = resp }
    )
  }

  generateNewCode() {
    this.usuarioService.generateNewCode(this.id).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == 'success') {
          this.router.navigate(['/confirmacion/correo/', this.email]);
        }
      }
    )
  }
  
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
}
