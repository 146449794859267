import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/core/services/usuario.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public idServicio: string;
  public id_user: string;
  public log: boolean;
  public descripcion: boolean = true;
  public imagen: boolean = false;
  public finalizar: boolean = false;

  constructor(
    private usuarioService: UsuarioService
  ) {
    if (usuarioService.getLog) { this.log = usuarioService.getLog() }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids }   
  }

  ngOnInit() {}

  cambioValorHijo(id: string) {
    if (id != "") {
      this.descripcion = false;
      this.imagen = true;
      this.idServicio = id;     
    }
  }

  nextFinalizar(f: boolean) {
    if (f == true) {
      this.descripcion = false;
      this.imagen = false;
      this.finalizar = true;
    }
  }
}
