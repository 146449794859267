<div class="container mx-auto">
    <div class="mx-8 xl:mx-0 mt-6 xl:mt-32 mb-24">

        <div class="grid xl:grid-cols-4  gap-8">

            <div class="xl:col-span-1">
                <menu-perfil [id]="id" [tipo]="'c'" class="xl:hidden"></menu-perfil>
                <info-perfil></info-perfil>
            </div>

            <div class="xl:col-span-3">
                <menu-perfil [id]="id" [tipo]="'c'" class="hidden xl:block"></menu-perfil>

                <div class="mt-8">




                    <div class="flex justify-between">
                        <div class="text-dark-400">
                            <h2 class="text-xl  font-bold">Balance</h2>
                        </div>
                        <div class="p-5 bg-white shadow-2xl rounded-2xl text-center">
                            <h5 class="text-dark-400  font-bold">Puntos</h5>
                            <h3 class="text-2xl text-dark-400">0</h3>
                        </div>
                    </div>

                    <!-- <div class="text-dark-400 mt-5 text-justify">
                        <h2 class="text-xl  font-bold">Información de perfil</h2>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores, nesciunt. Debitis dolore neque quia fugit illo dicta perspiciatis quod, quae molestiae obcaecati possimus rerum cumque ducimus praesentium tempore nobis. Magni!
                    </div>

                    <div class="text-dark-400  mt-5">
                        <h3 class="font-bold text-dark-400 text-xl">Estudios</h3>
                        <ul class="list-inside">
                            <li class="ml-3"> <i class="icon-villeta-circle-alt text-dark-400 mr-3"></i>Tecnico en redes</li>
                        </ul>
                        <h3 class="font-bold text-dark-400 text-xl">Experiencia</h3>
                        <ul class="list-inside">
                            <li class="ml-3"> <i class="icon-villeta-circle-alt text-dark-400 mr-3"></i>Redes electricas</li>
                            <li class="ml-3"> <i class="icon-villeta-circle-alt text-dark-400 mr-3"></i>Diseños de redes electricas</li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container mx-auto">
    <div class="mx-8 my-8 xl:my-24 xl:mx-0">

        <div class="flex flex-col">

            <menu-perfil></menu-perfil>

            <info-perfil></info-perfil>


            <div class="flex justify-between">
                <div class="space-y-2">
                    <h1 class="text-dark-400 font-bold text-xl  mt-5">Balance</h1>
                    <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 500.000</h4>
                    <button class="input-1 bg-aply-600 px-10 text-white hover:shadow-inner">retirar</button>

                </div>
                <div class="flex flex-col  content-center items-center bg-white rounded-xl shadow-2xl p-8">
                    <h4 class="text-dark-400 font-medium">Puntos</h4>
                    <h5 class="text-4xl text-dark-400 font-bold">224</h5>
                </div>
            </div>


            <div class="my-16">
                <div class="flex flex-col">
                    <div class="bg-white border p-4">
                        <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 150.000</h4>
                        <h5 class="ml-5 flex">
                            <img src="assets/imagen/icons/new-moon.png" alt="" class="w-3 h-3 mt-2 mr-3"> Pago de factura #001
                        </h5>
                        <p class="ml-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo alias expedita beatae.</p>
                    </div>
                </div>

                <div class="flex flex-col">
                    <div class="bg-white border p-4">
                        <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 150.000</h4>
                        <h5 class="ml-5 flex">
                            <img src="assets/imagen/icons/new-moon.png" alt="" class="w-3 h-3 mt-2 mr-3"> Pago de factura #001
                        </h5>
                        <p class="ml-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo alias expedita beatae.</p>
                    </div>
                </div>


                <div class="flex flex-col">
                    <div class="bg-white border p-4">
                        <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 150.000</h4>
                        <h5 class="ml-5 flex">
                            <img src="assets/imagen/icons/new-moon.png" alt="" class="w-3 h-3 mt-2 mr-3"> Pago de factura #001
                        </h5>
                        <p class="ml-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo alias expedita beatae.</p>
                    </div>
                </div>


                <div class="flex flex-col">
                    <div class="bg-white border p-4">
                        <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 150.000</h4>
                        <h5 class="ml-5 flex">
                            <img src="assets/imagen/icons/new-moon.png" alt="" class="w-3 h-3 mt-2 mr-3"> Pago de factura #001
                        </h5>
                        <p class="ml-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo alias expedita beatae.</p>
                    </div>
                </div>

                <div class="flex flex-col">
                    <div class="bg-white border p-4">
                        <h4 class="text-2xl text-dark-400 "><span class="text-yellow-600">$ </span> 150.000</h4>
                        <h5 class="ml-5 flex">
                            <img src="assets/imagen/icons/new-moon.png" alt="" class="w-3 h-3 mt-2 mr-3"> Pago de factura #001
                        </h5>
                        <p class="ml-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo alias expedita beatae.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div> -->