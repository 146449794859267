import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sh-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  @Input() alert: string;
  @Input() msg: string;

  constructor() { }

  ngOnInit() { }

}
