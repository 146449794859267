import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from "./page"
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.component/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedPipesModule } from 'src/app/shared.pipes/shared.pipes.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SlickCarouselModule,
    SharedPipesModule.forRoot(),
    InfiniteScrollModule
  ],
  declarations: [
    ...fromComponents.components
  ]

})
export class NecesidadesModule { }
