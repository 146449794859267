<div class="px-3 mt-2">
    <div class="flex flex-wrap items-center space-x-3 text-dark-400 ">
        <a [routerLink]="[ '/perfil', id] " [ngClass]="{ 'active-menu': perfil, '': !perfil} " class="flex flex-col flex-1 menu ">
            <div class="lg:flex items-center text-center ">
                <i class="icon-user-cirlce-alt text-2xl lg:text-3xl lg:mr-3 "></i>
                <h4 class="hidden lg:block font-bold text-dark-300 text-md mt-1 "> Perfil</h4>
            </div>
        </a>
        <a *ngIf="verificacion " [routerLink]="[ '/perfil/servicios', id] " [ngClass]="{ 'active-menu': servicio, '': !servicio} " class="flex flex-col flex-1 menu ">
            <div class="lg:flex items-center text-center ">
                <i class="icon-file-config text-2xl lg:text-3xl lg:mr-3 "></i>
                <h4 class="hidden lg:block font-bold text-dark-300 text-md mt-1 ">Servicios</h4>
            </div>
        </a>
        <a [routerLink]="[ '/perfil/necesidades', id] " [ngClass]="{ 'active-menu': necesidad, '': !necesidad} " class="flex flex-col flex-1 menu ">
            <div class="lg:flex items-center text-center ">
                <i class="icon-msg-fav text-2xl lg:text-3xl lg:mr-3 "></i>
                <h4 class="hidden lg:block font-bold text-dark-300 text-md mt-1 ">Necesidades</h4>
            </div>
        </a>
        <a [routerLink]="[ '/perfil/recomendaciones', id] " [ngClass]="{ 'active-menu': recome, '': !recome} " class="flex flex-col flex-1 menu ">
            <div class="lg:flex items-center text-center ">
                <i class="icon-msg-recomendacion text-2xl lg:text-3xl lg:mr-3 "></i>
                <h4 class="hidden lg:block font-bold text-dark-300 text-md mt-1 ">Recomendaciones</h4>
            </div>
        </a>
        <!-- <a [routerLink]="[ '/perfil/cuenta', id] " [ngClass]="{ 'active-menu': cuenta, '': !cuenta} " class="flex flex-col flex-1 menu ">
            <div class="lg:flex items-center text-center ">
                <i class="icon-precio text-2xl lg:text-3xl lg:mr-3 "></i>
                <h4 class="hidden lg:block font-bold text-dark-300 text-md mt-1 ">Cuenta</h4>
            </div>
        </a> -->
    </div>
</div>