import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';

// guard 
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { checkConfirmationMailGuard } from 'src/app/core/guards/checkConfirmationMail.guard';

import { FormsComponent} from './page';

const routes: Routes = [
  	{ 
		path: 'creacion',
		component: ModulosComponent,
		canActivate: [AuthGuard, checkConfirmationMailGuard],
		children: [
		  {path:'perfil', component: FormsComponent}
		]
	},
];

@NgModule({
	imports: [
    	RouterModule.forChild(routes),
  	],
	exports: [RouterModule]
})

export class CreadorPerfilRoutes { }