<div class="hidden lg:flex relative items-menu transition duration-500 ease-in-out" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <div class="">
        <span class="icon-notificacion"></span>
    </div>
    <div *ngIf="n_notificacion > 0" class="w-5 h-5 bg-red-500 rounded-full absolute top-0 -right-2 flex justify-center">
        <p class="text-sm text-white font-semibold">{{n_notificacion}}</p>
    </div>
</div>

<div [routerLink]="[ '/notificaciones/all' ]" class="lg:hidden relative  items-menu transition duration-500 ease-in-out">
    <div class="">
        <span class="icon-notificacion"></span>
    </div>
    <div *ngIf="n_notificacion > 0" class="w-5 h-5 bg-red-500 rounded-full absolute top-0 -right-2 flex justify-center">
        <p class="text-sm text-white font-semibold">{{n_notificacion}}</p>
    </div>
</div>


  <mat-menu #menu="matMenu" class="absolute medidas h-4/5 right-24 top-6 border rounded-bl-lg rounded-tl-lg rounded-br-lg bg-white overflow-y-scroll contenedor">
    <div class="flex justify-center w-full h-6 bg-gray-200 cursor-pointer">
        <a [routerLink]="[ '/notificaciones/all']">
            <p class="text-sm text-gray-600 hover:text-blue-500 hover:decoration-blue-500 hover:underline">Todas</p>
        </a>
    </div>

    <div *ngFor="let notificacion of notificaciones" class="block w-full h-auto  border-b py-2 px-2 ">
        <div class="flex w-full">
            <div class="w-24 pr-2 lg:mr-4 py-2" [routerLink]="['/perfil', notificacion.sender._id]">
                <img *ngIf="notificacion.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="notificacion.sender.imagen.thumbnail" alt="">
                <img *ngIf="!notificacion.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
            </div>
            <div class="w-full content-start">
                <p [routerLink]="['/perfil', notificacion.sender._id]" class="text-base font-semibold text-gray-700 cursor-pointer">{{notificacion.sender.name}}</p>
                <p class="text-sm text-gray-500 font-semibold -mt-1">{{notificacion.message[0].description}} {{notificacion.message[0].tittle}}</p>

            </div>
            <div class="w-36 pr-2 lg:ml-4 py-2">
                <div class="flex justify-end mb-6">
                    <p class="text-xs">{{ notificacion.message[0].time | date:'mediumDate'}}</p>
                </div>
                <div class="flex justify-end cursor-pointer">
                    <div (click)="menu(notificacion._id)" class="w-auto hover:bg-gray-100 rounded-full inline-block align-middle">
                        <i class='bx bx-dots-horizontal-rounded text-3xl mx-1 mt-1'></i>
                    </div>

                    <div *ngIf="n_menu && id_notificacion == notificacion._id" class="block absolute w-56 h-auto border rounded-bl-lg rounded-tl-lg rounded-br-lg bg-white mt-10">
                        <div class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
                            <div class="w-1/5 py-2 flex justify-center">
                                <i class='bx bxs-trash text-2xl text-gray-600'></i>
                            </div>
                            <div class="w-4/5 py-1">
                                <p class="text-sm font-semibold text-gray-600">Eliminar</p>
                                <p class="text-sm font-normal text-gray-600">Eliminar esta notificacion</p>
                            </div>
                        </div>
                        <div class="flex w-full h-12 px-2 hover:bg-gray-100 cursor-pointer">
                            <div class="w-1/5 py-2 flex justify-center">
                                <i class='bx bxs-check-circle text-2xl text-gray-600'></i>
                            </div>
                            <div class="w-4/5 py-1">
                                <p class="text-sm font-semibold text-gray-600">Marcar</p>
                                <p class="text-sm font-normal text-gray-600">Marcar vista notificacion</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--   <div class="flex w-full h-10 mb-4 pl-24">
            <div class="w-80 flex">
                <div class="w-36 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-red-500/50">
                    <p class="text-lg text-white font-semibold pb-1">Ver</p>
                </div>
            </div>
        </div>
    --> 
    </div>

    <div class="flex justify-center w-full h-6 bg-gray-200 cursor-pointer">
        <a [routerLink]="[ '/notificaciones/all']">
            <p class="text-sm text-gray-600 hover:text-blue-500 hover:decoration-blue-500 hover:underline">Todas</p>
        </a>
    </div>
  </mat-menu>
