import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';

import { DetalleComponent, ServiciosComponent } from './page';

const routes: Routes = [
	{
		path: 'servicios',
		component: ModulosComponent,
		children: [
			{ path: '', component: ServiciosComponent },
			{ path: ':id', component: DetalleComponent },
		]
	},
];


@NgModule({
	imports: [
		RouterModule.forChild(routes),
	],
	exports: [RouterModule]
})


export class ServiciosRoutes { }
