import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Horario } from 'src/app/core/models/horario.models';


import { DataService } from 'src/app/core/services/data.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'att-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.css']
})
export class HorarioComponent implements OnInit {

  @Input() id: string;

  public id_user2: string;
  public id_user1: string;
  public auxUsuario: boolean = false;
  public log: boolean = false;
  
  public atencion: any;

  public dias: any;

  public formSubmitted: boolean;

  public horarioForms = this.fb.group(
    {
      id_service : ['',  [ Validators.required ]],
      day : ['',  [ Validators.required ]],
      start_time : ['',  [ Validators.required ]],
      end_time : ['',  [ Validators.required ]],
    }
  );

  public horarioUpdtaForms = this.fb.group(
    {
      day : ['',  [ Validators.required ]],
      start_time : ['',  [ Validators.required ]],
      end_time : ['',  [ Validators.required ]],
    }
  );

  
  constructor(
    private servicioService: ServiciosService,
    private modalService: ModalService,
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private usuarioService: UsuarioService
  ) { 
    this.dias = dataService.dias;
    if (usuarioService.getLog) {
      this.log = usuarioService.getLog();
    }

    if (this.log == true) {
      this.id_user1 = usuarioService.usuarios._ids;
    } 
    
  }

  ngOnInit(): void {
    this.cargarHorarios();
    this.verificacionCuenta();
  }

  cargarHorarios() {
    if (this.id) {
      this.servicioService.cargarHorarios(this.id).subscribe(
        resp => {
          this.atencion = resp.horario.attention;          
        }
      ) 
    }
  }

  guardarHorario() {

    this.formSubmitted = true;

    if (this.horarioForms.invalid) {
      return;
    }

    this.servicioService.agregarHorario(this.horarioForms.value).subscribe(
      resp => {
        console.log(resp);
        
        if (resp.success) {
          this.cargarHorarios();
          this.closeModal('crear')
        }
      }
    )
  }   

  updateHorario() {}

  deleteHorario(horario: Horario) {  
    Swal.fire({
      title: 'Estas seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicioService.eliminarHorario(horario._id).subscribe(
          resp => {
            this.cargarHorarios();
            Swal.fire(
              'horario borrado',
              `${ horario.day } fue eliminado correctamente`,
              'success'
            );
        }  
      );
      }
    })

  }

  // modales
  openModalCrear(id: string) {  
    if (this.auxUsuario == true) {
        this.modalService.open(id);
    } else {
      this.onModal();
    }    
  }

  openModalEditar(id: string, horario: Horario) {
    if (this.auxUsuario == true) {
         console.log(horario);
        this.horarioUpdtaForms.setValue({ day:horario.day, start_time:horario.start_time, end_time:horario.end_time });
        this.modalService.open(id);
    } else {
      this.onModal();
    } 
  }
  
  closeModal(id: string) {
      this.modalService.close(id);
  }

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  verificacionCuenta() {

    if (this.log == false) {
      return;
    }

    this.servicioService.idUsuario(this.id).subscribe(
      resp => {
    
        this.id_user2 = resp.id_user.profile.user._id;

        if (this.id_user1 == this.id_user2) {
          this.auxUsuario = true;
        }
      }
    )    
  }

}
