import { CardboardComponent } from "./cardboard/cardboard.component";
import { RecomendacionesComponent } from "./recomendaciones/recomendaciones.component";


export const components: any[] = [
    RecomendacionesComponent,
    CardboardComponent    
]

export * from "./cardboard/cardboard.component";
export * from "./recomendaciones/recomendaciones.component";