<div class="w-full" class="home-form-search">
  <div style="width: 50%; text-align: center">
    <form class="mx-auto" style="top: -80px; position: relative">
      <div class="mb-5">
        <h1 class="text-white text-3xl">
          !Encuentra lo que Necesitas con los Mejores Proveedores.
        </h1>
        <br />
        <input
          type="text"
          placeholder="Buscar empresas, prestadores de servicios..."
          id="large-input"
          class="text-center block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      <button
        type="button"
        class="inline-flex px-6 py-3.5 text-base font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Buscar
      </button>
    </form>
  </div>
</div>
<div class="w-full">
  <categorias-random></categorias-random>
</div>
<div class="w-full" style="display: none">
  <recomendaciones [log]="log"></recomendaciones>
</div>
<div class="margin-0">
  <app-services style="display: none"></app-services>
  <div class="w-full h-auto mt-8 margin-0 relative">
    <div
      class="w-full h-auto md:h-96 md:flex md:px-24 bg-pattern"
      style="display: flex; flex-direction: column"
    >
      <div class="w-full h-full flex justify-center items-center md:px-8"></div>
    </div>
    <app-necesity></app-necesity>
  </div>
  <div class="grid grid-cols-4 gap-4 d3una-container-sponsor">
    <!-- Column 1 -->
    <div class="col-span-1 w-full">
      <div class="sponsor-1"></div>
    </div>

    <!-- Column 2 -->
    <div class="col-span-1 w-full">
      <div class="sponsor-2"></div>
    </div>

    <!-- Column 3 -->
    <div class="col-span-1 w-full">
      <div class="sponsor-3"></div>
    </div>

    <!-- Column 4 -->
    <div class="col-span-1 w-full">
      <div class="sponsor-4"></div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
