<div class="container mx-auto  xl:my-0">
    <div class="mx-5 xl:mx-0">
        <div class="flex flex-col items-center justify-center content-center h-screen">

            <span class="icon-bandera text-9xl text-dark-400"></span>

            <h2 class="text-4xl text-center text-dark-400 mx-8 my-5">
                Tu servicio ya esta listo para publicar.
            </h2>

            <h2 class="text-xl text-center text-dark-400 mx-8 my-3">
                ¡Recuerda que los servicios los podrás modificar cuando lo prefieras!
            </h2>

            <div class="flex flex-col items-center justify-center my-10 space-y-3">
                <!-- si tiene al menos una imagen subida entonces podra publicarse  -->

                <button [routerLink]="['/servicios', idServicio]" *ngIf="count <= 0" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    Finalizar
                </button>
                <button *ngIf="count >= 1" (click)="publicar()" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                    Publicar
                </button>
                <a [routerLink]="['/servicios', idServicio]" *ngIf="count >= 1" class="btn-1 focus:ring text-dark-400 hover:text-white  hover:bg-gray-500 active:bg-gray-500 focus:ring-gray-300 bg-gray-300">
                    Omitir
                </a>

            </div>

        </div>
    </div>