import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Img } from 'src/app/core/interfaces/images.interface';
import { FileUploadService } from 'src/app/core/services/fileUpload.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'e-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  @Output() finalizar: EventEmitter<boolean> = new EventEmitter();
  @Output() countsImagen: EventEmitter<number> = new EventEmitter();
  @Input('id') idServicio: string;
  @Input() idUser: string;

  public images: any;
  public count: number = 0;
  public imagenes: Img[] = [];
  public isLoading: boolean = false;
  public isLoadingUp:boolean = false;

  constructor(
    private fileUploadService: FileUploadService,
  ) { }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  ngOnInit() {
  }

  omitir() { 
    this.finalizar.emit(true); 
  }
  
  siguiente() { 

    this.isLoadingUp = true;
    if(this.count <= 3){
      //almacenar las imagenes para el servicio y continuar con el siguiente paso 
      this.fileUploadService.uploadImages(this.imagenes, this.idServicio, 'Service').subscribe( resp => { 
        this.Toast.fire({ icon: resp.alert, title: resp.msg });
        this.finalizar.emit(true);
      }); 
      
    }else{
      //pasar al siguiente paso de la creación del
      this.finalizar.emit(true);
    }

  }
  
  cambiarImagen(file: File) {
    //cargando imagen
    this.isLoading = true;
    //capturar imagen cuando llega 
    const imagenci: File = file;

    //si la imagen existe entonces
    if (imagenci) {

      //TODO: leer la imagen
      const reader = new FileReader();
      reader.readAsDataURL(file);

      //TODO: cuando la imagen es leida entonces...
      reader.onload = (_event) => {

        //TODO: crear un objeto con el nombre de la imagen, el archivo y el resultado de la lectura
        let imagencita: Img = {
          filename    : imagenci.name,
          file        : imagenci,
          url         : reader.result
        }

        //TODO: Hacer push de la imagen al array de imagenes y aumentar el contador
        this.imagenes.push(imagencita);
        this.count += 1;
        this.isLoading = false;
      }
    }
  }

  delete(index) {
    //TODO: Eliminar una imagen del array de imagenes por el indice y descontar del contador
    this.imagenes.splice(index, 1);
    this.count -= 1;
  }

}