import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() {}
  
  dias = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  horas = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  minutos = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
  type = ['am', 'pm'];

    
 
  
}
