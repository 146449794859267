import { Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-web_pago',
  templateUrl: './web_pago.component.html',
  styleUrls: ['./web_pago.component.css']
})
export class Web_pagoComponent implements OnInit {

  public dato:boolean = false;

  constructor(
    private menuservice: MenuService,
    private chat: ChatService
  ) { }

  ngOnInit() {
  }


  //emito valor para cambiar de valor el componente web cabecera
  crear_propuesta(valor){

    this.chat.crear_propuesta.emit(
    { data : valor }
    )
  }

  
  credito():void{
    this.psee = false;
    this.credi = !this.credi;
    this.debi = false;
  }
  debito():void{
    this.debi = !this.debi;
    this.credi = false;
    this.psee = false;
  }
  pse():void{
    this.credi = false;
    this.debi = false
    this.psee = !this.psee;
  }

  pasarela(){
    this.menuservice.pasarela.emit(
      {data:this.dato})
    console.log('tocando aqui');
  }

  detalle(){
    this.menuservice.detalle.emit(
      {data:this.dato})
    console.log('tocando aqui');
  }

  credi = false;
  debi = false;
  psee = false;

}
