import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuarios } from 'src/app/core/models/usuarios.models';
import { CreacionesService } from 'src/app/core/services/creaciones.service';
import { FileUploadService } from 'src/app/core/services/fileUpload.service';
import { ServiciosService } from 'src/app/core/services/servicios.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'e-finalizar',
  templateUrl: './Finalizar.component.html',
  styleUrls: ['./Finalizar.component.css']
})
export class FinalizarComponent implements OnInit {

  @Input('id') idServicio: string;
  @Input() idUser: string;

  public count: number;
  public usuario: Usuarios;
  public log: boolean;
  public id_user: string;
  
  constructor(
    private creacionService: CreacionesService,
    private servicioService: ServiciosService,
    private usuarioService: UsuarioService,
    private fileService: FileUploadService,
    private route: Router,
  ) {
    this.usuario = usuarioService.usuarios;
    if (usuarioService.getLog) { this.log = usuarioService.getLog(); }
    if (this.log == true) { this.id_user = usuarioService.usuarios._ids}   
  }

  ngOnInit() {
    this.listadoImages();
  }

  listadoImages() {
    this.fileService.obtenerImages(this.idServicio, 'Service').subscribe(
      resp => { this.count = resp.data.count; }
    )
  }

  publicar() {
    if (this.idServicio == null) { return }
    this.creacionService.publicar(this.idServicio, 'service').subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        this.route.navigate(['/perfil/servicios', this.id_user]);  //id del perfil
      }
    ) 
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}