import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { loginForm } from '../interfaces/login.form.interfaces';
import { RegistroForm } from '../interfaces/registro.form.interfaces';
import { Usuarios } from '../models/usuarios.models';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public usuarios: Usuarios;
  public roles;
  public log: boolean;

  constructor(
    private http: HttpClient
  ) { }

  getLog() { return this.log; }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: { 'auth-token': this.token }
    }
  }

  user() {
    const url = `${base_url}/user/show`;
    return this.http.get(url, this.headers).pipe( tap((resp: any) => {}) );
  }

  //metodo para loguearse
  login(formData: loginForm) {
    const url = `${base_url}/login`;
    return this.http.post(url, formData).pipe(
      tap((resp: any) => { localStorage.setItem('token', resp.data.token) })
    );
  }

  //metodo para registrarse
  registro(formData: RegistroForm) {
    const url = `${base_url}/register`;
    return this.http.post(url, formData).pipe(
      tap((resp: any) => { localStorage.setItem('token', resp.data.token) })
    )
  }


  confirmacionCorreo( formData: { code: string, email: string } ) {
    const url = `${base_url}/register/verify/${formData.code}`;
    return this.http.get(url, this.headers).pipe( tap((resp:any) => {})  )
  }

  validarToken(): Observable<boolean> {
    const url = `${base_url}/user/show`;
    return this.http.get(url, this.headers).pipe(
      map((resp: any) => {

        if (resp.data == false) {
          return false;
        }

        this.log = true;
        const { name, email, _id } = resp.data;
        this.roles = resp.data.role.name;
        if (resp.data.imagen) {
          const { thumbnail } = resp.data.imagen;
          this.usuarios = new Usuarios(email, name, this.roles, thumbnail, _id);
          return true;
        } else {
          this.usuarios = new Usuarios(email, name, this.roles, '', _id);
          return true;
        }
      }),
      catchError(error => of(false))
    );
  }
  
  checkConfirmationMail() {
    const url = `${base_url}/comprobacion/correo`;
    return this.http.get(url, this.headers).pipe(
      map((resp: any) => { return resp.data })
    )
  }

  generateNewCode(id:string) {
    const url = `${base_url}/generar/codigo/${id}`;
    return this.http.get(url, this.headers).pipe( tap((resp:any) => {} ))
  }
  
  // api/profile
  mostrarPerfil(id: string) {
    const url = `${base_url}/profile/${id}`;
    return this.http.get(url).pipe( tap((resp: any) => { }) )
  }


  // cerrar session 
  logout() { localStorage.removeItem('token') }

  name(id: string) {
    const url = `${base_url}/user/show/${id}`;
    return this.http.get(url, this.headers).pipe( tap((resp: any) => { }) )
  }

  showUser(id: string) {
    const url = `${base_url}/user/${id}`;
    return this.http.get(url, this.headers).pipe( tap((resp: any) => { }) )
  }

  nameUpdate( data: { name: string }, id: string ) {
    const url = `${base_url}/user/update/${id}/name`; 
    return this.http.put(url, data, this.headers).pipe( tap((resp:any) => { }) )
  }

  passUpdate( data: { password: string; new_password: string; confirm_password: string; } ) {
    const url = `${base_url}/change/password`;
    return this.http.post(url, data, this.headers).pipe( tap((resp:any) => { }) )
  }

}