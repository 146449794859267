<div class="w-full">


<!-- canecera de mensajes -->


<div  class="contenedor h-screen overflow-y-scroll pt-32 w-full bg-gray-50 sm:justify-center items-center sm:pt-0">
  
  <div *ngIf="propuesta == ''" class="w-full h-screen px-4">
    <div class="w-full flex justify-center pt-9">
      <img class="w-1/2" src="assets/imagen/general/no-propuestas.png" alt="">
    </div>
    <div class="w-full h-auto flex justify-center pt-8 lg:pt-2 mt-3">
      <p class="text-2xl font-semibold text-gray-700">¡No hay Propuestas aun!</p>
    </div>
    <div class="w-full h-auto flex justify-center mt-4">
      <p class="text-xl font-medium text-gray-700 text-center">Enviale el precio que estas dispuesto a pagar por un servicio o necesidad tuya</p>
    </div>
    <div class="w-full h-auto flex justify-center pt-8">
      <div (click)="crear_propuesta(true)" class="w-auto px-4 h-8 bg-blue-500 rounded-full flex justify-center items-center shadow-lg shadow-red-500/50 cursor-pointer hover:bg-blue-400">
        <p class="text-lg text-white font-semibold pb-1">Crear propuesta</p>
      </div>
    </div>
    
  </div>

  <div  *ngFor="let pro of propuesta" class="block w-full h-auto bg-gray-50 border py-2 ">
    <div class="flex w-full">
      <div class="w-auto flex justify-center items-center px-2"> 
        <div class="w-4 h-4 bg-blue-500 rounded-full">

        </div>
      </div>
      <div class="w-auto pr-2 py-2 cursor-pointer" [routerLink]="['/perfil', pro.sender._id]"> 
        <img *ngIf="pro.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" [src]="pro.sender.imagen.thumbnail" alt="">
        <img *ngIf="!pro.sender.imagen" class="object-cover h-12 w-12 rounded-full shadow-md border-md" src="assets/imagen/general/no-imagen.png" alt="">
       </div>
      <div>
        <p [routerLink]="['/perfil', pro.sender._id]" class="text-base font-semibold text-gray-700 cursor-pointer">{{pro.sender.name}}</p>
        <p class="text-sm text-gray-500 font-semibold -mt-1">Prestador de servicio</p>
        <div [ngClass]="{'caro': pro.status == 'Costoso', 
        'barato': pro.status == 'Barato',
        'bien': pro.status == 'Confirmar',
        'cancelar': pro.status == 'Cancelar',
        'espera': pro.status == 'Espera'}" class="w-3/4 h-6 flex justify-center items-center">
        <div [ngClass]="{'icon_espera': pro.status == 'Espera'}" class="hidden"> 
        <i  class="bx bx-loader-circle text-xl mr-2 hidden"></i>
        </div>
        <div [ngClass]="{'icon_caro': pro.status == 'Costoso'}" class="hidden">
          <i  class='bx bx-sad text-xl mr-2'></i>
        </div>
        <div [ngClass]="{'icon_barato': pro.status == 'Barato'}" class="hidden">
          <i  class='bx bx-happy-beaming text-xl mr-2 hidden'></i>
        </div>
        <div [ngClass]="{'icon_bien': pro.status == 'Confirmar'}" class="hidden">
          <i  class='bx bx-happy-heart-eyes text-xl mr-2 hidden'></i>
        </div>
        <div [ngClass]="{'icon_cancelar': pro.status == 'Cancelar'}" class="hidden">
          <i  class='bx bx-dizzy text-xl mr-2 hidden'></i>
        </div>
        
          <p class="text-base font-semibold">{{pro.status}}</p>
        </div>
      </div>
    </div>
    <div class="flex w-full h-10 mb-4 ">
      <div class="w-1/2 flex justify-center items-center">
        <p class="text-lg font-semibold text-gray-700">Propone:</p>
      </div>
      <div class="w-1/2 flex justify-center items-center">
        <i class='bx bx-dollar text-yellow-500 text-xl font-semibold'></i>
        <p class="text-xl font-semibold text-gray-700">{{pro.value}}</p>
      </div>
    </div>

    <div [ngClass]="{'estado': pro.status != 'Espera', 'propuesta_mia' : user == pro.sender._id}" class="w-full h-10 ">
      <!-- estados -->
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
       <div ngxSlickItem class="w-80 flex" (click)="selec_estado(pro._id, 'Costoso','costoso')">
          <div class="w-36 h-8 bg-red-300 rounded-full flex justify-center items-center shadow-lg shadow-red-500/50 cursor-pointer">
            <i class='bx bx-sad text-xl text-red-600 mr-2'></i>
            <p class="text-lg text-red-600 font-semibold pb-1">Costoso</p>
          </div>
        </div>
         <div ngxSlickItem class="w-80 flex" (click)="selec_estado(pro._id,'Barato', 'barato')" >
          <div class="w-36 h-8 bg-blue-200  rounded-full flex justify-center items-center shadow-lg shadow-blue-400/50 cursor-pointer">
            <i class='bx bx-happy-beaming text-xl text-blue-500 mr-2'></i>
            <p class="text-lg text-blue-500 font-semibold pb-1">Barato</p>
          </div>
        </div>
        <div ngxSlickItem class="w-80 flex" (click)="selec_estado(pro._id,'Confirmar', 'confirmar')">
          <div class="w-36 h-8 bg-green-200 rounded-full flex justify-center items-center shadow-lg shadow-green-500/50 cursor-pointer">
            <i class='bx bx-happy-heart-eyes text-xl text-green-500 mr-2'></i>
            <p class="text-lg text-green-500 font-semibold pb-1">Confirmar</p>
          </div>
        </div>
        <div ngxSlickItem class="w-80 flex" (click)="selec_estado(pro._id,'Cancelar', 'cancelar')" >
          <div class="w-36 h-8 bg-gray-300 rounded-full flex justify-center items-center shadow-lg shadow-gray-500/50 cursor-pointer">
            <i class='bx bx-dizzy text-xl text-gray-600 mr-2'></i>
            <p class="text-lg text-gray-600 font-semibold pb-1">Cancelar</p>
          </div>
        </div>
        
        
       </ngx-slick-carousel>

    </div>

  </div>

</div>

</div>

