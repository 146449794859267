<div class="h-20 w-full lg:hidden"></div>

<div *ngIf="!isLoadingUp" class="container mx-auto xl:my-0 mt-5 lg:mt-24 mb-24">
    <div class="mx-5 xl:mx-0">
        <div class="flex flex-col items-center justify-center content-center lg:h-screen">
            <div class="w-full mb-5">
                <h1 class="text-3xl font-bold text-center text-aply-100">
                    Creación de servicios
                </h1>
            </div>

            <h2 class="text-xl text-center text-dark-400 mx-8">
                Sube una o varias fotos de tu servicio, así los clientes sabrán que es lo que haces.
            </h2>

            <div class="w-full md:w-4/5 xl:w-1/2">
                <div class="w-full mr-2 order-2">
                    <div class="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-1 mt-2 h-30">

                        <div *ngFor="let i of imagenes; let j = index" class="relative col-span-1">
                            <span (click)="delete(j)" class="absolute top-1.5 right-1 bg-white rounded-full w-7 h-7  flex shadow-xl cursor-pointer">
                                <i class="bx bx-trash p-1.5 flex content-center items-center text-md text-dark-400"></i>
                            </span>
                            <img [src]="i.url" alt="" class="object-center object-cover w-full h-30 rounded-2xl mb-1">
                        </div>
                        <div *ngIf="count <= 2" class="col-span-1 h-30" [ngClass]="isLoading ? 'animate-pulse' : ''">
                            <label class="cursor-pointer hover:shadow-lg hover:bg-white rounded-2xl  transition duration-500 ease-in-out h-30">
                                <div class="hover:border-transparent hover:shadow-xs w-full h-full py-12 lg:py-10 xl:py-16  rounded-lg border-4 border-dashed border-gray-200 h-30">

                                    <div class="h-full w-full flex flex-col items-center justify-center items-center h-30">
                                        <span *ngIf="!imgTemp" class="icon-img text-3xl lg:text-5xl text-dark-400 -mt-2"></span>
                                        <div *ngIf="!imgTemp"  class="pointer-none text-xs lg:text-sm font-bold text-dark-300 mt-1 text-center">Nueva imagen</div>
                                    </div>

                                    <input type="file" multiple="true" class="hidden" id="file-upload" (change)="cambiarImagen($event.target.files[0])">
                                </div>  
                            </label>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container mx-auto lg:hidden mb-5">
                <div class="mx-5 mt-3">
                    <div class="flex justify-between">
                        <button *ngIf="count <= 0" (click)="omitir()" class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <h2 class="text-base">Omitir</h2>
                        </button>
                        <a [routerLink]="['/perfil', idUser]" class="btn-sm focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">
                            <h2 class="text-base">Cancelar</h2>
                        </a>
                        <button (click)="siguiente()" class="btn-sm focus:ring text-white hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">
                            <i class='bx bx-chevron-right text-xl'></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-center my-10 space-x-3 hidden lg:block">
                <a [routerLink]="['/perfil', idUser]" class="btn-1 focus:ring text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300">Cancelar</a>
                <button *ngIf="count <= 0" (click)="omitir()" class="btn-1 focus:ring text-aply-100 hover:text-white bg-white hover:bg-dark-200 active:bg-dark-100 focus:ring-dark-100">Omitir</button>
                <button (click)="siguiente()" class="btn-1 focus:ring text-white  hover:bg-blue-500 active:bg-blue-500 focus:ring-blue-300 bg-aply-100">Siguiente</button>
            </div>
        </div>
    </div>

</div>

<div class="justify-center items-center" *ngIf="isLoadingUp">
    <div class="flex justify-center items-center">
        <div class="loadingio-spinner-rolling-efvpdb46s7j"><div class="ldio-yjpgvb4fxr7">
        <div></div>
        </div></div>      
    </div>
    <div>
        <h1 class="text-3xl font-bold text-center text-aply-100">Ya casi terminamos! Espera, por favor...</h1>
    </div> 
</div>

