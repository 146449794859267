import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})

export class ContactosComponent implements OnInit {

  visible: boolean = false;
  breakpoint: number = 1000;

  public conver;
  public conversacion;
  public user:any;
  public id:any;

  public _converation = []

  view:string = 'chat';
  onlineUsers: any;
  search: string;
  contactos: any[];
  constructor(
    private router: Router,
    private chat: ChatService
  ) { }

  ngOnInit() {

    this.chat.onLineUsers.subscribe(
      resp => {
        this.onlineUsers = resp.data
      }
    )
    
    this.i();
    this.conversaciones();

  }

  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
      }
    )
  }

  conversaciones(){
    this.chat.conversaciones().subscribe(
      resp => {
        this.conver = resp;
        this.conversacion = this.conver.conversations;
        this.onChatRestructurar(this.conversacion)
      }
    )
  }


  onChatRestructurar(valor){

    //cambiar esto ´por map, se vera mas bonito
      valor.forEach(element => {
        var _id = element._id
        element.members.forEach(element => {

          console.log('usuario aqui ', element)
          if(element._id != this.user){
            if(element.imagen){
              var _image = element.imagen
            }else{
              _image = '' 
            }
            this._converation.push(
              {
                id : element._id,
                name: element.name,
                image: _image,
                id_conversacion: _id,
                createdAt: element.createdAt
              }
            )

            this.contactos = this._converation
          }
        });
    });

  }

  id_conversacion(valor:any){

    this.router.navigate(['chat/mensajes/'+valor.id_conversacion+'/'+valor.id]);

  }

  onResize(event) {
    const w = event.target.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
      console.log('pantalla esta grande');
      this.router.navigate(['web/chat']);
    } else {
      // whenever the window is less than 768, hide this component.
      console.log('pantalla esta pequeña');
      this.visible = false;
    }
  }

  onSearch(valor:string){
    this.search = valor
  }

}
