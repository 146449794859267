export class VerificacionCode{

    constructor(
        public email: string,
        public code: string, 
    ){}

    get emails() {
        return this.email;
    }

    get codigo() {
        return this.code;
    }
       
}