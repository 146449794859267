import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/core/services/chat.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import { io } from "socket.io-client";
import { on } from 'events';
import Swal from 'sweetalert2';
@Component({
  selector: 'web-app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  //io recibe como parámetro la dirección del servidor y el puerto
  socket = io('http://127.0.0.1:3333');
  //socket = io('http://core.d3una.com:3000');
  

  public id:any;
  public mensaje:any;
  public user:string;
  public contacto:any;

  public formSubmitted = false;

  public imagenSubir: File;
  public imgTemp: any = null;

  public subir:boolean = false;

  public messageForm = this.fb.group({
    conversation_id: ['', [Validators.required], Validators.minLength(3)],
    text: ['', [Validators.required, Validators.minLength(3)] ]
  });
  miembros: any;
  id_user: any;

  
  constructor(
    private chat: ChatService,
    private usuario: UsuarioService,
    private activerouter: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {

    this.id      = this.activerouter.snapshot.paramMap.get('id');
    this.id_user = this.activerouter.snapshot.paramMap.get('user');

    this.chat.id_conversacion.subscribe(
      data => {
        this.id = data.data._id;
        this.contacto = data.data.members;
        this.members();
        this.mensajes(this.id);
        console.log("llego id de contactos", data.data.members);
      }
    )
    this.members();
    this.mensajes(this.id);

    this.i();

    this.chat.push_mensaje.subscribe(
      data => {
        console.log("mensaje emitido", data);
        
        if(data.data.conversationId == this.id){

          if(data.data.images != ''){
            setTimeout(() => {
              this.mensaje.push(data.data);
              this.scroll();
              this.Mensaje_sound();
            }, 8500);
          }else{
            this.mensaje.push(data.data);
            this.scroll();
            this.Mensaje_sound();
          }
        }
      }
    )

    

  }

  members(){

    this.chat.members(this.id).subscribe(
      resp => {
        this.miembros = resp.members;
        console.log("miembros", this.miembros);
      }
    )
  }


  i(){

    this.chat.user().subscribe(
      resp => {
        this.user = resp.data._id
        console.log("usuario logueado", this.user);
        
       
      }

    )
   
  }

  mensajes(valor){

    this.socket.emit('new:user', this.user );

    this.socket.on('news', (data) => {
      console.log(data)
    });
    
    this.chat.mensajes(valor).subscribe( 
      data => {
        this.mensaje = data.conversation;
        console.log("mensajes", data.conversation);
      } )

      this.scroll();
  }

  onSend(){

    this.chat.send(this.messageForm.value).subscribe(
      data => {
        this.mensaje.push(data.message);
        console.log("mensajes ",this.mensaje);
        this.messageForm.controls['text'].reset();
        console.log("return de sms", data);

      }
    )

    this.scroll();
  }

  cambiarImagen(file: File) {
    this.subir = true;
    console.log("subiendo foto");
    this.imagenSubir = file;

    if (!file) { return this.imgTemp = null }
    
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.imgTemp = reader.result;
      
    }
  }

  subirImagen() {

    this.formSubmitted = true;
    
    if (this.imagenSubir == null) {
      
      return;
    }
    let id: any = this.activerouter.snapshot.paramMap.get('id');
    this.chat.uploadImagen(this.imagenSubir, id, this.messageForm.value.text )
      .then(resp => {
          console.log(resp);

          if (resp.message) {
            setTimeout(() => {
              this.mensaje.push(resp.message);
              this.scroll();
             }, 8500);
            console.log("mensajes ",this.mensaje);
            this.imgTemp = null;
            this.imagenSubir = null;
            this.subir = false;
            this.Toast.fire({ icon: 'success', title: '' + 'imagen subio' }) 
            this.messageForm.controls['text'].reset();
          } else {
            this.imgTemp = null;
            this.imagenSubir = null;
            this.Toast.fire({ icon: 'error', title: 'Error, ' + resp.danger })
            console.log("imag",resp.danger );
          }
        }
      )    
  }

  cerrar(){

    this.subir = false;
  }

  scroll(){
    setTimeout(() => {
      //get container element
    var container = document.getElementById('sms');
    //scroll down
    container.scrollTop = container.scrollHeight;
     }, 900);
  }

  Mensaje_sound() {
    const audio = new Audio('assets/audio/mensaje.mp3');
    audio.play();
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

}
