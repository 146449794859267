<div class="col-span-1 bg-white shadow-md rounded-2xl border border-opacity-0 mb-5">
    <div class="p-3">
        <div class="flex flex-col space-y-3" [title]="s.name">
            <div class="flex space-x-4 pb-3">
                <div class="w-40 md:w-44">
                    <a [routerLink]="['/servicios', s._id]">
                        <img *ngIf="s.images != ''" [src]="s.images[0].thumbnail" alt="" class="object-center object-cover w-40 md:w-44 h-36 rounded-2xl border flex-1">
                        <img *ngIf="s.images == ''" src="assets/imagen/general/no-imagen.png" title="no hay imagen" class="object-center object-cover w-40 md:w-44 h-36 rounded-2xl border flex-1">
                    </a>
                </div>
                <div class="flex-1">
                    <a [routerLink]="['/servicios', s._id]">
                        <h3 [title]="s.name" class="font-bold text-dark-400 text-md md:text-xl">
                            {{s.name | slice:0:20}}{{ (s.name.length> 20) ? '...': '' }}
                        </h3>
                    </a>
                    <small class="text-dark-300 font-medium mb-4"> 
                    {{s.subcategory.name}}
                  </small> <br>
                    <small class="text-aply-100 font-medium mb-4">
                    <i class="icofont-location-pin"></i> 
                    {{s.town.name}}
                  </small>
                    <rating-start [quality]="s.qualification" [id_ref]="s._id" [type_model]="'Service'"></rating-start>
                </div>
            </div>


            <p class="text-dark-300">
                {{s.description | slice:0:120}}{{ (s.description.length> 120) ? '...': '' }} <a [routerLink]="['/servicios', s._id]" class="italic font-bold text-sm text-dark-300 hover:underline cursor-pointer">Leer mas</a>
            </p>

            <div>
                <div class="py-1 relative min-w-full">
                    <div class="h-2 bg-gray-200 rounded-full">
                        <div class="absolute h-2 rounded-full bg-aply-100 w-0" style="width: 75.1935%; left: 11.2903%;"></div>
                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 11.2903%;"></div>
                        <div class="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer" unselectable="on" onselectstart="return false;" style="left: 85.4839%;"></div>
                        <div class="absolute text-gray-800 text-md  ml-1 bottom-0 left-0 -mb-7">${{s.min_price}}
                        </div>
                        <div class="absolute text-gray-800 text-md  mr-1 bottom-0 right-0 -mb-7">${{s.max_price}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between pt-5 text-sm font-semibold">
                <rating-like [likes]="s.like" [id_ref]="s._id" [type_model]="'Service'"></rating-like>
                <div class="flex items-center content-center">
                    <i class="icon-msg-espera-alt text-aply-100 mr-1"> </i> {{s.review.length}} Reseña
                </div>
            </div>
        </div>
    </div>

    <div class="p-3 bg-dark-100 rounded-b-2xl text-dark-300">
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <a (click)="profileOfLogin(s.profile.user._id)" class="cursor-pointer">
                    <img *ngIf="!s.profile.user.imagen" src="assets/imagen/general/perfil-1.svg" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                    <img *ngIf="s.profile.user.imagen" [src]="s.profile.user.imagen.thumbnail" alt="perfil" class="object-cover object-center w-10 h-10 rounded-full">
                </a>
                <div class="ml-2">
                    <a (click)="profileOfLogin(s.profile.user._id)">
                        <h4 class="font-bold capitalize text-md cursor-pointer">{{s.profile.user.name}}</h4>
                    </a>
                    <h6 class="font-semibold capitalize text-xs">{{s.profile.type}}</h6>
                </div>
            </div>
            <div class="text-xs font-normal uppercase text-right">
                {{s.updatedAt | date}}
            </div>
        </div>
    </div>


</div>