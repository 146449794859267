
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from '../modulos.component';
import { DetalleComponent, NecesidadesComponent } from './page';




const routes: Routes = [
  { 
		path: 'necesidades',
		component: ModulosComponent,
		children: [
			{path:'', component: NecesidadesComponent },
			{path:':id', component: DetalleComponent },
		  ]
	},
];


@NgModule({
	imports: [
    RouterModule.forChild(routes),
  ],
	exports: [RouterModule]
})


export class NecesidadesRoutes { }
