import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Img } from 'src/app/core/interfaces/images.interface';
import { ciudadI, deptI } from 'src/app/core/interfaces/ubicacion.form.interfaces';
import { ModalService } from 'src/app/core/services/modal.service';
import { RecomendacionesService } from 'src/app/core/services/recomendaciones.service';
import { UbicacionService } from 'src/app/core/services/ubicacion.service';
import { UsuarioService } from 'src/app/core/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'recomendaciones',
  templateUrl: './recomendaciones.component.html',
  styleUrls: ['./recomendaciones.component.css']
})
export class RecomendacionesComponent implements OnInit {

  @Input() log: boolean;

  public selectedDept: deptI = { _id: "", name: "" };
  public deptItems: deptI;
  public muniItems: ciudadI;

  public id_user: string;
  public id_ref: string;

  public num: number = 1;
  public recomendacions: any;
  public activar = false;

  public formSubmitted = false;
  public tipForms = this.fb.group({ message : ['',  [ Validators.required, Validators.minLength(10) ]] });

  public images: any;
  public count: number = 0;
  public imagenes: Img[] = [];
  public isLoading: boolean = false;
  public isLoadingUp:boolean = false;

  public newRecomendationForm!: FormGroup;
  name: any;
  user: any;

  constructor(
    private fb: FormBuilder,
    private recomendaService: RecomendacionesService,
    private router: Router,
    private modalService: ModalService,
    private usuarioService: UsuarioService,
    private ubicacionService: UbicacionService,
  ) { 
    
    if (this.log == true) { 
      this.name = usuarioService.usuarios._ids
      this.id_user = usuarioService.usuarios._ids; }
    
  }

  ngOnInit() {

   
    

    this.listadoDept();
    this.newRecomendationForm = this.createFormRecomendationBuilder( this.fb )
    //this.listadoRecomendaciones();
    this.listRecomendation()
    this.i()

  }


  validation_create_recomendacion = {

    'message': [
      { type: 'required',  message: 'descripbe la necesidad' },
      { type: 'minlength', message: 'El nombre debe contener al menos 3 caracteres' },
    ],

    'municipality': [
      { type: 'required', message: 'Selecciona la ciudad' },
    ],

  }

  createFormRecomendationBuilder ( formBuilder: FormBuilder ) {
    return formBuilder.group( {
      message: ['', [Validators.required,  Validators.minLength( 3 )]],
      municipality: ['', [Validators.required]],
    } )
  
  }

  i(){
    this.usuarioService.user().subscribe(
      resp => { this.user = resp.data }
    )
    //emitir un evento del nuevo usuario con su id
    //this.socket.emit('new:user', this.user );
  }
  
  resetearListado(valor: boolean) {
    if (valor == true) { this.listadoRecomendaciones(); }
  }
  
  listadoRecomendaciones() {
    this.recomendaService.allRecomendaciones().subscribe(
      resp => { this.recomendacions = resp.data.recommendations;         }
    )
  }

  onTip() {
    this.formSubmitted = true;
    if (this.tipForms.invalid) { return }
    this.recomendaService.crearRecomendacion(this.tipForms.value).subscribe(
      resp => {
        this.Toast.fire({ icon: resp.alert, title: resp.msg })
        if (resp.alert == 'success') {
          this.listadoRecomendaciones();
          this.closeModal('crear-tip')
        }
      }
    )
  }

  openModal(id: string) {  
    if (this.log == true) { this.modalService.open(id); }
    else { this.onModal(); }    
  }

  closeModal(id: string) { this.modalService.close(id); }

  onModal(){
    Swal.fire({
      imageUrl: 'assets/imagen/logo/logo-blue.png',
      imageHeight: 100,
      title: 'Veo que no has iniciado sesion!',
      text: `Para pedir una recomendación necesitas una cuenta en D3Una.com`,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: `Registrarme`,
      confirmButtonColor:'#2784FE',
      denyButtonText: `Iniciar Sesion`,
      denyButtonColor: '#2784FE',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/register']);
      }else if(result.isDenied){
        this.router.navigate(['/login']);
      }
    })
  }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  campoNoValido(campo: string): boolean {
    if (this.tipForms.get(campo).invalid && this.formSubmitted) {
      return true;
    } else{
      return false;
    }
  }

  irAlPerfil(id:string) {
    if (this.log == true) { this.router.navigate(['/perfil', id]) }
    else { this.onModal(); }
  }


  cambiarImagen(file: File) {
    //cargando imagen
    this.isLoading = true;
    //capturar imagen cuando llega 
    const imagenci: File = file;

    //si la imagen existe entonces
    if (imagenci) {

      //TODO: leer la imagen
      const reader = new FileReader();
      reader.readAsDataURL(file);

      //TODO: cuando la imagen es leida entonces...
      reader.onload = (_event) => {

        //TODO: crear un objeto con el nombre de la imagen, el archivo y el resultado de la lectura
        let imagencita: Img = {
          filename    : imagenci.name,
          file        : imagenci,
          url         : reader.result
        }

        //TODO: Hacer push de la imagen al array de imagenes y aumentar el contador
        this.imagenes.push(imagencita);
        this.count += 1;
        this.isLoading = false;
      }
    }
  }

  delete(index) {
    //TODO: Eliminar una imagen del array de imagenes por el indice y descontar del contador
    this.imagenes.splice(index, 1);
    this.count -= 1;
  }


  createRecomendation() {

    if(this.newRecomendationForm.invalid){

      return;
    }

    if (this.imagenes == null) {
      
      return;
    }
 
    this.recomendaService.CreateConversation(this.imagenes, this.newRecomendationForm.value.municipality, this.newRecomendationForm.value.message ).subscribe( 
      resp => { 

        this.recomendacions.unshift(resp.data)
        this.newRecomendationForm.reset();
        this.imagenes == null;
        this.closeModal('crear-tip')
    
    });
         
  }


  listRecomendation()
  {
    this.recomendaService.listRecomendacion().subscribe(
      resp => {
        this.recomendacions = resp.data.recommendations.reverse()

        
        console.log("list recomedacion ",this.recomendacions )
      }
    )
  }

  // listadoDepartamento
  listadoDept() {
    this.ubicacionService.listadoDepartamento().subscribe(
      resp => { this.deptItems = resp.data; }
    )
  }

  listadoMunicipio(id: any): void{
    this.ubicacionService.listadoMunicipio(id).subscribe(
      resp => { this.muniItems = resp.data.municipalities; }
    )
  }

}
